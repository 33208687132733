import React, { memo, useContext, useEffect, useState } from "react";
import {
  Badge,
  Box,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { MdRemove, MdAdd, MdDelete } from "react-icons/md";
import { EntryMerchandisesContext, InputCurrency } from "components";
import { currency } from "lib";

const Item = memo(({ product, amount, defaultAmount, quantity, max }) => {
  const { searchKey, handleUpdateEntry, handleDeleteEntry, handleChangeAmount, isAmountEditable, isClosed } =
    useContext(EntryMerchandisesContext);
  const [innerAmount, setInnerAmount] = useState(amount);

  useEffect(() => {
    setInnerAmount(amount);
  }, [amount]);

  return (
    <HStack justify="space-between">
      <HStack>
        {!isClosed && (
          <IconButton icon={<Icon as={MdDelete} />} colorScheme="red" variant="ghost" onClick={() => handleDeleteEntry(product)} />
        )}
        <Box>
          <Text fontSize="xs">
            NID {product.nid} | SKU {product.sku}
          </Text>
          <Text fontWeight="bold">{product.name}</Text>
          <Text fontSize="xs">{_.join(_.map(product.attrs, "name"), ",")}</Text>
          {isAmountEditable ? (
            <FormControl mt="5px">
              <InputGroup>
                <InputLeftElement>
                  <IconButton
                    size="sm"
                    colorScheme={innerAmount !== defaultAmount ? "purple" : "gray"}
                    icon={<Text>R$</Text>}
                    onClick={() => handleChangeAmount(product, defaultAmount)}
                  />
                </InputLeftElement>
                <Input
                  as={InputCurrency}
                  value={innerAmount}
                  onChange={(amount) => setInnerAmount(amount)}
                  onBlur={() => handleChangeAmount(product, innerAmount)}
                  isDisabled={!isAmountEditable}
                />
                <InputRightAddon fontSize="xs" whiteSpace="nowrap">
                  x {quantity} (<strong>{currency.format(amount * quantity)}</strong>)
                </InputRightAddon>
              </InputGroup>
            </FormControl>
          ) : (
            <Text fontSize="xs" whiteSpace="nowrap">
              {currency.format(amount)} x {quantity} (<strong>{currency.format(amount * quantity)}</strong>)
            </Text>
          )}
        </Box>
      </HStack>
      {isClosed ? (
        <Text fontWeight="bold">{quantity?.toLocaleString?.()}</Text>
      ) : (
        <HStack>
          <IconButton
            icon={<Icon as={MdRemove} />}
            colorScheme="main"
            variant="ghost"
            onClick={() => handleUpdateEntry(product[searchKey], -1)}
            isDisabled={quantity <= 1}
          />
          <VStack alignItems="stretch">
            <Input
              as={InputCurrency}
              precision="0"
              textAlign="center"
              value={quantity}
              onChange={(value) => handleUpdateEntry(product[searchKey], 0, value)}
            />
            {_.isNumber(max) && (
              <Badge textAlign="center" colorScheme={quantity > max ? "red" : "gray"}>
                {max.toLocaleString()}
              </Badge>
            )}
          </VStack>
          <IconButton
            icon={<Icon as={MdAdd} />}
            colorScheme="main"
            variant="ghost"
            onClick={() => handleUpdateEntry(product[searchKey], 1)}
          />
        </HStack>
      )}
    </HStack>
  );
});

export default Item;
