import React, { useMemo, useContext, useEffect, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdMoneyOff, MdRefresh, MdRemove } from "react-icons/md";
import { RiPercentFill } from "react-icons/ri";
import { currency, percent } from "lib";
import { DatePicker, BoxData, InputCurrency } from "components";
import { PackSettlementsDetailsContext } from "../index";
import { TbExternalLink } from "react-icons/tb";

const Commission = (props, ref) => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const {
    formData,
    setFormData,
    formErrors,
    wallets,
    isLoadingWallets,
    refreshWallets,
    paymentCategories,
    isLoadingPaymentCategories,
    refreshPaymentCategories,
  } = useContext(PackSettlementsDetailsContext);
  const isPaid = useMemo(() => formData.agentCommission?.status === "paid", [formData.agentCommission?.status]);

  const handleChangeFormData = useCallback(
    (obj) => {
      setFormData((state) => ({ ...state, agentCommission: { ...state.agentCommission, ...obj } }));
    },
    [setFormData]
  );

  useEffect(() => {
    if (!_id) {
      const totalAgentCommissionAmount = formData.packsAgentCommissionAmount + formData.paymentsAgentCommissionAmount;
      setFormData((state) => ({
        ...state,
        totalAgentCommissionPercentage: 1,
        defaultTotalAgentCommissionAmount: totalAgentCommissionAmount,
        totalAgentCommissionAmount,
      }));
    }
  }, [_id, setFormData, formData.packsAgentCommissionAmount, formData.paymentsAgentCommissionAmount]);

  useEffect(() => {
    if (!_id) {
      setFormData((state) => {
        const totalAgentCommissionAmount = formData.totalAgentCommissionPercentage * state.defaultTotalAgentCommissionAmount;
        return { ...state, totalAgentCommissionAmount };
      });
    }
  }, [_id, setFormData, formData.totalAgentCommissionPercentage]);

  return (
    <>
      <HStack justifyContent="space-between" mb={6}>
        <HStack>
          <Center bg="red.100" w="25px" h="25px" borderRadius="lg">
            <Icon as={MdRemove} color="red.500" />
          </Center>
          <Heading size="sm">Comissão do representante</Heading>
        </HStack>
        <Button
          mt="5px"
          size="sm"
          variant="outline"
          rightIcon={<Icon as={TbExternalLink} />}
          onClick={() => navigate(`/payments/edit/${formData.commissionPayment}`)}
          isDisabled={!_id || !formData.commissionPayment}
        >
          visitar comissão gerada
        </Button>
      </HStack>
      <Box position="relative">
        {!_id && (
          <>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={3}>
                <FormControl isRequired={true} isInvalid={formErrors["agentCommission.status"]}>
                  <FormLabel fontSize="sm">Status</FormLabel>
                  <Select
                    value={formData.agentCommission?.status ?? ""}
                    onChange={({ target }) => handleChangeFormData({ status: target.value })}
                  >
                    <option value="">--Selecione</option>
                    <option value="paid">Pago</option>
                    <option value="pending">Pendente</option>
                  </Select>
                  <FormErrorMessage>{formErrors["agentCommission.status"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl isRequired={true} isInvalid={formErrors["agentCommission.paymentMethod"]}>
                  <FormLabel fontSize="sm">Método de pagamento</FormLabel>
                  <Select
                    value={formData.agentCommission?.paymentMethod ?? ""}
                    onChange={({ target }) => handleChangeFormData({ paymentMethod: target.value })}
                  >
                    <option value="">--Selecione</option>
                    <option value="pix">Pix</option>
                    <option value="credit_card">Cartão de crédito</option>
                    <option value="debit_card">Cartão de débito</option>
                    <option value="boleto">Boleto</option>
                    <option value="deposit">Depósito bancário</option>
                    <option value="cash">Dinheiro</option>
                  </Select>
                  <FormErrorMessage>{formErrors["agentCommission.paymentMethod"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl isRequired={true} isInvalid={formErrors["agentCommission.wallet"]}>
                  <FormLabel fontSize="sm">Carteira</FormLabel>
                  <HStack>
                    <Select
                      value={formData.agentCommission?.wallet ?? ""}
                      onChange={({ target }) => handleChangeFormData({ wallet: target.value })}
                    >
                      <option value="">--Selecione</option>
                      {_.map(wallets?.data, (o) => (
                        <option key={o._id} value={o._id}>
                          {o.title}
                        </option>
                      ))}
                    </Select>
                    <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingWallets} onClick={refreshWallets} />
                  </HStack>
                  <FormErrorMessage>{formErrors["agentCommission.wallet"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl isRequired={true} isInvalid={formErrors["agentCommission.paymentCategory"]}>
                  <FormLabel fontSize="sm">Categoria de pagamento</FormLabel>
                  <HStack>
                    <Select
                      value={formData.agentCommission?.paymentCategory ?? ""}
                      onChange={({ target }) => handleChangeFormData({ paymentCategory: target.value })}
                    >
                      <option value="">--Selecione</option>
                      {_.map(paymentCategories.expense, (o) => (
                        <option key={o._id} value={o._id}>
                          {o.title}
                        </option>
                      ))}
                    </Select>
                    <IconButton
                      variant="outline"
                      icon={<Icon as={MdRefresh} />}
                      isLoading={isLoadingPaymentCategories}
                      onClick={refreshPaymentCategories}
                    />
                  </HStack>
                  <FormErrorMessage>{formErrors["agentCommission.paymentCategory"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>

            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={4}>
                <FormControl isRequired={true} isInvalid={formErrors["agentCommission.issueDate"]}>
                  <FormLabel fontSize="sm">Data de emissão</FormLabel>
                  <DatePicker
                    selected={formData.agentCommission?.issueDate}
                    onChange={(issueDate) => handleChangeFormData({ issueDate: moment(issueDate).startOf("day").toDate() })}
                    dateFormat="d, MMMM yyyy"
                  />
                  <FormErrorMessage>{formErrors["agentCommission.issueDate"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl isRequired={true} isInvalid={formErrors["agentCommission.dueDate"]}>
                  <FormLabel fontSize="sm">Data de vencimento</FormLabel>
                  <DatePicker
                    selected={formData.agentCommission?.dueDate}
                    onChange={(dueDate) => handleChangeFormData({ dueDate: moment(dueDate).startOf("day").toDate() })}
                    dateFormat="d, MMMM yyyy"
                  />
                  <FormErrorMessage>{formErrors["agentCommission.dueDate"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl isRequired={isPaid} isInvalid={formErrors["agentCommission.paymentDate"]}>
                  <BoxData
                    label="Data de pagamento"
                    value={isPaid ? moment(formData.agentCommission?.paymentDate).format("D, MMMM yyyy") : "-"}
                  />
                  <FormErrorMessage>{formErrors["agentCommission.paymentDate"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </>
        )}

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.defaultTotalAgentCommissionAmount}>
              <BoxData label="Valor sugerido da comissão" value={currency.format(formData.defaultTotalAgentCommissionAmount || 0)} />
              <FormErrorMessage>{formErrors.defaultTotalAgentCommissionAmount}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={4}>
            {_id ? (
              <FormControl isRequired={true} isInvalid={formErrors.totalAgentCommissionPercentage}>
                <BoxData label="% aplicada da comissão" value={percent.format(formData.totalAgentCommissionPercentage || 0)} />
                <FormErrorMessage>{formErrors.totalAgentCommissionPercentage}</FormErrorMessage>
              </FormControl>
            ) : (
              <FormControl isRequired={true} isInvalid={formErrors.totalAgentCommissionAmount}>
                <FormLabel fontSize="sm">% aplicada da comissão</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={(formData.totalAgentCommissionPercentage ?? 0) * 100}
                    onChange={(value) => setFormData((state) => ({ ...state, totalAgentCommissionPercentage: value / 100 }))}
                    isDisabled={!isPaid}
                  />
                  <InputRightElement>
                    <IconButton
                      size="sm"
                      variant={formData.totalAgentCommissionPercentage === 1 ? "outline" : "solid"}
                      colorScheme={formData.totalAgentCommissionPercentage === 1 ? "gray" : "main"}
                      icon={<Icon as={RiPercentFill} />}
                      onClick={() => setFormData((state) => ({ ...state, totalAgentCommissionPercentage: 1 }))}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formErrors.totalAgentCommissionPercentage}</FormErrorMessage>
              </FormControl>
            )}
          </GridItem>
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.totalAgentCommissionAmount}>
              <BoxData label="Valor final da comissão" value={currency.format(formData.totalAgentCommissionAmount || 0)} />
              <FormErrorMessage>{formErrors.totalAgentCommissionAmount}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        {formData.defaultTotalAgentCommissionAmount === 0 && (
          <Center position="absolute" left="0" top="0" w="100%" h="100%">
            <Box position="absolute" left="0" top="0" w="100%" h="100%" _light={{ bg: "white" }} _dark={{ bg: "gray.800" }} opacity="0.9" />
            <VStack spacing="0" zIndex="1">
              <Icon as={MdMoneyOff} boxSize="60px" mb="10px" />
              <Heading size="md" textAlign="center">
                Nenhum valor aplicável
              </Heading>
              <Text textAlign="center">Não existem valores aplicáveis para a geração de comissão do representante.</Text>
            </VStack>
          </Center>
        )}
      </Box>
    </>
  );
};

export default Commission;
