import React, { useContext, useRef, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import { currency, translator, percent, masks } from "lib";
import { AuthContext } from "AuthProvider";
import { MdClose, MdPrint } from "react-icons/md";
import PacksDetailsContext from "./context";

const Row = ({ children, ...props }) => {
  return (
    <Table size="sm" borderCollpase="collapse" borderWidth="1px" borderColor="black" borderBottomWidth="0" {...props}>
      <Tbody>{children}</Tbody>
    </Table>
  );
};

const Column = ({ children, ...props }) => {
  return (
    <Td borderWidth="1px" borderColor="black" borderBottomWidth="0" p="4px" {...props}>
      {children}
    </Td>
  );
};

const Container = ({ children, isOpen, onClose }) => {
  const { data, dealerCommission } = useContext(PacksDetailsContext);
  const { colorMode } = useColorMode();
  const colorScheme = useMemo(() => (colorMode === "light" ? "blackAlpha" : "whiteAlpha"[colorMode]));
  const bodyRef = useRef();

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="flex-end" position="fixed" top="0" left="0" w="100%" py="10px" px="20px">
          <ReactToPrint
            content={() => bodyRef.current}
            trigger={() => <IconButton colorScheme={colorScheme} icon={<Icon as={MdPrint} />} />}
          />
          <IconButton colorScheme={colorScheme} icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <ModalBody ref={bodyRef}>
          <Row bg="blackAlpha.100">
            <Column pl="20%">
              <Heading size="md" textAlign="center">
                {data?.isLoad ? "Detalhamento da carga" : "Detalhamento do pacote"}
              </Heading>
            </Column>
            <Column w="20%" whiteSpace="nowrap">
              <Text fontSize="xs">Data de impressão</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {moment().format("DD/MM/YYYY [às] HH:mm:ss")}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text fontSize="xs">NID</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.nid}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Tipo</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.isLoad ? "Carga" : "Pacote"}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Atribuição</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {translator(data?.assignment)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Status</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {translator(data?.status)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Tipo de preço</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {translator(data?.priceType)}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text fontSize="xs">Representante</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.agent?.name ?? "-"}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Revendedor</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.dealer?.name ?? "-"}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text fontSize="xs">Previsão de acerto</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.predictedSettlementDate ? moment(data?.predictedSettlementDate).format("DD/MM/YYYY") : "-"}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Despachado em</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.dispatchedAt ? moment(data?.dispatchedAt).format("DD/MM/YYYY") : "-"}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Acertado em</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.settledAt ? moment(data?.settledAt).format("DD/MM/YYYY") : "-"}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Fechado em</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.closedAt ? moment(data?.closedAt).format("DD/MM/YYYY") : "-"}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text fontSize="xs">Desempenho</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {percent.format(data?.performance ?? 0)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Valor atual</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {currency.format(data?.currentAmount ?? 0)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Qtd atual</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {(data?.currentQuantity ?? 0).toLocaleString()}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Valor carregado</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {currency.format(data?.loadedAmount ?? 0)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Qtd carregada</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {(data?.loadedQuantity ?? 0).toLocaleString()}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Valor devolvido</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {currency.format(data?.unloadedAmount ?? 0)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Qtd devolvida</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {(data?.unloadedQuantity ?? 0).toLocaleString()}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text fontSize="xs">Valor recebido</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {currency.format(data?.receivedAmount ?? 0)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Valor restante</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {currency.format(data?.remainingAmount ?? 0)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Comissão do rev. (R$)</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {currency.format(dealerCommission.amount ?? 0)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Comissão do rev. (%)</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {percent.format(dealerCommission.percentage ?? 0)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Comissão do rep. (R$)</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {currency.format(data?.agentCommissionAmount ?? 0)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Comissão do rep. (%)</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {percent.format(data?.agentCommissionPercentage ?? 0)}
              </Text>
            </Column>
          </Row>

          {data?.agent && (
            <>
              <Row>
                <Column bg="blackAlpha.100">
                  <Heading size="md" textAlign="center">
                    Representante
                  </Heading>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Text fontSize="xs">NID</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.agent.nid ?? "-"}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Nome</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.agent.name}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">CPF/CNPJ</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {masks.document(data?.agent?.document)}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Telefone</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {masks.phone(data?.agent?.phone1)}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">E-mail</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.agent?.email ?? "-"}
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Text fontSize="xs">Endereço</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.agent.address?.street ?? "-"}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Número</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.agent.address?.number ?? "-"}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Complemento</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.agent.address?.complement ?? "-"}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Bairro</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.agent.address?.neighborhood ?? "-"}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Cidade/Estado</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.agent.address?.city ?? "-"}/{data?.agent.address?.state ?? "-"}
                  </Text>
                </Column>
              </Row>
            </>
          )}

          {data?.dealer && (
            <>
              <Row>
                <Column bg="blackAlpha.100">
                  <Heading size="md" textAlign="center">
                    Revendedor
                  </Heading>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Text fontSize="xs">NID</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.dealer.nid ?? "-"}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Nome</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.dealer.name}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">CPF/CNPJ</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {masks.document(data?.dealer?.document)}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Telefone</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {masks.phone(data?.dealer?.phone1)}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">E-mail</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.dealer?.email ?? "-"}
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Text fontSize="xs">Endereço</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.dealer.address?.street ?? "-"}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Número</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.dealer.address?.number ?? "-"}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Complemento</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.dealer.address?.complement ?? "-"}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Bairro</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.dealer.address?.neighborhood ?? "-"}
                  </Text>
                </Column>
                <Column>
                  <Text fontSize="xs">Cidade/Estado</Text>
                  <Text fontSize="xs" fontWeight="semibold">
                    {data?.dealer.address?.city ?? "-"}/{data?.dealer.address?.state ?? "-"}
                  </Text>
                </Column>
              </Row>
            </>
          )}

          <Row>
            <Column bg="blackAlpha.100">
              <Heading size="md" textAlign="center">
                Mercadorias
              </Heading>
            </Column>
          </Row>
          {children}

          <Row borderTopWidth="0">
            <Column borderTopWidth="0" bg="blackAlpha.100">
              <Heading size="md" textAlign="center">
                Observações
              </Heading>
            </Column>
          </Row>
          <Row>
            <Column h="150px" />
          </Row>
          <Divider borderColor="black" />

          <HStack alignItems="flex-start" spacing="20px" px="40px" pt="120px" pb="50px">
            {data?.agent && (
              <VStack flex="1" spacing="0" fontSize="sm">
                <Divider borderBottomWidth="2px" borderColor="black" />
                <Text fontSize="xs">Representante</Text>
                <Text fontSize="xs" fontWeight="semibold">
                  {data?.agent.name}
                </Text>
                <Text fontSize="xs">CPF/CNPJ: {masks.document(data?.agent.document)}</Text>
              </VStack>
            )}
            {data?.dealer && (
              <VStack flex="1" spacing="0" fontSize="sm">
                <Divider borderBottomWidth="2px" borderColor="black" />
                <Text fontSize="xs">Revendedor</Text>
                <Text fontSize="xs" fontWeight="semibold">
                  {data?.dealer.name}
                </Text>
                <Text fontSize="xs">CPF/CNPJ: {masks.document(data?.dealer.document)}</Text>
              </VStack>
            )}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const PrintGroupedDetailing = ({ isOpen, onClose }) => {
  const { data } = useContext(PacksDetailsContext);
  const items = useMemo(() => {
    if (!isOpen) return [];
    const response = {};
    for (const item of data?.merchandises ?? []) {
      const { _id, title } = item.product.productGroup;
      if (!response[_id]) response[_id] = { title, quantity: 0, amount: 0 };
      response[_id].quantity += item.quantity;
      response[_id].amount += item.quantity * item.amount;
    }
    return Object.values(response);
  }, [isOpen, data?.merchandises]);

  return (
    <Container isOpen={isOpen} onClose={onClose}>
      <table className="print-detailing">
        <thead>
          <tr>
            <th>Grupo</th>
            <th>Qtd</th>
            <th>Valor total</th>
          </tr>
        </thead>
        <tbody>
          {_.map(items, (item) => (
            <tr>
              <td>{item.title}</td>
              <td>{item.quantity.toLocaleString()}</td>
              <td>{currency.format(item.amount)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export const PrintUngroupedDetailing = ({ isOpen, onClose }) => {
  const { data } = useContext(PacksDetailsContext);

  return (
    <Container isOpen={isOpen} onClose={onClose}>
      <table className="print-detailing">
        <thead>
          <tr>
            <th>SKU</th>
            <th>NID</th>
            <th>Nome</th>
            <th>Qtd</th>
            <th>Valor un.</th>
            <th>Valor total</th>
          </tr>
        </thead>
        <tbody>
          {_.map(data?.merchandises, (item) => (
            <tr>
              <td>{item.product.sku}</td>
              <td>{item.product.nid}</td>
              <td>{item.product.name}</td>
              <td>{item.quantity.toLocaleString()}</td>
              <td>{currency.format(item.amount)}</td>
              <td>{currency.format(item.amount * item.quantity)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};
