import React, { useContext, useMemo } from "react";
import { Box, Text, Divider, HStack, Center, Icon } from "@chakra-ui/react";
import _ from "lodash";
import { currency } from "lib";
import { MdPercent, MdOutlineShoppingCart } from "react-icons/md";
import PacksDetailsContext from "../context";

const Summary = () => {
  const { formData: currentPack, dealerCommission } = useContext(PacksDetailsContext);

  return (
    <>
      <HStack space="10px" borderWidth="1px" p="20px" borderRadius="lg">
        <Center bg="gray.500" w="30px" h="30px" borderRadius="lg">
          <Icon as={MdOutlineShoppingCart} color="white" />
        </Center>
        <Box>
          <Text fontSize="sm">Valor vendido</Text>
          <Text fontSize="lg" fontWeight="semibold">
            {currency.format(currentPack.currentAmount ?? 0)}
          </Text>
        </Box>
      </HStack>
      <HStack space="10px" borderWidth="1px" p="20px" borderRadius="lg">
        <Center bg="purple.500" w="30px" h="30px" borderRadius="lg">
          <Icon as={MdPercent} color="white" />
        </Center>
        <Box>
          <Text fontSize="sm">Comissão do revendedor</Text>
          <Text fontSize="lg" fontWeight="semibold">
            {currency.format(dealerCommission.amount ?? 0)}
          </Text>
        </Box>
      </HStack>
    </>
  );
};

export default Summary;
