import styled from "styled-components";

export const Wrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker {
    font-size: 14px !important;
    font-weight: 400;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    &,
    * {
      color: ${({ backgroundContrast }) => backgroundContrast}!important;
    }
  }
  .react-datepicker-popper {
    z-index: 3;
  }
  .react-datepicker__time-container {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
  .react-datepicker__header {
    background-color: ${({ background }) => background};
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: ${({ background }) => background};
  }
  .react-datepicker,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    background-color: ${({ background }) => background};
    color: ${({ backgroundContrast }) => backgroundContrast};
  }
  .react-datepicker__day--today {
    background: ${({ primary }) => primary};
    color: #fff !important;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: auto;
    padding: 10px;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    font-size: 16px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    &:hover {
      background-color: ${({ primaryDarken }) => primaryDarken};
      color: #fff !important;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-.react-datepicker__day--in-range,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${({ primary }) => primary};
    color: #fff !important;
  }
`;
