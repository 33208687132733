import moment from "moment";
import { currency, percent, translator } from "lib";
import { CustomBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <CustomBadge.Packs.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "assignment",
    title: "Atribuição",
    isVisible: false,
    formatter: ({ assignment }) => translator(assignment),
  },
  {
    accessor: "priceType",
    title: "Tipo de preço",
    isVisible: true,
    formatter: ({ priceType }) => translator(priceType),
  },
  {
    accessor: "agent",
    title: "Representante",
    isVisible: true,
    formatter: ({ agent }) => agent?.name ?? "-",
  },
  {
    accessor: "dealer",
    title: "Revendedor",
    isVisible: true,
    formatter: ({ dealer }) => dealer?.name ?? "-",
  },
  {
    accessor: "predictedSettlementDate",
    title: "Previsão de acerto",
    isVisible: true,
    formatter: ({ predictedSettlementDate }) => (predictedSettlementDate ? moment(predictedSettlementDate).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "currentAmount",
    title: "Valor atual",
    isVisible: true,
    formatter: ({ currentAmount }) => currency.format(currentAmount),
  },
  {
    accessor: "currentQuantity",
    title: "Qtd. atual",
    isVisible: true,
    formatter: ({ currentQuantity }) => currentQuantity?.toLocaleString(),
  },
  {
    accessor: "performance",
    title: "Desempenho",
    isVisible: true,
    formatter: ({ performance }) => percent.format(performance),
    isAllowed: ({ isLoad }) => !isLoad,
  },
  {
    accessor: "loadedAmount",
    title: "Valor total carregado",
    isVisible: false,
    formatter: ({ loadedAmount }) => currency.format(loadedAmount),
    isAllowed: ({ isLoad }) => !isLoad,
  },
  {
    accessor: "loadedQuantity",
    title: "Qtd. total carregada",
    isVisible: false,
    formatter: ({ loadedQuantity }) => loadedQuantity?.toLocaleString(),
    isAllowed: ({ isLoad }) => !isLoad,
  },
  {
    accessor: "unloadedAmount",
    title: "Valor total devolvido",
    isVisible: false,
    formatter: ({ unloadedAmount }) => currency.format(unloadedAmount),
    isAllowed: ({ isLoad }) => !isLoad,
  },
  {
    accessor: "unloadedQuantity",
    title: "Qtd. total devolvida",
    isVisible: false,
    formatter: ({ unloadedQuantity }) => unloadedQuantity?.toLocaleString(),
    isAllowed: ({ isLoad }) => !isLoad,
  },
  {
    accessor: "dealerCommissionAmount",
    title: "Valor comissão rev.",
    isVisible: true,
    formatter: ({ dealerCommissionAmount }) => currency.format(dealerCommissionAmount),
    isAllowed: ({ isLoad }) => !isLoad,
  },
  {
    accessor: "dealerCommissionPercentage",
    title: "Porcent. comissão rev.",
    isVisible: false,
    formatter: ({ dealerCommissionPercentage }) => percent.format(dealerCommissionPercentage),
    isAllowed: ({ isLoad }) => !isLoad,
  },
  {
    accessor: "agentCommissionAmount",
    title: "Valor comissão rep.",
    isVisible: false,
    formatter: ({ agentCommissionAmount }) => currency.format(agentCommissionAmount),
    isAllowed: ({ isLoad }) => !isLoad,
  },
  {
    accessor: "agentCommissionPercentage",
    title: "Porcent. comissão rep.",
    isVisible: false,
    formatter: ({ agentCommissionPercentage }) => percent.format(agentCommissionPercentage),
    isAllowed: ({ isLoad }) => !isLoad,
  },
  {
    accessor: "receivedAmount",
    title: "Valor recebido",
    isVisible: true,
    formatter: ({ receivedAmount }) => currency.format(receivedAmount),
    isAllowed: ({ isLoad }) => !isLoad,
  },
  {
    accessor: "remainingAmount",
    title: "Valor restante",
    isVisible: true,
    formatter: ({ remainingAmount }) => currency.format(remainingAmount),
    isAllowed: ({ isLoad }) => !isLoad,
  },
  {
    accessor: "isLoad",
    title: "Carga?",
    isVisible: false,
    formatter: ({ isLoad }) => (isLoad ? "Sim" : "Não"),
  },
  {
    accessor: "dispatchedAt",
    title: "Despachado em",
    isVisible: false,
    formatter: ({ dispatchedAt }) => (dispatchedAt ? moment(dispatchedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "settledAt",
    title: "Acertado em",
    isVisible: false,
    formatter: ({ settledAt }) => (settledAt ? moment(settledAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "closedAt",
    title: "Fechado em",
    isVisible: false,
    formatter: ({ closedAt }) => (closedAt ? moment(closedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "packExpedition",
    title: "Expedição",
    isVisible: false,
    formatter: ({ packExpedition }) => packExpedition?.nid ?? "-",
  },
  {
    accessor: "packSettlement",
    title: "Acerto",
    isVisible: false,
    formatter: ({ packSettlement }) => packSettlement?.nid ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criação",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualização",
    isVisible: false,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
