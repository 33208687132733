import React, { useCallback, useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { useArrayItemHandlers } from "hooks";
import { InputCurrency, Map } from "components";
import { PaymentsDetailsContext } from "./index";
import { currency } from "lib";
import PacksDetailsContext from "./context";

const Location = ({ isOpen, onClose }) => {
  const { formData } = useContext(PacksDetailsContext);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="space-between">
          <Text>Localização do acerto</Text>
          <IconButton variant="ghost" icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <Divider />
        <ModalBody p="0">
          <Map location={formData.location} borderRadius="0" />
        </ModalBody>
        <Divider />
        <ModalFooter as={HStack}>
          <Button onClick={onClose}>fechar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Location;
