import axios from "axios";
import * as EventEmitter from "./eventEmitter";
import { getDevice } from "./getDevice";

const errorHandlers = {
  INVALID_AUTHORIZATION: (error) => EventEmitter.dispatch("onAuthorizationError", error),
  PERMISSION_DENIED: (error) => EventEmitter.dispatch("onPermissionDenied", error),
  APP_VERSION: (error) => EventEmitter.dispatch("onAppVersionError", error),
  MERCHANDISE_MOVEMENT: (error) => EventEmitter.dispatch("onMerchandiseMovement", error),
  REQUIRED_CONTRACT: (error) => EventEmitter.dispatch("onRequiredContractError", error),
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 180000,
});

api.interceptors.request.use(
  async (config) => {
    try {
      const device = await getDevice();
      config.headers.authorization = localStorage.getItem("@token");
      config.headers.os = "default";
      config.headers.packagename = device.object.packageName;
      config.headers.appversion = device.object.appVersion;
      config.headers.device = device.encrypted;
      return config;
    } catch (error) {
      return config;
    }
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  ({ data }) => {
    if (!data.error) return data;
    const handler = errorHandlers[data.error.type];
    if (handler) {
      data.error.handled = true;
      handler(data.error);
    }
    return Promise.reject(data.error);
  },
  (error) => {
    if (error.code === "ERR_NETWORK") {
      error.handled = true;
      EventEmitter.dispatch("onNetworkError", error);
    } else {
      const handler = errorHandlers[error.response.data.type];
      if (handler) {
        error.response.data.handled = true;
        handler(error.response.data);
      }
    }
    return Promise.reject(error.response?.data ?? error);
  }
);

api.upload = (path, file) => {
  const data = new FormData();
  data.append("file", file, file.name);
  return api.post(path, data, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    },
  });
};
