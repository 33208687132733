import { useEffect, useState } from "react";

const cache = {};

export const useCacheState = (key, defaultValue) => {
  const [value, setValue] = useState(() => cache[key] ?? defaultValue);

  useEffect(() => {
    cache[key] = value;
  }, [key, value]);

  return [value, setValue];
};
