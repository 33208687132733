import { useCallback } from "react";
import { Heading, Icon, Text, HStack, Box, IconButton } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { useCustomToast } from "hooks";
import { Lottie } from "components";
import animationData from "assets/lottie/copy.json";

const CustomToast = ({ onClose }) => {
  return (
    <HStack color="white" p="15px" bg="teal.900" borderRadius="lg" position="relative">
      <Box w="40px" h="40px" bg="teal.800" borderRadius="full">
        <Lottie animationData={animationData} speed={2} style={{ width: 40, height: 40 }} />
      </Box>
      <Box flex="1">
        <Heading size="xs">Copiado</Heading>
        <Text fontSize="xs" fontWeight="semibold">
          O conteúdo foi copiado para a área de transferência!
        </Text>
      </Box>
      <IconButton
        size="xs"
        right="2px"
        top="2px"
        position="absolute"
        variant="ghost"
        colorScheme="whiteAlpha"
        icon={<Icon as={MdClose} color="white" boxSize="15px" />}
        onClick={onClose}
      />
    </HStack>
  );
};

export const useClipboard = () => {
  const toast = useCustomToast();

  return useCallback(
    (value) => {
      navigator?.clipboard?.writeText?.(value);
      toast({ render: (props) => <CustomToast {...props} />, duration: 4000 });
    },
    [toast]
  );
};
