import React, { createContext, useState, useEffect, useMemo, useCallback, useRef } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { MdChevronLeft, MdMoreHoriz, MdOutlineLocalPrintshop } from "react-icons/md";
import * as yup from "yup";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { messages } from "consts";
import { Breadcrumb, Portal, Tags } from "components";
import { Content } from "pages/Private/Container";
import General from "./general";
import Images from "./images";
import Attachments from "./attachments";

export const ProductsDetailsContext = createContext();

export const ProductsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar produto" : "Novo produto");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/products/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useCustomToast();
  const tagsSelectorRef = useRef();

  useEffect(() => {
    const formData = data ?? { type: "simple", imgs: [], isManagedStock: true, isActive: true };
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.put(`/products/${_id}`, data) : await api.post("/products", data);
        navigate(`/products/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        retailPrice: yup.number().min(0.01, messages.error.required).required(messages.error.required),
        wholesalePrice: yup.number().min(0.01, messages.error.required).required(messages.error.required),
        productGroup: yup.string().required(messages.error.required),
      });
      const data = { ...formData, supplier: formData.supplier?._id };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleOpenTags = useCallback(() => {
    const documents = [{ document: formData, quantity: 1 }];
    tagsSelectorRef.current.open("product", documents, true);
  }, [formData]);

  return (
    <ProductsDetailsContext.Provider value={{ formData, setFormData, formErrors, isLoadingData, refreshData }}>
      <Content>
        <HStack justify="space-between">
          <HStack spacing="10px">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/products", label: "produtos" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <SlideFade in={true} offsetY="-20px">
            <Box>
              <Menu>
                <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />} isDisabled={!_id}>
                  mais ações
                </MenuButton>
                <Portal>
                  <MenuList fontSize="sm">
                    <MenuItem onClick={handleOpenTags} isDisabled={!_id}>
                      <HStack>
                        <Icon as={MdOutlineLocalPrintshop} />
                        <Text>imprimir etiquetas</Text>
                      </HStack>
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </Box>
          </SlideFade>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Produto</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
          <HStack spacing="20px">
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Público?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isPublic}
                onChange={() => setFormData((state) => ({ ...state, isPublic: !state.isPublic }))}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>
        <Tabs colorScheme="main" isLazy={true}>
          <TabList>
            <Tab>dados gerais</Tab>
            <Tab>imagens</Tab>
            <Tab>anexos</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Images />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Attachments />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
            salvar
          </Button>
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            cancelar
          </Button>
        </HStack>
      </SlideFade>

      <Tags.Selector ref={tagsSelectorRef} />
    </ProductsDetailsContext.Provider>
  );
};
