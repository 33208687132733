import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { api } from "lib";
import { HiOutlineFilter } from "react-icons/hi";
import { AsyncSelect, DatePicker } from "components";
import { useCacheState } from "hooks";

let loadUsersTimeout = null;

const Filters = ({ onQuery }) => {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [query, setQuery] = useCacheState("PackSettlementsList.Filters.query", {});
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);

  useEffect(() => {
    const response = {};
    if (query.agent?.length) _.set(response, "agent.$in", _.map(query.agent, "_id"));
    if (query.createdAtStartDate) _.set(response, "createdAt.$gte", query.createdAtStartDate);
    if (query.createdAtEndDate) _.set(response, "createdAt.$lte", query.createdAtEndDate);
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
  }, [onQuery, query, location.state]);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const query = { roles: "agent" };
      const response = await api.get("/users", { params: { query, search } });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
    onClose();
  }, [setQuery, formData, onClose]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, []);

  return (
    <>
      <Button colorScheme={isFiltering ? "main" : "gray"} variant="outline" rightIcon={<Icon as={HiOutlineFilter} />} onClick={onOpen}>
        filtros
      </Button>
      {isFiltering && (
        <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
          limpar filtros
        </Button>
      )}
      <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Filtros</DrawerHeader>
          <Divider />
          <DrawerBody overflow="visible">
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
              <GridItem colSpan={4}>
                <FormControl>
                  <FormLabel fontSize="sm">Representante</FormLabel>
                  <AsyncSelect
                    isMulti
                    value={formData.agent ?? []}
                    defaultOptions
                    loadOptions={handleLoadUsers}
                    placeholder="Selecione o representante"
                    onChange={(agent) => setFormData((state) => ({ ...state, agent }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ name }) => name}
                    isClearable={true}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl isRequired={true}>
                  <FormLabel fontSize="sm">Criação (inicial)</FormLabel>
                  <DatePicker
                    selected={formData.createdAtStartDate}
                    onChange={(value) => setFormData((state) => ({ ...state, createdAtStartDate: moment(value).startOf("day").toDate() }))}
                    selectsStart
                    startDate={formData.createdAtStartDate}
                    endDate={formData.createdAtEndDate}
                    dateFormat="d, MMMM yyyy"
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl isRequired={true}>
                  <FormLabel fontSize="sm">Criação (final)</FormLabel>
                  <DatePicker
                    selected={formData.createdAtEndDate}
                    onChange={(value) => setFormData((state) => ({ ...state, createdAtEndDate: moment(value).startOf("day").toDate() }))}
                    selectsEnd
                    startDate={formData.createdAtStartDate}
                    endDate={formData.createdAtEndDate}
                    minDate={formData.createdAtStartDate}
                    dateFormat="d, MMMM yyyy"
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </DrawerBody>
          <Divider />
          <DrawerFooter>
            <HStack justifyContent="flex-end">
              <Button size="sm" onClick={onClose}>
                cancelar
              </Button>
              <Button size="sm" colorScheme="main" onClick={handleSubmit}>
                aplicar
              </Button>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Filters;
