import React, { memo, useMemo } from "react";
import ReactDOM from "react-dom";
import { Box, Icon, Text, Button, HStack, Link } from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { formatBytes } from "lib";
import { Image } from "../components";

const portal = document.createElement("div");
document.body.appendChild(portal);

const ImageItem = memo(({ provided, snapshot, _id, title, type, size, thumb, location, onExpand, onDelete }) => {
  const isImage = useMemo(() => /^image/i.test(type), [type]);
  const child = (
    <HStack
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      p="10px"
      borderWidth="1px"
      borderRadius="xl"
      _light={{ bg: "white" }}
      _dark={{ bg: "gray.800" }}
    >
      <Image type={type} title={title} src={thumb} boxSize="80px" cursor={isImage && "pointer"} onClick={isImage ? onExpand : undefined} />
      <Box flex="1">
        <Link fontWeight="semibold" href={location} isExternal>
          {title}
        </Link>
        <Text fontSize="xs">{type}</Text>
        <Text fontSize="xs">{formatBytes(size)}</Text>
      </Box>
      <Button size="sm" colorScheme="black" variant="outline" rightIcon={<Icon as={MdDelete} />} onClick={() => onDelete(_id)}>
        deletar
      </Button>
    </HStack>
  );
  if (!snapshot.isDragging) return child;
  return ReactDOM.createPortal(child, portal);
});

export default ImageItem;
