import React, { useState, useImperativeHandle, forwardRef, useCallback, useMemo } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import _ from "lodash";
import { MdPrint, MdRefresh } from "react-icons/md";
import { InputCurrency } from "components";
import { useApiGet } from "hooks";
import Printer from "../printer";

const Selector = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [isSingle, setIsSingle] = useState(false);
  const [type, setType] = useState();
  const [documents, setDocuments] = useState([]);
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(
      () => ({
        path: "/tags",
        params: { query: { type }, perPage: -1 },
        options: { isEnabled: isOpen && type },
      }),
      [isOpen, type]
    )
  );

  const open = useCallback(async (type, documents, isSingle) => {
    setIsOpen(true);
    setType(type);
    setDocuments(documents);
    setIsSingle(isSingle);
  }, []);

  const close = useCallback(() => {
    setDocuments([]);
    setIsOpen(false);
    setType();
  }, []);

  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  return (
    <Modal isOpen={isOpen} onClose={close} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Impressão de etiquetas</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel fontSize="xs" mb="5px">
              Template
            </FormLabel>
            <HStack>
              <Select value={selected?._id ?? ""} onChange={({ target }) => setSelected(_.find(data?.data, (o) => o._id === target.value))}>
                <option value="">--Selecione</option>
                {_.map(data?.data, (o) => (
                  <option key={o._id} value={o._id}>
                    {o.title}
                  </option>
                ))}
              </Select>
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
            </HStack>
          </FormControl>
          {isSingle && (
            <FormControl mt={4}>
              <FormLabel fontSize="xs" mb="5px">
                Quantidade
              </FormLabel>
              <Input as={InputCurrency} precision="0" value={quantity ?? 1} onChange={(quantity) => setQuantity(quantity)} />
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Printer
            tag={selected}
            documents={isSingle ? [{ document: documents?.[0]?.document, quantity }] : documents}
            trigger={({ isLoading }) => (
              <Button
                w="100%"
                colorScheme="main"
                leftIcon={<Icon as={MdPrint} />}
                isLoading={isLoadingData || isLoading}
                isDisabled={!selected?._id}
              >
                Imprimir
              </Button>
            )}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default Selector;
