import React, { useContext } from "react";
import { Flex, Box, useColorModeValue } from "@chakra-ui/react";
import Renderer from "../../renderer";
import { BuilderContext } from "..";

export const Canvas = () => {
  const { options, formData, currentElement, setCurrentElement } = useContext(BuilderContext);
  const backgroundColor = useColorModeValue("gray.100", "gray.700");

  const handleCleanSelection = (e) => {
    e.stopPropagation();
    setCurrentElement({});
  };

  return (
    <Flex
      flex={1}
      backgroundColor={backgroundColor}
      alignItems="center"
      justifyContent="center"
      overflow="auto"
      onClick={handleCleanSelection}
    >
      <Box transform={`scale(${options.scale})`} backgroundColor="white">
        <Renderer elements={formData.elements} selected={currentElement} onSelect={(data) => setCurrentElement(data)} />
      </Box>
    </Flex>
  );
};
