import React, { useMemo, useState, useContext, useCallback } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { useParams, Link as RouterLink } from "react-router-dom";
import { MdClose, MdHistory, MdRefresh } from "react-icons/md";
import { useApiGet, useCustomToast } from "hooks";
import { Paginator } from "components";
import { api, currency } from "lib";
import { ProductsDetailsContext } from "./index";
import { TbExternalLink } from "react-icons/tb";
import { FiTrash } from "react-icons/fi";

const PriceHistory = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const {
    formData: formProductData,
    isLoadingData: isLoadingProductData,
    refreshData: refreshProductData,
  } = useContext(ProductsDetailsContext);
  const [sort] = useState({ createdAt: -1 });
  const [page, setPage] = useState(0);
  const [perPage] = useState(20);
  const query = useMemo(() => ({ priceType: "costPrice" }), []);
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(() => ({ path: `/products/${_id}/price-history`, params: { query, sort, page, perPage } }), [_id, query, page, sort, perPage])
  );
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState({});
  const isLoading = useMemo(() => isLoadingProductData || isLoadingData, [isLoadingProductData, isLoadingData]);
  const isLoadingSomeDeleteData = useMemo(() => Object.keys(isLoadingDeleteData).length > 0, [isLoadingDeleteData]);
  const [selected, setSelected] = useState(null);
  const toast = useCustomToast();

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData({ [selected]: true });
      await api.delete(`/products/${_id}/price-history/${selected}`);
      refreshData();
      refreshProductData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData({});
      setSelected(null);
    }
  }, [_id, selected, toast, refreshData, refreshProductData]);

  return (
    <>
      <Modal size="xl" scrollBehavior="inside" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay>
          <ModalContent>
            <ModalHeader as={HStack}>
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoading} onClick={refreshData} />
              <Box>
                <Text fontSize="xs" fontWeight="normal">
                  Preço de custo médio
                </Text>
                <Text fontSize="md" fontWeight="semibold">
                  {currency.format(formProductData.costPrice ?? 0)}
                </Text>
              </Box>
              <HStack flex="1" justify="flex-end">
                <IconButton variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
              </HStack>
            </ModalHeader>
            <Divider />
            <ModalBody>
              <VStack align="stretch" spacing="0" divider={<StackDivider />}>
                {_.map(data?.data, (item) => (
                  <Box key={item._id} py="15px" opacity={item.deletedAt ? "0.5" : "1"}>
                    <HStack>
                      <Tooltip label="Visitar movimentação de estoque de origem">
                        <IconButton
                          as={RouterLink}
                          to={`/stock-movements/edit/${item.stockMovement}`}
                          target="_blank"
                          size="lg"
                          variant="outline"
                          icon={<Icon as={TbExternalLink} />}
                        />
                      </Tooltip>
                      <Box flex="1" pr="20px">
                        <Text>{item.stockMovement.title}</Text>
                        <Text fontSize="xs">
                          Quantidade adicionada <strong>{item.quantity.toLocaleString()}</strong>
                        </Text>
                        <Text fontSize="xs">
                          Preço informado <strong>{currency.format(item.amount)}</strong>
                        </Text>
                        <Text fontSize="xs">Criado em {moment(item.createdAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
                        {item.deletedAt && (
                          <Text fontSize="xs">
                            Excluído em {moment(item.deletedAt).format("DD/MM/YYYY [às] HH:mm")} por{" "}
                            <strong>{item.deletedBy?.name ?? "exclusão automática"}</strong>
                          </Text>
                        )}
                      </Box>
                      <VStack alignItems="flex-end">
                        <Heading size="md" textDecoration={item.deletedAt ? "line-through" : "none"}>
                          {currency.format(item.quantity * item.amount)}
                        </Heading>
                        {!item.deletedAt && (
                          <Button
                            size="xs"
                            variant="outline"
                            rightIcon={<Icon as={FiTrash} />}
                            isLoading={isLoadingDeleteData[item._id]}
                            isDisabled={isLoadingSomeDeleteData}
                            onClick={() => setSelected(item._id)}
                          >
                            excluir
                          </Button>
                        )}
                      </VStack>
                    </HStack>
                  </Box>
                ))}
              </VStack>
              {isLoading ? (
                <Center p="30px">
                  <Spinner />
                </Center>
              ) : (
                _.size(data?.data) === 0 && (
                  <VStack spacing="0" py="40px">
                    <Icon as={MdHistory} boxSize="80px" />
                    <Heading size="md" textAlign="center">
                      Nenhum histórico disponível
                    </Heading>
                    <Text textAlign="center">Este produto ainda não possui histórico de lançamentos de preço de custo.</Text>
                  </VStack>
                )
              )}
            </ModalBody>
            <Divider />
            <ModalFooter>
              <Paginator loading={isLoading} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <AlertDialog isOpen={selected} onClose={() => setSelected(null)} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>
            Deseja realmente excluir o registro selecionado? O preço de custo médio do produto será recalculado após a exclusão.
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={() => setSelected(null)}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingSomeDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default PriceHistory;
