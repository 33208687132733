import React from "react";
import { Select, VStack, FormControl } from "@chakra-ui/react";
import { Box } from "./Box";
import { Label } from "../components";

export const Flex = ({ data, onChange }) => {
  return (
    <>
      <VStack>
        <FormControl>
          <Label onClean={() => onChange({ ...data, props: { ...data.props, direction: undefined } })}>Direção</Label>
          <Select
            value={data.props.direction ?? ""}
            onChange={({ target }) => onChange({ ...data, props: { ...data.props, direction: target.value } })}
          >
            <option value="row">Linha</option>
            <option value="column">Coluna</option>
          </Select>
        </FormControl>
        <FormControl>
          <Label onClean={() => onChange({ ...data, props: { ...data.props, alignItems: undefined } })}>Alinhar conteúdo</Label>
          <Select
            value={data.props.alignItems ?? ""}
            onChange={({ target }) => onChange({ ...data, props: { ...data.props, alignItems: target.value } })}
          >
            <option value="flex-start">Flex-Início</option>
            <option value="center">Centro</option>
            <option value="flex-end">Flex-Final</option>
          </Select>
        </FormControl>
        <FormControl>
          <Label onClean={() => onChange({ ...data, props: { ...data.props, justifyContent: undefined } })}>Justificar conteúdo</Label>
          <Select
            value={data.props.justifyContent ?? ""}
            onChange={({ target }) => onChange({ ...data, props: { ...data.props, justifyContent: target.value } })}
          >
            <option value="flex-start">Flex-Início</option>
            <option value="center">Centro</option>
            <option value="flex-end">Flex-Final</option>
          </Select>
        </FormControl>
        <Box data={data} onChange={onChange} />
      </VStack>
    </>
  );
};
