import styled, { css } from "styled-components";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const commonStyle = css`
  &.react-select--is-disabled {
    opacity: 0.4 !important;
  }
  .react-select__option {
    &.react-select__option--is-focused,
    &.react-select__option--is-selected,
    &:hover {
      background: ${({ primary }) => primary} !important;
      &,
      * {
        color: #fff !important;
      }
    }
    &.react-select__option--is-disabled {
      background: ${({ backgroundColor }) => backgroundColor} !important;
      &,
      * {
        color: ${({ disabledColor }) => disabledColor} !important;
      }
    }
  }
  .react-select__value-container,
  .react-select__menu {
    &,
    * {
      color: ${({ color }) => color} !important;
      font-size: 15px;
    }
  }
  .react-select__control {
    min-height: 40px;
    background: ${({ backgroundColor }) => backgroundColor} !important;
    border-color: ${({ isInvalid, borderColor, red }) => (isInvalid ? red : borderColor)} !important;
    border-width: ${({ isInvalid }) => (isInvalid ? 2 : 1)}px;
  }
  .react-select__menu {
    background: ${({ backgroundColor }) => backgroundColor} !important;
    z-index: 10;
  }
  .react-select__multi-value {
    background: ${({ primary }) => primary} !important;
    color: #fff !important;
  }
  .react-select__multi-value__remove {
    background: rgba(0, 0, 0, 0.1) !important;
    &,
    * {
      color: #fff !important;
    }
  }
  .react-select__multi-value__label {
    font-weight: bold;
    font-size: 12px !important;
    color: #fff !important;
  }
`;

export const ReactAsyncSelect = styled(AsyncSelect)`
  ${commonStyle}
  flex:1;
`;

export const ReactSelect = styled(Select)`
  ${commonStyle}
  flex:1;
`;
