import React, { useMemo, useState, useEffect, useCallback, Fragment, createContext, useRef } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { MdArrowDropDown, MdOutlineAccountBalanceWallet, MdRefresh } from "react-icons/md";
import { BiTransferAlt } from "react-icons/bi";
import { RxEyeOpen, RxEyeClosed } from "react-icons/rx";
import { FaStar } from "react-icons/fa";
import { useApiGet, useDocumentTitle } from "hooks";
import { TableEmpty, Breadcrumb, Paginator } from "components";
import { currency } from "lib";
import { Content } from "pages/Private/Container";
import { TbArrowAutofitDown, TbArrowAutofitUp } from "react-icons/tb";
import { Balance, CreditDebit } from "./item";
import Movement from "./movement";

export const WalletsListContext = createContext();

export const WalletsList = () => {
  useDocumentTitle("Carteiras");
  const [sort] = useState({ createdAt: -1 });
  const [page, setPage] = useState(0);
  const [perPage] = useState(20);
  const [wallets, isLoadingWallets, refreshWallets] = useApiGet(
    useMemo(() => ({ path: "/wallets", params: { sort: { isPrimary: -1, title: 1 }, page: 0, perPage: 50 } }), [])
  );
  const [currentWallet, setCurrentWallet] = useState({});
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(
      () => ({ path: `/wallets/${currentWallet._id}/movements`, params: { sort, page, perPage } }),
      [currentWallet._id, sort, page, perPage]
    )
  );
  const [isVisible, setIsVisible] = useState(false);
  const isLoading = useMemo(() => isLoadingWallets || isLoadingData, [isLoadingWallets, isLoadingData]);
  const movementsRef = useRef();

  useEffect(() => {
    const findData = () => {
      const { _id, title, balance } = _.find(wallets.data, (o) => o.isPrimary) || {};
      setCurrentWallet({ _id, title, balance });
    };
    _.size(wallets?.data) && findData();
  }, [wallets]);

  const handleRefreshData = useCallback(() => {
    refreshData();
    refreshWallets();
  }, [refreshData, refreshWallets]);

  return (
    <WalletsListContext.Provider value={{ wallets, currentWallet, handleRefreshData }}>
      <Content>
        <HStack justify="space-between">
          <Breadcrumb items={[{ label: "financeiro" }, { to: "/wallets", label: "carteiras" }]} />
          <HStack>
            <Box>
              <Menu>
                <MenuButton
                  as={Button}
                  size="sm"
                  colorScheme="main"
                  isDisabled={!currentWallet._id}
                  rightIcon={<Icon as={MdArrowDropDown} />}
                >
                  nova movimentação
                </MenuButton>
                <MenuList fontSize="sm">
                  <MenuItem onClick={() => movementsRef.current.open("credit")}>
                    <HStack>
                      <Icon as={TbArrowAutofitDown} />
                      <Text>novo aporte</Text>
                    </HStack>
                  </MenuItem>
                  <MenuItem onClick={() => movementsRef.current.open("debit")}>
                    <HStack>
                      <Icon as={TbArrowAutofitUp} />
                      <Text>novo saque</Text>
                    </HStack>
                  </MenuItem>
                  <MenuItem onClick={() => movementsRef.current.open("transfer")}>
                    <HStack>
                      <Icon as={BiTransferAlt} />
                      <Text>nova transferência</Text>
                    </HStack>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </HStack>
        </HStack>

        <Heading my="15px" size="md">
          Carteiras
        </Heading>

        <HStack mb="30px">
          <Box>
            <Menu>
              <MenuButton
                as={Button}
                variant="outline"
                leftIcon={<Icon as={MdOutlineAccountBalanceWallet} />}
                rightIcon={<Icon as={MdArrowDropDown} />}
                isLoading={isLoadingWallets}
              >
                {currentWallet.title}
              </MenuButton>
              <MenuList fontSize="sm">
                <MenuOptionGroup
                  type="radio"
                  value={currentWallet._id}
                  onChange={(value) => setCurrentWallet(_.find(wallets?.data, (o) => o._id === value))}
                >
                  {_.map(wallets?.data, (item) => (
                    <MenuItemOption key={item._id} value={item._id}>
                      <HStack justifyContent="space-between">
                        <Text>{item.title}</Text>
                        {item.isPrimary && <Icon as={FaStar} color="yellow.500" />}
                      </HStack>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Box>
          <HStack>
            <IconButton
              variant="outline"
              icon={<Icon as={isVisible ? RxEyeOpen : RxEyeClosed} />}
              onClick={() => setIsVisible((state) => !state)}
            />
            <Box>
              <Text fontSize="xs">Saldo atual</Text>
              <Heading size="sm">{isVisible ? currency.format(currentWallet.balance || 0) : "●●●●"}</Heading>
            </Box>
          </HStack>
          <HStack flex="1" justify="flex-end">
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={handleRefreshData} />
          </HStack>
        </HStack>

        <VStack align="stretch" spacing="0" divider={<StackDivider />}>
          {_.map(data?.data, (item) => (
            <Fragment key={item._id}>{item.type === "balance" ? <Balance {...item} /> : <CreditDebit {...item} />}</Fragment>
          ))}
        </VStack>

        {isLoading && (
          <Center p="30px">
            <Spinner />
          </Center>
        )}

        <TableEmpty isLoading={isLoading} size={_.size(data?.data)} hasNewButton={false} />
      </Content>

      <Divider />

      <Box p="20px">
        <Paginator loading={isLoading} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
      </Box>

      <Movement ref={movementsRef} />
    </WalletsListContext.Provider>
  );
};
