import React, { useMemo, useState, Fragment, createContext, useContext } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { MdClose, MdRefresh } from "react-icons/md";
import { useApiGet } from "hooks";
import { Paginator, Lottie } from "components";
import { Balance, CreditDebit } from "./item";
import AddScore from "./add";
import { UsersDetailsContext } from "../index";
import animationData from "assets/lottie/coin.json";

export const ScoresContext = createContext();

const Scores = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const { formData: currentUserData } = useContext(UsersDetailsContext);
  const [sort] = useState({ createdAt: -1 });
  const [page, setPage] = useState(0);
  const [perPage] = useState(20);
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(() => ({ path: `/users/${_id}/scores`, params: { sort, page, perPage } }), [_id, page, sort, perPage])
  );
  const { isOpen: isOpenAddItem, onOpen: onOpenAddItem, onClose: onCloseAddItem } = useDisclosure();

  return (
    <ScoresContext.Provider value={{ refreshData }}>
      <Modal size="4xl" scrollBehavior="inside" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay>
          <ModalContent>
            <ModalHeader as={HStack}>
              <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
              <Box>
                <Text fontSize="xs" fontWeight="normal">
                  Saldo atual
                </Text>
                <Heading size="sm">{currentUserData.score?.toLocaleString() ?? 0} pontos</Heading>
              </Box>
              <HStack flex="1" justify="flex-end">
                <Button size="sm" colorScheme="main" onClick={onOpenAddItem}>
                  incluir pontuação
                </Button>
                <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
              </HStack>
            </ModalHeader>
            <Divider />
            <ModalBody>
              <VStack align="stretch" spacing="0" divider={<StackDivider />}>
                {_.map(data?.data, (item) => (
                  <Fragment key={item._id}>{item.type === "balance" ? <Balance {...item} /> : <CreditDebit {...item} />}</Fragment>
                ))}
              </VStack>
              {isLoadingData ? (
                <Center p="30px">
                  <Spinner />
                </Center>
              ) : (
                _.size(data?.data) === 0 && (
                  <VStack spacing="0" py="40px">
                    <Lottie animationData={animationData} style={{ width: 150, height: 150 }} />
                    <Heading size="md" textAlign="center">
                      Nenhuma pontuação disponível
                    </Heading>
                    <Text textAlign="center">Este usuário ainda não possui lançamentos de pontuação.</Text>
                  </VStack>
                )
              )}
            </ModalBody>
            <Divider />
            <ModalFooter>
              <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
      <AddScore isOpen={isOpenAddItem} onClose={onCloseAddItem} />
    </ScoresContext.Provider>
  );
};

export default Scores;
