import _ from "lodash";
import CryptoJS from "crypto-js";
import * as rdd from "react-device-detect";

let device;

const getEncryptionKey = (packageName) => {
  const ascii = [];
  for (let i = 0; i < packageName.length; i++) ascii.push(packageName.charCodeAt(i));
  const chunked = _.chunk(ascii, 2);
  const reversed = chunked.reverse();
  const flatten = _.flatten(reversed);
  const joined = flatten.join("");
  const encryptionKey = CryptoJS.MD5(joined).toString();
  return encryptionKey;
};

export const getDevice = async () => {
  if (device) return device;
  const object = {
    brand: rdd.mobileVendor,
    model: rdd.mobileModel,
    osName: rdd.osName,
    osVersion: rdd.osVersion,
    appVersion: process.env.REACT_APP_VERSION,
    packageName: process.env.REACT_APP_PACKAGE_NAME,
    browserName: rdd.browserName,
    browserVersion: rdd.fullBrowserVersion,
    isBrowser: true,
    uuid: localStorage.getItem("@device-uuid"),
  };
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(object), getEncryptionKey(object.packageName)).toString();
  device = { object, encrypted };
  return device;
};
