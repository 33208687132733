import React, { useCallback, useContext, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { MdAdd, MdArrowBack, MdSave } from "react-icons/md";
import { GrDocumentConfig } from "react-icons/gr";
import { VscOpenPreview } from "react-icons/vsc";
import { elementPrototypes } from "../../../consts";
import { fakeData } from "../consts";
import Printer from "../../printer";
import { BuilderContext } from "..";
import PageSettings from "./pageSettings";
import { InputCurrency } from "components";

export const Header = () => {
  const navigate = useNavigate();
  const {
    loading,
    formData,
    setFormData,
    formErrors,
    currentElementPrototype,
    setCurrentElement,
    options,
    currentElement,
    searchElementPath,
    setOptions,
  } = useContext(BuilderContext);
  const [isOpenPageSettings, setIsOpenPageSettings] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const handleZoomIn = () => {
    setOptions((state) => ({
      ...state,
      scale: state.scale < 10 ? state.scale + 0.1 : 10,
    }));
  };

  const handleZoomOut = () => {
    setOptions((state) => ({
      ...state,
      scale: state.scale > 0.5 ? state.scale - 0.1 : 0.5,
    }));
  };

  const handleAddElement = useCallback(
    (element) => {
      const selected = { ...currentElement };
      const path = searchElementPath(formData.elements, currentElement);
      const newElement = element.constructor();
      selected.children.push(newElement);
      const elements = [...formData.elements];
      _.set(elements, path, selected);
      setFormData((state) => ({ ...state, elements }));
      setCurrentElement(newElement);
    },
    [searchElementPath, setFormData, setCurrentElement, formData.elements, currentElement]
  );

  return (
    <>
      <HStack p="10px" justifyContent="space-between">
        <HStack>
          <IconButton size="sm" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          <FormControl isRequired={true} isInvalid={formErrors.title}>
            <Input
              size="sm"
              value={formData.title ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))}
            />
            <FormErrorMessage>{formErrors.title}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors.type}>
            <Select
              size="sm"
              value={formData.type ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}
            >
              <option value="product">Etiqueta de produto</option>
              <option value="pack">Etiqueta de pacote</option>
            </Select>
            <FormErrorMessage>{formErrors.type}</FormErrorMessage>
          </FormControl>
          <Box>
            <Button leftIcon={<Icon as={GrDocumentConfig} />} size="sm" onClick={() => setIsOpenPageSettings(true)}>
              Configurações de página
            </Button>
          </Box>
        </HStack>
        <HStack>
          <HStack spacing={1}>
            <Input
              as={InputCurrency}
              size="sm"
              width="50px"
              precision="0"
              value={quantity ?? 1}
              onChange={(quantity) => setQuantity(quantity)}
            />
            <Printer
              tag={formData}
              documents={[{ document: fakeData[formData.type], quantity }]}
              trigger={({ isLoading }) => <IconButton size="sm" icon={<Icon as={VscOpenPreview} />} isLoading={isLoading} />}
            />
          </HStack>
          <HStack>
            <IconButton size="sm" icon={<Icon as={AiOutlineZoomOut} />} onClick={handleZoomOut} />
            <Text fontWeight="bold" fontSize="sm">
              {parseInt(options.scale * 100)}%
            </Text>
            <IconButton size="sm" icon={<Icon as={AiOutlineZoomIn} />} onClick={handleZoomIn} />
          </HStack>
          <Box>
            <Menu>
              <MenuButton
                size="sm"
                as={Button}
                leftIcon={<Icon as={MdAdd} />}
                disabled={!currentElement?.key || !currentElementPrototype?.isWrapper}
              >
                Adicionar elemento
              </MenuButton>
              <MenuList zIndex={99}>
                {elementPrototypes.map((item) => (
                  <MenuItem key={item.type} onClick={() => handleAddElement(item)}>
                    <HStack>
                      <Icon as={item.icon} />
                      <Text fontSize="xs" fontWeight="bold">
                        {item.title}
                      </Text>
                    </HStack>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Box>
          <Button type="submit" size="sm" colorScheme="main" leftIcon={<Icon as={MdSave} />} isLoading={loading}>
            Salvar
          </Button>
        </HStack>
      </HStack>
      <PageSettings isOpen={isOpenPageSettings} onClose={() => setIsOpenPageSettings(false)} />
    </>
  );
};
