import React, { useCallback, useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { useArrayItemHandlers } from "hooks";
import { BoxData, InputCurrency } from "components";
import { CommissionsDetailsContext } from "./index";
import { percent } from "lib";

const AddRange = ({ isOpen, onClose }) => {
  const { formData: parentFormData, setFormData: setParentFormData, type } = useContext(CommissionsDetailsContext);
  const [formData, setFormData] = useState({ soldStart: 0 });
  const [formErrors, setFormErrors] = useState({});
  const { handleAddArrayItem } = useArrayItemHandlers(setParentFormData);

  useEffect(() => {
    const soldStart = (() => {
      try {
        const last = _.last(parentFormData.ranges);
        const add = type.isPercentage ? 0.0001 : 0.01;
        return last.soldEnd + add;
      } catch (error) {
        return 0;
      }
    })();
    setFormData({ soldStart });
    setFormErrors({});
  }, [isOpen, type, parentFormData.ranges]);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const last = _.last(parentFormData.ranges);
        const schema = yup.object().shape({
          soldEnd: yup
            .number()
            .moreThan(formData.soldStart, `${messages.error.moreThan} ${percent.format(formData.soldStart)}`)
            .required(messages.error.required)
            .when("isPercentage", () => {
              if (type.isPercentage) return yup.number().max(1, `${messages.error.lessOrEqual} 100%`);
            }),
          commissionPercentage: yup
            .number()
            .moreThan(last?.commissionPercentage ?? 0, `${messages.error.moreThan} ${percent.format(last?.commissionPercentage ?? 0)}`)
            .max(1, `${messages.error.greaterOrEqual} 100%`)
            .required(messages.error.required),
        });
        await schema.validate(formData, { abortEarly: false });
        handleAddArrayItem("ranges", formData);
        onClose();
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [parentFormData.type, parentFormData.ranges, formData, handleAddArrayItem, onClose]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Text>Adicionar intervalo</Text>
            <IconButton variant="ghost" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody>
            <Grid my={4}>
              <GridItem>
                <FormControl isInvalid={formErrors.soldStart}>
                  <BoxData label={`${type.symbol} vendido (inicial)`} value={type.format(formData.soldStart)} />
                  <FormErrorMessage>{formErrors.soldStart}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid mb={4}>
              <GridItem>
                <FormControl isInvalid={formErrors.soldEnd}>
                  <FormLabel fontSize="sm">{type.symbol} vendido (final)</FormLabel>
                  <Input
                    as={InputCurrency}
                    value={formData.soldEnd ?? 0}
                    onChange={(soldEnd) => setFormData((state) => ({ ...state, soldEnd }))}
                    isPercentage={type.isPercentage}
                  />
                  <FormErrorMessage>{formErrors.soldEnd}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid mb={4}>
              <GridItem>
                <FormControl isInvalid={formErrors.commissionPercentage}>
                  <FormLabel fontSize="sm">% comissão</FormLabel>
                  <Input
                    as={InputCurrency}
                    value={formData.commissionPercentage ?? ""}
                    onChange={(commissionPercentage) => setFormData((state) => ({ ...state, commissionPercentage }))}
                    isPercentage={true}
                  />
                  <FormErrorMessage>{formErrors.commissionPercentage}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack}>
            <Button onClick={onClose}>cancelar</Button>
            <Button type="submit" colorScheme="main">
              adicionar
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddRange;
