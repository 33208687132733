import React, { useEffect, useContext, useCallback, memo } from "react";
import {
  Box,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { MdMoreHoriz, MdRefresh } from "react-icons/md";
import { FiClipboard, FiEdit, FiPercent } from "react-icons/fi";
import { currency, percent, translator } from "lib";
import { InputCurrency, Lottie } from "components";
import { useClipboard } from "hooks";
import animationData from "assets/lottie/payment.json";
import { RiPercentFill } from "react-icons/ri";
import { PackSettlementsDetailsContext } from "../index";

const ListItem = memo(({ index, item, onPercentageChange }) => {
  const copyToClipboard = useClipboard();

  return (
    <Tr _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } }}>
      <Td>
        <HStack>
          <Box>
            <Menu placement="right-start">
              <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
              <MenuList>
                <MenuGroup title={`${translator(item.type)} ${item.nid}`} pb="5px">
                  <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                    copiar código
                  </MenuItem>
                  <MenuItem icon={<Icon as={FiEdit} />} as={RouterLink} to={`/payments/edit/${item._id}`} target="_blank">
                    editar
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </Box>
          <Tooltip label={item.isCommissionable ? "Pagamento comissionável" : "Pagamento não comissionável"}>
            <Center bg={item.isCommissionable ? "purple.100" : "gray.100"} w="25px" h="25px" borderRadius="lg">
              <Icon as={FiPercent} color={item.isCommissionable ? "purple.500" : "gray.500"} />
            </Center>
          </Tooltip>
        </HStack>
      </Td>
      <Td>{item.user?.name ?? "-"}</Td>
      <Td>{moment(item.paymentDate).format("DD, MMMM [de] YYYY")}</Td>
      <Td>{currency.format(item.receivedAmount)}</Td>
      <Td>{item.pack?.nid ?? "-"}</Td>
      <Td>{percent.format(item.pack?.performance)}</Td>
      <Td>
        <InputGroup>
          <Input
            as={InputCurrency}
            textAlign="center"
            value={(item.agentCommissionPercentage ?? 0) * 100}
            onChange={(value) => onPercentageChange(index, value / 100)}
            isDisabled={!item.isCommissionable}
          />
          <InputRightElement>
            <IconButton
              size="sm"
              variant={item.agentCommissionPercentage === item.defaultAgentCommissionPercentage ? "outline" : "solid"}
              colorScheme={item.agentCommissionPercentage === item.defaultAgentCommissionPercentage ? "gray" : "main"}
              icon={<Icon as={RiPercentFill} />}
              onClick={() => onPercentageChange(index, item.defaultAgentCommissionPercentage)}
              isDisabled={!item.isCommissionable}
            />
          </InputRightElement>
        </InputGroup>
      </Td>
      <Td textAlign="center" fontWeight="semibold">
        <Box _light={{ bg: "gray.100" }} _dark={{ bg: "gray.800" }} p="12px" borderRadius="lg">
          {currency.format(item.agentCommissionAmount)}
        </Box>
      </Td>
    </Tr>
  );
});

const Payments = () => {
  const { formData, setFormData, payments, isLoadingPayments, refreshPayments } = useContext(PackSettlementsDetailsContext);

  useEffect(() => {
    const filtered = _.filter(payments?.data, (o) => !o.pack || o.pack.isClosed);
    const mapped = filtered.map((item) => {
      let agentCommissionPercentage = item.pack?.agentCommissionPercentage ?? 0;
      let agentCommissionAmount = agentCommissionPercentage * item.receivedAmount;
      return {
        ...item,
        defaultAgentCommissionPercentage: agentCommissionPercentage,
        agentCommissionPercentage,
        agentCommissionAmount,
        isCommissionable: item.pack?.isClosed === true,
        isChecked: true,
      };
    });
    setFormData((state) => ({ ...state, payments: mapped }));
  }, [payments]);

  useEffect(() => {
    const counters = { paymentsReceivedAmount: 0, paymentsAgentCommissionAmount: 0 };
    for (const item of formData.payments ?? []) {
      counters.paymentsReceivedAmount += item.receivedAmount;
      counters.paymentsAgentCommissionAmount += item.agentCommissionAmount;
    }
    setFormData((state) => ({ ...state, ...counters }));
  }, [formData.payments, setFormData]);

  const handleAgentCommissionPercentageChange = useCallback((index, value) => {
    setFormData((state) => {
      const payments = [...state.payments];
      payments[index].agentCommissionPercentage = value;
      payments[index].agentCommissionAmount = value * payments[index].receivedAmount;
      return { ...state, payments };
    });
  }, []);

  return (
    <>
      <HStack mb={4}>
        <Text fontSize="sm" fontWeight="semibold">
          {_.size(formData.payments)} registros
        </Text>
        <HStack flex="1" justify="flex-end">
          <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingPayments} onClick={refreshPayments} />
        </HStack>
      </HStack>

      <Table size="sm" whiteSpace="nowrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Usuário</Th>
            <Th>Data de pagamento</Th>
            <Th>Valor recebido</Th>
            <Th>Pacote de origem</Th>
            <Th>Desempenho</Th>
            <Th>Porcent. comissão rep.</Th>
            <Th>Valor. comissão rep.</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(formData.payments, (item, index) => (
            <ListItem
              key={item._id}
              index={index}
              item={item}
              agentCommissionPercentage={item.agentCommissionPercentage}
              agentCommissionAmount={item.agentCommissionAmount}
              onPercentageChange={handleAgentCommissionPercentageChange}
            />
          ))}
        </Tbody>
      </Table>
      {isLoadingPayments ? (
        <Center p="30px">
          <Spinner />
        </Center>
      ) : (
        _.size(formData.payments) === 0 && (
          <Center py="60px">
            <VStack maxW="lg">
              <Center w="200px" h="200px" position="relative" overflow="hidden">
                <Lottie animationData={animationData} style={{ width: 220, height: 220, position: "absolute" }} />
              </Center>
              <Heading size="lg" textAlign="center">
                Nenhum pagamento adicionado
              </Heading>
              <Text textAlign="center">Não encontramos pagamentos que correspondam ao representate selecionado.</Text>
            </VStack>
          </Center>
        )
      )}
    </>
  );
};

export default Payments;
