import { memo, useMemo } from "react";
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdInfoOutline } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { currency, translator } from "lib";
import { TbTruckDelivery } from "react-icons/tb";

export const PackExpedition = memo(() => {
  const location = useLocation();
  const data = useMemo(() => location.state?.packExpedition, [location.state?.packExpedition]);

  if (data?._id)
    return (
      <Flex mb="15px">
        <HStack bg="blue.500" spacing="15px" p="15px" borderRadius="md">
          <Center w="40px" h="40px" bg="whiteAlpha.300" borderRadius="full">
            <Icon as={TbTruckDelivery} boxSize="30px" color="white" />
          </Center>
          <Box flex="1" color="white">
            <Text fontSize="sm">Expedição relacionada</Text>
            <HStack divider={<Text px="5px">•</Text>}>
              <Text fontWeight="semibold">NID {data.nid}</Text>
            </HStack>
          </Box>
          <Popover>
            <PopoverTrigger>
              <IconButton variant="outline" colorScheme="whiteAlpha" icon={<Icon as={MdInfoOutline} />} />
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody p="20px">
                  <VStack alignItems="stretch">
                    <HStack>
                      <Text fontSize="xs">NID</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {data.nid}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Tipo de preço</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {translator(data.priceType ?? "-")}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Representante</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {data.agent?.name ?? "-"}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Valor carregado</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {currency.format(data.amount)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Quantidade carregada</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {data.quantity}
                      </Text>
                    </HStack>
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </HStack>
      </Flex>
    );
  return null;
});
