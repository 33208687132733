import React, { memo, useCallback, useMemo } from "react";
import _ from "lodash";
import { Icon, IconButton, Image, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { MdChevronRight, MdChevronLeft, MdClose } from "react-icons/md";

const Gallery = memo(({ activeIndex, data, isOpen, onClose, onChangeIndex }) => {
  const imgs = useMemo(() => _.filter(data, (o) => /^image/i.test(o.type)), [data]);

  const handleChangeIndex = useCallback(
    (increment) => {
      const nextIndex = activeIndex + increment;
      const lastIndex = imgs.length - 1;
      if (nextIndex > lastIndex) return onChangeIndex(0);
      if (nextIndex < 0) return onChangeIndex(lastIndex - 1);
      return onChangeIndex(nextIndex);
    },
    [imgs, activeIndex, onChangeIndex]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.700" />
      <IconButton
        colorScheme="blackAlpha"
        position="absolute"
        zIndex="9999"
        top="10px"
        right="10px"
        icon={<Icon as={MdClose} />}
        onClick={onClose}
      />
      <IconButton
        size="lg"
        colorScheme="blackAlpha"
        position="absolute"
        zIndex="9999"
        top="50%"
        left="20px"
        icon={<Icon as={MdChevronLeft} />}
        onClick={() => handleChangeIndex(-1)}
      />
      <IconButton
        size="lg"
        colorScheme="blackAlpha"
        position="absolute"
        zIndex="9999"
        top="50%"
        right="20px"
        icon={<Icon as={MdChevronRight} />}
        onClick={() => handleChangeIndex(1)}
      />
      <ModalContent>
        <Image borderRadius="md" src={imgs?.[activeIndex]?.location} />
      </ModalContent>
    </Modal>
  );
});

export default Gallery;
