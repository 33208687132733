import React, { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { currency, translator } from "lib";
import { useClipboard } from "hooks";
import { TbArrowAutofitDown, TbArrowAutofitUp, TbExternalLink } from "react-icons/tb";
import { MdOutlineCommentBank } from "react-icons/md";

const CircleIcon = memo(({ type, ...rest }) => {
  switch (type) {
    case "credit":
      return (
        <Center w="40px" h="40px" bg="green.100" borderRadius="full" cursor="pointer" _active={{ transform: "scale(0.9)" }} {...rest}>
          <Icon as={TbArrowAutofitDown} color="green.500" />
        </Center>
      );
    default:
      return (
        <Center w="40px" h="40px" bg="gray.100" borderRadius="full" cursor="pointer" _active={{ transform: "scale(0.9)" }} {...rest}>
          <Icon as={TbArrowAutofitUp} color="gray.800" />
        </Center>
      );
  }
});

export const Balance = memo(({ score, createdAt }) => {
  return (
    <HStack p="15px" _light={{ bg: "gray.100" }} _dark={{ bg: "whiteAlpha.100" }} justifyContent="space-between">
      <Text fontSize="sm">{moment(createdAt).format("dddd, DD [de] MMMM [de] YYYY")}</Text>
      <HStack divider={<Text px="5px">•</Text>}>
        <Text fontSize="sm">saldo do dia</Text>
        <Text fontWeight="bold">{score.toLocaleString()} pontos</Text>
      </HStack>
    </HStack>
  );
});

export const CreditDebit = memo(({ _id, releaseType, type, amount, relatedRef, related, score, comments, createdAt }) => {
  const copyToClipboard = useClipboard();
  return (
    <Box p="15px">
      <HStack spacing="20px">
        <CircleIcon type={type} onClick={() => copyToClipboard(_id)} />
        <Box flex="1">
          <Heading size="sm">Pontuação {type === "credit" ? "creditada" : "debitada"}</Heading>
          <Text fontSize="sm">{translator(releaseType)}</Text>
          {related?.paymentMethod && (
            <Text fontSize="xs" opacity="0.8">
              {translator(related.paymentMethod)}
            </Text>
          )}
        </Box>
        <Flex direction="column" alignItems="flex-end">
          <Text fontSize="sm">{moment(createdAt).format("dddd, DD [de] MMMM [de] YYYY [às] HH:mm")}</Text>
          <HStack divider={<Text px="5px">•</Text>}>
            {amount && <Text fontSize="sm">{currency.format(amount)}</Text>}
            <Text fontSize="md" fontWeight="bold">
              {score.toLocaleString()} pontos
            </Text>
          </HStack>
          {relatedRef === "Payment" && related?._id && (
            <Button
              as={RouterLink}
              to={`/payments/edit/${related._id}`}
              size="xs"
              variant="outline"
              rightIcon={<Icon as={TbExternalLink} />}
              mt="5px"
            >
              visitar pagamento
            </Button>
          )}
          {comments?.length > 0 && (
            <Popover placement="left">
              <PopoverTrigger>
                <Button size="xs" variant="outline" rightIcon={<Icon as={MdOutlineCommentBank} />} mt="5px">
                  observações
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody fontSize="sm">{comments}</PopoverBody>
              </PopoverContent>
            </Popover>
          )}
        </Flex>
      </HStack>
    </Box>
  );
});
