import React, { useCallback, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  SlideFade,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { MdBusiness, MdKeyboardArrowRight, MdMail } from "react-icons/md";
import * as yup from "yup";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import { LogoAnimated } from "components";
import { SplashContext } from "SplashProvider";
import { AuthContext } from "AuthProvider";
import { __DEV__ } from "index";

export const SignInEmailAndCompany = () => {
  const navigate = useNavigate();
  const { fetchUsersWithEmail, sendVerificationCode } = useContext(AuthContext);
  const { isAppReady } = useContext(SplashContext);
  const [formData, setFormData] = useState(__DEV__ ? { email: "marcotulio.magalhaes@gmail.com" } : {});
  const [users, setUsers] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingFetchUsersWithEmail, setIsLoadingFetchUsersWithEmail] = useState(false);
  const [isLoadingSendVerificationCode, setIsLoadingSendVerificationCode] = useState({});
  const [autoplay, setAutoplay] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    if (isAppReady) {
      const timeout = setTimeout(() => setAutoplay(true), 1000);
      return () => clearTimeout(timeout);
    }
  }, [isAppReady]);

  const handleFetchUsersWithEmail = useCallback(
    async ({ email }) => {
      try {
        setIsLoadingFetchUsersWithEmail(true);
        const users = await fetchUsersWithEmail(email);
        if (users.length === 1) {
          const [{ _id }] = users;
          await sendVerificationCode(_id);
          navigate(`/sign-in/verification-code`, { state: { _id, email } });
        } else setUsers(users);
      } catch (error) {
        toast({ description: error.message, status: "error", duration: 5000, isClosable: true });
      } finally {
        setIsLoadingFetchUsersWithEmail(false);
      }
    },
    [navigate, toast, fetchUsersWithEmail]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
        });
        await schema.validate(formData, { abortEarly: false });
        handleFetchUsersWithEmail(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [handleFetchUsersWithEmail, formData]
  );

  const handleSendVerificationCode = useCallback(
    async (_id) => {
      try {
        setIsLoadingSendVerificationCode((state) => ({ ...state, [_id]: true }));
        await sendVerificationCode(_id);
        navigate(`/sign-in/verification-code`, { state: { _id, email: formData.email } });
      } catch (error) {
        toast({ description: error.message, status: "error", duration: 5000, isClosable: true });
      } finally {
        setIsLoadingSendVerificationCode((state) => ({ ...state, [_id]: false }));
      }
    },
    [sendVerificationCode, formData.email]
  );

  return (
    <>
      <VStack spacing={0} mb={6}>
        <LogoAnimated width={100} loop={false} autoplay={autoplay} pulse={false} speed={0.5} />
        <Heading>ConsigSys</Heading>
        <Text fontSize="sm" textAlign="center">
          Por favor, informe suas credenciais para que possamos enviar o código de verificação.
        </Text>
      </VStack>
      <SlideFade in={users.length >= 1} hidden={users.length === 0} offsetX="20px">
        <VStack alignItems="stretch">
          {users.map((user) => (
            <Button
              key={user._id}
              h="auto"
              p="15px"
              variant="outline"
              leftIcon={<Icon as={MdBusiness} />}
              rightIcon={isLoadingSendVerificationCode[user._id] ? <Spinner size="sm" /> : <Icon as={MdKeyboardArrowRight} />}
              onClick={() => handleSendVerificationCode(user._id)}
              isDisabled={Object.values(isLoadingSendVerificationCode).includes(true)}
            >
              <Box flex="1">
                <Text fontWeight="semibold">{user.company.name}</Text>
                <Text fontSize="xs" fontWeight="normal">
                  {user.name}
                </Text>
              </Box>
            </Button>
          ))}
        </VStack>
      </SlideFade>
      <SlideFade in={users.length === 0} hidden={users.length >= 1} offsetX="20px">
        <form onSubmit={handleSubmit}>
          <VStack alignItems="stretch" spacing={4}>
            <FormControl isRequired={true} isInvalid={formErrors.email}>
              <FormLabel fontSize="sm">E-mail</FormLabel>
              <InputGroup>
                <InputLeftAddon>
                  <Icon as={MdMail} />
                </InputLeftAddon>
                <Input
                  value={formData.email ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))}
                />
              </InputGroup>
              <FormErrorMessage>{formErrors.email}</FormErrorMessage>
            </FormControl>
            <Button
              type="submit"
              colorScheme="main"
              rightIcon={<Icon as={MdKeyboardArrowRight} />}
              isLoading={isLoadingFetchUsersWithEmail}
            >
              Enviar
            </Button>
          </VStack>
        </form>
      </SlideFade>
    </>
  );
};
