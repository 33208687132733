import React, { useCallback, memo, useState } from "react";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { VStack } from "@chakra-ui/react";
import ImageItem from "./item";
import Gallery from "./gallery";

export const List = memo(({ data = [], onChange }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleDelete = useCallback(
    (_id) => {
      const imgs = [...data];
      const index = _.findIndex(imgs, (o) => o._id === _id);
      imgs.splice(index, 1);
      onChange(imgs);
    },
    [data, onChange]
  );

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      const imgs = [...data];
      const [removed] = imgs.splice(result.source.index, 1);
      imgs.splice(result.destination.index, 0, removed);
      onChange(imgs);
    },
    [data, onChange]
  );

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <VStack {...provided.droppableProps} ref={provided.innerRef} align="stretch">
              {_.map(data, (item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided, snapshot) => (
                    <ImageItem
                      {...{ provided, snapshot }}
                      _id={item._id}
                      title={item.title}
                      type={item.type}
                      size={item.size}
                      thumb={item.thumb}
                      location={item.location}
                      onExpand={() => setActiveIndex(index)}
                      onDelete={handleDelete}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>
      <Gallery
        data={data}
        activeIndex={activeIndex}
        onChangeIndex={(index) => setActiveIndex(index)}
        isOpen={activeIndex !== null}
        onClose={() => setActiveIndex(null)}
      />
    </>
  );
});
