import React from "react";
import { Select, VStack, FormControl, FormLabel, Textarea, Input } from "@chakra-ui/react";
import _ from "lodash";
import { Label, InputSize } from "../components";

export const TextCore = ({ data, onChange }) => (
  <>
    <FormControl>
      <FormLabel fontSize="xs" mb="2px">
        Tamanho
      </FormLabel>
      <InputSize
        precision="0"
        defaultUnit="px"
        value={data.props?.fontSize}
        onChange={(fontSize) => onChange({ ...data, props: { ...data.props, fontSize } })}
      />
    </FormControl>
    <FormControl>
      <FormLabel fontSize="xs" mb="2px">
        Peso
      </FormLabel>
      <Select
        value={data.props?.fontWeight ?? ""}
        onChange={({ target }) => onChange({ ...data, props: { ...data.props, fontWeight: target.value } })}
      >
        {[
          _.range(100, 1000, 100).map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          )),
        ]}
      </Select>
    </FormControl>
    <FormControl>
      <FormLabel fontSize="xs" mb="2px">
        Estilo
      </FormLabel>
      <Select
        value={data.props?.fontStyle ?? ""}
        onChange={({ target }) => onChange({ ...data, props: { ...data.props, fontStyle: target.value } })}
      >
        <option value="normal">Normal</option>
        <option value="italic">Itálico</option>
      </Select>
    </FormControl>
    <FormControl>
      <FormLabel fontSize="xs" mb="2px">
        Alinhamento
      </FormLabel>
      <Select
        value={data.props?.textAlign ?? ""}
        onChange={({ target }) => onChange({ ...data, props: { ...data.props, textAlign: target.value } })}
      >
        <option value="left">Esquerda</option>
        <option value="center">Centro</option>
        <option value="right">Direita</option>
        <option value="justify">Justificado</option>
      </Select>
    </FormControl>
    <FormControl>
      <Label onClean={() => onChange({ ...data, props: { ...data.props, color: undefined } })}>Cor do conteúdo</Label>
      <Input
        value={data.props.color ?? ""}
        onChange={({ target }) => onChange({ ...data, props: { ...data.props, color: target.value } })}
      />
    </FormControl>
  </>
);

export const Text = ({ data, onChange }) => {
  return (
    <VStack>
      <TextCore data={data} onChange={onChange} />
      <FormControl>
        <FormLabel fontSize="xs" mb="2px">
          Conteúdo
        </FormLabel>
        <Textarea fontSize="xs" value={data.children ?? ""} onChange={({ target }) => onChange({ ...data, children: target.value })} />
      </FormControl>
    </VStack>
  );
};
