import React, { useContext, useCallback, useMemo, useEffect } from "react";
import { FormControl, FormErrorMessage, FormLabel, Grid, GridItem, HStack, Icon, IconButton, Input, Select } from "@chakra-ui/react";
import InputMask from "react-input-mask";
import moment from "moment";
import { api, currency } from "lib";
import { BoxData, EntryMerchandises, AsyncSelect } from "components";
import { MdClose } from "react-icons/md";
import StockMovementsDetailsContext from "./context";

let loadSuppliersTimeout = null;

const General = () => {
  const { formData, formErrors, setFormData } = useContext(StockMovementsDetailsContext);
  const isEntry = useMemo(() => ["exit", "recount"].indexOf(formData.type) === -1, [formData.type]);
  const isAmountEditable = useMemo(
    () => ["entry", "recount"].indexOf(formData.type) !== -1 && !formData.isClosed,
    [formData.type, formData.isClosed]
  );

  useEffect(() => {
    setFormData((state) => ({ ...state, priceType: "costPrice" }));
  }, [formData.type]);

  const handleLoadSuppliers = useCallback((search, cb) => {
    clearTimeout(loadSuppliersTimeout);
    loadSuppliersTimeout = setTimeout(async () => {
      const query = { roles: "supplier" };
      const response = await api.get("/users", { params: { query, search, perPage: 20 } });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={8}>
          <FormControl isRequired={true} isInvalid={formErrors.title}>
            <FormLabel fontSize="sm">Título</FormLabel>
            <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
            <FormErrorMessage>{formErrors.title}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl isRequired={true} isInvalid={formErrors.author}>
            <BoxData label="Autor" value={formData.author?.name ?? "-"} />
            <FormErrorMessage>{formErrors.author}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={isEntry ? 6 : 4}>
          <FormControl isRequired={true} isInvalid={formErrors.status}>
            <FormLabel fontSize="sm">Status</FormLabel>
            <Select value={formData.status} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
              <option>--Selecione</option>
              <option value="pending">Pendente</option>
              <option value="closed">Fechado</option>
            </Select>
            <FormErrorMessage>{formErrors.status}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={isEntry ? 6 : 4}>
          <FormControl isRequired={true} isInvalid={formErrors.type}>
            <FormLabel fontSize="sm">Tipo</FormLabel>
            <Select value={formData.type} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
              <option>--Selecione</option>
              <option value="entry">Entrada</option>
              <option value="exit">Saída</option>
              <option value="recount">Recontagem</option>
            </Select>
            <FormErrorMessage>{formErrors.type}</FormErrorMessage>
          </FormControl>
        </GridItem>
        {formData.type === "exit" && (
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.priceType}>
              <FormLabel fontSize="sm">Tipo de preço</FormLabel>
              <Select
                value={formData.priceType ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, priceType: target.value }))}
              >
                <option value="costPrice">Custo</option>
                <option value="wholesalePrice">Atacado</option>
                <option value="retailPrice">Varejo</option>
              </Select>
              <FormErrorMessage>{formErrors.priceType}</FormErrorMessage>
            </FormControl>
          </GridItem>
        )}
        {formData.type === "recount" && (
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.notListedProducts}>
              <FormLabel fontSize="sm">Produtos não listados</FormLabel>
              <Select
                value={formData.notListedProducts}
                onChange={({ target }) => setFormData((state) => ({ ...state, notListedProducts: target.value }))}
              >
                <option>--Selecione</option>
                <option value="ignore">Ignorar</option>
                <option value="reset_stock">Zerar estoque</option>
              </Select>
              <FormErrorMessage>{formErrors.notListedProducts}</FormErrorMessage>
            </FormControl>
          </GridItem>
        )}
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={3}>
          <FormControl isInvalid={formErrors.fiscalDocument}>
            <FormLabel fontSize="sm">Documento fiscal</FormLabel>
            <Input
              value={formData.fiscalDocument ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, fiscalDocument: target.value }))}
            />
            <FormErrorMessage>{formErrors.fiscalDocument}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl isInvalid={formErrors.supplier}>
            <FormLabel fontSize="sm">Fornecedor</FormLabel>
            <AsyncSelect
              isInvalid={formErrors.supplier}
              value={formData.supplier}
              defaultOptions
              loadOptions={handleLoadSuppliers}
              placeholder="Selecione o fornecedor"
              onChange={(supplier) => setFormData((state) => ({ ...state, supplier }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name }) => name}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.supplier}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl isInvalid={formErrors.manufacturer}>
            <FormLabel fontSize="sm">Fabricante</FormLabel>
            <Input
              value={formData.manufacturer ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, manufacturer: target.value }))}
            />
            <FormErrorMessage>{formErrors.manufacturer}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl isInvalid={formErrors.purchaseDate}>
            <FormLabel fontSize="sm">Data da compra</FormLabel>
            <Input
              as={InputMask}
              mask="99/99/9999"
              value={formData.purchaseDate ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, purchaseDate: target.value }))}
            />
            <FormErrorMessage>{formErrors.purchaseDate}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Mercadorias" value={formData.quantity?.toLocaleString?.() ?? 0} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Valor" value={currency.format(formData.amount ?? 0)} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Linhas" value={formData.merchandises?.length ?? 0} />
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Criação" value={moment(formData?.createdAt).format("D, MMMM yyyy [às] HH:mm")} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Atualização" value={moment(formData?.updatedAt).format("D, MMMM yyyy [às] HH:mm")} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Fechamento" value={formData?.closedAt ? moment(formData?.closedAt).format("D, MMMM yyyy [às] HH:mm") : "-"} />
          </FormControl>
        </GridItem>
      </Grid>

      <EntryMerchandises formData={formData} setFormData={setFormData} isAmountEditable={isAmountEditable} />
    </>
  );
};

export default General;
