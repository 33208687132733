import React, { useContext, useState } from "react";
import { Box, Text, Button, Icon, HStack, IconButton, Divider, Flex } from "@chakra-ui/react";
import _ from "lodash";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { BuilderContext } from "..";
import { elementPrototypes } from "../../../consts";

export const Layers = () => {
  const { formData, currentElement, setCurrentElement } = useContext(BuilderContext);
  const [opened, setOpened] = useState({});
  const [isVisible, setIsVisible] = useState(true);

  const renderLayers = (children) => {
    return children.map((child) => {
      return (
        <Box key={child.key} borderLeftWidth="1px">
          <HStack p="5px" cursor="pointer">
            {_.isArray(child.children) ? (
              <IconButton
                size="xs"
                icon={<Icon as={opened[child.key] !== false ? MdKeyboardArrowDown : MdKeyboardArrowRight} />}
                onClick={() =>
                  setOpened((state) => ({ ...state, [child.key]: state[child.key] === undefined ? false : !state[child.key] }))
                }
              />
            ) : (
              <Box paddingLeft="22px" />
            )}
            <Button size="xs" colorScheme={currentElement.key === child.key ? "main" : "gray"} onClick={() => setCurrentElement(child)}>
              {elementPrototypes.find((o) => o.type === child.elementType).title}
            </Button>
          </HStack>
          {opened[child.key] !== false && <Box paddingLeft="15px">{_.isArray(child.children) && renderLayers(child.children)}</Box>}
        </Box>
      );
    });
  };

  return (
    <Flex direction="column" width={isVisible ? "200px" : "auto"} overflowY="hidden">
      <HStack p="10px" justifyContent="space-between">
        {isVisible && (
          <Text fontWeight="bold" fontSize="sm">
            Layers
          </Text>
        )}
        <IconButton
          size="xs"
          icon={<Icon as={isVisible ? RiMenuFoldLine : RiMenuUnfoldLine} />}
          onClick={() => setIsVisible((state) => !state)}
        />
      </HStack>
      <Divider />
      {isVisible && (
        <Box overflowY="auto" flex={1}>
          {renderLayers(formData.elements)}
        </Box>
      )}
    </Flex>
  );
};
