import React, { useEffect, useState, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import _ from "lodash";
import * as yup from "yup";
import { api } from "lib";
import { messages } from "consts";
import { useCustomToast } from "hooks";
import { InputCurrency } from "components";
import { UsersDetailsContext } from "../index";
import { ScoresContext } from "./index";

const AddScore = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const { formData: currentUserData, setFormData: setCurrentUserData } = useContext(UsersDetailsContext);
  const { refreshData } = useContext(ScoresContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenSubmitConfirmDialog, onOpen: onOpenSubmitConfirmDialog, onClose: onCloseSubmitConfirmDialog } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({ type: "credit" });
    setFormErrors({});
  }, [isOpen]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const { score } = await api.post(`/users/${_id}/scores`, data);
        toast({ status: "success", description: messages.success.saveData, isClosable: true });
        setCurrentUserData((state) => ({ ...state, score }));
        onClose();
        refreshData();
      } catch (error) {
        toast({ status: "error", description: error.message, isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [toast, setCurrentUserData, onClose, refreshData, _id]
  );

  const handleSubmit = useCallback(async () => {
    try {
      onCloseSubmitConfirmDialog();
      const schema = yup.object().shape({
        type: yup.string().required(messages.error.required),
        score: yup.number().min(0.01, `${messages.error.greaterOrEqual} 0,01.`).required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [handleSaveData, onCloseSubmitConfirmDialog, _id, formData]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Box>
              <Text fontSize="lg" lineHeight="1">
                Nova pontuação
              </Text>
              <HStack divider={<Text px="5px">•</Text>}>
                <Text fontSize="sm" fontWeight="normal">
                  Saldo atual
                </Text>
                <Text fontSize="sm">{currentUserData.score?.toLocaleString() || 0} pontos</Text>
              </HStack>
            </Box>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={6}>
                <FormControl isInvalid={formErrors.type}>
                  <FormLabel fontSize="sm">Tipo de pessoa</FormLabel>
                  <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
                    <option value="credit">Crédito</option>
                    <option value="debit">Débito</option>
                  </Select>
                  <FormErrorMessage>{formErrors.type}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={6}>
                <FormControl isRequired={true} isInvalid={formErrors.score}>
                  <FormLabel fontSize="sm">Pontuação</FormLabel>
                  <Input
                    as={InputCurrency}
                    value={formData.score ?? ""}
                    onChange={(score) => setFormData((state) => ({ ...state, score }))}
                  />
                  <FormErrorMessage>{formErrors.score}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.comments}>
                  <FormLabel fontSize="sm">Observações</FormLabel>
                  <Textarea
                    value={formData.comments ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack}>
            <Button onClick={onClose}>cancelar</Button>
            <Button colorScheme="main" isLoading={isLoadingSaveData} onClick={onOpenSubmitConfirmDialog}>
              salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={isOpenSubmitConfirmDialog} onClose={onCloseSubmitConfirmDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Atenção
            </AlertDialogHeader>
            <AlertDialogBody>Deseja realmente salvar a nova movimentação nesta carteira?</AlertDialogBody>
            <AlertDialogFooter as={HStack}>
              <Button onClick={onCloseSubmitConfirmDialog}>cancelar</Button>
              <Button colorScheme="yellow" onClick={handleSubmit}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AddScore;
