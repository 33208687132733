import React, { memo } from "react";
import { Image as ChakraImage, Spinner, Center } from "@chakra-ui/react";
import ImgPdf from "assets/imgs/pdf.jpg";

const getSrc = (type, src) => {
  switch (type) {
    case "application/pdf":
      return ImgPdf;
    default:
      return src;
  }
};

export const Image = memo(({ title, type, src, ...props }) => (
  <ChakraImage
    src={getSrc(type, src)}
    objectFit="cover"
    borderRadius="md"
    alt={title}
    fallback={
      <Center py={6}>
        <Spinner size="sm" />
      </Center>
    }
    {...props}
  />
));
