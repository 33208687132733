import React, { useState, useEffect, useMemo, useCallback, createContext, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  SlideFade,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdChevronLeft, MdClose, MdMoreHoriz, MdOutlineLocalPrintshop } from "react-icons/md";
import * as yup from "yup";
import { api, currency } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { messages } from "consts";
import { Breadcrumb, AsyncSelect, BoxData, DatePicker, Tags, Portal } from "components";
import { Content } from "pages/Private/Container";
import Packs from "./packs";
import { TbExternalLink } from "react-icons/tb";
import { FiSmartphone } from "react-icons/fi";

let loadUsersTimeout = null;

export const ExpeditionsDetailsContext = createContext();

export const PackExpeditionsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar expedição de pacotes" : "Nova expedição de pacotes");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/pack-expeditions/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenSubmitConfirmDialog, onOpen: onOpenSubmitConfirmDialog, onClose: onCloseSubmitConfirmDialog } = useDisclosure();
  const toast = useCustomToast();
  const tagsSelectorRef = useRef();

  useEffect(() => {
    if (!_id) {
      const id = "sound-alerts";
      if (!toast.isActive(id)) toast({ id, status: "sound", position: "top-right", isClosable: true, duration: 5000 });
    }
  }, [_id]);

  useEffect(() => {
    const formData = data ?? { packs: [] };
    if (formData.predictedSettlementDate && moment(formData.predictedSettlementDate).isValid())
      formData.predictedSettlementDate = moment(formData.predictedSettlementDate).toDate();
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = await api.post("/pack-expeditions", data);
        navigate(`/pack-expeditions/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      onCloseSubmitConfirmDialog();
      const schema = yup.object().shape({
        agent: yup.string().required(messages.error.required),
        predictedSettlementDate: yup.date().typeError(messages.error.required).required(messages.error.required),
        packs: yup.array().min(1, messages.error.required),
      });
      const data = { ...formData, agent: formData.agent?._id };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData, onCloseSubmitConfirmDialog]);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const query = { roles: "agent" };
      const response = await api.get("/users", { params: { query, search, perPage: 20 } });
      cb(_.map(response?.data, (o) => ({ ...o, isDisabled: o.ownership === "app" })));
    }, 1000);
  }, []);

  const handleOpenTags = useCallback(() => {
    const documents = _.map(formData.packs, (document) => ({ document, quantity: 1 }));
    tagsSelectorRef.current.open("pack", documents, false);
  }, [formData.packs]);

  return (
    <ExpeditionsDetailsContext.Provider value={{ formData, setFormData, formErrors }}>
      <Content>
        <HStack justify="space-between">
          <HStack spacing="10px">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "consignação" },
                { to: "/pack-expeditions", label: "expedições de pacotes" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {!_id && (
            <SlideFade in={true} offsetY="-20px">
              <Box>
                <Menu>
                  <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                    mais ações
                  </MenuButton>
                  <Portal>
                    <MenuList fontSize="sm">
                      <MenuItem onClick={handleOpenTags}>
                        <HStack>
                          <Icon as={MdOutlineLocalPrintshop} />
                          <Text>imprimir etiquetas</Text>
                        </HStack>
                      </MenuItem>
                    </MenuList>
                  </Portal>
                </Menu>
              </Box>
            </SlideFade>
          )}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Expedição de pacotes</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ?? "Novo cadastro"}</Text>
          </Box>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={2}>
            <FormControl isInvalid={formErrors.nid}>
              <BoxData label="NID" value={formData.nid ?? "-"} />
              <FormErrorMessage>{formErrors.nid}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={10}>
            <FormControl isInvalid={formErrors.agent}>
              <FormLabel fontSize="sm">Representante</FormLabel>
              <AsyncSelect
                isInvalid={formErrors.agent}
                value={formData.agent}
                defaultOptions
                loadOptions={handleLoadUsers}
                placeholder="Selecione o representante"
                onChange={(agent) => setFormData((state) => ({ ...state, agent }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name, isDisabled }) => (
                  <HStack>
                    <Text flex="1">{name}</Text>
                    {isDisabled && <Icon as={FiSmartphone} />}
                  </HStack>
                )}
                isClearable={true}
              />
              <FormErrorMessage>{formErrors.agent}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={6}>
            <FormControl isInvalid={formErrors.priceType}>
              <FormLabel fontSize="sm">Tipo de preço</FormLabel>
              <Select
                value={formData.priceType ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, priceType: target.value }))}
              >
                <option value="">--Selecione</option>
                <option value="costPrice">Custo</option>
                <option value="wholesalePrice">Atacado</option>
                <option value="retailPrice">Varejo</option>
              </Select>
              <FormErrorMessage>{formErrors.priceType}</FormErrorMessage>
              <FormHelperText>
                O tipo de preço é um atributo opcional, contudo, se preenchido, só serão permitidos pacotes do tipo de preço selecionado.
              </FormHelperText>
            </FormControl>
          </GridItem>
          <GridItem colSpan={6}>
            <FormControl isRequired={true} isInvalid={formErrors.predictedSettlementDate}>
              <FormLabel fontSize="sm">Previsão de acerto</FormLabel>
              <DatePicker
                selected={formData.predictedSettlementDate}
                onChange={(value) => setFormData((state) => ({ ...state, predictedSettlementDate: moment(value).startOf("day").toDate() }))}
                dateFormat="d, MMMM yyyy"
              />
              <FormErrorMessage>{formErrors.predictedSettlementDate}</FormErrorMessage>
              <FormHelperText>
                Caso o pacote carregado já possua data prevista de acerto, esta será sobrescrita pela data informada acima.
              </FormHelperText>
            </FormControl>
          </GridItem>
        </Grid>

        <Divider my={8} />

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={6}>
            <FormControl isRequired={true} isInvalid={formErrors.amount}>
              <BoxData
                label="Pacotes"
                value={formData.packsCount?.toLocaleString() ?? 0}
                RightComponent={
                  _id && (
                    <Button
                      size="sm"
                      variant="outline"
                      rightIcon={<Icon as={TbExternalLink} />}
                      mt="5px"
                      onClick={() => navigate(`/packs`, { state: { packExpedition: formData } })}
                      isDisabled={!formData._id}
                    >
                      visitar
                    </Button>
                  )
                }
              />
              <FormErrorMessage>{formErrors.packsCount}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={6}>
            <FormControl isRequired={true} isInvalid={formErrors.amount}>
              <BoxData label="Valor carregado" value={currency.format(formData.amount ?? 0)} />
              <FormErrorMessage>{formErrors.amount}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={6}>
            <FormControl isRequired={true} isInvalid={formErrors.quantity}>
              <BoxData label="Quantidade carregada" value={formData.quantity?.toLocaleString() ?? 0} />
              <FormErrorMessage>{formErrors.quantity}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={6}>
            <FormControl isRequired={true} isInvalid={formErrors.createdAt}>
              <BoxData label="Fechado em" value={formData.createdAt ? moment(formData.createdAt).format("D, MMMM yyyy") : "-"} />
              <FormErrorMessage>{formErrors.createdAt}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        {!_id && <Packs />}
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          {!_id && (
            <Button
              size="sm"
              colorScheme="main"
              isLoading={isLoadingData || isLoadingSaveData}
              isDisabled={_id}
              onClick={onOpenSubmitConfirmDialog}
            >
              salvar
            </Button>
          )}
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            {_id ? "voltar" : "cancelar"}
          </Button>
        </HStack>
      </SlideFade>

      <AlertDialog isOpen={isOpenSubmitConfirmDialog} onClose={onCloseSubmitConfirmDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Atenção
            </AlertDialogHeader>
            <AlertDialogBody>
              Esta é uma ação irreversível. Você está realizando o fechamento deste pagamento. Deseja realmente continuar?
            </AlertDialogBody>
            <AlertDialogFooter as={HStack}>
              <Button onClick={onCloseSubmitConfirmDialog}>cancelar</Button>
              <Button colorScheme="yellow" onClick={handleSubmit}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Tags.Selector ref={tagsSelectorRef} />
    </ExpeditionsDetailsContext.Provider>
  );
};
