import React from "react";
import ReactBarCode from "react-barcode";
import { Text } from "@chakra-ui/react";

export const BarCode = ({ children, width, height, displayValue, fontSize, ...props }) => {
  return (
    <Text p="1px" {...props}>
      <ReactBarCode value={children.toString()} {...{ width, height, displayValue, fontSize }} />
    </Text>
  );
};
