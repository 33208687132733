import moment from "moment";
import { currency, percent, translator } from "lib";
import { CustomBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <CustomBadge.Commissions.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "type",
    title: "Tipo",
    isVisible: true,
    formatter: ({ type }) => translator(type),
  },
  {
    accessor: "percentage",
    title: "Porcentagem",
    isVisible: true,
    formatter: ({ percentage }) => percent.format(percentage),
  },
  {
    accessor: "amount",
    title: "Valor",
    isVisible: true,
    formatter: ({ amount }) => currency.format(amount),
  },
  {
    accessor: "pack",
    title: "Pacote",
    isVisible: true,
    formatter: ({ pack }) => pack?.nid ?? "-",
  },
  {
    accessor: "user",
    title: "Usuário",
    isVisible: true,
    formatter: ({ user }) => user?.name ?? "-",
  },
  {
    accessor: "commissionRule",
    title: "Regra aplicada",
    isVisible: true,
    formatter: ({ commissionRule }) => commissionRule?.title ?? "-",
  },
  {
    accessor: "paidAt",
    title: "Pago em",
    isVisible: true,
    formatter: ({ paidAt }) => (paidAt ? moment(paidAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "createdAt",
    title: "Criação",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualização",
    isVisible: false,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
