import React, { useEffect } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  SimpleGrid,
  Text,
  VStack,
  Image,
  Box,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";

export const Selecteds = ({ data = [], isOpen, onClose, onSelect }) => {
  useEffect(() => {
    if (data.length === 0) onClose?.();
  }, [data, onClose]);

  return (
    <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
      <DrawerOverlay zIndex="1400" />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader as={VStack} spacing={0} justify="stretch" align="left">
          <Text fontSize="md">Arquivos selecionados</Text>
          <Text fontSize="xs" fontWeight="normal">
            Os arquivos abaixo foram selecionados. Para desselecionar clique no arquivo desejado.
          </Text>
        </DrawerHeader>
        <DrawerBody pb={6}>
          <SimpleGrid columns={[4, 12]} spacing={2}>
            {data.map((selected) => (
              <Box key={selected._id} position="relative">
                <Image boxSize="100px" objectFit="cover" src={selected.thumb} alt={selected.title} borderRadius="lg" />
                <IconButton
                  icon={<Icon as={MdClose} />}
                  colorScheme="blackAlpha"
                  position="absolute"
                  w="100%"
                  h="100%"
                  top="0"
                  left="0"
                  opacity="0"
                  _hover={{ opacity: 1 }}
                  onClick={() => onSelect(selected)}
                />
              </Box>
            ))}
          </SimpleGrid>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
