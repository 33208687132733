import React, { useMemo } from "react";
import _ from "lodash";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { Portal } from "components";
import { phoneCategories } from "consts";
import { MdRemove } from "react-icons/md";

export const PhoneInput = ({ isRequired, isInvalid, label, phone, category, onChangePhone, onChangeCategory }) => {
  const { icon, mask } = useMemo(() => phoneCategories.find((o) => o.value === category) ?? {}, [category]);

  return (
    <FormControl {...{ isRequired, isInvalid }}>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <InputGroup>
        <InputLeftElement>
          <Menu closeOnSelect={false}>
            <MenuButton as={IconButton} size="sm" variant="outline" icon={<Icon as={icon ?? MdRemove} />} />
            <Portal>
              <MenuList minWidth="240px" zIndex="9999">
                <MenuOptionGroup title="Categoria do telefone" type="radio" value={category} onChange={onChangeCategory}>
                  {phoneCategories.map(({ icon, label, value }) => (
                    <MenuItemOption key={value} value={value}>
                      <HStack justifyContent="space-between">
                        <Text fontSize="xs">{label}</Text>
                        <Icon as={icon} boxSize="15px" />
                      </HStack>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Portal>
          </Menu>
        </InputLeftElement>
        <Input as={InputMask} mask={mask} value={phone ?? ""} onChange={({ target }) => onChangePhone(target.value)} />
      </InputGroup>
      <FormErrorMessage>{isInvalid}</FormErrorMessage>
    </FormControl>
  );
};
