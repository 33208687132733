import React, { useContext } from "react";
import { VStack, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { TextCore } from "./Text";
import { BuilderContext } from "..";
import { processors } from "../../../lib";

export const Variable = ({ data, onChange }) => {
  const { formData } = useContext(BuilderContext);
  return (
    <VStack>
      <FormControl>
        <FormLabel fontSize="xs" mb="2px">
          Valor
        </FormLabel>
        <Select value={data.children} onChange={({ target }) => onChange({ ...data, children: target.value })}>
          {Object.keys(processors[formData.type]).map((key) => (
            <option key={key} value={`[${key}]`}>
              {key}
            </option>
          ))}
        </Select>
      </FormControl>
      <TextCore data={data} onChange={onChange} />
    </VStack>
  );
};
