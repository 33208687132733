import React, { useState, useEffect, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Link,
  ModalFooter,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { Lottie } from "components";
import { EventEmitter } from "lib";
import animationData from "assets/lottie/sad-box.json";

export const MerchandiseMovement = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const onEvent = ({ data }) => setData(data);
    EventEmitter.subscribe("onMerchandiseMovement", onEvent);
  }, []);

  const handleClose = useCallback(() => setData(null), []);

  return (
    <Drawer isOpen={data} onClose={handleClose} size="md" isCentered>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader as={HStack} spacing="20px">
          <Lottie animationData={animationData} style={{ width: 150 }} />
          <Box>
            <Heading size="md">{data?.title}</Heading>
            <Text fontSize="sm" fontWeight="normal">
              {data?.message}
            </Text>
          </Box>
        </DrawerHeader>
        <Divider />
        <DrawerBody>
          <VStack alignItems="stretch" spacing="0" divider={<StackDivider />}>
            {_.map(data?.merchandises, (item) => (
              <HStack key={item._id} p="15px">
                <Box flex="1">
                  <HStack lineHeight="1" divider={<Text px="5px">•</Text>}>
                    <Text fontSize="xs">NID {item.nid}</Text>
                    <Text fontSize="xs">SKU {item.sku}</Text>
                  </HStack>
                  <Link as={RouterLink} to={`/products/edit/${item._id}`} target="_blank" rel="noopener noreferrer">
                    <Text fontWeight="semibold">{item.name}</Text>
                  </Link>
                </Box>
                <VStack spacing="0" alignItems="flex-end">
                  <HStack>
                    <Text fontSize="xs">Quantidade atual</Text>
                    <Text fontSize="xs" fontWeight="bold">
                      {item.currentQuantity.toLocaleString()}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize="xs">Quantidade pretendida</Text>
                    <Text fontSize="xs" fontWeight="bold">
                      {item.intendedQuantity.toLocaleString()}
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
            ))}
          </VStack>
        </DrawerBody>
        <Divider />
        <ModalFooter>
          <Button size="sm" colorScheme="main" onClick={handleClose}>
            fechar
          </Button>
        </ModalFooter>
      </DrawerContent>
    </Drawer>
  );
};
