import { useEffect, useState, useCallback, useMemo } from "react";
import _ from "lodash";

export const useTable = (props) => {
  const [columns, setColumns] = useState([]);
  const cells = useMemo(() => _.filter(columns, (o) => o.isVisible), [columns]);

  useEffect(() => {
    const cols = _.map(props.defaultColumns, (item) => ({ ...item }));
    try {
      const data = JSON.parse(localStorage.getItem(props.id));
      if (data._v !== props._v) {
        localStorage.removeItem(props.id);
        throw new Error();
      }
      const columns = [];
      for (const { accessor, isVisible } of data.columns) {
        const finded = cols.find((o) => o.accessor === accessor);
        columns.push({ ...finded, isVisible });
      }
      setColumns(columns);
    } catch (error) {
      setColumns(cols);
    }
  }, [props]);

  const updateTableColumns = useCallback(
    async (columns) => {
      const data = {
        id: props.id,
        columns: _.map(columns, ({ accessor, isVisible }) => ({ accessor, isVisible })),
        _v: props._v,
      };
      localStorage.setItem(props.id, JSON.stringify(data));
      setColumns(columns);
    },
    [props.id, props._v]
  );

  return { columns, cells, updateTableColumns };
};
