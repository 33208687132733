export const messages = {
  success: {
    saveData: "Registro salvo com sucesso!",
    deleteData: "Registro removido com sucesso!",
    importData: "Importação realizada com sucesso!",
    generateCredentials: "As credenciais do usuário foram geradas e enviadas para o e-mail cadastrado.",
    acceptContract: "Contrato aceito com sucesso!",
  },
  error: {
    saveData: "Problemas ao salvar registro.",
    deleteData: "Problemas ao remover registro.",
    required: "Este campo é obrigatório.",
    invalidDate: "A data informada é inválida.",
    invalidEmail: "O e-mail informado é inválido.",
    productNotFound: "Produto não encontrado.",
    greaterOrEqual: "O valor informado deve ser maior ou igual a ",
    lessOrEqual: "O valor informado deve ser menor ou igual a ",
    moreThan: "O valor informado deve ser maior que ",
    lessThan: "O valor informado deve ser menor que ",
    packNotFound: "O pacote informado não foi encontrado.",
    importListSize: "A lista de importação deve conter no máximo 500 registros.",
  },
  warning: {
    deleteDataConfirm: "Deseja realmente excluir este registro?",
    closeDataConfirm: "Esta é uma ação irreversível. Você está realizando o fechamento deste registro. Deseja realmente continuar?",
  },
};
