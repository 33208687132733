import React, { memo } from "react";
import { Lottie } from "components";
import { useToken, Box } from "@chakra-ui/react";
import animationData from "./line.json";
import "./style.css";

const widthIndex = 0.890575389142724;
const heightIndex = 0.756790637342213;
const leftIndex = 0.054450940912259;
const topIndex = 0.054450940912259;

export const LogoAnimated = memo(({ width = 826.432, loop = true, autoplay = true, speed = 2, pulse = true }) => {
  const [stroke] = useToken("colors", ["main.500"]);

  return (
    <Box position="relative" transform="scale(1)" animation={pulse && "pulse 2s infinite"}>
      <Lottie
        loop={loop}
        autoplay={autoplay}
        animationData={animationData}
        speed={speed}
        style={{ position: "absolute", top: width * topIndex, left: width * leftIndex, width: width * widthIndex }}
      />
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width * heightIndex} viewBox="0 0 826.432 625.436">
        <g transform="translate(-98.597 -198.996)">
          <path
            d="M311.446,623C139.44,623,0,483.536,0,311.5S139.44,0,311.446,0C417.408,0,511.013,52.928,567.272,133.79q5.979-.289,12.02-.29C714.44,133.5,824,243.078,824,378.25,824,513.125,714.919,622.518,580.181,623v0Z"
            transform="translate(100 200)"
            fill={stroke}
          />
          <path
            d="M575.541,340.235C538.214,302.8,484.848,274.43,411,274c-162.256,2.959-237.2,135.979-237.4,238S251.808,747.719,411,749s186.319-.465,269,0,168.111-63.914,170-170S759.7,406.659,680,407,509.372,467.639,509,579c-.106,31.626,7.878,58.8,21.018,81.75"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeWidth="150"
          />
          <path
            d="M575.541,340.235C538.214,302.8,484.848,274.43,411,274c-162.256,2.959-237.2,135.979-237.4,238S251.808,747.719,411,749s186.319-.465,269,0,168.111-63.914,170-170S759.7,406.659,680,407,509.372,467.639,509,579c-.106,31.626,7.878,58.8,21.018,81.75"
            fill="none"
            stroke="rgba(0,0,0,0.1)"
            strokeLinecap="round"
            strokeWidth="59"
          />
        </g>
      </svg>
    </Box>
  );
});
