import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdBrightness7, MdBrightness2, MdMenu, MdOutlineShield } from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
import { TbFiles } from "react-icons/tb";
import { HiOutlineDocumentText } from "react-icons/hi";
import { LogoAnimated, Portal } from "components";
import { AuthContext } from "AuthProvider";
import { SplashContext } from "SplashProvider";
import { ContainerContext } from "./index";

const Toolbar = () => {
  const { currentUser, signOut } = useContext(AuthContext);
  const { isAppReady } = useContext(SplashContext);
  const { isMobileView, onOpenSidebar } = useContext(ContainerContext);
  const { colorMode, toggleColorMode } = useColorMode();
  const [autoplay, setAutoplay] = useState(false);
  const iconButtonColor = useColorModeValue("gray.500", "white");

  useEffect(() => {
    if (isAppReady) {
      const timeout = setTimeout(() => setAutoplay(true), 1000);
      return () => clearTimeout(timeout);
    }
  }, [isAppReady]);

  return (
    <HStack h="50px" px="20px" justifyContent="space-between" _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }}>
      <HStack>
        {isMobileView && <IconButton variant="ghost" isRound icon={<Icon as={MdMenu} />} onClick={onOpenSidebar} />}
        <LogoAnimated width={40} loop={false} autoplay={autoplay} pulse={false} speed={0.5} />
        <Heading size="md">ConsigSys</Heading>
      </HStack>
      <HStack>
        <Tooltip label={`Tema ${colorMode === "light" ? "escuro" : "claro"}`}>
          <IconButton
            icon={<Icon as={colorMode === "light" ? MdBrightness7 : MdBrightness2} />}
            variant="ghost"
            color={iconButtonColor}
            onClick={toggleColorMode}
            isRound
          />
        </Tooltip>
        <Box>
          <Menu>
            <MenuButton as={IconButton} variant="ghost" isRound>
              <Avatar size="sm" />
            </MenuButton>
            <Portal>
              <MenuList>
                <Box textAlign="center" p="10px">
                  <Avatar size="lg" marginBottom="10px" />
                  <Text fontWeight="bold" maxW="250px" noOfLines={1}>
                    {currentUser.name}
                  </Text>
                  {currentUser.email && <Text fontSize="xs">{currentUser.email}</Text>}
                  <Text fontSize="xs">v{process.env.REACT_APP_VERSION}</Text>
                </Box>
                <MenuDivider />
                {currentUser.isProprietary && (
                  <>
                    <MenuItem as={RouterLink} to="/contracts">
                      <Icon as={HiOutlineDocumentText} marginRight="10px" />
                      <Text fontSize="xs">Contratos</Text>
                    </MenuItem>
                    <MenuDivider />
                  </>
                )}
                <MenuItem onClick={() => window.open("https://consigsys.com/politica-de-privacidade")}>
                  <Icon as={MdOutlineShield} marginRight="10px" />
                  <Text fontSize="xs">Política de Privacidade</Text>
                </MenuItem>
                <MenuItem onClick={() => window.open("https://consigsys.com/termos")}>
                  <Icon as={TbFiles} marginRight="10px" />
                  <Text fontSize="xs">Termos de Uso</Text>
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={signOut}>
                  <Icon as={IoLogOutOutline} marginRight="10px" />
                  <Text fontSize="xs">Sair da minha conta</Text>
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Box>
      </HStack>
    </HStack>
  );
};

export default Toolbar;
