import React, { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  StackDivider,
  VStack,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { MdAddCircleOutline, MdOutlineDelete, MdRefresh } from "react-icons/md";
import { api } from "lib";
import { Address, AsyncSelect, Map, EmailAutocomplete, PhoneInput } from "components";
import { useApiGet, useArrayItemHandlers } from "hooks";
import { UsersDetailsContext } from "./index";

let loadAgentsTimeout = null;

const General = () => {
  const { _id } = useParams();
  const { formData, setFormData, formErrors } = useContext(UsersDetailsContext);
  const [commissionRules, isLoadingCommissionRules, refreshCommissionRules] = useApiGet(
    useMemo(() => ({ path: "/commission-rules", params: { query: { isActive: true } } }), [])
  );
  const documentMask = useMemo(() => (formData.type === "pj" ? "99.999.999/9999-99" : "999.999.999-99"), [formData.type]);
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);

  const handleLoadAgents = useCallback(
    (search, cb) => {
      clearTimeout(loadAgentsTimeout);
      loadAgentsTimeout = setTimeout(async () => {
        const query = { _id: { $ne: _id }, roles: { $in: ["agent"] } };
        const response = await api.get("/users", { params: { query, search } });
        cb(response?.data ?? []);
      }, 1000);
    },
    [_id]
  );

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <FormControl isInvalid={formErrors.nid}>
            <FormLabel fontSize="sm">NID</FormLabel>
            <Input value={formData.nid ?? ""} isDisabled={true} />
            <FormErrorMessage>{formErrors.nid}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.name}>
            <FormLabel fontSize="sm">Nome</FormLabel>
            <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
            <FormErrorMessage>{formErrors.name}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.nickname}>
            <FormLabel fontSize="sm">{formData.type === "pj" ? "Fantasia" : "Apelido"}</FormLabel>
            <Input
              value={formData.nickname ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, nickname: target.value }))}
            />
            <FormErrorMessage>{formErrors.nickname}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isInvalid={formErrors.type}>
            <FormLabel fontSize="sm">Tipo de pessoa</FormLabel>
            <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
              <option value="pf">Pessoa física</option>
              <option value="pj">Pessoa jurídica</option>
            </Select>
            <FormErrorMessage>{formErrors.type}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isInvalid={formErrors.document}>
            <FormLabel fontSize="sm">CPF/CNPJ</FormLabel>
            <Input
              as={InputMask}
              mask={documentMask}
              value={formData.document ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
            />
            <FormErrorMessage>{formErrors.document}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isInvalid={formErrors.rg}>
            <FormLabel fontSize="sm">RG</FormLabel>
            <Input value={formData.rg ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, rg: target.value }))} />
            <FormErrorMessage>{formErrors.rg}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isInvalid={formErrors.ie}>
            <FormLabel fontSize="sm">Inscrição estadual</FormLabel>
            <Input value={formData.ie ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, ie: target.value }))} />
            <FormErrorMessage>{formErrors.ie}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={formErrors.agents}>
            <FormLabel fontSize="sm">Representantes responsáveis</FormLabel>
            <AsyncSelect
              isInvalid={formErrors.agents}
              isMulti
              value={formData.agents ?? []}
              defaultOptions
              loadOptions={handleLoadAgents}
              placeholder="Selecione os representantes"
              onChange={(agents) => setFormData((state) => ({ ...state, agents }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name }) => name}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.agents}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={formErrors.commissionRule}>
            <FormLabel fontSize="sm">Comissão</FormLabel>
            <HStack>
              <Select
                flex="1"
                value={formData.commissionRule ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, commissionRule: target.value }))}
              >
                <option value="">--Selecione</option>
                {_.map(commissionRules?.data, (item) => (
                  <option key={item._id} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </Select>
              <IconButton
                variant="outline"
                icon={<Icon as={MdRefresh} />}
                isLoading={isLoadingCommissionRules}
                onClick={refreshCommissionRules}
              />
            </HStack>
            <FormErrorMessage>{formErrors.commissionRule}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Endereço
      </Heading>
      <Map location={formData.location} mb="20px" />
      <Address {...{ formData, setFormData, formErrors }} />

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Contato
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 1"
            phone={formData.phone1}
            category={formData.phone1Category}
            onChangePhone={(phone1) => setFormData((state) => ({ ...state, phone1 }))}
            onChangeCategory={(phone1Category) => setFormData((state) => ({ ...state, phone1Category }))}
            isRequired={false}
            isInvalid={formErrors.phone1}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 2"
            phone={formData.phone2}
            category={formData.phone2Category}
            onChangePhone={(phone2) => setFormData((state) => ({ ...state, phone2 }))}
            onChangeCategory={(phone2Category) => setFormData((state) => ({ ...state, phone2Category }))}
            isRequired={false}
            isInvalid={formErrors.phone2}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 3"
            phone={formData.phone3}
            category={formData.phone3Category}
            onChangePhone={(phone3) => setFormData((state) => ({ ...state, phone3 }))}
            onChangeCategory={(phone3Category) => setFormData((state) => ({ ...state, phone3Category }))}
            isRequired={false}
            isInvalid={formErrors.phone3}
          />
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={formErrors.website}>
            <FormLabel fontSize="sm">WebSite</FormLabel>
            <Input
              value={formData.website ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, website: target.value }))}
            />
            <FormErrorMessage>{formErrors.website}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={formErrors.email}>
            <FormLabel fontSize="sm">E-mail</FormLabel>
            <EmailAutocomplete
              value={formData.email ?? ""}
              onChange={(email) => setFormData((state) => ({ ...state, email }))}
              isInvalid={formErrors.email}
            />
            <FormErrorMessage>{formErrors.email}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Pessoas de contato
      </Heading>
      <VStack align="stretch" divider={<StackDivider opacity={{ base: "1", lg: "0" }} />} spacing={{ base: "30px", lg: "10px" }}>
        {_.map(formData.contacts, (contact, index) => (
          <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl isInvalid={formErrors[`contacts[${index}].name`]}>
                <FormLabel fontSize="sm">Nome</FormLabel>
                <Input
                  value={contact.name ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("contacts", index, { name: target.value })}
                />
                <FormErrorMessage>{formErrors[`contacts[${index}].name`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <FormControl isInvalid={formErrors[`contacts[${index}].department`]}>
                <FormLabel fontSize="sm">Setor</FormLabel>
                <Input
                  value={contact.department ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("contacts", index, { department: target.value })}
                />
                <FormErrorMessage>{formErrors[`contacts[${index}].department`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <FormControl isInvalid={formErrors[`contacts[${index}].email`]}>
                <FormLabel fontSize="sm">E-mail</FormLabel>
                <EmailAutocomplete
                  value={contact.email ?? ""}
                  onChange={(email) => handleChangeArrayItem("contacts", index, { email })}
                  isInvalid={formErrors[`contacts[${index}].email`]}
                />
                <FormErrorMessage>{formErrors[`contacts[${index}].email`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <PhoneInput
                label="Telefone"
                phone={contact.phone}
                category={contact.phoneCategory}
                onChangePhone={(phone) => handleChangeArrayItem("contacts", index, { phone })}
                onChangeCategory={(phoneCategory) => handleChangeArrayItem("contacts", index, { phoneCategory })}
                isRequired={false}
                isInvalid={formErrors[`contacts[${index}].phone`]}
              />
            </GridItem>
            <GridItem colSpan={{ base: 7, lg: 1 }}>
              <FormControl isInvalid={formErrors[`contacts[${index}].branch`]}>
                <FormLabel fontSize="sm">Ramal</FormLabel>
                <Input
                  value={contact.branch ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("contacts", index, { branch: target.value })}
                />
                <FormErrorMessage>{formErrors[`contacts[${index}].branch`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 5, lg: 1 }}>
              <FormLabel fontSize="sm">&nbsp;</FormLabel>
              <Button
                w="100%"
                size="md"
                variant="outline"
                rightIcon={<Icon as={MdOutlineDelete} />}
                onClick={() => handleDeleteArrayItem("contacts", index)}
              >
                deletar
              </Button>
            </GridItem>
          </Grid>
        ))}
      </VStack>
      <Button
        size="sm"
        colorScheme="main"
        variant="ghost"
        mt={4}
        leftIcon={<Icon as={MdAddCircleOutline} />}
        onClick={() => handleAddArrayItem("contacts")}
      >
        adicionar contato
      </Button>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Redes sociais
      </Heading>
      <VStack align="stretch" divider={<StackDivider opacity={{ base: "1", lg: "0" }} />} spacing={{ base: "30px", lg: "10px" }}>
        {_.map(formData.socialNetworks, (contact, index) => (
          <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl isInvalid={formErrors[`socialNetworks[${index}].name`]}>
                <FormLabel fontSize="sm">Nome</FormLabel>
                <Input
                  value={contact.name ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("socialNetworks", index, { name: target.value })}
                />
                <FormErrorMessage>{formErrors[`socialNetworks[${index}].name`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 7, lg: 5 }}>
              <FormControl isInvalid={formErrors[`socialNetworks[${index}].nick`]}>
                <FormLabel fontSize="sm">Nick</FormLabel>
                <Input
                  value={contact.nick ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("socialNetworks", index, { nick: target.value })}
                />
                <FormErrorMessage>{formErrors[`socialNetworks[${index}].nick`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 5, lg: 1 }}>
              <FormLabel fontSize="sm">&nbsp;</FormLabel>
              <Button
                rightIcon={<Icon as={MdOutlineDelete} />}
                size="md"
                variant="outline"
                onClick={() => handleDeleteArrayItem("socialNetworks", index)}
              >
                deletar
              </Button>
            </GridItem>
          </Grid>
        ))}
      </VStack>
      <Button
        size="sm"
        colorScheme="main"
        variant="ghost"
        mt={4}
        leftIcon={<Icon as={MdAddCircleOutline} />}
        onClick={() => handleAddArrayItem("socialNetworks")}
      >
        adicionar rede social
      </Button>
    </>
  );
};

export default General;
