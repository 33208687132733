import ReactDatePicker from "react-datepicker";
import { Input, useColorModeValue, useTheme } from "@chakra-ui/react";
import * as Styled from "./styled";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";

export const DatePicker = (props) => {
  const theme = useTheme();
  const background = _.get(theme.colors, useColorModeValue("white", "gray.800"));
  const backgroundContrast = _.get(theme.colors, useColorModeValue("black", "white"));
  const primary = _.get(theme.colors, useColorModeValue("main.500", "main.400"));
  const primaryDarken = _.get(theme.colors, useColorModeValue("main.700", "main.600"));

  return (
    <Styled.Wrapper {...{ background, backgroundContrast, primary, primaryDarken }}>
      <Input as={ReactDatePicker} {...props} autoComplete="off" />
    </Styled.Wrapper>
  );
};
