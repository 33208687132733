import React, { useState, useCallback, useContext, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Select,
  StackDivider,
  Switch,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import * as yup from "yup";
import { InputCurrency } from "components";
import { api } from "lib";
import { messages } from "consts";
import { useCustomToast, useApiGet } from "hooks";
import { MdEdit } from "react-icons/md";
import { AuthContext } from "AuthProvider";
import PackFootnotes from "./packFootnotes";
import PreferencesContext from "./context";

const Preferences = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [data, isLoadingData] = useApiGet(
    useMemo(() => ({ path: `/companies/${currentUser.company._id}/preferences` }), [currentUser.company._id])
  );
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenPackFootnotes, onOpen: onOpenPackFootnotes, onClose: onClosePackFootnotes } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.put(`/companies/${currentUser.company._id}/preferences`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [currentUser.company._id, toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({});
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <PreferencesContext.Provider value={{ formData, setFormData, formErrors, setFormErrors }}>
      <VStack mb="30px" alignItems="center" spacing="20px" divider={<StackDivider />}>
        <FormControl as={HStack} isRequired={true} isInvalid={formErrors.isAllowedDuplicatedDocuments}>
          <Box flex="1">
            <FormLabel mb="0">Permitir documentos duplicados</FormLabel>
            <FormHelperText mt="0">Permitir que sejam cadastrados usuários com CPF ou CNPJ que já existam na base de dados.</FormHelperText>
          </Box>
          <Box>
            <Switch
              isChecked={formData.isAllowedDuplicatedDocuments}
              onChange={() =>
                setFormData((state) => ({
                  ...state,
                  isAllowedDuplicatedDocuments: !state.isAllowedDuplicatedDocuments,
                }))
              }
            />
            <FormErrorMessage>{formErrors.isAllowedDuplicatedDocuments}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl as={HStack} isRequired={true} isInvalid={formErrors.isActiveDocumentValidation}>
          <Box flex="1">
            <FormLabel mb="0">Ativar validação de documentos</FormLabel>
            <FormHelperText mt="0">Ativar a validação de documentos CPF e CNPJ.</FormHelperText>
          </Box>
          <Box>
            <Switch
              isChecked={formData.isActiveDocumentValidation}
              onChange={() =>
                setFormData((state) => ({
                  ...state,
                  isActiveDocumentValidation: !state.isActiveDocumentValidation,
                }))
              }
            />
            <FormErrorMessage>{formErrors.isActiveDocumentValidation}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl as={HStack} isRequired={true} isInvalid={formErrors.isEnabledRewards}>
          <Box flex="1">
            <FormLabel mb="0">Permitir o programa Rewards</FormLabel>
            <FormHelperText mt="0">
              O rewards é um programa de fidelidade flexível para sua empresa. Você poderá gerenciar os prêmios oferecidos e as solicitações
              de resgates dos usuários.
            </FormHelperText>
          </Box>
          <Box>
            <Switch
              isChecked={formData.isEnabledRewards}
              onChange={() => setFormData((state) => ({ ...state, isEnabledRewards: !state.isEnabledRewards }))}
            />
            <FormErrorMessage>{formErrors.isEnabledRewards}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl
          as={HStack}
          isRequired={true}
          isDisabled={!formData.isEnabledRewards}
          isInvalid={formErrors.multiplicationFactorForRewardsScores}
        >
          <Box flex="1">
            <FormLabel mb="0">Fator de multiplicação dos pontos</FormLabel>
            <FormHelperText mt="0">
              O fator de multiplicação dos pontos é o número pelo qual cada real gasto pelos usuários será convertido para integrar o
              rewards.
            </FormHelperText>
          </Box>
          <Box w="100px">
            <InputCurrency
              textAlign="center"
              value={formData.multiplicationFactorForRewardsScores ?? ""}
              onChange={(multiplicationFactorForRewardsScores) =>
                setFormData((state) => ({ ...state, multiplicationFactorForRewardsScores }))
              }
            />
            <FormErrorMessage>{formErrors.multiplicationFactorForRewardsScores}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl as={HStack} isRequired={true} isInvalid={formErrors.isAllowedDistributionInCaseOfPendingPayments}>
          <Box flex="1">
            <FormLabel mb="0">Permitir a distribuição em caso de pagamentos pendentes</FormLabel>
            <FormHelperText mt="0">
              Permitir que o app do representante distribua pacotes para revendedores com pagamentos pendentes.
            </FormHelperText>
          </Box>
          <Box>
            <Switch
              isChecked={formData.isAllowedDistributionInCaseOfPendingPayments}
              onChange={() =>
                setFormData((state) => ({
                  ...state,
                  isAllowedDistributionInCaseOfPendingPayments: !state.isAllowedDistributionInCaseOfPendingPayments,
                }))
              }
            />
            <FormErrorMessage>{formErrors.isAllowedDistributionInCaseOfPendingPayments}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl as={HStack} isRequired={true} isInvalid={formErrors.isAllowedSettlementDateChange}>
          <Box flex="1">
            <FormLabel mb="0">Permitir a alteração da data de acerto</FormLabel>
            <FormHelperText mt="0">Permitir que o app do representante altere a data prevista de acerto dos pacotes.</FormHelperText>
          </Box>
          <Box>
            <Switch
              isChecked={formData.isAllowedSettlementDateChange}
              onChange={() =>
                setFormData((state) => ({
                  ...state,
                  isAllowedSettlementDateChange: !state.isAllowedSettlementDateChange,
                }))
              }
            />
            <FormErrorMessage>{formErrors.isAllowedSettlementDateChange}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl as={HStack} isRequired={true} isInvalid={formErrors.maximumSettlementDateChangeIntervalFromLoadedAtInDays}>
          <Box flex="1">
            <FormLabel mb="0">Intervalo máximo de alteração da data de acerto</FormLabel>
            <FormHelperText mt="0">
              Intervalo máximo em dias no qual o representante poderá realizar a alteração da data de acerto dos pacotes.
            </FormHelperText>
          </Box>
          <Box w="100px">
            <InputCurrency
              textAlign="center"
              precision="0"
              value={formData.maximumSettlementDateChangeIntervalFromLoadedAtInDays ?? ""}
              onChange={(maximumSettlementDateChangeIntervalFromLoadedAtInDays) =>
                setFormData((state) => ({ ...state, maximumSettlementDateChangeIntervalFromLoadedAtInDays }))
              }
            />
            <FormErrorMessage>{formErrors.maximumSettlementDateChangeIntervalFromLoadedAtInDays}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl as={HStack} isRequired={true} isInvalid={formErrors.defaultSettlementDateForNewPacksFromNowInDays}>
          <Box flex="1">
            <FormLabel mb="0">Data de acerto padrão para novos pacotes a partir de hoje</FormLabel>
            <FormHelperText mt="0">
              Novos pacotes criados a partir do aplicativo do representante receberão uma data de acerto padrão em dias contando a partir de
              hoje.
            </FormHelperText>
          </Box>
          <Box w="100px">
            <InputCurrency
              textAlign="center"
              precision="0"
              value={formData.defaultSettlementDateForNewPacksFromNowInDays ?? ""}
              onChange={(defaultSettlementDateForNewPacksFromNowInDays) =>
                setFormData((state) => ({ ...state, defaultSettlementDateForNewPacksFromNowInDays }))
              }
            />
            <FormErrorMessage>{formErrors.defaultSettlementDateForNewPacksFromNowInDays}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl as={HStack} isRequired={true} isInvalid={formErrors.defaultDueDateForNewPaymentsFromNowInDays}>
          <Box flex="1">
            <FormLabel mb="0">Data de vencimento padrão para novos pagamentos a partir de hoje</FormLabel>
            <FormHelperText mt="0">
              Novos pagamentos criados a partir do aplicativo do representante receberão uma data de vencimento padrão em dias contando a
              partir de hoje.
            </FormHelperText>
          </Box>
          <Box w="100px">
            <InputCurrency
              textAlign="center"
              precision="0"
              value={formData.defaultDueDateForNewPaymentsFromNowInDays ?? ""}
              onChange={(defaultDueDateForNewPaymentsFromNowInDays) =>
                setFormData((state) => ({ ...state, defaultDueDateForNewPaymentsFromNowInDays }))
              }
            />
            <FormErrorMessage>{formErrors.defaultDueDateForNewPaymentsFromNowInDays}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl as={HStack}>
          <Box flex="1">
            <FormLabel mb="0">Notas de rodapé da impressão do pacote</FormLabel>
            <FormHelperText mt="0">
              {formData.packFootnotes?.length ? formData.packFootnotes : "Nenhuma nota de rodapé informada."}
            </FormHelperText>
          </Box>
          <IconButton variant="outline" icon={<Icon as={MdEdit} />} onClick={onOpenPackFootnotes} />
        </FormControl>
      </VStack>
      <Divider />
      <HStack p="20px">
        <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
          salvar
        </Button>
        <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
          cancelar
        </Button>
      </HStack>

      <PackFootnotes isOpen={isOpenPackFootnotes} onClose={onClosePackFootnotes} />
    </PreferencesContext.Provider>
  );
};

export default Preferences;
