import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";

export const Map = ({ location, width = "100%", height = "300px", ...props }) => {
  const src = useMemo(
    () => `https://www.google.com/maps?q=${location?.lat},${location?.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`,
    [location]
  );

  return location ? (
    <Box borderRadius="lg" overflow="hidden" {...props}>
      <iframe title="Localização" {...{ width, height, src }} allowFullScreen />
    </Box>
  ) : null;
};
