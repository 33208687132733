import React, { useState, useImperativeHandle, forwardRef, useCallback, useEffect, useContext, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import * as yup from "yup";
import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { paymentMethods, messages } from "consts";
import { currency, translator } from "lib";
import { useApiGet } from "hooks";
import { AuthContext } from "AuthProvider";
import { MdRemove, MdRefresh } from "react-icons/md";
import SettlementsContext from "./context";
import PacksDetailsContext from "../context";
import { BoxData } from "components";

const Debit = forwardRef((props, ref) => {
  const { currentUser } = useContext(AuthContext);
  const [preferences, isLoadingPreferences] = useApiGet(
    useMemo(() => ({ path: `/companies/${currentUser.company._id}/preferences` }), [currentUser.company._id])
  );
  const { formData: currentPack } = useContext(PacksDetailsContext);
  const {
    wallets,
    isLoadingWallets,
    refreshWallets,
    paymentCategories,
    isLoadingPaymentCategories,
    refreshPaymentCategories,
    receivedAmount,
  } = useContext(SettlementsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const isHidden = useMemo(() => formData.amount === 0, [formData.amount]);
  const dueDate = useMemo(
    () => moment().add(preferences?.defaultDueDateForNewPaymentsFromNowInDays, "days").toDate(),
    [preferences?.defaultDueDateForNewPaymentsFromNowInDays]
  );

  useEffect(() => {
    const amount = _.round(currentPack.receivableAmount - receivedAmount, 2);
    setFormData((state) => ({ ...state, amount }));
  }, [receivedAmount, currentPack.receivableAmount]);

  const submit = useCallback(async () => {
    try {
      if (isHidden) return;
      const schema = yup.object().shape({
        paymentMethod: yup.string().required(messages.error.required),
        wallet: yup.string().required(messages.error.required),
        paymentCategory: yup.string().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
      throw error;
    }
  }, [isHidden, formData]);

  const save = useCallback(() => {
    if (isHidden) return;
    const object = {
      type: "revenue",
      status: "pending",
      wallet: formData.wallet,
      paymentCategory: formData.paymentCategory,
      paymentMethod: formData.paymentMethod,
      issueDate: new Date(),
      dueDate,
      user: currentPack.dealer,
      items: [{ type: "charge", description: "Pagamento de pacote", amount: formData.amount }],
      receivableAmount: formData.amount,
      receivedAmount: 0,
      remainingAmount: formData.amount,
      agent: currentUser._id,
      pack: { _id: currentPack._id, nid: currentPack.nid },
      isDebit: true,
      company: currentPack.company,
    };
    return object;
  }, [isHidden, currentUser, currentPack, formData, dueDate]);

  useImperativeHandle(ref, () => ({ submit, save }), [submit, save]);

  if (isHidden) return null;

  return (
    <Box p="20px" borderWidth="1px" borderRadius="lg">
      <HStack space="10px" mb="20px">
        <Center bg="red.500" w="30px" h="30px" borderRadius="lg">
          <Icon as={MdRemove} color="white" />
        </Center>
        <Box>
          <Text fontSize="sm">Débito o revendedor</Text>
          <Text fontSize="lg" fontWeight="semibold">
            {currency.format(formData.amount ?? 0)}
          </Text>
        </Box>
      </HStack>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.paymentMethod}>
            <FormLabel fontSize="sm">Método</FormLabel>
            <Select
              value={formData.paymentMethod ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, paymentMethod: target.value }))}
            >
              <option>--Selecione</option>
              {_.map(paymentMethods, (value) => (
                <option key={value} value={value}>
                  {translator(value)}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formErrors.paymentMethod}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.wallet}>
            <FormLabel fontSize="sm">Carteira</FormLabel>
            <HStack>
              <Select value={formData.wallet ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, wallet: target.value }))}>
                <option>--Selecione</option>
                {_.map(wallets?.data, ({ _id, title }) => (
                  <option key={_id} value={_id}>
                    {title}
                  </option>
                ))}
              </Select>
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingWallets} onClick={refreshWallets} />
            </HStack>
            <FormErrorMessage>{formErrors.wallet}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.paymentCategory}>
            <FormLabel fontSize="sm">Categoria de pagamento</FormLabel>
            <HStack>
              <Select
                value={formData.paymentCategory ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, paymentCategory: target.value }))}
              >
                <option>--Selecione</option>
                {_.map(paymentCategories.revenue, ({ _id, title }) => (
                  <option key={_id} value={_id}>
                    {title}
                  </option>
                ))}
              </Select>
              <IconButton
                variant="outline"
                icon={<Icon as={MdRefresh} />}
                isLoading={isLoadingPaymentCategories}
                onClick={refreshPaymentCategories}
              />
            </HStack>
            <FormErrorMessage>{formErrors.paymentCategory}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <BoxData
            label="Data de vencimento"
            value={moment(dueDate).format("DD, MMMM [de] YYYY")}
            RightComponent={isLoadingPreferences && <Spinner />}
          />
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <BoxData label="Valor do débito" value={currency.format(formData.amount ?? 0)} />
        </GridItem>
      </Grid>
    </Box>
  );
});

export default Debit;
