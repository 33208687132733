import React, { useRef, useState, useEffect, useContext, useCallback } from "react";
import {
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { api, currency, percent, translator } from "lib";
import { TableEmpty } from "components";
import { useArrayItemHandlers, useCustomToast } from "hooks";
import { messages } from "consts";
import { CgPushChevronRight } from "react-icons/cg";
import { ExpeditionsDetailsContext } from "./index";
import barcodeReaderSound from "assets/sounds/scanner.mp3";
import errorSound from "assets/sounds/error.mp3";
import { MdOutlineDelete } from "react-icons/md";
import { useParams } from "react-router-dom";
import { TbTruckDelivery } from "react-icons/tb";

const barcodeReaderAudio = new Audio(barcodeReaderSound);
const errorSoundAudio = new Audio(errorSound);

export const Packs = () => {
  const { _id } = useParams();
  const { formData, setFormData, formErrors } = useContext(ExpeditionsDetailsContext);
  const [packs, setPacks] = useState([]);
  const [isLoadingPacks, setIsLoadingPacks] = useState(false);
  const { handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const formRef = useRef();
  const inputRef = useRef();
  const toast = useCustomToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingPacks(true);
        const query = {
          $or: [{ agent: formData.agent._id }, { agent: { $exists: false } }],
          status: "preparing",
          assignment: "agent",
          ownership: "system",
        };
        if (formData.priceType) query.priceType = formData.priceType;
        const response = await api.get("/packs", { params: { query, perPage: -1 } });
        setPacks(response.data);
        setFormData((state) => ({
          ...state,
          packs: _.filter(response.data, (o) => o.agent?._id === formData.agent._id),
        }));
      } finally {
        setIsLoadingPacks(false);
      }
    };
    !_id && formData.agent && fetchData();
  }, [_id, formData.agent, formData.priceType, setFormData]);

  useEffect(() => {
    const counters = { packsCount: _.size(formData.packs), amount: 0, quantity: 0 };
    _.forEach(formData.packs, (item) => {
      counters.amount += item.currentAmount;
      counters.quantity += item.currentQuantity;
    });
    setFormData((state) => ({ ...state, ...counters }));
  }, [formData.packs, setFormData]);

  const handleUpdateEntry = useCallback(
    async (id) => {
      try {
        if (!id) return;
        const finded = _.find(packs, (o) => o._id === id || o.nid === parseInt(id));
        if (!finded) throw new Error();
        setFormData((state) => {
          const packs = [...(state.packs ?? [])];
          const index = _.findIndex(packs, (o) => o._id === finded._id);
          if (index === -1) packs.push(finded);
          return { ...state, packs };
        });
        barcodeReaderAudio.play();
      } catch (error) {
        toast({ title: "Oops", description: messages.error.packNotFound, status: "error", isClosable: true });
        errorSoundAudio.play();
      }
    },
    [toast, packs, setFormData]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const value = inputRef.current.value;
      formRef.current.reset();
      handleUpdateEntry(value, 1, undefined, true);
      inputRef.current.focus();
    },
    [handleUpdateEntry]
  );

  return (
    <>
      <Divider />

      <FormControl isInvalid={formErrors.packs} mt={8} mb={3}>
        <Heading size="sm" lineHeight="1">
          Pacotes
        </Heading>
        <FormErrorMessage>{formErrors.packs}</FormErrorMessage>
      </FormControl>

      {!_id && (
        <form ref={formRef} onSubmit={handleSubmit}>
          <HStack align="flex-end" mt={3} mb={2}>
            <FormControl>
              <Button mb="8px" size="xs" colorScheme="main">
                ID/NID
              </Button>
              <Input type="text" ref={inputRef} autoFocus={true} />
            </FormControl>
            <IconButton type="submit" variant="outline" icon={<Icon as={CgPushChevronRight} />} isLoading={isLoadingPacks} />
          </HStack>
        </form>
      )}

      <Table size="sm" whiteSpace="nowrap" mt={6}>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>NID</Th>
            <Th>Revendedor</Th>
            <Th>Previsão de acerto</Th>
            <Th>Tipo de preço</Th>
            <Th>Atual</Th>
            <Th>Desempenho</Th>
            <Th>Total carregado</Th>
            <Th>Total devolvido</Th>
            <Th>Recebido</Th>
            <Th>Restante</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(formData.packs, (item, index) => (
            <Tr key={item._id} _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } }}>
              <Td>
                <HStack>
                  <IconButton
                    size="xs"
                    variant="outline"
                    icon={<Icon as={MdOutlineDelete} />}
                    onClick={() => handleDeleteArrayItem("packs", index)}
                    isDisabled={_id}
                  />
                  {item.isLoad && (
                    <Tooltip label="Carga">
                      <Center w="25px" h="25px" borderRadius="lg" bg="green.100">
                        <Icon as={TbTruckDelivery} color="green.500" />
                      </Center>
                    </Tooltip>
                  )}
                </HStack>
              </Td>
              <Td>{item.nid}</Td>
              <Td>{item.dealer?.name ?? "-"}</Td>
              <Td>{item.predictedSettlementDate ? moment(item.predictedSettlementDate).format("DD, MMMM [de] YYYY") : "-"}</Td>
              <Td>{translator(item.priceType)}</Td>
              <Td>
                {currency.format(item.currentAmount)} • {item.currentQuantity.toLocaleString()}
              </Td>
              <Td>{item.isLoad ? "-" : percent.format(item.performance)}</Td>
              <Td>{item.isLoad ? "-" : `${currency.format(item.loadedAmount)} • ${item.loadedQuantity.toLocaleString()}`}</Td>
              <Td>{item.isLoad ? "-" : `${currency.format(item.unloadedAmount)} • ${item.unloadedQuantity.toLocaleString()}`}</Td>
              <Td>{item.isLoad ? "-" : currency.format(item.receivedAmount)}</Td>
              <Td>{item.isLoad ? "-" : currency.format(item.remainingAmount)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoadingPacks && (
        <Center p="30px">
          <Spinner />
        </Center>
      )}
      <TableEmpty isLoading={isLoadingPacks} size={_.size(formData.packs)} hasNewButton={false} />
    </>
  );
};

export default Packs;
