import React, { createContext, useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  SlideFade,
  Spinner,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import * as yup from "yup";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { messages } from "consts";
import { Breadcrumb, Portal } from "components";
import { Content } from "pages/Private/Container";
import General from "./general";
import Complementary from "./complementary";
import Roles from "./roles";
import Attachments from "./attachments";
import Scores from "./scores";
// import Devices from "./devices";
import { MdArrowDropDown, MdChevronLeft } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { FiSmartphone } from "react-icons/fi";
import { TbDeviceDesktop } from "react-icons/tb";

export const UsersDetailsContext = createContext();

const ownerships = [
  { icon: TbDeviceDesktop, value: "system", label: "Sistema" },
  { icon: FiSmartphone, value: "app", label: "Aplicativo" },
];

export const UsersDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar usuário" : "Novo usuário");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/users/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenScores, onOpen: onOpenScores, onClose: onCloseScores } = useDisclosure();
  const inApp = useMemo(() => data?.ownership === "app", [data?.ownership]);
  const isAgent = useMemo(() => _.findIndex(formData.roles, (role) => role === "agent") !== -1, [formData.roles]);
  const ownership = useMemo(() => ownerships.find((o) => o.value === formData.ownership), [formData.ownership]);
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? {
      type: "pf",
      phone1Category: "phone",
      phone2Category: "phone",
      phone3Category: "phone",
      roles: ["customer"],
      ownership: "system",
      isActive: true,
    };
    if (_.size(formData.contacts) === 0) formData.contacts = [{}];
    if (_.size(formData.socialNetworks) === 0) formData.socialNetworks = [{}];
    if (formData.birth) formData.birth = moment(formData.birth).format("DD/MM/YYYY");
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.put(`/users/${_id}`, data) : await api.post("/users", data);
        navigate(`/users/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        birth: yup.date().typeError(messages.error.invalidDate),
        roles: yup.array().min(1, messages.error.required).required(messages.error.required),
        email: yup.string().email(messages.error.invalidEmail),
        contacts: yup.array().of(
          yup.object().shape({
            email: yup.string().email(messages.error.invalidEmail),
          })
        ),
      });
      const data = {
        ...formData,
        birth: formData.birth && moment(formData.birth, "DD/MM/YYYY").toDate(),
        contacts: _.filter(formData.contacts, (value) => _.isEmpty(value) === false),
        socialNetworks: _.filter(formData.socialNetworks, (value) => _.isEmpty(value) === false),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <UsersDetailsContext.Provider value={{ formData, setFormData, formErrors }}>
      <Content>
        <HStack justify="space-between">
          <HStack spacing="10px">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/users", label: "usuários" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <SlideFade in={true} offsetY="-20px">
            <HStack>
              <Button
                size="sm"
                variant="outline"
                leftIcon={<Icon as={GrMoney} />}
                isLoading={isLoadingData}
                onClick={onOpenScores}
                isDisabled={!_id}
              >
                {formData.score?.toLocaleString() ?? 0} pontos
              </Button>
              <Box>
                <Menu>
                  <MenuButton
                    as={Button}
                    size="sm"
                    variant="outline"
                    leftIcon={<Icon as={ownership?.icon} />}
                    rightIcon={<Icon as={MdArrowDropDown} />}
                    isDisabled={inApp || !isAgent}
                  >
                    {ownership?.label}
                  </MenuButton>
                  <Portal>
                    <MenuList fontSize="sm">
                      <MenuOptionGroup
                        type="radio"
                        value={formData.ownership}
                        onChange={(ownership) => setFormData((state) => ({ ...state, ownership }))}
                      >
                        {ownerships.map((ownership) => (
                          <MenuItemOption key={ownership.value} value={ownership.value}>
                            {ownership.label}
                          </MenuItemOption>
                        ))}
                      </MenuOptionGroup>
                    </MenuList>
                  </Portal>
                </Menu>
              </Box>
            </HStack>
          </SlideFade>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Usuário</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>
        <Tabs colorScheme="main">
          <TabList overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
            <Tab>dados gerais</Tab>
            <Tab>dados complementares</Tab>
            <Tab>anexos</Tab>
            <Tab>funções</Tab>
            {/* <Tab>dispositivos</Tab> */}
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Complementary />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Attachments />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Roles />
            </TabPanel>
            {/* <TabPanel px="0" py="30px">
              <Devices />
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} isDisabled={inApp} onClick={handleSubmit}>
            salvar
          </Button>
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            cancelar
          </Button>
        </HStack>
      </SlideFade>

      <Scores isOpen={isOpenScores} onClose={onCloseScores} />
    </UsersDetailsContext.Provider>
  );
};
