import React, { useContext } from "react";
import { FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, Select, Textarea } from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { UsersDetailsContext } from ".";

const Complement = () => {
  const { formData, setFormData, formErrors } = useContext(UsersDetailsContext);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.maritalStatus}>
            <FormLabel fontSize="sm">Estado civil</FormLabel>
            <Select
              value={formData.maritalStatus ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, maritalStatus: target.value }))}
            >
              <option value="">Selecione</option>
              <option value="Casado">Casado</option>
              <option value="Solteiro">Solteiro</option>
              <option value="Viúvo">Viúvo</option>
              <option value="Separado">Separado</option>
              <option value="Desquitado">Desquitado</option>
            </Select>
            <FormErrorMessage>{formErrors.maritalStatus}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.profession}>
            <FormLabel fontSize="sm">Profissão</FormLabel>
            <Input
              value={formData.profession ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, profession: target.value }))}
            />
            <FormErrorMessage>{formErrors.profession}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.gender}>
            <FormLabel fontSize="sm">Sexo</FormLabel>
            <Select value={formData.gender ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, gender: target.value }))}>
              <option value="female">Feminino</option>
              <option value="male">Masculino</option>
              <option value="other">Outro</option>
            </Select>
            <FormErrorMessage>{formErrors.gender}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={formErrors.birth}>
            <FormLabel fontSize="sm">Data de nascimento</FormLabel>
            <Input
              as={InputMask}
              mask="99/99/9999"
              value={formData.birth ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, birth: target.value }))}
            />
            <FormErrorMessage>{formErrors.birth}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={formErrors.naturalness}>
            <FormLabel fontSize="sm">Naturalidade</FormLabel>
            <Input
              value={formData.naturalness ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, naturalness: target.value }))}
            />
            <FormErrorMessage>{formErrors.naturalness}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 8 }}>
          <FormControl isInvalid={formErrors.fathersName}>
            <FormLabel fontSize="sm">Nome do pai</FormLabel>
            <Input
              value={formData.fathersName ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, fathersName: target.value }))}
            />
            <FormErrorMessage>{formErrors.fathersName}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.fathersCpf}>
            <FormLabel fontSize="sm">CPF do pai</FormLabel>
            <Input
              as={InputMask}
              mask="999.999.999-99"
              value={formData.fathersCpf ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, fathersCpf: target.value }))}
            />
            <FormErrorMessage>{formErrors.fathersCpf}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 8 }}>
          <FormControl isInvalid={formErrors.mothersName}>
            <FormLabel fontSize="sm">Nome do mãe</FormLabel>
            <Input
              value={formData.mothersName ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, mothersName: target.value }))}
            />
            <FormErrorMessage>{formErrors.mothersName}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.mothersCpf}>
            <FormLabel fontSize="sm">CPF da mãe</FormLabel>
            <Input
              as={InputMask}
              mask="999.999.999-99"
              value={formData.mothersCpf ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, mothersCpf: target.value }))}
            />
            <FormErrorMessage>{formErrors.mothersCpf}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={12}>
          <FormControl isInvalid={formErrors.comments}>
            <FormLabel fontSize="sm">Observações</FormLabel>
            <Textarea
              h="200px"
              value={formData.comments ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
            />
            <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={12}>
          <FormControl isInvalid={formErrors.packFootnotes}>
            <FormLabel fontSize="sm">Notas de rodapé do pacote</FormLabel>
            <Textarea
              h="200px"
              value={formData.packFootnotes ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, packFootnotes: target.value }))}
            />
            <FormErrorMessage>{formErrors.packFootnotes}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </>
  );
};

export default Complement;
