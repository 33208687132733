import React, { useContext } from "react";
import moment from "moment";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { MdSave } from "react-icons/md";
import { DatePicker } from "components";
import { currency } from "lib";
import { PaymentsDetailsContext } from "./index";

const PartialPayment = () => {
  const { formData, setFormData, formErrors, setFormErrors, handleSubmitConfirm, loadingSaveData } = useContext(PaymentsDetailsContext);

  const handleClose = () => {
    setFormErrors({});
    setFormData((state) => ({ ...state, nextDueDate: undefined }));
  };

  return (
    <Modal isOpen={formErrors.nextDueDate} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p="30px">
          <Heading>Pagamento parcial</Heading>
          <Text fontSize="sm" marginBottom="1.5rem">
            Você está realizando um pagamento parcial. Será gerado um novo documento com o valor restante de{" "}
            <strong>{currency.format(formData.remainingAmount)}</strong>. Por favor, escolha a data de vencimento no campo abaixo.
          </Text>
          <SimpleGrid>
            <FormControl isRequired={true} isInvalid={formErrors.nextDueDate}>
              <FormLabel>Data de vencimento</FormLabel>
              <DatePicker
                selected={formData.nextDueDate}
                onChange={(nextDueDate) => setFormData((state) => ({ ...state, nextDueDate: moment(nextDueDate).startOf("day").toDate() }))}
                dateFormat="d, MMMM yyyy"
              />
              <FormErrorMessage>{formErrors.nextDueDate}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <HStack justify="flex-end" spacing={4} mt={6}>
            <Button onClick={handleClose}>cancelar</Button>
            <Button
              type="submit"
              leftIcon={<Icon as={MdSave} />}
              colorScheme="main"
              onClick={handleSubmitConfirm}
              isLoading={loadingSaveData}
            >
              salvar
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PartialPayment;
