import React, { memo, useMemo } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { Heading, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, VStack, StackDivider, Icon, Link } from "@chakra-ui/react";
import { useDocumentTitle } from "hooks";
import { Breadcrumb } from "components";
import { Content } from "pages/Private/Container";
import { MdOutlineCircle } from "react-icons/md";
import Preferences from "./Preferences";

const tabs = [
  { hash: "#records", title: "cadastros" },
  { hash: "#preferences", title: "preferências" },
  { hash: "#imports", title: "importações" },
];

const records = [
  { to: "/settings/records/commission-rules", title: "Regras de comissão" },
  { to: "/settings/records/tags", title: "Etiquetas" },
  { to: "/settings/records/product-groups", title: "Grupos de produtos" },
  { to: "/settings/records/payment-categories", title: "Categorias de pagamento" },
  { to: "/settings/records/wallets", title: "Carteiras" },
  { to: "/settings/records/rewards", title: "Rewards" },
];

const imports = [{ to: "/settings/imports/products", title: "Produtos" }];

const ListItem = memo(({ to, title }) => {
  return (
    <HStack p="10px" transition="200ms" fontSize="sm">
      <Icon as={MdOutlineCircle} />
      <Link as={RouterLink} to={to}>
        {title}
      </Link>
    </HStack>
  );
});

const Container = () => {
  useDocumentTitle("Configurações");
  const location = useLocation();
  const activeIndex = useMemo(() => {
    const index = tabs.findIndex((o) => o.hash === location.hash);
    if (index !== -1) return index;
    return 0;
  }, [location.hash]);

  return (
    <Content>
      <HStack justify="space-between">
        <HStack spacing="20px">
          <Breadcrumb items={[{ to: "/settings", label: "configurações" }]} />
        </HStack>
      </HStack>

      <Heading size="md" my="15px">
        Configurações
      </Heading>

      <Tabs defaultIndex={activeIndex} colorScheme="main">
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.hash} as={RouterLink} to={tab.hash}>
              {tab.title}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel p="0" pt="10px">
            <VStack align="stretch" spacing={0} divider={<StackDivider />}>
              {records.map((item) => (
                <ListItem key={item.to} {...item} />
              ))}
            </VStack>
          </TabPanel>
          <TabPanel px="0" py="30px">
            <Preferences />
          </TabPanel>
          <TabPanel p="0" pt="10px">
            <VStack align="stretch" spacing={0} divider={<StackDivider />}>
              {imports.map((item) => (
                <ListItem key={item.to} {...item} />
              ))}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Content>
  );
};

export default Container;
