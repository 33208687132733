import React, { useState, useCallback, useMemo, useEffect } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { MdSearch, MdClose, MdDelete, MdRefresh, MdFilterList, MdVisibility, MdCheck } from "react-icons/md";
import { BiListCheck, BiListMinus } from "react-icons/bi";
import { TbFiles } from "react-icons/tb";
import { Paginator } from "components";
import { messages } from "consts";
import { useApiGet, useCustomToast } from "hooks";
import { api } from "lib";
import { Upload } from "./upload";
import { Selecteds } from "./selecteds";
import { ImagePreview } from "./preview";

const sortable = [
  { key: "title", label: "Título" },
  { key: "type", label: "Tipo" },
  { key: "size", label: "Tamanho" },
  { key: "createdAt", label: "Criação" },
];

const getTypes = (type) => {
  switch (type) {
    case "image":
      return ["image/jpeg", "image/png", "image/webp"];
    default:
      return [];
  }
};

export const Library = ({ type, isOpen, onClose, onFinish }) => {
  const [perPage, setPerPage] = useState(50);
  const [sortKey, setSortKey] = useState("title");
  const [sortDirection, setSortDirection] = useState(1);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const query = useMemo(() => ({ type: { $in: getTypes(type) } }), [type]);
  const sort = useMemo(() => ({ [sortKey]: sortDirection }), [sortKey, sortDirection]);
  const [response, loading, refresh] = useApiGet(
    useMemo(() => ({ path: "/files", params: { query, search, perPage, page, sort } }), [query, search, perPage, page, sort])
  );
  const [selecteds, setSelecteds] = useState([]);
  const [showSelecteds, setShowSelecteds] = useState(false);
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const isAllChecked = useMemo(() => selecteds.length === _.size(response?.data), [selecteds.length, response?.data]);
  const toast = useCustomToast();

  useEffect(() => {
    setSelecteds([]);
  }, [isOpen]);

  const handleFinishUpload = useCallback(
    (docs) => {
      setSelecteds(docs);
      refresh();
    },
    [refresh]
  );

  const handleDeleteData = async () => {
    try {
      setIsLoadingDeleteData(true);
      const promises = _.map(selecteds, (o) => api.delete(`files/${o._id}`));
      await Promise.all(promises);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      onCloseDeleteDialog();
      setSelecteds([]);
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
    }
  };

  const handleSelect = useCallback((item) => {
    setSelecteds((state) => {
      const tmp = [...state];
      const index = tmp.findIndex((o) => o._id === item._id);
      if (index === -1) tmp.push(item);
      else tmp.splice(index, 1);
      return tmp;
    });
  }, []);

  const handleSearchSubmit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setSearch(searchText);
    },
    [searchText]
  );

  const handleFinish = useCallback(() => {
    onFinish?.(selecteds);
    onClose();
  }, [selecteds]);

  const handleCloseSelectedsDrawer = useCallback(() => setShowSelecteds(false), []);

  return (
    <>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="full" scrollBehavior="inside" isCentered={true} autoFocus={false}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader fontSize="md">
            <HStack justifyContent="space-between" mb="10px">
              <Heading size="md">Biblioteca de arquivos</Heading>
              <HStack>
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="main"
                  leftIcon={<Icon as={isAllChecked ? BiListMinus : BiListCheck} boxSize={5} />}
                  onClick={() => setSelecteds(isAllChecked ? [] : response?.data ?? [])}
                >
                  {isAllChecked ? "desmarcar todos" : "marcar todos"}
                </Button>
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="red"
                  leftIcon={<Icon as={MdDelete} />}
                  onClick={onOpenDeleteDialog}
                  isDisabled={selecteds.length === 0}
                >
                  excluir
                </Button>
                <Button
                  size="sm"
                  colorScheme="main"
                  leftIcon={<Icon as={MdCheck} />}
                  onClick={handleFinish}
                  isDisabled={selecteds.length === 0}
                >
                  finalizar seleção
                </Button>
                <Button size="sm" variant="outline" leftIcon={<Icon as={MdClose} />} onClick={onClose}>
                  cancelar seleção
                </Button>
              </HStack>
            </HStack>
            <HStack justifyContent="space-between">
              <form onSubmit={handleSearchSubmit}>
                <InputGroup>
                  <Input
                    variant="filled"
                    placeholder="Buscar em arquivos..."
                    value={searchText}
                    onChange={({ target }) => setSearchText(target.value)}
                  />
                  <InputRightElement>
                    <IconButton type="submit" icon={<Icon as={MdSearch} />} isLoading={loading} variant="ghost" colorScheme="none" />
                  </InputRightElement>
                </InputGroup>
              </form>

              <HStack>
                <Box>
                  <Menu closeOnSelect={false}>
                    <Tooltip label="Ordenação">
                      <MenuButton as={IconButton} variant="outline" icon={<Icon as={MdFilterList} />} />
                    </Tooltip>
                    <MenuList fontSize="sm">
                      <MenuOptionGroup
                        type="radio"
                        title="Direção"
                        value={sortDirection.toString()}
                        onChange={(value) => setSortDirection(parseInt(value))}
                      >
                        <MenuItemOption value="1">Crescente</MenuItemOption>
                        <MenuItemOption value="-1">Decrescente</MenuItemOption>
                      </MenuOptionGroup>
                      <MenuDivider />
                      <MenuOptionGroup type="radio" title="Atributo" value={sortKey} onChange={(value) => setSortKey(value)}>
                        {sortable.map(({ key, label }) => (
                          <MenuItemOption key={key} value={key}>
                            {label}
                          </MenuItemOption>
                        ))}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </Box>
                <Box>
                  <Menu closeOnSelect={true}>
                    <Tooltip label="Limite">
                      <MenuButton as={Button} variant="outline" leftIcon={<Icon as={MdVisibility} boxSize={4} />} fontSize="xs">
                        {perPage}
                      </MenuButton>
                    </Tooltip>
                    <MenuList fontSize="sm">
                      <MenuOptionGroup value={perPage.toString()} type="radio" onChange={(value) => setPerPage(parseInt(value))}>
                        {["10", "20", "50", "100", "200"].map((e) => (
                          <MenuItemOption key={e} value={e}>
                            {e}
                          </MenuItemOption>
                        ))}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </Box>
                <Tooltip label="Atualizar">
                  <IconButton icon={<Icon as={MdRefresh} />} variant="outline" isLoading={loading} onClick={refresh} />
                </Tooltip>
              </HStack>
            </HStack>
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <SimpleGrid columns={[2, 6]} spacing="10px">
              {_.map(response?.data, (item) => (
                <ImagePreview
                  key={item._id}
                  data={item}
                  isSelected={selecteds.findIndex((o) => o._id === item._id) !== -1}
                  onSelect={handleSelect}
                />
              ))}
            </SimpleGrid>
            {loading && (
              <Center py={6}>
                <Spinner />
              </Center>
            )}
            {_.size(response?.data) === 0 && !loading && (
              <VStack spacing={0} py={6}>
                <Icon as={TbFiles} boxSize={20} />
                <Text fontSize="lg" fontWeight="bold">
                  Nenhum arquivo encontrado
                </Text>
                <Text fontSize="sm">Não encontramos arquivos correspondentes à sua busca.</Text>
              </VStack>
            )}
          </DrawerBody>
          <Divider />
          <DrawerFooter justifyContent="space-between">
            <HStack>
              <Upload onFinish={handleFinishUpload} />
              {selecteds.length > 0 && (
                <Button onClick={() => setShowSelecteds(true)} fontSize="sm">
                  {selecteds.length} selecionados
                </Button>
              )}
            </HStack>
            <Paginator loading={loading} page={page} size={response?.size ?? 0} perPage={perPage} onPaginate={(page) => setPage(page)} />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Selecteds isOpen={showSelecteds} onSelect={handleSelect} onClose={handleCloseSelectedsDrawer} data={selecteds} />

      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDeleteDialog}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
