import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @page {
    margin: 0;
    padding: 0;
    size: auto;
  }
}
`;

export const Document = styled.div``;

export const Page = styled.table`
  margin-left: ${({ paddingLeft, columnsGap }) => (paddingLeft ?? 0) + (columnsGap ?? 0)}cm;
  margin-right: ${({ paddingRight }) => paddingRight ?? 0}cm;
  margin-top: ${({ paddingTop, rowsGap }) => (paddingTop ?? 0) + (rowsGap ?? 0)}cm;
  margin-bottom: ${({ paddingBottom }) => paddingBottom ?? 0}cm;
  page-break-after: always;
`;

export const Row = styled.tr`
  padding-bottom: ${({ rowsGap }) => rowsGap ?? 0}cm;
`;

export const Column = styled.td`
  padding-right: ${({ columnsGap }) => columnsGap ?? 0}cm;
`;
