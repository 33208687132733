import moment from "moment";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { currency, translator } from "lib";
import { CustomBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    render: ({ _id, title }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {title}
      </Link>
    ),
    exporter: ({ title }) => title,
  },
  {
    accessor: "type",
    title: "Tipo",
    isVisible: false,
    render: ({ type }) => <CustomBadge.StockMovements.Type type={type} />,
    exporter: ({ type }) => translator(type),
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <CustomBadge.StockMovements.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "notListedProducts",
    title: "Não listados",
    isVisible: true,
    formatter: ({ notListedProducts }) => translator(notListedProducts ?? "-"),
  },
  {
    accessor: "priceType",
    title: "Tipo de preço",
    isVisible: true,
    formatter: ({ priceType }) => translator(priceType),
  },
  {
    accessor: "quantity",
    title: "Quantidade",
    isVisible: true,
    formatter: ({ quantity }) => quantity?.toLocaleString(),
  },
  {
    accessor: "amount",
    title: "Valor",
    isVisible: true,
    formatter: ({ amount }) => currency.format(amount),
  },
  {
    accessor: "author",
    title: "Autor",
    isVisible: true,
    formatter: ({ author }) => author?.name,
  },
  {
    accessor: "supplier",
    title: "Fornecedor",
    isVisible: true,
    formatter: ({ supplier }) => supplier?.name,
  },
  {
    accessor: "purchaseDate",
    title: "Data da compra",
    isVisible: true,
    formatter: ({ purchaseDate }) => purchaseDate && moment(purchaseDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "fiscalDocument",
    title: "Documento fiscal",
    isVisible: false,
    formatter: ({ fiscalDocument }) => fiscalDocument,
  },
  {
    accessor: "closedAt",
    title: "Fechamento",
    isVisible: true,
    formatter: ({ closedAt }) => closedAt && moment(closedAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "createdAt",
    title: "Criação",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualização",
    isVisible: false,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
