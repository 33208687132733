import React, { useContext } from "react";
import { FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, Textarea } from "@chakra-ui/react";
import { InputCurrency } from "components";
import { RewardsDetailsContext } from "./index";

const General = () => {
  const { formData, setFormData, formErrors } = useContext(RewardsDetailsContext);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={8}>
          <FormControl isRequired={true} isInvalid={formErrors.title}>
            <FormLabel fontSize="sm">Título</FormLabel>
            <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
            <FormErrorMessage>{formErrors.title}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl isInvalid={formErrors.brand}>
            <FormLabel fontSize="sm">Marca</FormLabel>
            <Input value={formData.brand ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, brand: target.value }))} />
            <FormErrorMessage>{formErrors.brand}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={6}>
          <FormControl isInvalid={formErrors.originalRequiredScore}>
            <FormLabel fontSize="sm">Pontuação requerida original</FormLabel>
            <Input
              as={InputCurrency}
              precision="0"
              value={formData.originalRequiredScore ?? ""}
              onChange={(originalRequiredScore) => setFormData((state) => ({ ...state, originalRequiredScore }))}
            />
            <FormErrorMessage>{formErrors.originalRequiredScore}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={6}>
          <FormControl isRequired={true} isInvalid={formErrors.currentRequiredScore}>
            <FormLabel fontSize="sm">Pontuação requerida atual</FormLabel>
            <Input
              as={InputCurrency}
              precision="0"
              value={formData.currentRequiredScore ?? ""}
              onChange={(currentRequiredScore) => setFormData((state) => ({ ...state, currentRequiredScore }))}
            />
            <FormErrorMessage>{formErrors.currentRequiredScore}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
        <GridItem colSpan={12}>
          <FormControl isRequired={true} isInvalid={formErrors.description}>
            <FormLabel fontSize="sm">Descrição</FormLabel>
            <Textarea
              h="300px"
              value={formData.description ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
            />
            <FormErrorMessage>{formErrors.description}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </>
  );
};

export default General;
