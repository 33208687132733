import moment from "moment";
import { currency, percent } from "lib";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "agent",
    title: "Representante",
    isVisible: true,
    formatter: ({ agent }) => agent?.name ?? "-",
  },
  {
    accessor: "startDate",
    title: "Data inicial",
    isVisible: false,
    formatter: ({ startDate }) => moment(startDate).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "endDate",
    title: "Data final",
    isVisible: false,
    formatter: ({ endDate }) => moment(endDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "totalReceivedAmount",
    title: "Total recebido",
    isVisible: true,
    formatter: ({ totalReceivedAmount }) => currency.format(totalReceivedAmount),
  },
  {
    accessor: "packsDealerCommissionAmount",
    title: "Comissões pagas",
    isVisible: true,
    formatter: ({ packsDealerCommissionAmount }) => currency.format(packsDealerCommissionAmount),
  },
  {
    accessor: "paymentsReceivedAmount",
    title: "Outros pgts",
    isVisible: true,
    formatter: ({ paymentsReceivedAmount }) => currency.format(paymentsReceivedAmount),
  },
  {
    accessor: "totalAgentCommissionAmount",
    title: "Comissão do rep.",
    isVisible: true,
    formatter: ({ totalAgentCommissionAmount }) => currency.format(totalAgentCommissionAmount),
  },
  {
    accessor: "totalAgentDebitAmount",
    title: "Débito do rep.",
    isVisible: true,
    formatter: ({ totalAgentDebitAmount }) => currency.format(totalAgentDebitAmount),
  },
  {
    accessor: "packsAvgPerformance",
    title: "Desempenho médio",
    isVisible: true,
    formatter: ({ packsAvgPerformance }) => percent.format(packsAvgPerformance),
  },
  {
    accessor: "packsInsolvency",
    title: "Inadimplência",
    isVisible: true,
    formatter: ({ packsInsolvency }) => percent.format(packsInsolvency),
  },
  {
    accessor: "closedAt",
    title: "Fechado em",
    isVisible: true,
    formatter: ({ closedAt }) => (closedAt ? moment(closedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "createdAt",
    title: "Criação",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualização",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
