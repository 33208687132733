export const document = (value) => {
  try {
    value = value.replace(/[^\d]/g, "");
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } catch (error) {
    return "-";
  }
};

export const phone = (value) => {
  try {
    value = value.replace(/[^\d]/g, "");
    return value.replace(/(\d{2})(\d{4})(\d{4,})/, "($1) $2-$3");
  } catch (error) {
    return "-";
  }
};

export const zipCode = (value) => {
  try {
    value = value.replace(/[^\d]/g, "");
    return value.replace(/(\d{5})(\d{2})/, "$1-$2");
  } catch (error) {
    return "-";
  }
};
