import moment from "moment";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    render: ({ nid }) => nid,
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    render: ({ _id, title }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {title}
      </Link>
    ),
  },
  {
    accessor: "isPrimary",
    title: "Principal?",
    isVisible: true,
    render: ({ isPrimary }) => (isPrimary ? "Sim" : "Não"),
  },
  {
    accessor: "isPublic",
    title: "Público?",
    isVisible: true,
    render: ({ isPublic }) => (isPublic ? "Sim" : "Não"),
  },
  {
    accessor: "isActive",
    title: "Ativo?",
    isVisible: true,
    render: ({ isActive }) => (isActive ? "Sim" : "Não"),
  },
  {
    accessor: "createdAt",
    title: "Criação",
    isVisible: true,
    render: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualização",
    isVisible: true,
    render: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
