import React, { memo, useContext, useMemo } from "react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { Button, HStack, Icon, Link, Menu, MenuButton, MenuItem, MenuList, MenuGroup, Text, IconButton } from "@chakra-ui/react";
import { MdChevronRight } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { AppContext } from "AppProvider";

export const Breadcrumb = memo((props) => {
  const { isMobileView } = useContext(AppContext);
  const [group, items] = useMemo(() => {
    const [group, ...rest] = props.items;
    return [group, rest];
  }, [props.items]);

  return isMobileView ? (
    <Menu>
      <MenuButton as={IconButton} size="sm" variant="outline" icon={<Icon as={HiMenuAlt2} />} />
      <MenuList>
        <MenuGroup title={group.label}>
          {items.map((item) => (
            <MenuItem key={item.label} as={RouterLink} to={item.to}>
              {item.label}
            </MenuItem>
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  ) : (
    <HStack fontSize="sm" divider={<Icon as={MdChevronRight} position="relative" top="2px" border="none" opacity="0.5" />}>
      <HStack h="32px" opacity="0.5">
        <Icon as={HiMenuAlt2} />
        <Text fontWeight="bold">{group.label}</Text>
      </HStack>
      {items.map((item, index) => (
        <Link key={item.label} as={RouterLink} to={item.to} fontWeight="bold" opacity={index + 1 !== items.length && 0.4}>
          {item.label}
        </Link>
      ))}
    </HStack>
  );
});
