import moment from "moment";
import _ from "lodash";
import { currency, translator } from "lib";
import { CustomBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "type",
    title: "Tipo",
    isVisible: false,
    render: ({ type }) => <CustomBadge.Payments.Type type={type} />,
    exporter: ({ type }) => translator(type),
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <CustomBadge.Payments.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "user",
    title: "Usuário",
    isVisible: true,
    formatter: ({ user }) => user?.name,
  },
  {
    accessor: "paymentMethod",
    title: "Método",
    isVisible: true,
    formatter: ({ paymentMethod }) => translator(paymentMethod),
  },
  {
    accessor: "wallet",
    title: "Carteira",
    isVisible: true,
    formatter: ({ wallet }) => wallet?.title,
  },
  {
    accessor: "paymentCategory",
    title: "Categoria",
    isVisible: true,
    formatter: ({ paymentCategory }) => paymentCategory?.title,
  },
  {
    accessor: "issueDate",
    title: "Data emissão",
    isVisible: true,
    formatter: ({ issueDate }) => moment(issueDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "dueDate",
    title: "Data vencimento",
    isVisible: true,
    formatter: ({ dueDate }) => moment(dueDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "paymentDate",
    title: "Data pagamento",
    isVisible: true,
    formatter: ({ paymentDate }) => paymentDate && moment(paymentDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "receivableAmount",
    title: "Recebíveis",
    isVisible: true,
    formatter: ({ receivableAmount }) => currency.format(receivableAmount),
  },
  {
    accessor: "receivedAmount",
    title: "Recebido",
    isVisible: true,
    formatter: ({ receivedAmount }) => currency.format(receivedAmount),
  },
  {
    accessor: "remainingAmount",
    title: "Restante",
    isVisible: false,
    formatter: ({ remainingAmount }) => currency.format(remainingAmount),
  },
  {
    accessor: "attachments",
    title: "Anexos",
    isVisible: true,
    formatter: ({ attachments }) => _.size(attachments),
  },
  {
    accessor: "closedAt",
    title: "Fechamento",
    isVisible: true,
    formatter: ({ closedAt }) => closedAt && moment(closedAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "createdAt",
    title: "Criação",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualização",
    isVisible: false,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
