import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MerchandiseMovement, RequiredContractError } from "exceptions";
import { SplashContext } from "SplashProvider";
import { Home } from "./Home";
import { UsersList, UsersDetails } from "./Users";
import { ProductsList, ProductsDetails } from "./Products";
import { PacksList, PacksDetails } from "./Packs";
import { StockMovementsList, StockMovementsDetails } from "./StockMovements";
import { PackMovementsList, PackMovementsDetails } from "./PackMovements";
import { PaymentsList, PaymentsDetails } from "./Payments";
import { PackOrdersList, PackOrdersDetails } from "./PackOrders";
import { PackExpeditionsList, PackExpeditionsDetails } from "./PackExpeditions";
import { PackSettlementsList, PackSettlementsDetails } from "./PackSettlements";
import { CommissionsList } from "./Commissions";
import { WalletsList } from "./Wallets";
import { ContractsList } from "./Contracts";
import * as Settings from "./Settings";
import Container from "./Container";

const Private = () => {
  const { setIsAppReady } = useContext(SplashContext);

  useEffect(() => {
    setIsAppReady(true);
  }, [setIsAppReady]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Container />}>
          <Route index={true} path="home" element={<Home />} />

          <Route path="users">
            <Route index={true} element={<UsersList />} />
            <Route path="new" element={<UsersDetails />} />
            <Route path="edit/:_id" element={<UsersDetails />} />
          </Route>

          <Route path="products">
            <Route index={true} element={<ProductsList />} />
            <Route path="new" element={<ProductsDetails />} />
            <Route path="edit/:_id" element={<ProductsDetails />} />
          </Route>

          <Route path="packs">
            <Route index={true} element={<PacksList />} />
            <Route path="new" element={<PacksDetails />} />
            <Route path="edit/:_id" element={<PacksDetails />} />
          </Route>

          <Route path="pack-orders">
            <Route index={true} element={<PackOrdersList />} />
            <Route path="new" element={<PackOrdersDetails />} />
            <Route path="edit/:_id" element={<PackOrdersDetails />} />
          </Route>

          <Route path="pack-movements">
            <Route index={true} element={<PackMovementsList />} />
            <Route path="new" element={<PackMovementsDetails />} />
            <Route path="edit/:_id" element={<PackMovementsDetails />} />
          </Route>

          <Route path="stock-movements">
            <Route index={true} element={<StockMovementsList />} />
            <Route path="new" element={<StockMovementsDetails />} />
            <Route path="edit/:_id" element={<StockMovementsDetails />} />
          </Route>

          <Route path="payments">
            <Route index={true} element={<PaymentsList />} />
            <Route path="new" element={<PaymentsDetails />} />
            <Route path="edit/:_id" element={<PaymentsDetails />} />
          </Route>

          <Route path="pack-expeditions">
            <Route index={true} element={<PackExpeditionsList />} />
            <Route path="new" element={<PackExpeditionsDetails />} />
            <Route path="edit/:_id" element={<PackExpeditionsDetails />} />
          </Route>

          <Route path="pack-settlements">
            <Route index={true} element={<PackSettlementsList />} />
            <Route path="new" element={<PackSettlementsDetails />} />
            <Route path="edit/:_id" element={<PackSettlementsDetails />} />
          </Route>

          <Route path="commissions">
            <Route index={true} element={<CommissionsList />} />
          </Route>

          <Route path="wallets">
            <Route index={true} element={<WalletsList />} />
          </Route>

          <Route path="contracts">
            <Route index={true} element={<ContractsList />} />
          </Route>

          <Route path="settings">
            <Route index={true} element={<Settings.Container />} />

            <Route path="records">
              <Route path="tags" element={<Settings.Records.TagsList />} />

              <Route path="payment-categories">
                <Route index={true} element={<Settings.Records.PaymentCategoriesList />} />
                <Route path="new" element={<Settings.Records.PaymentCategoriesDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.PaymentCategoriesDetails />} />
              </Route>

              <Route path="product-groups">
                <Route index={true} element={<Settings.Records.ProductGroupsList />} />
                <Route path="new" element={<Settings.Records.ProductGroupsDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.ProductGroupsDetails />} />
              </Route>

              <Route path="commission-rules">
                <Route index={true} element={<Settings.Records.CommissionRulesList />} />
                <Route path="new" element={<Settings.Records.CommissionRulesDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.CommissionRulesDetails />} />
              </Route>

              <Route path="wallets">
                <Route index={true} element={<Settings.Records.WalletsList />} />
                <Route path="new" element={<Settings.Records.WalletsDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.WalletsDetails />} />
              </Route>

              <Route path="rewards">
                <Route index={true} element={<Settings.Records.RewardsList />} />
                <Route path="new" element={<Settings.Records.RewardsDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.RewardsDetails />} />
              </Route>
            </Route>

            <Route path="imports">
              <Route path="products" element={<Settings.Imports.Products />} />
            </Route>
          </Route>
        </Route>

        <Route path="/settings/records/tags">
          <Route path="new/" element={<Settings.Records.TagsDetails />} />
          <Route path="edit/:_id" element={<Settings.Records.TagsDetails />} />
        </Route>

        <Route path="*" element={<Navigate to="/home" replace={true} />} />
      </Routes>
      <MerchandiseMovement />
      <RequiredContractError />
    </BrowserRouter>
  );
};

export default Private;
