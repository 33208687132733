import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  SlideFade,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VisuallyHidden,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { Breadcrumb } from "components";
import { messages } from "consts";
import { api } from "lib";
import { Content } from "pages/Private/Container";
import { MdAttachFile, MdDeleteOutline, MdDownload, MdOutlineCloudUpload } from "react-icons/md";
import { useDocumentTitle, useCustomToast } from "hooks";
import papaparse from "papaparse";
import processor from "./processor";
import { useNavigate } from "react-router-dom";

const parse = (file) => {
  return new Promise((resolve) => {
    papaparse.parse(file, {
      header: true,
      skipEmptyLines: true,
      delimiter: ";",
      complete: resolve,
    });
  });
};

export const Products = () => {
  useDocumentTitle("Importação de produtos");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { isOpen, onOpen } = useDisclosure();
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const formRef = useRef();
  const inputRef = useRef();
  const toast = useCustomToast();

  useEffect(() => {
    const timeout = setTimeout(onOpen, 500);
    return () => clearTimeout(timeout);
  }, [onOpen]);

  const handleDownload = useCallback(() => {
    const data = {};
    Object.keys(processor).forEach((key) => (data[key] = processor[key].example));
    const response = Array(4).fill(data);
    const csv = papaparse.unparse(response, { delimiter: ";" });
    const file = new Blob([csv], { type: "csv" });
    const fileURL = window.URL.createObjectURL(file);
    const alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = "modelo-produtos.csv";
    alink.click();
  }, []);

  const handleFileChange = useCallback(async ({ target }) => {
    try {
      const [file] = target.files;
      const data = [];
      const keys = Object.keys(processor);
      const result = await parse(file);
      if (result.length > 500) throw new Error(messages.error.importListSize);
      _.forEach(result.data, (item) => {
        const tmp = {};
        for (const key of keys) {
          if (item[key]) _.set(tmp, processor[key].accessor, processor[key].formatter?.(item[key]) ?? item[key]);
        }
        if (Object.keys(tmp).length) data.push(tmp);
      });
      setData(data);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      formRef.current.reset();
    }
  }, []);

  const handleDelete = useCallback((index) => {
    setData((state) => {
      const tmp = [...state];
      tmp.splice(index, 1);
      return tmp;
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoadingSaveData(true);
      await api.post("/imports/products", data);
      navigate(`/products`, { replace: true });
      toast({ description: messages.success.importData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, [data]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <Breadcrumb
            items={[
              { label: "configurações" },
              { to: "/settings#imports", label: "importações" },
              { to: "/settings/imports/products", label: "produtos" },
            ]}
          />
          <SlideFade in={isOpen} offsetY="-20px">
            <Button size="sm" colorScheme="main" leftIcon={<Icon as={MdDownload} />} onClick={handleDownload}>
              baixar exemplo
            </Button>
          </SlideFade>
        </HStack>

        <Heading my="15px" size="md">
          Importação de produtos
        </Heading>

        {data.length === 0 ? (
          <Center py="60px">
            <VStack maxW="lg">
              <Icon as={MdAttachFile} boxSize="100px" />
              <Heading size="lg" textAlign="center">
                Selecione o arquivo
              </Heading>
              <Text textAlign="center">
                Para iniciar a importação selecione o arquivo com os dados. É importante que o arquivo siga o exemplo disponibilizado.
              </Text>
              <Box h="5px" />
              <HStack>
                <Button variant="outline" leftIcon={<Icon as={MdAttachFile} />} onClick={() => inputRef.current.click()}>
                  selecionar arquivo
                </Button>
              </HStack>
            </VStack>
          </Center>
        ) : (
          <Table size="sm" whiteSpace="nowrap">
            <Thead>
              <Tr>
                <Th>#</Th>
                {Object.keys(processor).map((item) => (
                  <Th key={item}>{item}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, index) => (
                <Tr key={index}>
                  <Td>
                    <IconButton size="xs" variant="outline" icon={<Icon as={MdDeleteOutline} />} onClick={() => handleDelete(index)} />
                  </Td>
                  {Object.values(processor).map(({ accessor, render }) => {
                    const value = _.get(item, accessor);
                    return <Td key={accessor}>{_.isNil(value) ? "-" : render?.(value) ?? value}</Td>;
                  })}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Content>

      <Divider />

      <SlideFade in={isOpen} offsetY="20px">
        <HStack justifyContent="space-between" p="20px">
          <Button size="sm" onClick={() => setData([])}>
            limpar importação
          </Button>
          <Button
            size="sm"
            colorScheme="green"
            leftIcon={<Icon as={MdOutlineCloudUpload} />}
            onClick={handleSubmit}
            isDisabled={data.length === 0}
            isLoading={isLoadingSaveData}
          >
            importar dados
          </Button>
        </HStack>
      </SlideFade>

      <VisuallyHidden>
        <form ref={formRef}>
          <input ref={inputRef} type="file" accept=".csv" onChange={handleFileChange} />
        </form>
      </VisuallyHidden>
    </>
  );
};
