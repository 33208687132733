import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { api, translator } from "lib";
import { HiOutlineFilter } from "react-icons/hi";
import { AsyncSelect, DatePicker, SyncSelect } from "components";
import { useCacheState } from "hooks";

const statuses = ["pending", "paid"];

let loadUsersTimeout;

const Filters = ({ onQuery }) => {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [query, setQuery] = useCacheState("CommissionsList.Filters.query", { status: ["preparing", "pending"] });
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);

  useEffect(() => {
    const response = {};
    if (query.status?.length) _.set(response, "status.$in", query.status);
    if (query.user?.length) _.set(response, "user.$in", _.map(query.user, "_id"));
    if (query.paidAtStartDate) _.set(response, "paidAt.$gte", query.paidAtStartDate);
    if (query.paidAtEndDate) _.set(response, "paidAt.$lte", query.paidAtEndDate);
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
  }, [onQuery, query, location.state]);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.get("/users", { params: { query, search } });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
    onClose();
  }, [setQuery, formData, onClose]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, []);

  return (
    <>
      <Button colorScheme={isFiltering ? "main" : "gray"} variant="outline" rightIcon={<Icon as={HiOutlineFilter} />} onClick={onOpen}>
        filtros
      </Button>
      {isFiltering && (
        <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
          limpar filtros
        </Button>
      )}
      <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Filtros</DrawerHeader>
          <Divider />
          <DrawerBody overflow="visible">
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl>
                  <FormLabel fontSize="sm">Status</FormLabel>
                  <SyncSelect
                    isMulti
                    value={_.map(formData.status, (value) => ({ value, label: translator(value) }))}
                    placeholder="Selecione o status"
                    options={_.map(statuses, (value) => ({ value, label: translator(value) }))}
                    onChange={(status) => setFormData((state) => ({ ...state, status: _.map(status, ({ value }) => value) }))}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl>
                  <FormLabel fontSize="sm">Usuário</FormLabel>
                  <AsyncSelect
                    isMulti
                    value={formData.user ?? []}
                    defaultOptions
                    loadOptions={handleLoadUsers}
                    placeholder="Selecione o representante"
                    onChange={(user) => setFormData((state) => ({ ...state, user }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ name }) => name}
                    isClearable={true}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true}>
                  <FormLabel fontSize="sm">Pago em (inicial)</FormLabel>
                  <DatePicker
                    selected={formData.paidAtStartDate}
                    onChange={(value) => setFormData((state) => ({ ...state, paidAtStartDate: moment(value).startOf("day").toDate() }))}
                    selectsStart
                    startDate={formData.paidAtStartDate}
                    endDate={formData.paidAtEndDate}
                    dateFormat="d, MMMM yyyy"
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true}>
                  <FormLabel fontSize="sm">Pago em (final)</FormLabel>
                  <DatePicker
                    selected={formData.paidAtEndDate}
                    onChange={(value) => setFormData((state) => ({ ...state, paidAtEndDate: moment(value).startOf("day").toDate() }))}
                    selectsEnd
                    startDate={formData.paidAtStartDate}
                    endDate={formData.paidAtEndDate}
                    minDate={formData.paidAtStartDate}
                    dateFormat="d, MMMM yyyy"
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </DrawerBody>
          <Divider />
          <DrawerFooter>
            <HStack justifyContent="flex-end">
              <Button size="sm" onClick={onClose}>
                cancelar
              </Button>
              <Button size="sm" colorScheme="main" onClick={handleSubmit}>
                aplicar
              </Button>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Filters;
