import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
  VisuallyHidden,
  VStack,
} from "@chakra-ui/react";
import { MdCheck, MdError, MdCloudUpload, MdAttachFile } from "react-icons/md";
import { api, formatBytes } from "lib";

export const Upload = ({ onFinish }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [successUploadedFilesCount, setSuccessUploadedFilesCount] = useState(0);
  const [errorUploadedFilesCount, setErrorUploadedFilesCount] = useState(0);
  const inputRef = useRef();

  useEffect(() => {
    const uploadedFilesValues = Object.values(uploadedFiles);
    const successUploadedFilesCount = uploadedFilesValues.filter((o) => o._id).length;
    setSuccessUploadedFilesCount(successUploadedFilesCount);
    setErrorUploadedFilesCount(uploadedFilesValues.length - successUploadedFilesCount);
    setIsLoading(uploadedFilesValues.length !== droppedFiles.length);
  }, [droppedFiles, uploadedFiles]);

  useEffect(() => {
    if (droppedFiles.length > 0) {
      const responses = Object.values(uploadedFiles);
      const isFinished = responses.length === droppedFiles.length;
      if (isFinished) onFinish?.(responses.filter((o) => o._id));
    }
  }, [droppedFiles, uploadedFiles, onFinish]);

  useEffect(() => {
    (async () => {
      for (const droppedFile of droppedFiles) {
        try {
          const doc = await api.upload("/files", droppedFile);
          setUploadedFiles((state) => ({ ...state, [droppedFile.name]: doc }));
        } catch (error) {
          setUploadedFiles((state) => ({ ...state, [droppedFile.name]: error }));
        }
      }
    })();
  }, [droppedFiles]);

  const handleChangeFiles = ({ target }) => {
    setDroppedFiles([]);
    setUploadedFiles({});
    const files = Array.from(target.files);
    for (const file of files) file.location = URL.createObjectURL(file);
    setDroppedFiles(files);
  };

  return (
    <Box>
      <VisuallyHidden>
        <input type="file" ref={inputRef} multiple={true} onChange={handleChangeFiles} />
      </VisuallyHidden>
      <Popover placement="top-start">
        <PopoverTrigger>
          <Button colorScheme="main" leftIcon={isLoading ? <Spinner size="sm" /> : <Icon as={MdCloudUpload} boxSize={5} />} fontSize="sm">
            Novo upload
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>
            <Button
              leftIcon={<Icon as={MdAttachFile} />}
              colorScheme="main"
              width="100%"
              isLoading={isLoading}
              onClick={() => inputRef.current.click()}
            >
              Selecionar arquivos
            </Button>
          </PopoverHeader>
          {droppedFiles.length > 0 && (
            <>
              <PopoverBody maxHeight="200px" overflowY="auto">
                <VStack align="stretch" spacing={2}>
                  {droppedFiles.map((droppedFile) => (
                    <HStack key={droppedFile.name} justify="space-between">
                      <HStack>
                        <Image
                          width="50px"
                          height="50px"
                          objectFit="cover"
                          src={droppedFile.location}
                          alt={droppedFile.name}
                          borderRadius="lg"
                        />
                        <VStack align="stretch" spacing={0}>
                          <Text fontSize="xs" fontWeight="bold" noOfLines={1}>
                            {droppedFile.name}
                          </Text>
                          <Text fontSize="xs">{formatBytes(droppedFile.size)}</Text>
                        </VStack>
                      </HStack>
                      {uploadedFiles[droppedFile.name] ? (
                        <Tooltip label={uploadedFiles[droppedFile.name]?.message ?? ""}>
                          <IconButton
                            icon={<Icon as={uploadedFiles[droppedFile.name]._id ? MdCheck : MdError} />}
                            colorScheme={uploadedFiles[droppedFile.name]._id ? "green" : "red"}
                            size="xs"
                            isRound={true}
                          />
                        </Tooltip>
                      ) : (
                        <IconButton isLoading={true} size="xs" isRound={true} />
                      )}
                    </HStack>
                  ))}
                </VStack>
              </PopoverBody>
              <Divider />
              <PopoverFooter as={VStack} spacing={0}>
                <Text fontSize="xs">
                  {successUploadedFilesCount}/{droppedFiles.length} arquivos finalizados com sucesso
                </Text>
                {errorUploadedFilesCount > 0 && (
                  <Text fontSize="xs" color="red.500">
                    {errorUploadedFilesCount} arquivos com falha
                  </Text>
                )}
              </PopoverFooter>
            </>
          )}
        </PopoverContent>
      </Popover>
    </Box>
  );
};
