import { Input } from "@chakra-ui/react";
import ReactCurrencyInput from "react-currency-input";

let componentDidMount_super = ReactCurrencyInput.prototype.componentDidMount;
ReactCurrencyInput.prototype.componentDidMount = function () {
  if (!this.props.autoFocus) {
    let setSelectionRange_super = this.theInput.setSelectionRange;
    this.theInput.setSelectionRange = () => {};
    componentDidMount_super.call(this, ...arguments);
    this.theInput.setSelectionRange = setSelectionRange_super;
  } else {
    componentDidMount_super.call(this, ...arguments);
  }
};
let componentDidUpdate_super = ReactCurrencyInput.prototype.componentDidUpdate;
ReactCurrencyInput.prototype.componentDidUpdate = function () {
  if (!this.props.autoFocus) {
    let setSelectionRange_super = this.theInput.setSelectionRange;
    this.theInput.setSelectionRange = () => {};
    componentDidUpdate_super.call(this, ...arguments);
    this.theInput.setSelectionRange = setSelectionRange_super;
  } else {
    componentDidMount_super.call(this, ...arguments);
  }
};

export const CurrencyInput = ReactCurrencyInput;

export const InputCurrency = ({ precision = "2", value, onChange, isPercentage, ...rest }) => {
  return (
    <Input
      type="tel"
      as={ReactCurrencyInput}
      decimalSeparator=","
      thousandSeparator="."
      precision={precision}
      value={isPercentage ? value * 100 : value}
      onChangeEvent={(_, __, value) => onChange(isPercentage ? value / 100 : value)}
      autoFocus={false}
      {...rest}
    />
  );
};
