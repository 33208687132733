import React, { useEffect, useContext, useCallback, memo } from "react";
import {
  Box,
  Center,
  Checkbox,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import { MdMoreHoriz, MdRefresh } from "react-icons/md";
import { currency, percent } from "lib";
import { useClipboard } from "hooks";
import { CustomBadge, InputCurrency, Lottie } from "components";
import { PackSettlementsDetailsContext } from "../index";
import { FiClipboard, FiEdit } from "react-icons/fi";
import moment from "moment";
import animationData from "assets/lottie/box.json";
import { TbTruckDelivery } from "react-icons/tb";
import { RiPercentFill } from "react-icons/ri";

const ListItem = memo(({ index, item, isChecked, onCheckedChange, onPercentageChange }) => {
  const copyToClipboard = useClipboard();

  return (
    <Tr
      _hover={{
        _light: { bg: isChecked ? "main.100" : "gray.50" },
        _dark: { bg: isChecked ? "main.900" : "gray.900" },
      }}
      _light={{ bg: isChecked ? "main.50" : "white" }}
      _dark={{ bg: isChecked ? "main.800" : "gray.800" }}
    >
      <Td>
        <HStack>
          <Checkbox isChecked={isChecked} onChange={() => onCheckedChange(index)} />
          <Box>
            <Menu placement="right-start">
              <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
              <MenuList>
                <MenuGroup title={`${item.isLoad ? "Carga" : "Pacote"} ${item.nid}`} pb="5px">
                  <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                    copiar código
                  </MenuItem>
                  <MenuItem icon={<Icon as={FiEdit} />} as={RouterLink} to={`/packs/edit/${item._id}`} target="_blank">
                    editar
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </Box>
          {item.isLoad && (
            <Tooltip label="Carga">
              <Center w="25px" h="25px" borderRadius="lg" bg="green.100">
                <Icon as={TbTruckDelivery} color="green.500" />
              </Center>
            </Tooltip>
          )}
        </HStack>
      </Td>
      <Td>
        <CustomBadge.Packs.Status status={item.status} />
      </Td>
      <Td>{item.dealer?.name ?? "-"}</Td>
      <Td>{moment(item.predictedSettlementDate).format("DD, MMMM [de] YYYY")}</Td>
      <Td>{item.settledAt ? moment(item.settledAt).format("DD, MMMM [de] YYYY") : "-"}</Td>
      <Td>{item.isLoad ? "-" : percent.format(item.performance)}</Td>
      <Td>{currency.format(item.currentAmount)}</Td>
      <Td>{item.currentQuantity.toLocaleString()}</Td>
      <Td>{item.isLoad ? "-" : `${currency.format(item.dealerCommissionAmount)} • ${percent.format(item.dealerCommissionPercentage)}`}</Td>
      <Td>{item.isLoad ? "-" : currency.format(item.receivedAmount)}</Td>
      <Td>{item.isLoad ? "-" : currency.format(item.remainingAmount)}</Td>
      <Td>
        {item.isLoad ? (
          "-"
        ) : (
          <InputGroup>
            <Input
              as={InputCurrency}
              textAlign="center"
              value={(item.agentCommissionPercentage ?? 0) * 100}
              onChange={(value) => onPercentageChange(index, value / 100)}
              isDisabled={!item.settledAt}
            />
            <InputRightElement>
              <IconButton
                size="sm"
                variant={item.agentCommissionPercentage === item.defaultAgentCommissionPercentage ? "outline" : "solid"}
                colorScheme={item.agentCommissionPercentage === item.defaultAgentCommissionPercentage ? "gray" : "main"}
                icon={<Icon as={RiPercentFill} />}
                onClick={() => onPercentageChange(index, item.defaultAgentCommissionPercentage)}
                isDisabled={!item.settledAt}
              />
            </InputRightElement>
          </InputGroup>
        )}
      </Td>
      <Td>{item.isLoad ? "-" : currency.format(item.agentCommissionAmount)}</Td>
    </Tr>
  );
});

const Packs = () => {
  const { formData, setFormData, packs, isLoadingPacks, refreshPacks } = useContext(PackSettlementsDetailsContext);

  useEffect(() => {
    const mapped = _.map(packs?.data, (item) => {
      let agentCommissionPercentage = 0;
      let agentCommissionAmount = 0;
      const key = formData.agent?.commissionRule?.type === "percentual_amount" ? "performance" : "currentAmount";
      for (const range of formData.agent?.commissionRule?.ranges ?? []) {
        if (!item.settledAt) continue;
        if (range.soldStart <= item[key] && range.soldEnd >= item[key]) {
          agentCommissionPercentage = range.commissionPercentage;
          agentCommissionAmount = agentCommissionPercentage * item.receivedAmount;
          break;
        }
      }
      return {
        ...item,
        defaultAgentCommissionPercentage: agentCommissionPercentage,
        agentCommissionPercentage,
        agentCommissionAmount,
        isChecked: item.settledAt,
      };
    });
    setFormData((state) => ({ ...state, packs: mapped }));
  }, [formData.agent, packs]);

  useEffect(() => {
    const counters = {
      packsSoldAmount: 0,
      packsSoldQuantity: 0,
      packsReceivableAmount: 0,
      packsReceivedAmount: 0,
      packsRemainingAmount: 0,
      packsDealerCommissionAmount: 0,
      packsPerformanceAmount: 0,
      packsAvgPerformance: 0,
      packsAgentDebitAmount: 0,
      packsAgentCommissionAmount: 0,
      packsInsolvency: 0,
    };
    let size = 0;
    for (const item of formData.packs ?? []) {
      if (!item.isChecked) continue;
      if (item.settledAt) {
        size++;
        counters.packsSoldAmount += item.currentAmount;
        counters.packsSoldQuantity += item.currentQuantity;
        counters.packsReceivableAmount += item.receivedAmount + item.remainingAmount;
        counters.packsReceivedAmount += item.receivedAmount;
        counters.packsRemainingAmount += item.remainingAmount;
        counters.packsDealerCommissionAmount += item.dealerCommissionAmount;
        counters.packsPerformanceAmount += item.performance;
        counters.packsAgentCommissionAmount += item.agentCommissionAmount;
      } else {
        counters.packsAgentDebitAmount += item.currentAmount;
      }
    }
    if (counters.packsReceivableAmount > 0 && counters.packsRemainingAmount > 0)
      counters.packsInsolvency = counters.packsRemainingAmount / counters.packsReceivableAmount;
    counters.packsAvgPerformance = _.divide(counters.packsPerformanceAmount, size) || 0;
    setFormData((state) => ({ ...state, ...counters }));
  }, [formData.packs, setFormData]);

  const handleToggleCheck = useCallback((index) => {
    setFormData((state) => {
      const packs = [...state.packs];
      packs[index].isChecked = !packs[index].isChecked;
      return { ...state, packs };
    });
  }, []);

  const handleAgentCommissionPercentageChange = useCallback((index, value) => {
    setFormData((state) => {
      const packs = [...state.packs];
      packs[index].agentCommissionPercentage = value;
      packs[index].agentCommissionAmount = value * packs[index].receivedAmount;
      return { ...state, packs };
    });
  }, []);

  return (
    <>
      <HStack mb={4}>
        <Text fontSize="sm" fontWeight="semibold">
          {_.size(formData.packs)} registros
        </Text>
        <HStack flex="1" justify="flex-end">
          <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingPacks} onClick={refreshPacks} />
        </HStack>
      </HStack>

      <Table size="sm" whiteSpace="nowrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Status</Th>
            <Th>Revendedor</Th>
            <Th>Previsão de acerto</Th>
            <Th>Acertado em</Th>
            <Th>Desempenho</Th>
            <Th>R$ vendido</Th>
            <Th>Qtd. vendida</Th>
            <Th>Comissão rev.</Th>
            <Th>R$ recebido</Th>
            <Th>R$ restante</Th>
            <Th>% comissão rep.</Th>
            <Th>R$ comissão rep.</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(formData.packs, (item, index) => (
            <ListItem
              key={item._id}
              index={index}
              item={item}
              isChecked={item.isChecked}
              agentCommissionPercentage={item.agentCommissionPercentage}
              agentCommissionAmount={item.agentCommissionAmount}
              onCheckedChange={handleToggleCheck}
              onPercentageChange={handleAgentCommissionPercentageChange}
            />
          ))}
        </Tbody>
      </Table>
      {isLoadingPacks ? (
        <Center p="30px">
          <Spinner />
        </Center>
      ) : (
        _.size(formData.packs) === 0 && (
          <Center py="60px">
            <VStack maxW="lg">
              <Center w="200px" h="200px" position="relative" overflow="hidden" pt="50px">
                <Lottie animationData={animationData} style={{ width: 420, height: 420, position: "absolute" }} />
              </Center>
              <Heading size="lg" textAlign="center">
                Nenhum pacote adicionado
              </Heading>
              <Text textAlign="center">Não encontramos pacotes que correspondam ao representate ou período selecionados.</Text>
            </VStack>
          </Center>
        )
      )}
    </>
  );
};

export default Packs;
