import React, { useState, useRef } from "react";
import _ from "lodash";
import { VisuallyHidden } from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import Renderer from "../renderer";
import { processors } from "../../lib";
import * as Styled from "./styled";

const Printer = ({ tag = {}, documents = [], trigger }) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const bodyRef = useRef();

  const handleBeforeGetContent = () => {
    return new Promise((resolve) => {
      setIsLoading(true);
      const items = _.flatMap(documents, ({ document, quantity }) => _.range(0, quantity, 1).map(() => document));
      const rows = _.chunk(items, tag.columnsQuantity);
      const pages = _.chunk(rows, tag.rowsQuantity);
      setItems(pages);
      setTimeout(resolve, 1000);
    });
  };

  const handleAfterPrint = () => {
    setItems([]);
    setIsLoading(false);
  };

  return (
    <>
      <ReactToPrint
        content={() => bodyRef.current}
        trigger={() => trigger?.({ isLoading })}
        onBeforeGetContent={handleBeforeGetContent}
        onAfterPrint={handleAfterPrint}
      />
      <VisuallyHidden>
        <Styled.GlobalStyle />
        <Styled.Document ref={bodyRef}>
          {items.map((rows, pageIndex) => (
            <Styled.Page key={pageIndex} {...tag.pageStyle}>
              <tbody>
                {rows.map((columns, rowIndex) => (
                  <Styled.Row key={`${pageIndex}-${rowIndex}`} rowsGap={tag.pageStyle?.rowsGap}>
                    {columns.map((document, columnIndex) => (
                      <Styled.Column key={`${pageIndex}-${rowIndex}-${columnIndex}`} columnsGap={tag.pageStyle?.columnsGap}>
                        <Renderer document={document} elements={tag.elements} processor={processors[tag.type]} />
                      </Styled.Column>
                    ))}
                  </Styled.Row>
                ))}
              </tbody>
            </Styled.Page>
          ))}
        </Styled.Document>
      </VisuallyHidden>
    </>
  );
};

export default Printer;
