import React, { useEffect, useRef, memo } from "react";
import _ from "lodash";
import LottieWeb from "lottie-web";
import { colorify, getColors } from "lottie-colorify";

export const Lottie = memo(
  ({ colors = [], replaceColors = [], speed = 1, loop = true, autoplay = true, animationData, showColors = false, style }) => {
    const containerRef = useRef();

    useEffect(() => {
      const uniqueColors = {};
      const animationColors = getColors(animationData);
      for (const animationColor of animationColors) uniqueColors[JSON.stringify(animationColor)] = animationColor;
      const colors = animationColors.map((animationColor) => {
        for (const { from, to } of replaceColors) if (_.isEqual(animationColor, from)) return to;
        return animationColor;
      });
      if (showColors) console.info("uniqueColors", Object.values(uniqueColors));
      const animation = LottieWeb.loadAnimation({
        container: containerRef.current,
        loop,
        autoplay,
        animationData: colorify(colors, animationData),
      });
      animation.setSpeed(speed);
      return () => animation.destroy();
    }, [colors, replaceColors, animationData, showColors, loop, autoplay, containerRef.current]);

    return <div ref={containerRef} style={style} />;
  }
);
