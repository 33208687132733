import { memo, useMemo } from "react";
import { Badge } from "@chakra-ui/react";
import { translator } from "lib";

export const Status = memo(({ status }) => {
  const colorScheme = useMemo(() => {
    switch (status) {
      case "pending":
        return "yellow";
      default:
        return "gray";
    }
  }, [status]);
  return <Badge colorScheme={colorScheme}>{translator(status)}</Badge>;
});

export const Type = memo(({ type }) => {
  const colorScheme = useMemo(() => {
    switch (type) {
      case "entry":
        return "main";
      case "exit":
        return "gray";
      case "recount":
        return "purple";
    }
  }, [type]);
  return <Badge colorScheme={colorScheme}>{translator(type)}</Badge>;
});
