import React, { useCallback, useContext, useEffect, useState, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { api } from "lib";
import { messages } from "consts";
import { useApiGet, useCustomToast } from "hooks";
import { MdClose, MdCheck } from "react-icons/md";
import PacksDetailsContext from "../context";
import SettlementContext from "./context";
import Summary from "./summary";
import Remaining from "./remaining";
import Debit from "./debit";

const Settlements = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const { formData: currentPack, dealerCommission, refreshData } = useContext(PacksDetailsContext);
  const [wallets, isLoadingWallets, refreshWallets] = useApiGet(useMemo(() => ({ path: "/wallets", params: { perPage: -1 } }), []));
  const [_paymentCategories, isLoadingPaymentCategories, refreshPaymentCategories] = useApiGet(
    useMemo(() => ({ path: "/payment-categories", params: { perPage: -1 } }), [])
  );
  const paymentCategories = useMemo(() => {
    const getObjects = (type) => _.filter(_paymentCategories?.data, (o) => o.type === type);
    return { revenue: getObjects("revenue"), expense: getObjects("expense") };
  }, [_paymentCategories]);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenSubmitConfirmDialog, onOpen: onOpenSubmitConfirmDialog, onClose: onCloseSubmitConfirmDialog } = useDisclosure();
  const remainingRef = useRef();
  const debitRef = useRef();
  const commissionRef = useRef();
  const toast = useCustomToast();

  const handleSaveData = useCallback(async () => {
    try {
      onCloseSubmitConfirmDialog();
      setIsLoadingSaveData(true);
      const data = {};
      data.pack = {
        dealerCommissionPercentage: dealerCommission.percentage,
        dealerCommissionAmount: dealerCommission.amount,
        status: "closed",
      };
      data.remaining = remainingRef.current.save();
      data.debit = debitRef.current.save();
      data.dealerCommission = {
        type: "dealer",
        status: "paid",
        percentage: dealerCommission.percentage,
        amount: dealerCommission.amount,
        user: currentPack.dealer._id,
        pack: currentPack._id,
        commissionRule: currentPack.dealer.commissionRule._id,
        company: currentPack.company,
      };
      await api.post(`/packs/${_id}/settlement`, data);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      onClose();
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, [_id, dealerCommission, currentPack._id, refreshData, onCloseSubmitConfirmDialog, onClose]);

  const handleSubmit = useCallback(async () => {
    await Promise.all([remainingRef.current.submit(), debitRef.current.submit()]);
    onOpenSubmitConfirmDialog();
  }, [remainingRef.current, debitRef.current, commissionRef.current, onOpenSubmitConfirmDialog]);

  return (
    <SettlementContext.Provider
      value={{
        wallets,
        isLoadingWallets,
        refreshWallets,
        paymentCategories,
        isLoadingPaymentCategories,
        refreshPaymentCategories,
        receivedAmount,
        setReceivedAmount,
      }}
    >
      <Modal size="6xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Text>Acerto do pacote</Text>
            <IconButton variant="ghost" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody py="20px">
            <VStack spacing="10px" alignItems="stretch">
              <Summary />
              <Remaining ref={remainingRef} />
              <Debit ref={debitRef} />
            </VStack>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack}>
            <Button size="sm" onClick={onClose}>
              fechar
            </Button>
            <Button size="sm" colorScheme="green" rightIcon={<Icon as={MdCheck} />} isLoading={isLoadingSaveData} onClick={handleSubmit}>
              finalizar acerto
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={isOpenSubmitConfirmDialog} onClose={onCloseSubmitConfirmDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Atenção
            </AlertDialogHeader>
            <AlertDialogBody>{messages.warning.closeDataConfirm}</AlertDialogBody>
            <AlertDialogFooter as={HStack}>
              <Button onClick={onCloseSubmitConfirmDialog}>cancelar</Button>
              <Button colorScheme="yellow" onClick={handleSaveData}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </SettlementContext.Provider>
  );
};

export default Settlements;
