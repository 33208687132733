import React, { useState, useEffect, useMemo, useCallback, createContext, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import * as yup from "yup";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { messages } from "consts";
import { Breadcrumb, Portal, Tags } from "components";
import { MdChevronLeft, MdMoreHoriz, MdOutlineLocalPrintshop } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { Content } from "pages/Private/Container";
import PackMovementsDetailsContext from "./context";
import General from "./general";
import Comments from "./comments";
import PrintDetailing from "./printDetailing";

export const PackMovementsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar movimentação de pacote" : "Nova movimentação de pacote");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/pack-movements/${_id}` }), [_id]));
  const [source, setSource] = useState({});
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const isClosing = useMemo(() => formData.status === "closed", [formData.status]);
  const { isOpen: isOpenPrintDetailing, onOpen: onOpenPrintDetailing, onClose: onClosePrintDetailing } = useDisclosure();
  const { isOpen: isOpenSubmitConfirmDialog, onOpen: onOpenSubmitConfirmDialog, onClose: onCloseSubmitConfirmDialog } = useDisclosure();
  const toast = useCustomToast();
  const tagsSelectorRef = useRef();

  useEffect(() => {
    const priceType = data?.priceType || location.state?.destination?.priceType || location.state?.source?.priceType;
    const formData = (() => {
      if (data) {
        const sourceMerchandises = {};
        _.forEach(data.source?.merchandises, (o) => (sourceMerchandises[o.product] = o));
        return {
          ...data,
          merchandises: _.map(data.merchandises, (o) => ({ ...o, max: sourceMerchandises[o.product._id]?.quantity || 0 })),
        };
      }
      return {
        ...location.state,
        priceType,
        merchandises: _.map(location.state?.source?.merchandises, ({ quantity: max, ...rest }) => ({ ...rest, quantity: 0, max })),
      };
    })();
    setFormData(formData);
  }, [location.state, data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.put(`/pack-movements/${_id}`, data) : await api.post("/pack-movements", data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        if (isClosing) navigate(-1);
        else {
          navigate(`/pack-movements/edit/${saved._id}`, { state: location.state, replace: true });
          refreshData();
        }
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, location.state, isClosing, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      onCloseSubmitConfirmDialog();
      const schema = yup.object().shape({
        status: yup.string().required(messages.error.required),
        type: yup.string().required(messages.error.required),
        priceType: yup.string().required(messages.error.required),
        source: yup.string().when("type", (type) => {
          if (["unload", "transfer"].indexOf(type) !== -1) return yup.string().required(messages.error.required);
        }),
        destination: yup.string().when("type", (type) => {
          if (["load", "transfer"].indexOf(type) !== -1) return yup.string().required(messages.error.required);
        }),
      });
      const data = {
        ...formData,
        merchandises: _.map(formData.merchandises, ({ product, ...rest }) => ({ ...rest, product: product._id })),
        source: formData.source?._id,
        destination: formData.destination?._id,
      };
      if (data.status === "closed") data.merchandises = _.filter(data.merchandises, (o) => o.quantity > 0);
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData, onCloseSubmitConfirmDialog]);

  const handleSubmitConfirm = useCallback(() => {
    if (isClosing) return onOpenSubmitConfirmDialog();
    return handleSubmit();
  }, [isClosing, handleSubmit, onOpenSubmitConfirmDialog]);

  const handleOpenTags = useCallback(() => {
    const documents = _.map(formData.merchandises, ({ product, quantity }) => ({ document: product, quantity }));
    tagsSelectorRef.current.open("product", documents, false);
  }, [formData]);

  return (
    <PackMovementsDetailsContext.Provider value={{ data, formData, formErrors, setFormData, isClosing }}>
      <Content>
        <HStack justify="space-between">
          <HStack spacing="10px">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "consignação" },
                { to: "/pack-movements", label: "movimentações de pacote" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <SlideFade in={true} offsetY="-20px">
            <Box>
              <Menu>
                <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />} isDisabled={!_id}>
                  mais ações
                </MenuButton>
                <Portal>
                  <MenuList fontSize="sm">
                    <MenuItem onClick={onOpenPrintDetailing} isDisabled={!_id}>
                      <HStack>
                        <Icon as={TbReportSearch} />
                        <Text>detalhamento da movimentação</Text>
                      </HStack>
                    </MenuItem>
                    <MenuItem onClick={handleOpenTags} isDisabled={!_id}>
                      <HStack>
                        <Icon as={MdOutlineLocalPrintshop} />
                        <Text>imprimir etiquetas</Text>
                      </HStack>
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </Box>
          </SlideFade>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Movimentação de pacote</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
        </HStack>

        <Tabs colorScheme="main">
          <TabList>
            <Tab>dados gerais</Tab>
            <Tab>observações</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Comments />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <Button
            size="sm"
            colorScheme="main"
            isLoading={isLoadingData || isLoadingSaveData}
            isDisabled={formData.isClosed}
            onClick={handleSubmitConfirm}
          >
            salvar
          </Button>
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            cancelar
          </Button>
        </HStack>
      </SlideFade>

      <AlertDialog isOpen={isOpenSubmitConfirmDialog} onClose={onCloseSubmitConfirmDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Atenção
            </AlertDialogHeader>
            <AlertDialogBody>{messages.warning.closeDataConfirm}</AlertDialogBody>
            <AlertDialogFooter as={HStack}>
              <Button onClick={onCloseSubmitConfirmDialog}>cancelar</Button>
              <Button colorScheme="yellow" onClick={handleSubmit}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Tags.Selector ref={tagsSelectorRef} />
      <PrintDetailing isOpen={isOpenPrintDetailing} onClose={onClosePrintDetailing} />
    </PackMovementsDetailsContext.Provider>
  );
};
