import { currency } from "lib";

const stringToNumber = (value) => value.replaceAll(".", "").replaceAll(",", ".");
const toInt = (value = "0") => parseInt(stringToNumber(value));
const toFloat = (value = "0") => parseFloat(stringToNumber(value));
const toBoolean = (value = "SIM") => value.toUpperCase() === "SIM";

const processor = {
  NOME: {
    accessor: "name",
    example: "Colar longo de miçangas com pérola",
  },
  SKU: {
    accessor: "sku",
    example: "CL0001",
  },
  CFOR: {
    accessor: "supplierCode",
    example: "F0001",
  },
  ID_FORNECEDOR: {
    accessor: "supplier",
    example: "63ce79e7600a89757bdb71fd",
  },
  PRECO_CUSTO: {
    accessor: "costPrice",
    example: "69,9",
    formatter: toFloat,
    render: (value) => currency.format(value),
  },
  PRECO_ATACADO: {
    accessor: "wholesalePrice",
    example: "89,9",
    formatter: toFloat,
    render: (value) => currency.format(value),
  },
  PRECO_VAREJO: {
    accessor: "retailPrice",
    example: "99,9",
    formatter: toFloat,
    render: (value) => currency.format(value),
  },
  ICMS: {
    accessor: "icms",
    example: "",
  },
  NCM: {
    accessor: "ncm",
    example: "",
  },
  GTIN: {
    accessor: "gtin",
    example: "",
  },
  CEST: {
    accessor: "cest",
    example: "",
  },
  ESTOQUE_GERENCIADO: {
    accessor: "isManagedStock",
    example: "SIM",
    formatter: toBoolean,
    render: (o) => (o ? "SIM" : "NÃO"),
  },
  ESTOQUE_MINIMO: {
    accessor: "minStock",
    example: "10",
    formatter: toInt,
    render: (o) => o.toLocaleString(),
  },
  ESTOQUE_MAXIMO: {
    accessor: "maxStock",
    example: "100",
    formatter: toInt,
    render: (o) => o.toLocaleString(),
  },
  LOCALIZACAO: {
    accessor: "location",
    example: "Corredor A",
  },
  PUBLICO: {
    accessor: "isPublic",
    example: "SIM",
    formatter: toBoolean,
    render: (o) => (o ? "SIM" : "NÃO"),
  },
  ATIVO: {
    accessor: "isActive",
    example: "SIM",
    formatter: toBoolean,
    render: (o) => (o ? "SIM" : "NÃO"),
  },
};

export default processor;
