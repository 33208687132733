import React, { useMemo, useState, useCallback, forwardRef, useImperativeHandle, useContext } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import _ from "lodash";
import * as yup from "yup";
import { api, currency, translator } from "lib";
import { messages } from "consts";
import { useCustomToast } from "hooks";
import { InputCurrency, BoxData } from "components";
import { WalletsListContext } from "./index";

const Movement = forwardRef((props, ref) => {
  const { wallets, currentWallet, handleRefreshData } = useContext(WalletsListContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenSubmitConfirmDialog, onOpen: onOpenSubmitConfirmDialog, onClose: onCloseSubmitConfirmDialog } = useDisclosure();
  const isTransfer = useMemo(() => formData.type === "transfer", [formData.type]);
  const toast = useCustomToast();

  const handleClose = useCallback(() => {
    setFormData({});
    setFormErrors({});
  }, []);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.post(`/wallets/${currentWallet._id}/movements`, data);
        toast({ status: "success", description: messages.success.saveData, isClosable: true });
        handleClose();
        handleRefreshData();
      } catch (error) {
        toast({ status: "error", position: "bottom-right", title: "Oops!", description: error.message, isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [toast, handleClose, handleRefreshData, currentWallet._id]
  );

  const handleSubmit = useCallback(async () => {
    try {
      onCloseSubmitConfirmDialog();
      const schema = yup.object().shape({
        type: yup.string().required(messages.error.required),
        source: yup.string().required(messages.error.required),
        destination: yup.string().when("type", {
          is: "transfer",
          then: yup.string().required(messages.error.required),
        }),
        amount: yup.number().min(0.01, `${messages.error.greaterOrEqual} R$0,01.`).required(messages.error.required),
      });
      const data = { ...formData, source: currentWallet._id };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [handleSaveData, onCloseSubmitConfirmDialog, currentWallet._id, formData]);

  useImperativeHandle(ref, () => ({ open: (type) => setFormData((state) => ({ ...state, type })) }), []);

  return (
    <>
      <Drawer isOpen={formData.type} placement="bottom" onClose={handleClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader as={HStack} justifyContent="space-between">
            <Box>
              <Text lineHeight="1" mb="0">
                Nova movimentação
              </Text>
              <HStack divider={<Text px="5px">•</Text>}>
                <Text fontSize="sm" fontWeight="normal">
                  Saldo atual
                </Text>
                <Text fontSize="sm">{currency.format(currentWallet.balance || 0)}</Text>
              </HStack>
            </Box>
            <IconButton variant="outline" icon={<Icon as={MdClose} />} onClick={handleClose} />
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={isTransfer ? 3 : 4}>
                <FormControl isRequired={true} isInvalid={formErrors.type}>
                  <BoxData label="Tipo" value={translator(formData.type)} />
                  <FormErrorMessage>{formErrors.type}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={isTransfer ? 3 : 4}>
                <FormControl isRequired={true} isInvalid={formErrors.source}>
                  <BoxData label="Carteira" value={currentWallet.title} />
                  <FormErrorMessage>{formErrors.source}</FormErrorMessage>
                </FormControl>
              </GridItem>
              {isTransfer && (
                <GridItem colSpan={3}>
                  <FormControl isRequired={true} isInvalid={formErrors.destination}>
                    <FormLabel fontSize="sm">Carteira de destino</FormLabel>
                    <Select
                      value={formData.destination ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, destination: target.value }))}
                    >
                      <option value="">--Selecione</option>
                      {_.map(wallets?.data, (wallet) => {
                        return (
                          wallet._id !== currentWallet._id && (
                            <option key={wallet._id} value={wallet._id}>
                              {wallet.title}
                            </option>
                          )
                        );
                      })}
                    </Select>
                    <FormErrorMessage>{formErrors.destination}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              )}
              <GridItem colSpan={isTransfer ? 3 : 4}>
                <FormControl isRequired={true} isInvalid={formErrors.amount}>
                  <FormLabel fontSize="sm">Valor</FormLabel>
                  <InputGroup>
                    <InputLeftAddon>R$</InputLeftAddon>
                    <Input
                      as={InputCurrency}
                      value={formData.amount ?? ""}
                      onChange={(amount) => setFormData((state) => ({ ...state, amount }))}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formErrors.amount}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.comments}>
                  <FormLabel fontSize="sm">Observações</FormLabel>
                  <Textarea
                    value={formData.comments ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </DrawerBody>
          <Divider />
          <DrawerFooter as={HStack}>
            <Button onClick={handleClose}>cancelar</Button>
            <Button colorScheme="main" isLoading={isLoadingSaveData} onClick={onOpenSubmitConfirmDialog}>
              salvar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <AlertDialog isOpen={isOpenSubmitConfirmDialog} onClose={onCloseSubmitConfirmDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Atenção
            </AlertDialogHeader>
            <AlertDialogBody>Deseja realmente salvar a nova movimentação nesta carteira?</AlertDialogBody>
            <AlertDialogFooter as={HStack}>
              <Button onClick={onCloseSubmitConfirmDialog}>cancelar</Button>
              <Button colorScheme="yellow" onClick={handleSubmit}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
});

export default Movement;
