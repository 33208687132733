import { Spinner, useColorModeValue, useTheme } from "@chakra-ui/react";
import { ReactAsyncSelect, ReactSelect } from "./styled";
import _ from "lodash";

const GetColors = () => {
  const theme = useTheme();
  const backgroundColor = _.get(theme.colors, useColorModeValue("white", "gray.800"));
  const borderColor = _.get(theme.colors, useColorModeValue("blackAlpha.200", "whiteAlpha.300"));
  const color = _.get(theme.colors, useColorModeValue("black", "white"));
  const disabledColor = _.get(theme.colors, useColorModeValue("blackAlpha.600", "whiteAlpha.600"));
  const primary = _.get(theme.colors, useColorModeValue("main.500", "main.400"));
  const red = _.get(theme.colors, useColorModeValue("red.500", "red.400"));
  return { backgroundColor, borderColor, color, disabledColor, primary, red };
};

export const AsyncSelect = (props) => {
  const colors = GetColors();
  return (
    <ReactAsyncSelect
      classNamePrefix="react-select"
      loadingMessage={() => <Spinner size="xs" />}
      noOptionsMessage={() => `Nenhum registro encontrado`}
      {...colors}
      {...props}
    />
  );
};

export const SyncSelect = (props) => {
  const colors = GetColors();
  return (
    <ReactSelect
      classNamePrefix="react-select"
      loadingMessage={() => <Spinner size="xs" />}
      noOptionsMessage={() => `Nenhum registro encontrado`}
      {...colors}
      {...props}
    />
  );
};
