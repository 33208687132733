import React from "react";
import { HStack, IconButton, Text, Icon } from "@chakra-ui/react";
import { FaEraser } from "react-icons/fa";

export const Label = ({ children, onClean }) => {
  return (
    <HStack justify="space-between" mb="2px">
      <Text fontSize="xs" fontWeight="bold">
        {children}
      </Text>
      {onClean && <IconButton size="xs" icon={<Icon as={FaEraser} />} onClick={onClean} />}
    </HStack>
  );
};
