import React, { useMemo, useContext, useEffect, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdAdd, MdMoneyOff, MdRefresh } from "react-icons/md";
import { currency, percent } from "lib";
import { DatePicker, BoxData, InputCurrency } from "components";
import { RiPercentFill } from "react-icons/ri";
import { BsQuestionCircle } from "react-icons/bs";
import { PackSettlementsDetailsContext } from "../index";
import { TbExternalLink } from "react-icons/tb";

const Debit = (props, ref) => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const {
    formData,
    setFormData,
    formErrors,
    wallets,
    isLoadingWallets,
    refreshWallets,
    paymentCategories,
    isLoadingPaymentCategories,
    refreshPaymentCategories,
  } = useContext(PackSettlementsDetailsContext);
  const isPaid = useMemo(() => formData.agentDebit?.status === "paid", [formData.agentDebit?.status]);

  const handleChangeFormData = useCallback(
    (obj) => {
      setFormData((state) => ({ ...state, agentDebit: { ...state.agentDebit, ...obj } }));
    },
    [setFormData]
  );

  useEffect(() => {
    if (!_id) {
      const totalAgentDebitAmount = formData.packsAgentDebitAmount;
      setFormData((state) => ({
        ...state,
        totalAgentDebitPercentage: 1,
        defaultTotalAgentDebitAmount: totalAgentDebitAmount,
        totalAgentDebitAmount,
      }));
    }
  }, [_id, setFormData, formData.packsAgentDebitAmount]);

  useEffect(() => {
    if (!_id) {
      setFormData((state) => {
        const totalAgentDebitAmount = formData.totalAgentDebitPercentage * state.defaultTotalAgentDebitAmount;
        return { ...state, totalAgentDebitAmount };
      });
    }
  }, [_id, setFormData, formData.totalAgentDebitPercentage]);

  return (
    <>
      <HStack justifyContent="space-between" mb={6}>
        <HStack>
          <Center bg="green.100" w="25px" h="25px" borderRadius="lg">
            <Icon as={MdAdd} color="green.500" />
          </Center>
          <Heading size="sm">Débito do representante</Heading>
        </HStack>
        <Button
          mt="5px"
          size="sm"
          variant="outline"
          rightIcon={<Icon as={TbExternalLink} />}
          onClick={() => navigate(`/payments/edit/${formData.debitPayment}`)}
          isDisabled={!_id || !formData.debitPayment}
        >
          visitar débito gerado
        </Button>
      </HStack>

      <Box position="relative">
        {!_id && (
          <>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={3}>
                <FormControl isRequired={true} isInvalid={formErrors["agentDebit.status"]}>
                  <FormLabel fontSize="sm">Status</FormLabel>
                  <Select
                    value={formData.agentDebit?.status ?? ""}
                    onChange={({ target }) => handleChangeFormData({ status: target.value })}
                  >
                    <option value="">--Selecione</option>
                    <option value="paid">Pago</option>
                    <option value="pending">Pendente</option>
                  </Select>
                  <FormErrorMessage>{formErrors["agentDebit.status"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl isRequired={true} isInvalid={formErrors["agentDebit.paymentMethod"]}>
                  <FormLabel fontSize="sm">Método de pagamento</FormLabel>
                  <Select
                    value={formData.agentDebit?.paymentMethod ?? ""}
                    onChange={({ target }) => handleChangeFormData({ paymentMethod: target.value })}
                  >
                    <option value="">--Selecione</option>
                    <option value="pix">Pix</option>
                    <option value="credit_card">Cartão de crédito</option>
                    <option value="debit_card">Cartão de débito</option>
                    <option value="boleto">Boleto</option>
                    <option value="deposit">Depósito bancário</option>
                    <option value="cash">Dinheiro</option>
                  </Select>
                  <FormErrorMessage>{formErrors["agentDebit.paymentMethod"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl isRequired={true} isInvalid={formErrors["agentDebit.wallet"]}>
                  <FormLabel fontSize="sm">Carteira</FormLabel>
                  <HStack>
                    <Select
                      value={formData.agentDebit?.wallet ?? ""}
                      onChange={({ target }) => handleChangeFormData({ wallet: target.value })}
                    >
                      <option value="">--Selecione</option>
                      {_.map(wallets?.data, (o) => (
                        <option key={o._id} value={o._id}>
                          {o.title}
                        </option>
                      ))}
                    </Select>
                    <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingWallets} onClick={refreshWallets} />
                  </HStack>
                  <FormErrorMessage>{formErrors["agentDebit.wallet"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl isRequired={true} isInvalid={formErrors["agentDebit.paymentCategory"]}>
                  <FormLabel fontSize="sm">Categoria de pagamento</FormLabel>
                  <HStack>
                    <Select
                      value={formData.agentDebit?.paymentCategory ?? ""}
                      onChange={({ target }) => handleChangeFormData({ paymentCategory: target.value })}
                    >
                      <option value="">--Selecione</option>
                      {_.map(paymentCategories.revenue, (o) => (
                        <option key={o._id} value={o._id}>
                          {o.title}
                        </option>
                      ))}
                    </Select>
                    <IconButton
                      variant="outline"
                      icon={<Icon as={MdRefresh} />}
                      isLoading={isLoadingPaymentCategories}
                      onClick={refreshPaymentCategories}
                    />
                  </HStack>
                  <FormErrorMessage>{formErrors["agentDebit.paymentCategory"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>

            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={4}>
                <FormControl isRequired={true} isInvalid={formErrors["agentDebit.issueDate"]}>
                  <FormLabel fontSize="sm">Data de emissão</FormLabel>
                  <DatePicker
                    selected={formData.agentDebit?.issueDate}
                    onChange={(issueDate) => handleChangeFormData({ issueDate: moment(issueDate).startOf("day").toDate() })}
                    dateFormat="d, MMMM yyyy"
                  />
                  <FormErrorMessage>{formErrors["agentDebit.issueDate"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl isRequired={true} isInvalid={formErrors["agentDebit.dueDate"]}>
                  <FormLabel fontSize="sm">Data de vencimento</FormLabel>
                  <DatePicker
                    selected={formData.agentDebit?.dueDate}
                    onChange={(dueDate) => handleChangeFormData({ dueDate: moment(dueDate).startOf("day").toDate() })}
                    dateFormat="d, MMMM yyyy"
                  />
                  <FormErrorMessage>{formErrors["agentDebit.dueDate"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl isRequired={isPaid} isInvalid={formErrors["agentDebit.paymentDate"]}>
                  <BoxData
                    label="Data de pagamento"
                    value={isPaid ? moment(formData.agentDebit?.paymentDate).format("D, MMMM yyyy") : "-"}
                  />
                  <FormErrorMessage>{formErrors["agentDebit.paymentDate"]}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </>
        )}

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.defaultTotalAgentDebitAmount}>
              <BoxData
                label="Valor sugerido do débito"
                value={currency.format(formData.defaultTotalAgentDebitAmount ?? 0)}
                RightComponent={
                  <Box>
                    <Popover placement="top">
                      <PopoverTrigger>
                        <IconButton icon={<Icon as={BsQuestionCircle} />} isRound variant="outline" />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverHeader>Débito do representante</PopoverHeader>
                        <PopoverBody fontSize="xs">
                          O débito do representante é calculado a partir da soma de todos os pacotes pendentes selecionados. Caso deseje
                          realizar o acerto de um pacote pendente sem que a soma seja adicionada ao débito do representante, você deve
                          esvaziar o pacote através de uma movimentação de descarga.
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Box>
                }
              />
              <FormErrorMessage>{formErrors.defaultTotalAgentDebitAmount}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={4}>
            {_id ? (
              <FormControl isRequired={true} isInvalid={formErrors.totalAgentDebitPercentage}>
                <BoxData label="% aplicada do débito" value={percent.format(formData.totalAgentDebitPercentage || 0)} />
                <FormErrorMessage>{formErrors.totalAgentDebitPercentage}</FormErrorMessage>
              </FormControl>
            ) : (
              <FormControl isRequired={true} isInvalid={formErrors.totalAgentDebitPercentage}>
                <FormLabel fontSize="sm">% aplicada do débito</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={(formData.totalAgentDebitPercentage ?? 0) * 100}
                    onChange={(value) => setFormData((state) => ({ ...state, totalAgentDebitPercentage: value / 100 }))}
                    isDisabled={!isPaid}
                  />
                  <InputRightElement>
                    <IconButton
                      size="sm"
                      variant={formData.totalAgentDebitPercentage === 1 ? "outline" : "solid"}
                      colorScheme={formData.totalAgentDebitPercentage === 1 ? "gray" : "main"}
                      icon={<Icon as={RiPercentFill} />}
                      onClick={() => setFormData((state) => ({ ...state, totalAgentDebitPercentage: 1 }))}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formErrors.totalAgentDebitPercentage}</FormErrorMessage>
              </FormControl>
            )}
          </GridItem>
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.totalAgentDebitAmount}>
              <BoxData label="Valor final do débito" value={currency.format(formData.totalAgentDebitAmount ?? 0)} />
              <FormErrorMessage>{formErrors.totalAgentDebitAmount}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        {formData.defaultTotalAgentDebitAmount === 0 && (
          <Center position="absolute" left="0" top="0" w="100%" h="100%">
            <Box position="absolute" left="0" top="0" w="100%" h="100%" _light={{ bg: "white" }} _dark={{ bg: "gray.800" }} opacity="0.9" />
            <VStack spacing="0" zIndex="1">
              <Icon as={MdMoneyOff} boxSize="60px" mb="10px" />
              <Heading size="md" textAlign="center">
                Nenhum valor aplicável
              </Heading>
              <Text textAlign="center">Não existem valores aplicáveis para a geração de débito do representante.</Text>
            </VStack>
          </Center>
        )}
      </Box>
    </>
  );
};

export default Debit;
