import { memo } from "react";
import ReactDOM from "react-dom";
import { HStack, Icon, IconButton, Text, useColorModeValue } from "@chakra-ui/react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdDragHandle } from "react-icons/md";
import { FaSortAlphaDown, FaSortAlphaUpAlt } from "react-icons/fa";

const portal = document.createElement("div");
document.body.appendChild(portal);

const ListItem = memo(({ provided, snapshot, sorting, accessor, title, isSortable, isVisible, onChangeSort, onChangeVisibility }) => {
  const backgroundColor = useColorModeValue("white", "gray.700");
  const hoverBackgroundColor = useColorModeValue("gray.100", "gray.800");
  const activeBackgroundColor = useColorModeValue("gray.200", "gray.900");

  const child = (
    <HStack
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      p="10px"
      borderRadius="md"
      borderWidth="1px"
      background={snapshot.isDragging ? activeBackgroundColor : backgroundColor}
      _hover={{ backgroundColor: hoverBackgroundColor }}
      _active={{ backgroundColor: activeBackgroundColor }}
    >
      <Icon as={MdDragHandle} />
      <Text flex="1" fontSize="sm">
        {title}
      </Text>
      {isSortable !== false && (
        <IconButton
          size="xs"
          variant={sorting ? "solid" : "outline"}
          colorScheme={sorting ? "main" : "gray"}
          icon={<Icon as={sorting === 1 ? FaSortAlphaDown : FaSortAlphaUpAlt} />}
          onClick={() => onChangeSort(accessor)}
        />
      )}
      <IconButton
        size="xs"
        variant={isVisible ? "solid" : "outline"}
        colorScheme={isVisible ? "main" : "gray"}
        icon={<Icon as={isVisible ? FiEye : FiEyeOff} />}
        onClick={() => onChangeVisibility(accessor)}
      />
    </HStack>
  );

  if (!snapshot.isDragging) return child;
  return ReactDOM.createPortal(child, portal);
});

export default ListItem;
