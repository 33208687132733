import React, { createContext, useEffect, useState } from "react";
import { Center } from "@chakra-ui/react";
import { LogoAnimated } from "components";

export const SplashContext = createContext();

const SplashProvider = ({ children }) => {
  const [isAppReady, setIsAppReady] = useState(false);
  const [isEntered, setIsEntered] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsEntered(true), 4000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <SplashContext.Provider value={{ isAppReady, setIsAppReady }}>
      {!isAppReady && (
        <Center
          flexDirection="column"
          position="fixed"
          zIndex="99999"
          top="0"
          left="0"
          w="100vw"
          h="100vh"
          _light={{ bg: "white" }}
          _dark={{ bg: "gray.800" }}
          bg="red"
        >
          <LogoAnimated width={100} />
        </Center>
      )}
      {isEntered && children}
    </SplashContext.Provider>
  );
};

export default SplashProvider;
