export const icms = [
  {
    value: "0",
    label: "0 - Nacional, exceto as indicadas nos códigos 3 a 5",
  },
  {
    value: "1",
    label: "1 - Estrangeira - Importação direta, exceto a indicada no código 6",
  },
  {
    value: "2",
    label: "2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7",
  },
  {
    value: "3",
    label: "3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%",
  },
  {
    value: "4",
    label:
      "4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei nº 288/1967 , e as Leis nºs 8.248/1991, 8.387/1991, 10.176/2001 e 11.484/2007",
  },
  {
    value: "5",
    label: "5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%",
  },
  {
    value: "6",
    label: "6 - Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução Camex e gás natural",
  },
  {
    value: "7",
    label: "7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução Camex e gás natural.",
  },
];
