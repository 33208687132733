import React, { useCallback, useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { useArrayItemHandlers } from "hooks";
import { InputCurrency } from "components";
import { PaymentsDetailsContext } from "./index";
import { currency } from "lib";

const toNegative = ({ amount, type }) => {
  const index = type === "discount" ? -1 : 1;
  return Math.abs(amount) * index;
};

const AddItem = ({ isOpen, onClose }) => {
  const { formData: paymentFormData, setFormData: setPaymentFormData } = useContext(PaymentsDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { handleAddArrayItem } = useArrayItemHandlers(setPaymentFormData);

  useEffect(() => {
    setFormData({
      type: "charge",
      description: "",
      percentage: 0,
      amount: 0,
    });
    setFormErrors({});
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          amount: yup.number().min(0.01, messages.error.required).required(messages.error.required),
        });
        await schema.validate(formData, { abortEarly: false });
        handleAddArrayItem("items", { ...formData, amount: toNegative(formData), createdAt: new Date() });
        onClose();
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [formData, handleAddArrayItem, onClose]
  );

  const handleChangePercentage = useCallback(
    (percentage) => {
      const amount = (percentage / 100) * paymentFormData.receivableAmount;
      setFormData((state) => ({ ...state, percentage, amount }));
    },
    [paymentFormData.receivableAmount]
  );

  const handleChangeAmount = useCallback(
    (amount) => {
      const percentage = (amount / paymentFormData.receivableAmount) * 100;
      setFormData((state) => ({ ...state, percentage, amount }));
    },
    [paymentFormData.receivableAmount]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Box>
              <Text>Adicionar item</Text>
              <Text fontSize="xs" fontWeight="normal">
                Valor atual {currency.format(paymentFormData.receivableAmount || 0)}
              </Text>
            </Box>
            <IconButton variant="ghost" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody>
            <Grid mb={4}>
              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.type}>
                  <FormLabel fontSize="sm">Tipo</FormLabel>
                  <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
                    <option value="">--Selecione</option>
                    <option value="charge">Cobrança</option>
                    <option value="discount">Desconto</option>
                    <option value="taxes">Taxas</option>
                  </Select>
                  <FormErrorMessage>{formErrors.type}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid mb={4}>
              <GridItem>
                <FormControl isInvalid={formErrors.description}>
                  <FormLabel fontSize="sm">Descrição</FormLabel>
                  <Textarea
                    value={formData.description ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.description}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
              {paymentFormData.receivableAmount > 0 && (
                <GridItem>
                  <FormControl isRequired={true} isInvalid={formErrors.percentage}>
                    <FormLabel fontSize="sm">Porcentagem</FormLabel>
                    <InputGroup>
                      <Input as={InputCurrency} value={formData.percentage ?? ""} onChange={handleChangePercentage} />
                      <InputRightAddon>%</InputRightAddon>
                    </InputGroup>
                    <FormErrorMessage>{formErrors.percentage}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              )}
              <GridItem colSpan={paymentFormData.receivableAmount ? 1 : 2}>
                <FormControl isRequired={true} isInvalid={formErrors.amount}>
                  <FormLabel fontSize="sm">Valor</FormLabel>
                  <InputGroup>
                    <InputLeftAddon>R$</InputLeftAddon>
                    <Input as={InputCurrency} value={formData.amount ?? ""} onChange={handleChangeAmount} />
                  </InputGroup>
                  <FormErrorMessage>{formErrors.amount}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack}>
            <Button onClick={onClose}>cancelar</Button>
            <Button type="submit" colorScheme="main">
              adicionar
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddItem;
