import React, { useCallback, useContext } from "react";
import { FormControl, FormErrorMessage, FormLabel, Grid, GridItem, HStack, Icon, IconButton, Select, Text } from "@chakra-ui/react";
import moment from "moment";
import { api, currency } from "lib";
import { BoxData, EntryMerchandises, AsyncSelect } from "components";
import { PackOrdersDetailsContext } from "./index";
import { MdClose } from "react-icons/md";
import { FiSmartphone } from "react-icons/fi";

let loadUsersTimeout = { agent: null, dealer: null };

const General = () => {
  const { formData, formErrors, setFormData } = useContext(PackOrdersDetailsContext);

  const handleLoadUsers = useCallback((role, search, cb) => {
    clearTimeout(loadUsersTimeout[role]);
    loadUsersTimeout[role] = setTimeout(async () => {
      const query = { roles: role };
      const response = await api.get("/users", { params: { query, search, perPage: 20 } });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={6}>
          <FormControl isRequired={true} isInvalid={formErrors.status}>
            <FormLabel fontSize="sm">Status</FormLabel>
            <Select value={formData.status} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
              <option>--Selecione</option>
              <option value="waiting">Aguardando</option>
              <option value="transformed">Transformado</option>
            </Select>
            <FormErrorMessage>{formErrors.status}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={6}>
          <FormControl isInvalid={formErrors.priceType}>
            <FormLabel fontSize="sm">Tipo de preço</FormLabel>
            <Select
              value={formData.priceType ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, priceType: target.value }))}
            >
              <option value="costPrice">Custo</option>
              <option value="wholesalePrice">Atacado</option>
              <option value="retailPrice">Varejo</option>
            </Select>
            <FormErrorMessage>{formErrors.priceType}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={6}>
          <FormControl isInvalid={formErrors.agent}>
            <FormLabel fontSize="sm">Representante</FormLabel>
            <AsyncSelect
              isInvalid={formErrors.agent}
              value={formData.agent}
              defaultOptions
              loadOptions={(search, cb) => handleLoadUsers("agent", search, cb)}
              placeholder="Selecione o representante"
              onChange={(agent) => setFormData((state) => ({ ...state, agent }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name }) => name}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.agent}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={6}>
          <FormControl isInvalid={formErrors.dealer}>
            <FormLabel fontSize="sm">Revendedor</FormLabel>
            <AsyncSelect
              isInvalid={formErrors.dealer}
              value={formData.dealer}
              defaultOptions
              loadOptions={(search, cb) => handleLoadUsers("dealer", search, cb)}
              placeholder="Selecione o revendedor"
              onChange={(dealer) => setFormData((state) => ({ ...state, dealer }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name, isDisabled }) => (
                <HStack>
                  <Text flex="1">{name}</Text>
                  {isDisabled && <Icon as={FiSmartphone} />}
                </HStack>
              )}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.dealer}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Mercadorias" value={formData.quantity?.toLocaleString?.() ?? 0} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Valor" value={currency.format(formData.amount ?? 0)} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Linhas" value={formData.merchandises?.length ?? 0} />
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Criação" value={moment(formData?.createdAt).format("D, MMMM yyyy [às] HH:mm")} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Atualização" value={moment(formData?.updatedAt).format("D, MMMM yyyy [às] HH:mm")} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Fechamento" value={formData?.closedAt ? moment(formData?.closedAt).format("D, MMMM yyyy [às] HH:mm") : "-"} />
          </FormControl>
        </GridItem>
      </Grid>

      <EntryMerchandises formData={formData} setFormData={setFormData} />
    </>
  );
};

export default General;
