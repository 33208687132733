import moment from "moment";
import { currency } from "lib";

export const processors = {
  product: {
    ID: (o) => o._id.toString(),
    NID: (o) => o.nid.toString(),
    SKU: (o) => o.sku,
    CFOR: (o) => o.supplierCode,
    NOME: (o) => o.name,
    DESCRICAO: (o) => o.description,
    PRECO_CUSTO: (o) => currency.format(o.costPrice),
    PRECO_ATACADO: (o) => currency.format(o.wholesalePrice),
    PRECO_VAREJO: (o) => currency.format(o.retailPrice),
    DATA: () => moment().format("DD/MM/YYYY"),
    DATA_HORA: () => moment().format("DD/MM/YYYY [às] HH:mm"),
  },
  pack: {
    ID: (o) => o._id.toString(),
    NID: (o) => o.nid.toString(),
    VALOR_CARREGADO: (o) => currency.format(o.loadedAmount ?? 0),
    QUANTIDADE_CARREGADO: (o) => o.loadedQuantity?.toLocaleString() ?? 0,
    VALOR_ATUAL: (o) => currency.format(o.currentAmount ?? 0),
    QUANTIDADE_ATUAL: (o) => o.currentQuantity?.toLocaleString() ?? 0,
    REPRESENTANTE: (o) => o.agent?.name,
    REVENDEDOR: (o) => o.dealer?.name,
    PREVISAO_DE_ACERTO: (o) => moment(o.predictedSettlementDate).format("DD/MM/YYYY"),
    DATA: () => moment().format("DD/MM/YYYY"),
    DATA_HORA: () => moment().format("DD/MM/YYYY [às] HH:mm"),
  },
};
