import React, { useCallback, useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { useArrayItemHandlers } from "hooks";
import { InputCurrency } from "components";
import { PaymentsDetailsContext } from "./index";
import { currency } from "lib";
import PreferencesContext from "./context";

const PackFootnotes = ({ isOpen, onClose }) => {
  const { formData, setFormData } = useContext(PreferencesContext);

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="space-between">
          <Text>Notas de rodapé do pacote</Text>
          <IconButton variant="ghost" icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <Divider />
        <ModalBody>
          <GridItem colSpan={12}>
            <FormControl>
              <Textarea
                h="200px"
                value={formData.packFootnotes ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, packFootnotes: target.value }))}
                autoFocus={true}
              />
            </FormControl>
          </GridItem>
        </ModalBody>
        <Divider />
        <ModalFooter as={HStack}>
          <Button onClick={onClose}>fechar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PackFootnotes;
