import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { BsQuestionCircle } from "react-icons/bs";
import { TbExternalLink } from "react-icons/tb";
import { FiSmartphone } from "react-icons/fi";
import { api, currency, percent } from "lib";
import { BoxData, DatePicker, AsyncSelect } from "components";
import Commission from "./commission";
import Debit from "./debit";
import { PackSettlementsDetailsContext } from "../index";

let loadUsersTimeout = null;

const General = () => {
  const navigate = useNavigate();
  const { formData, setFormData, formErrors } = useContext(PackSettlementsDetailsContext);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const query = { roles: "agent" };
      const response = await api.get("/users", { params: { query, search, perPage: 20, withCommissionRules: true } });
      cb(_.map(response?.data, (o) => ({ ...o, isDisabled: o.ownership === "app" })));
    }, 1000);
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={2}>
          <FormControl isInvalid={formErrors.nid}>
            <BoxData label="NID" value={formData.nid ?? "-"} />
            <FormErrorMessage>{formErrors.nid}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={5}>
          <FormControl isInvalid={formErrors.author}>
            <BoxData label="Autor" value={formData.author?.name ?? "-"} />
            <FormErrorMessage>{formErrors.author}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={5}>
          <FormControl isInvalid={formErrors.agent || formErrors["agent._id"]}>
            <FormLabel fontSize="sm">Representante</FormLabel>
            <AsyncSelect
              isInvalid={formErrors.agent}
              value={formData.agent}
              defaultOptions
              loadOptions={handleLoadUsers}
              placeholder="Selecione o representante"
              onChange={(agent) => setFormData((state) => ({ ...state, agent }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name, isDisabled }) => (
                <HStack>
                  <Text flex="1">{name}</Text>
                  {isDisabled && <Icon as={FiSmartphone} />}
                </HStack>
              )}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.agent || formErrors["agent._id"]}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
        <GridItem colSpan={3}>
          <FormControl isRequired={true} isInvalid={formErrors.startDate}>
            <FormLabel fontSize="sm">Previsão de acerto (inicial)</FormLabel>
            <DatePicker
              selected={formData.startDate}
              onChange={(value) => setFormData((state) => ({ ...state, startDate: moment(value).startOf("day").toDate() }))}
              selectsStart
              startDate={formData.startDate}
              endDate={formData.endDate}
              dateFormat="d, MMMM yyyy"
            />
            <FormErrorMessage>{formErrors.startDate}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={3}>
          <FormControl isRequired={true} isInvalid={formErrors.endDate}>
            <FormLabel fontSize="sm">Previsão de acerto (final)</FormLabel>
            <DatePicker
              selected={formData.endDate}
              onChange={(value) => setFormData((state) => ({ ...state, endDate: moment(value).startOf("day").toDate() }))}
              selectsEnd
              startDate={formData.startDate}
              endDate={formData.endDate}
              minDate={formData.startDate}
              dateFormat="d, MMMM yyyy"
            />
            <FormErrorMessage>{formErrors.endDate}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={3}>
          <FormControl isRequired={true} isInvalid={formErrors.closedAt}>
            <BoxData label="Fechado em" value={formData.closedAt ? moment(formData.closedAt).format("D, MMMM yyyy") : "-"} />
            <FormErrorMessage>{formErrors.closedAt}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={3}>
          <FormControl isRequired={true} isInvalid={formErrors.totalReceivedAmount}>
            <BoxData label="Total recebido" value={currency.format(formData.totalReceivedAmount ?? 0)} />
            <FormErrorMessage>{formErrors.totalReceivedAmount}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={6} />

      <HStack justifyContent="space-between" mb={4}>
        <Heading size="sm">Pacotes</Heading>
        <Button
          size="sm"
          variant="outline"
          rightIcon={<Icon as={TbExternalLink} />}
          mt="5px"
          onClick={() => navigate("/packs", { state: { packSettlement: formData } })}
          isDisabled={!formData._id}
        >
          visitar pacotes
        </Button>
      </HStack>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={6}>
          <FormControl isRequired={true} isInvalid={formErrors.packsSoldAmount}>
            <BoxData
              label="Vendido"
              value={
                <Text>
                  {currency.format(formData.packsSoldAmount ?? 0)} • {formData.packsSoldQuantity ?? 0}
                </Text>
              }
            />
            <FormErrorMessage>{formErrors.packsSoldAmount}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={6}>
          <FormControl isRequired={true} isInvalid={formErrors.packsAvgPerformance}>
            <BoxData
              label="Desempenho médio"
              value={percent.format(formData.packsAvgPerformance ?? 0)}
              RightComponent={
                <Box>
                  <Popover placement="left">
                    <PopoverTrigger>
                      <IconButton icon={<Icon as={BsQuestionCircle} />} isRound variant="outline" />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverHeader>Desempenho médio</PopoverHeader>
                      <PopoverBody fontSize="xs">
                        O desempenho médio é calculado a partir da soma de todos os pacotes acertados selecionados divido pela quantidade de
                        itens selecionados.
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
              }
            />
            <FormErrorMessage>{formErrors.packsAvgPerformance}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
        <GridItem colSpan={3}>
          <FormControl isRequired={true} isInvalid={formErrors.packsReceivedAmount}>
            <BoxData label="Valor recebido" value={currency.format(formData.packsReceivedAmount ?? 0)} />
            <FormErrorMessage>{formErrors.packsReceivedAmount}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={3}>
          <FormControl isRequired={true} isInvalid={formErrors.packsDealerCommissionAmount}>
            <BoxData label="Comissões pagas" value={currency.format(formData.packsDealerCommissionAmount ?? 0)} />
            <FormErrorMessage>{formErrors.packsDealerCommissionAmount}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={3}>
          <FormControl isRequired={true} isInvalid={formErrors.packsRemainingAmount}>
            <BoxData label="Débitos dos revendedores" value={currency.format(formData.packsRemainingAmount ?? 0)} />
            <FormErrorMessage>{formErrors.packsRemainingAmount}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={3}>
          <FormControl isRequired={true} isInvalid={formErrors.packsInsolvency}>
            <BoxData label="Inadimplência" value={percent.format(formData.packsInsolvency ?? 0)} />
            <FormErrorMessage>{formErrors.packsInsolvency}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={6} />

      <HStack justifyContent="space-between" mb={4}>
        <Heading size="sm">Outros pagamentos</Heading>
        <Button
          size="sm"
          variant="outline"
          rightIcon={<Icon as={TbExternalLink} />}
          mt="5px"
          onClick={() => navigate("/payments", { state: { packSettlement: formData } })}
          isDisabled={!formData._id}
        >
          visitar pagamentos
        </Button>
      </HStack>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
        <GridItem colSpan={6}>
          <FormControl isRequired={true} isInvalid={formErrors.paymentsReceivedAmount}>
            <BoxData label="Recebido" value={currency.format(formData.paymentsReceivedAmount ?? 0)} />
            <FormErrorMessage>{formErrors.paymentsReceivedAmount}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={6}>
          <FormControl isRequired={true} isInvalid={formErrors.paymentsAgentCommissionAmount}>
            <BoxData label="Comissão do representante" value={currency.format(formData.paymentsAgentCommissionAmount ?? 0)} />
            <FormErrorMessage>{formErrors.paymentsAgentCommissionAmount}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={6} />

      <Commission />

      <Divider my={6} />

      <Debit />
    </>
  );
};

export default General;
