import React, { useState, useEffect, useMemo, createContext, useCallback, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  SlideFade,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdChevronLeft, MdRefresh } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { Breadcrumb } from "components";
import { Content } from "pages/Private/Container";
import Packs from "./packs";
import Payments from "./payments";
import General from "./general";
import { AuthContext } from "AuthProvider";

export const PackSettlementsDetailsContext = createContext();

export const PackSettlementsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar acerto de carga" : "Novo acerto de carga");
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/pack-settlements/${_id}` }), [_id]));
  const [formData, setFormData] = useState({ agentCommission: {}, agentDebit: {}, packs: [], payments: [] });
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [wallets, isLoadingWallets, refreshWallets] = useApiGet(useMemo(() => ({ path: "/wallets", perPage: -1 }), []));
  const [paymentCategoriesData, isLoadingPaymentCategories, refreshPaymentCategories] = useApiGet(
    useMemo(() => ({ path: "/payment-categories", params: { perPage: -1 } }), [])
  );
  const paymentCategories = useMemo(
    () => ({
      revenue: _.filter(paymentCategoriesData?.data, (o) => o.type === "revenue"),
      expense: _.filter(paymentCategoriesData?.data, (o) => o.type === "expense"),
    }),
    [paymentCategoriesData?.data]
  );
  const [packs, isLoadingPacks, refreshPacks] = useApiGet(
    useMemo(() => {
      const query = {
        status: { $in: ["pending", "settled"] },
        assignment: "agent",
        agent: formData.agent,
        predictedSettlementDate: { $gte: formData.startDate, $lte: formData.endDate },
      };
      const sort = { status: 1, nid: 1 };
      const isEnabled = formData.agent && formData.startDate && formData.endDate && !_id;
      return { path: "/packs", params: { query, sort, perPage: -1 }, options: { isEnabled } };
    }, [formData.agent, formData.startDate, formData.endDate, _id])
  );
  const [payments, isLoadingPayments, refreshPayments] = useApiGet(
    useMemo(() => {
      const query = { agent: formData.agent, type: "revenue", status: "pending_review" };
      const sort = { nid: 1 };
      const isEnabled = formData.agent && formData.startDate && formData.endDate && !_id;
      return { path: "payments", params: { query, sort, perPage: -1, withPacks: true }, options: { isEnabled } };
    }, [formData.agent, formData.startDate, formData.endDate, _id])
  );
  const { isOpen: isOpenSubmitConfirmDialog, onOpen: onOpenSubmitConfirmDialog, onClose: onCloseSubmitConfirmDialog } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? {
      author: currentUser,
      packs: [],
      payments: [],
      agentCommission: {
        status: "paid",
        issueDate: new Date(),
        dueDate: new Date(),
        paymentDate: new Date(),
      },
      agentDebit: {
        status: "paid",
        issueDate: new Date(),
        dueDate: new Date(),
        paymentDate: new Date(),
      },
    };
    if (formData.startDate && moment(formData.startDate).isValid()) formData.startDate = moment(formData.startDate).toDate();
    if (formData.endDate && moment(formData.endDate).isValid()) formData.endDate = moment(formData.endDate).toDate();
    setFormData(formData);
  }, [currentUser, data]);

  useEffect(() => {
    if (!_id) {
      const totalReceivedAmount = formData.packsReceivedAmount || 0 + formData.paymentsReceivedAmount || 0;
      setFormData((state) => ({ ...state, totalReceivedAmount }));
    }
  }, [_id, formData.packsReceivedAmount, formData.paymentsReceivedAmount]);

  const handleSaveData = useCallback(async () => {
    try {
      onCloseSubmitConfirmDialog();
      setIsLoadingSaveData(true);
      const data = {
        ...formData,
        agent: formData.agent?._id,
        packs: _.filter(formData.packs, (o) => o.isChecked).map((o) => ({
          _id: o._id,
          agentCommissionPercentage: o.agentCommissionPercentage,
          agentCommissionAmount: o.agentCommissionAmount,
        })),
        payments: _.map(formData.payments, (o) => ({ _id: o._id })),
      };
      const saved = await api.post("/pack-settlements", data);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      navigate(`/pack-settlements/edit/${saved._id}`, { replace: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, [navigate, toast, formData, refreshData, onCloseSubmitConfirmDialog]);

  const handleSubmit = useCallback(async () => {
    try {
      const paymentSchema = yup.object().shape({
        status: yup.string().required(messages.error.required),
        paymentMethod: yup.string().required(messages.error.required),
        wallet: yup.string().required(messages.error.required),
        paymentCategory: yup.string().required(messages.error.required),
        issueDate: yup.date().typeError(messages.error.required).required(messages.error.required),
        dueDate: yup.date().typeError(messages.error.required).required(messages.error.required),
        paymentDate: yup.date().when("status", {
          is: "paid",
          then: yup.date().typeError(messages.error.required).required(messages.error.required),
        }),
      });
      const schema = yup.object().shape({
        agent: yup.object().shape({
          _id: yup.string().required(messages.error.required),
        }),
        startDate: yup.date().typeError(messages.error.required).required(messages.error.required),
        endDate: yup.date().typeError(messages.error.required).required(messages.error.required),
        agentCommission: yup.object().when(["totalAgentCommissionAmount"], (totalAgentCommissionAmount) => {
          if (totalAgentCommissionAmount > 0) return paymentSchema;
        }),
        agentDebit: yup.object().when(["totalAgentDebitAmount"], (totalAgentDebitAmount) => {
          if (totalAgentDebitAmount > 0) return paymentSchema;
        }),
      });
      await schema.validate(formData, { abortEarly: false });
      onOpenSubmitConfirmDialog();
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, onOpenSubmitConfirmDialog]);

  return (
    <PackSettlementsDetailsContext.Provider
      value={{
        formData,
        setFormData,
        formErrors,
        setFormErrors,
        wallets,
        isLoadingWallets,
        refreshWallets,
        paymentCategories,
        isLoadingPaymentCategories,
        refreshPaymentCategories,
        packs,
        isLoadingPacks,
        refreshPacks,
        payments,
        isLoadingPayments,
        refreshPayments,
      }}
    >
      <Content>
        <HStack justify="space-between">
          <HStack spacing="10px">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "consignação" },
                { to: "/pack-settlements", label: "acertos de pacote" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Acerto de pacote</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
        </HStack>

        <Tabs colorScheme="main" mt={4}>
          <TabList>
            <Tab>dados gerais</Tab>
            {!_id && (
              <Tab as={HStack} cursor="pointer">
                <Text>pacotes ({_.size(formData.payments)})</Text>
                <IconButton size="xs" variant="ghost" icon={<Icon as={MdRefresh} />} isLoading={isLoadingPacks} onClick={refreshPacks} />
              </Tab>
            )}
            {!_id && (
              <Tab as={HStack} cursor="pointer">
                <Text>outros pagamentos ({_.size(formData.payments)})</Text>
                <IconButton
                  size="xs"
                  variant="ghost"
                  icon={<Icon as={MdRefresh} />}
                  isLoading={isLoadingPayments}
                  onClick={refreshPayments}
                />
              </Tab>
            )}
          </TabList>
          <TabPanels>
            <TabPanel px="0" pb="30px">
              <General />
            </TabPanel>
            {!_id && (
              <TabPanel px="0" pb="30px">
                <Packs />
              </TabPanel>
            )}
            {!_id && (
              <TabPanel px="0" pb="30px">
                <Payments />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack justifyContent="space-between" p="20px">
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} isDisabled={_id} onClick={handleSubmit}>
            finalizar acerto
          </Button>
        </HStack>
      </SlideFade>

      <AlertDialog isOpen={isOpenSubmitConfirmDialog} onClose={onCloseSubmitConfirmDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Atenção
            </AlertDialogHeader>
            <AlertDialogBody>
              Esta é uma ação irreversível. Você está realizando o fechamento deste pagamento. Deseja realmente continuar?
            </AlertDialogBody>
            <AlertDialogFooter as={HStack}>
              <Button onClick={onCloseSubmitConfirmDialog}>cancelar</Button>
              <Button colorScheme="yellow" onClick={handleSaveData}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </PackSettlementsDetailsContext.Provider>
  );
};
