import moment from "moment";
import { currency, translator } from "lib";
import { CustomBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: false,
    render: ({ status }) => <CustomBadge.PackExpeditions.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "agent",
    title: "Representante",
    isVisible: true,
    formatter: ({ agent }) => agent?.name ?? "-",
  },
  {
    accessor: "priceType",
    title: "Tipo de preço",
    isVisible: true,
    formatter: ({ priceType }) => translator(priceType ?? "-"),
  },
  {
    accessor: "predictedSettlementDate",
    title: "Previsão de acerto",
    isVisible: true,
    formatter: ({ predictedSettlementDate }) => moment(predictedSettlementDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "packsCount",
    title: "Pacotes",
    isVisible: true,
    formatter: ({ packsCount }) => packsCount,
  },
  {
    accessor: "amount",
    title: "Valor carregado",
    isVisible: true,
    formatter: ({ amount }) => currency.format(amount),
  },
  {
    accessor: "quantity",
    title: "Quantidade carregada",
    isVisible: true,
    formatter: ({ quantity }) => quantity.toLocaleString(),
  },
  {
    accessor: "closedAt",
    title: "Fechado em",
    isVisible: false,
    formatter: ({ closedAt }) => (closedAt ? moment(closedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "createdAt",
    title: "Criação",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualização",
    isVisible: false,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
