import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdChevronLeft, MdClose, MdMoreHoriz, MdOutlineLocalPrintshop } from "react-icons/md";
import { TbExternalLink, TbTags, TbChevronRight, TbReportSearch } from "react-icons/tb";
import { BiMap } from "react-icons/bi";
import * as yup from "yup";
import { api, currency, percent, translator } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle, useDealerCommission } from "hooks";
import { messages } from "consts";
import { Breadcrumb, AsyncSelect, BoxData, DatePicker, MerchandisesList, Tags, Portal } from "components";
import { Content } from "pages/Private/Container";
import PacksDetailsContext from "./context";
import Location from "./location";
import Settlements from "./settlements";
import { PrintGroupedDetailing, PrintUngroupedDetailing } from "./printDetailing";

let loadUsersTimeout = { agent: null, dealer: null };

export const PacksDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar pacote" : "Novo pacote");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/packs/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const {
    isOpen: isOpenPrintGroupedDetailing,
    onOpen: onOpenPrintGroupedDetailing,
    onClose: onClosePrintGroupedDetailing,
  } = useDisclosure();
  const {
    isOpen: isOpenPrintUngroupedDetailing,
    onOpen: onOpenPrintUngroupedDetailing,
    onClose: onClosePrintUngroupedDetailing,
  } = useDisclosure();
  const { isOpen: isOpenMerchandisesList, onOpen: onOpenMerchandisesList, onClose: onCloseMerchandisesList } = useDisclosure();
  const { isOpen: isOpenLocation, onOpen: onOpenLocation, onClose: onCloseLocation } = useDisclosure();
  const { isOpen: isOpenSettlements, onOpen: onOpenSettlements, onClose: onCloseSettlements } = useDisclosure();
  const inApp = useMemo(() => formData.ownership === "app", [formData.ownership]);
  const isLockedAgent = useMemo(() => data?.dispatchedAt && (data?.dealer || data?.receivedAmount > 0), [data]);
  const isLockedDealer = useMemo(
    () => (data?.status !== "preparing" && data?.assignment === "dealer") || data?.receivedAmount > 0 || data?.settledAt,
    [data]
  );
  const toast = useCustomToast();
  const tagsSelectorRef = useRef();
  const dealerCommission = useDealerCommission(useMemo(() => data, [data]));

  useEffect(() => {
    const formData = data ?? { status: "preparing", priceType: "retailPrice", assignment: "agent" };
    if (formData.predictedSettlementDate && moment(formData.predictedSettlementDate).isValid())
      formData.predictedSettlementDate = moment(formData.predictedSettlementDate).toDate();
    setFormData(formData);
  }, [data]);

  useEffect(() => {
    const receivableAmount = _.round(formData.currentAmount - dealerCommission.amount, 2);
    const remainingAmount = _.round(receivableAmount - formData.receivedAmount, 2) || 0;
    setFormData((state) => ({ ...state, receivableAmount, remainingAmount }));
  }, [dealerCommission.amount, formData.currentAmount]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.put(`/packs/${_id}`, data) : await api.post("/packs", data);
        navigate(`/packs/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        status: yup.string().required(messages.error.required),
        assignment: yup.string().required(messages.error.required),
        priceType: yup.string().required(messages.error.required),
        agent: yup.string().when("assignment", {
          is: "agent",
          then: yup.string().required(messages.error.required),
        }),
        dealer: yup.string().when("assignment", {
          is: "dealer",
          then: yup.string().required(messages.error.required),
        }),
        predictedSettlementDate: yup.date().typeError(messages.error.required).required(messages.error.required),
      });
      const data = { ...formData, agent: formData.agent?._id, dealer: formData.dealer?._id };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleLoadUsers = useCallback((role, search, cb) => {
    clearTimeout(loadUsersTimeout[role]);
    loadUsersTimeout[role] = setTimeout(async () => {
      const query = { roles: role };
      const response = await api.get("/users", { params: { query, search, perPage: 20 } });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleOpenPackTags = useCallback(() => {
    tagsSelectorRef.current.open("pack", [{ document: formData, quantity: 1 }], true);
  }, [formData]);

  const handleOpenMerchandisesTags = useCallback(() => {
    const documents = _.map(formData.merchandises, ({ product, quantity }) => ({ document: product, quantity }));
    tagsSelectorRef.current.open("product", documents, false);
  }, [formData.merchandises]);

  return (
    <PacksDetailsContext.Provider value={{ data, formData, setFormData, dealerCommission, refreshData }}>
      <Content>
        <HStack justify="space-between">
          <HStack spacing="10px">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "consignação" },
                { to: "/packs", label: "pacotes" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <SlideFade in={true} offsetY="-20px">
            <Box>
              <Menu>
                <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />} isDisabled={!_id}>
                  mais ações
                </MenuButton>
                <Portal>
                  <MenuList position="relative" zIndex="999999" fontSize="sm">
                    <MenuItem onClick={onOpenPrintUngroupedDetailing} isDisabled={!_id}>
                      <HStack>
                        <Icon as={TbReportSearch} />
                        <Text>detalhamento do pacote</Text>
                      </HStack>
                    </MenuItem>
                    <MenuItem onClick={onOpenPrintGroupedDetailing} isDisabled={!_id}>
                      <HStack>
                        <Icon as={TbReportSearch} />
                        <Text>detalhamento do pacote agrupado</Text>
                      </HStack>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleOpenPackTags} isDisabled={!_id}>
                      <HStack>
                        <Icon as={MdOutlineLocalPrintshop} />
                        <Text>imprimir etiqueta do pacote</Text>
                      </HStack>
                    </MenuItem>
                    <MenuItem onClick={handleOpenMerchandisesTags} isDisabled={!_id}>
                      <HStack>
                        <Icon as={MdOutlineLocalPrintshop} />
                        <Text>imprimir etiquetas das mercadorias</Text>
                      </HStack>
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </Box>
          </SlideFade>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Pacote</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            {!_id && (
              <Box>
                <FormControl display="flex" alignItems="center">
                  <FormLabel fontSize="sm" mb="0">
                    Carga?
                  </FormLabel>
                  <Switch
                    colorScheme="main"
                    isChecked={formData.isLoad}
                    onChange={() => setFormData((state) => ({ ...state, isLoad: !state.isLoad, assignment: "agent" }))}
                    isDisabled={_id}
                  />
                </FormControl>
              </Box>
            )}
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={3}>
            <BoxData label="NID" value={formData.nid ?? "-"} />
          </GridItem>
          <GridItem colSpan={3}>
            {_id ? (
              <BoxData label="Atribuição" value={translator(formData.assignment)} />
            ) : (
              <FormControl isRequired={true} isInvalid={formErrors.assignment} isDisabled={_id}>
                <FormLabel fontSize="sm">Atribuição</FormLabel>
                <Select
                  value={formData.assignment ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, assignment: target.value }))}
                >
                  <option value="agent">Representante</option>
                  <option value="dealer">Revendedor</option>
                </Select>
                <FormErrorMessage>{formErrors.assignment}</FormErrorMessage>
              </FormControl>
            )}
          </GridItem>
          <GridItem colSpan={3}>
            {data?.status !== "preparing" || formData.assignment === "agent" ? (
              <BoxData label="Status" value={translator(formData.status ?? "-")} />
            ) : (
              <FormControl isRequired={true} isInvalid={formErrors.status}>
                <FormLabel fontSize="sm">Status</FormLabel>
                <Select
                  value={formData.status ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}
                >
                  <option value="preparing">Preparando</option>
                  <option value="pending">Pendente</option>
                </Select>
                <FormErrorMessage>{formErrors.status}</FormErrorMessage>
              </FormControl>
            )}
          </GridItem>
          <GridItem colSpan={3}>
            {_id ? (
              <BoxData label="Tipo de preço" value={translator(formData.priceType)} />
            ) : (
              <FormControl isRequired={true} isInvalid={formErrors.priceType} isDisabled={_id}>
                <FormLabel fontSize="sm">Tipo de preço</FormLabel>
                <Select
                  value={formData.priceType ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, priceType: target.value }))}
                >
                  <option value="costPrice">Custo</option>
                  <option value="wholesalePrice">Atacado</option>
                  <option value="retailPrice">Varejo</option>
                </Select>
                <FormErrorMessage>{formErrors.priceType}</FormErrorMessage>
              </FormControl>
            )}
          </GridItem>

          {formData.assignment === "agent" && (
            <GridItem colSpan={formData.isLoad ? 12 : 6}>
              {isLockedAgent ? (
                <BoxData label="Representante" value={formData.agent?.name ?? "-"} />
              ) : (
                <FormControl isRequired={formData.assignment === "agent"} isInvalid={formErrors.agent}>
                  <FormLabel fontSize="sm">Representante</FormLabel>
                  <AsyncSelect
                    isInvalid={formErrors.agent}
                    value={formData.agent}
                    defaultOptions
                    loadOptions={(search, cb) => handleLoadUsers("agent", search, cb)}
                    placeholder="Selecione o representante"
                    onChange={(agent) => setFormData((state) => ({ ...state, agent }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ name }) => name}
                    isClearable={true}
                  />
                  <FormErrorMessage>{formErrors.agent}</FormErrorMessage>
                </FormControl>
              )}
            </GridItem>
          )}
          {!formData.isLoad && (
            <GridItem colSpan={{ base: 12, lg: formData.assignment === "agent" ? 6 : 12 }}>
              {isLockedDealer ? (
                <BoxData label="Revendedor" value={formData.dealer?.name ?? "-"} />
              ) : (
                <FormControl isRequired={formData.assignment === "dealer"} isInvalid={formErrors.dealer}>
                  <FormLabel fontSize="sm">Revendedor</FormLabel>
                  <AsyncSelect
                    isInvalid={formErrors.dealer}
                    value={formData.dealer}
                    defaultOptions
                    loadOptions={(search, cb) => handleLoadUsers("dealer", search, cb)}
                    placeholder="Selecione o revendedor"
                    onChange={(dealer) => setFormData((state) => ({ ...state, dealer }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ name }) => name}
                    isClearable={true}
                  />
                  <FormErrorMessage>{formErrors.dealer}</FormErrorMessage>
                </FormControl>
              )}
            </GridItem>
          )}

          <GridItem colSpan={3}>
            {formData.settledAt ? (
              <BoxData label="Previsão de acerto" value={moment(formData.predictedSettlementDate).format("d, MMMM yyyy")} />
            ) : (
              <FormControl isInvalid={formErrors.predictedSettlementDate}>
                <FormLabel fontSize="sm">Previsão de acerto</FormLabel>
                <DatePicker
                  selected={formData.predictedSettlementDate}
                  onChange={(value) =>
                    setFormData((state) => ({ ...state, predictedSettlementDate: moment(value).startOf("day").toDate() }))
                  }
                  dateFormat="d, MMMM yyyy"
                />
                <FormErrorMessage>{formErrors.predictedSettlementDate}</FormErrorMessage>
              </FormControl>
            )}
          </GridItem>

          <GridItem colSpan={3}>
            <BoxData label="Despachado em" value={formData.dispatchedAt ? moment(formData.dispatchedAt).format("D, MMMM yyyy") : "-"} />
          </GridItem>

          <GridItem colSpan={3}>
            <BoxData
              label="Acertado em"
              value={formData.settledAt ? moment(formData.settledAt).format("D, MMMM yyyy") : "-"}
              RightComponent={
                formData.location && <IconButton variant="outline" icon={<Icon as={BiMap} />} isRound onClick={onOpenLocation} />
              }
            />
          </GridItem>

          <GridItem colSpan={3}>
            <BoxData label="Fechado em" value={formData.closedAt ? moment(formData.closedAt).format("D, MMMM yyyy") : "-"} />
          </GridItem>
        </Grid>

        <Divider />
        <HStack mt={8} mb={6}>
          <Heading size="sm" flex="1">
            Mercadorias
          </Heading>
          <Button size="sm" variant="outline" leftIcon={<Icon as={TbTags} />} onClick={onOpenMerchandisesList}>
            visualizar mercadorias
          </Button>
          <Button
            size="sm"
            variant="outline"
            rightIcon={<Icon as={TbExternalLink} />}
            mt="5px"
            onClick={() => navigate(`/pack-movements`, { state: { pack: formData } })}
            isDisabled={!formData._id}
          >
            visitar movimentações
          </Button>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          {!formData.isLoad && (
            <GridItem colSpan={3}>
              <BoxData label="Desempenho" value={percent.format(formData.performance ?? 0)} />
            </GridItem>
          )}
          <GridItem colSpan={3}>
            <BoxData
              label="Total atual"
              value={
                <HStack divider={<Text px="5px">•</Text>}>
                  <Text>{currency.format(formData.currentAmount ?? 0)}</Text>
                  <Text>{formData.currentQuantity?.toLocaleString() ?? 0}</Text>
                </HStack>
              }
            />
          </GridItem>
          {!formData.isLoad && (
            <>
              <GridItem colSpan={3}>
                <BoxData
                  label="Total carregado"
                  value={
                    <HStack divider={<Text px="5px">•</Text>}>
                      <Text>{currency.format(formData.loadedAmount ?? 0)}</Text>
                      <Text>{formData.loadedQuantity?.toLocaleString() ?? 0}</Text>
                    </HStack>
                  }
                />
              </GridItem>
              <GridItem colSpan={3}>
                <BoxData
                  label="Total devolvido"
                  value={
                    <HStack divider={<Text px="5px">•</Text>}>
                      <Text>{currency.format(formData.unloadedAmount ?? 0)}</Text>
                      <Text>{formData.unloadedQuantity?.toLocaleString() ?? 0}</Text>
                    </HStack>
                  }
                />
              </GridItem>
            </>
          )}
        </Grid>

        {!formData.isLoad && (
          <>
            <Divider />
            <HStack mt={8} mb={6}>
              <Heading size="sm" flex="1">
                Pagamentos
              </Heading>
              <Button
                size="sm"
                variant="outline"
                rightIcon={<Icon as={TbExternalLink} />}
                mt="5px"
                onClick={() => navigate("/payments", { state: { pack: formData } })}
                isDisabled={!formData._id}
              >
                visitar pagamentos
              </Button>
            </HStack>

            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={6}>
                <BoxData label="Valor recebido" value={currency.format(formData.receivedAmount ?? 0)} />
              </GridItem>
              <GridItem colSpan={6}>
                <BoxData label="Valor restante" value={currency.format(formData.remainingAmount ?? 0)} />
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: formData.assignment === "agent" ? 6 : 12 }}>
                <BoxData
                  label="Comissão revendedor"
                  value={
                    <HStack divider={<Text px="5px">•</Text>}>
                      <Text>{currency.format(dealerCommission.amount ?? 0)}</Text>
                      <Text>{percent.format(dealerCommission.percentage ?? 0)}</Text>
                    </HStack>
                  }
                />
              </GridItem>
              {formData.assignment === "agent" && (
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <BoxData
                    label="Comissão representante"
                    value={
                      <HStack divider={<Text px="5px">•</Text>}>
                        <Text>{currency.format(formData.agentCommissionAmount ?? 0)}</Text>
                        <Text>{percent.format(formData.agentCommissionPercentage ?? 0)}</Text>
                      </HStack>
                    }
                  />
                </GridItem>
              )}
            </Grid>
          </>
        )}
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <HStack flex="1">
            <Button
              size="sm"
              colorScheme="main"
              isLoading={isLoadingData || isLoadingSaveData}
              isDisabled={inApp || formData.settledAt}
              onClick={handleSubmit}
            >
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              cancelar
            </Button>
          </HStack>
          {formData.status === "pending" && formData.assignment === "dealer" && (
            <HStack>
              <Button size="sm" colorScheme="green" rightIcon={<Icon as={TbChevronRight} />} onClick={onOpenSettlements}>
                acertar pacote
              </Button>
            </HStack>
          )}
        </HStack>
      </SlideFade>

      <MerchandisesList
        data={formData.merchandises}
        isOpen={isOpenMerchandisesList}
        onClose={onCloseMerchandisesList}
        isLoading={isLoadingData}
      />

      <Tags.Selector ref={tagsSelectorRef} />

      <Location isOpen={isOpenLocation} onClose={onCloseLocation} />
      <Settlements isOpen={isOpenSettlements} onClose={onCloseSettlements} />
      <PrintGroupedDetailing isOpen={isOpenPrintGroupedDetailing} onClose={onClosePrintGroupedDetailing} />
      <PrintUngroupedDetailing isOpen={isOpenPrintUngroupedDetailing} onClose={onClosePrintUngroupedDetailing} />
    </PacksDetailsContext.Provider>
  );
};
