import React, { memo, useEffect, useState, useCallback, useContext, useRef, createContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
} from "@chakra-ui/react";
import { MdChevronRight, MdClose, MdSearch } from "react-icons/md";
import _ from "lodash";
import * as yup from "yup";
import { api, getNumeric, translator } from "lib";
import { messages } from "consts";
import { BoxData } from "components";
import { useCustomToast } from "hooks";
import { FiSmartphone } from "react-icons/fi";

const MovementContext = createContext();

const Search = memo(({ field, isDisabled }) => {
  const { formData, formErrors, setFormData } = useContext(MovementContext);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("_id");
  const inputRef = useRef();
  const toast = useCustomToast();
  const isValidStyle = useMemo(() => {
    if (formData[field]?.ownership === "app") return { bg: "yellow.500", color: "white" };
    if (formData[field]?._id) return { bg: "green.500", color: "white" };
  }, [field, formData]);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setIsLoading(true);
        const value = inputRef.current.value;
        const query = { isClosed: false };
        if (searchKey === "_id") {
          query.$or = [{ nid: getNumeric(value) }];
          if (/^[0-9a-fA-F]{24}$/.test(value)) query.$or.push({ _id: value });
        } else query.sky = value;
        const { data, size } = await api.get("/packs", { params: { query, withMerchandises: true } });
        if (size === 0) throw new Error(messages.error.packNotFound);
        setFormData((state) => ({ ...state, [field]: data[0] }));
      } catch (error) {
        setFormData((state) => ({ ...state, [field]: undefined }));
        toast({ status: "error", position: "bottom-right", title: "Oops!", description: error.message, isClosable: true });
      } finally {
        setIsLoading(false);
      }
    },
    [field, inputRef.current, searchKey, setFormData, toast]
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isRequired={true} isInvalid={formErrors[field]}>
        <HStack mb="8px">
          <FormLabel fontSize="sm" mb="0" flex="1">
            Pesquisar {translator(field)}
          </FormLabel>
          {[
            { value: "_id", label: "ID/NID" },
            { value: "sku", label: "SKU" },
          ].map(({ value, label }) => (
            <Button
              key={value}
              size="xs"
              colorScheme={searchKey === value ? "main" : "gray"}
              onClick={() => setSearchKey(value)}
              isDisabled={isDisabled}
            >
              {label}
            </Button>
          ))}
        </HStack>
        <InputGroup mb="10px">
          <Input ref={inputRef} isDisabled={isDisabled} />
          <InputRightElement>
            <IconButton type="submit" size="sm" icon={<Icon as={MdSearch} />} isLoading={isLoading} isDisabled={isDisabled} />
          </InputRightElement>
        </InputGroup>
        <BoxData
          label={`Pacote de ${translator(field)}`}
          value={
            <HStack>
              <Text flex="1">NID {formData[field]?.nid ?? "-"}</Text>
              {formData[field]?.ownership === "app" && <Icon as={FiSmartphone} />}
            </HStack>
          }
          _light={isValidStyle}
          _dark={isValidStyle}
        />
        <FormErrorMessage>{formErrors[field]}</FormErrorMessage>
      </FormControl>
    </form>
  );
});

const Movement = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({ type: "load" });
  const [formErrors, setFormErrors] = useState({});
  const isTransfer = useMemo(() => formData.type === "transfer", [formData.type]);

  useEffect(() => {
    setFormErrors({});
  }, [isOpen]);

  useEffect(() => {
    if (location.state?.pack) {
      setFormData((state) => {
        switch (state.type) {
          case "unload":
          case "transfer":
            return { ...state, source: location.state?.pack, destination: undefined };
          default:
            return { ...state, source: undefined, destination: location.state?.pack };
        }
      });
    }
  }, [location.state?.pack, isOpen, formData.type]);

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        type: yup.string().required(messages.error.required),
        source: yup.object().when("type", (type) => {
          if (["unload", "transfer"].indexOf(type) !== -1) return yup.object().required(messages.error.required);
        }),
        destination: yup.object().when("type", (type) => {
          if (["load", "transfer"].indexOf(type) !== -1) return yup.object().required(messages.error.required);
        }),
      });
      await schema.validate(formData, { abortEarly: false });
      const state = (() => {
        switch (formData.type) {
          case "load":
            return { type: "load", destination: formData.destination };
          case "unload":
            return { type: "unload", source: formData.source };
          default:
            return { type: "transfer", source: formData.source, destination: formData.destination };
        }
      })();
      navigate("/pack-movements/new", { state });
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData]);

  return (
    <MovementContext.Provider value={{ formData, setFormData, formErrors }}>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader as={HStack} justifyContent="space-between">
            <Text lineHeight="1">Nova movimentação</Text>
            <IconButton variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={isTransfer ? 4 : 6}>
                <FormControl isInvalid={formErrors.type}>
                  <FormLabel fontSize="sm">Praça</FormLabel>
                  <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
                    <option value="load">Carga</option>
                    <option value="unload">Descarga</option>
                    <option value="transfer">Transferência</option>
                  </Select>
                  <FormErrorMessage>{formErrors.type}</FormErrorMessage>
                </FormControl>
              </GridItem>
              {["unload", "transfer"].indexOf(formData.type) !== -1 && (
                <GridItem colSpan={isTransfer ? 4 : 6}>
                  <Search field="source" />
                </GridItem>
              )}
              {["load", "transfer"].indexOf(formData.type) !== -1 && (
                <GridItem colSpan={isTransfer ? 4 : 6}>
                  <Search field="destination" />
                </GridItem>
              )}
            </Grid>
          </DrawerBody>
          <Divider />
          <DrawerFooter as={HStack}>
            <Button onClick={onClose}>cancelar</Button>
            <Button
              colorScheme="main"
              rightIcon={<Icon as={MdChevronRight} />}
              isDisabled={formData.source?.ownership === "app" || formData.destination?.ownership === "app"}
              onClick={handleSubmit}
            >
              continuar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </MovementContext.Provider>
  );
};

export default Movement;
