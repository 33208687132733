import { memo, useMemo } from "react";
import moment from "moment";
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdInfoOutline } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { TbTruckDelivery } from "react-icons/tb";
import { currency, percent } from "lib";

export const PackSettlement = memo(() => {
  const location = useLocation();
  const data = useMemo(() => location.state?.packSettlement, [location.state?.packSettlement]);

  if (data?._id)
    return (
      <Flex mb="15px">
        <HStack bg="blue.500" spacing="15px" p="15px" borderRadius="md">
          <Center w="40px" h="40px" bg="whiteAlpha.300" borderRadius="full">
            <Icon as={TbTruckDelivery} boxSize="30px" color="white" />
          </Center>
          <Box flex="1" color="white">
            <Text fontSize="sm">Acerto relacionado</Text>
            <HStack divider={<Text px="5px">•</Text>}>
              <Text fontWeight="semibold">NID {data.nid}</Text>
            </HStack>
          </Box>
          <Popover>
            <PopoverTrigger>
              <IconButton variant="outline" colorScheme="whiteAlpha" icon={<Icon as={MdInfoOutline} />} />
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody p="20px">
                  <VStack alignItems="stretch">
                    <HStack>
                      <Text fontSize="xs">NID</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {data.nid}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Representante</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {data.agent?.name ?? "-"}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Data inicial</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {moment(data.startDate).format("DD, MMMM [de] YYYY")}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Data final</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {moment(data.endDate).format("DD, MMMM [de] YYYY")}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Total recebido</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {currency.format(data.totalReceivedAmount)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Comissões pagas</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {currency.format(data.packsDealerCommissionAmount)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Outros pagamentos</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {currency.format(data.paymentsReceivedAmount)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Comissão do representante</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {currency.format(data.totalAgentCommissionAmount)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Débito do representante</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {currency.format(data.totalAgentDebitAmount)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Desempenho médio</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {percent.format(data.packsAvgPerformance)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Inadimplência</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {percent.format(data.packsInsolvency)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="xs">Fechado em</Text>
                      <Divider flex="1" />
                      <Text fontSize="xs" fontWeight="semibold">
                        {moment(data.closedAt).format("DD, MMMM [de] YYYY")}
                      </Text>
                    </HStack>
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </HStack>
      </Flex>
    );
  return null;
});
