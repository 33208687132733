import React, { useMemo, useRef, useState, useCallback, createContext } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import _ from "lodash";
import { useApiGet, useClipboard, useCustomToast, useDocumentTitle, useTable } from "hooks";
import {
  Breadcrumb,
  CheckboxBody,
  CheckboxHeader,
  CheckboxProvider,
  ExportCsv,
  Paginator,
  Portal,
  TableDrawer,
  TableEmpty,
} from "components";
import { api } from "lib";
import { MdMoreHoriz, MdRefresh, MdSearch } from "react-icons/md";
import { BiCog } from "react-icons/bi";
import { FiClipboard, FiEdit, FiTrash } from "react-icons/fi";
import { ImBoxAdd, ImBoxRemove } from "react-icons/im";
import { TbFileExport } from "react-icons/tb";
import { Content } from "pages/Private/Container";
import defaultColumns from "./defaultColumns";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import Duplicate from "./duplicate";

export const ProductsListContext = createContext();

export const ProductsList = () => {
  useDocumentTitle("Produtos");
  const location = useLocation();
  const [sort, setSort] = useState({ nid: 1 });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(() => ({ path: "/products", params: { sort, page, perPage, search } }), [sort, page, perPage, search])
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 3 }), [location.pathname])
  );
  const checks = useMemo(() => _.map(data?.data, "_id"), [data?.data]);
  const [checkeds, setCheckeds] = useState([]);
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const { isOpen: isOpenDuplicate, onOpen: onOpenDuplicate, onClose: onCloseDuplicate } = useDisclosure();
  const toast = useCustomToast();
  const tableDrawerRef = useRef();
  const copyToClipboard = useClipboard();

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    const [{ value }] = e.target;
    setSearch(value);
  }, []);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete("/products", { data: checkeds });
      setCheckeds([]);
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      onCloseDeleteDialog();
    }
  }, [checkeds, onCloseDeleteDialog, toast, refreshData]);

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  return (
    <ProductsListContext.Provider value={{ checkeds, setCheckeds, refreshData }}>
      <Content>
        <HStack justify="space-between">
          <Breadcrumb items={[{ label: "cadastros" }, { to: "/products", label: "produtos" }]} />
          <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="-20px">
            <HStack>
              <Button as={RouterLink} to="new" size="sm" colorScheme="main">
                incluir cadastro
              </Button>
              <Box>
                <Menu>
                  <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                    mais ações
                  </MenuButton>
                  <Portal>
                    <MenuList fontSize="sm">
                      <MenuItem onClick={onOpenExportData}>
                        <HStack>
                          <Icon as={TbFileExport} />
                          <Text>exportar produtos</Text>
                        </HStack>
                      </MenuItem>
                    </MenuList>
                  </Portal>
                </Menu>
              </Box>
            </HStack>
          </SlideFade>
        </HStack>

        <Heading my="15px" size="md">
          Produtos
        </Heading>

        <HStack mb="30px">
          <form onSubmit={handleSearch}>
            <InputGroup width="xs">
              <Input variant="filled" placeholder="Pesquisar..." />
              <InputRightElement>
                <Icon as={MdSearch} />
              </InputRightElement>
            </InputGroup>
          </form>
          <HStack flex="1" justify="flex-end">
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoadingData} onClick={refreshData} />
            <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={() => tableDrawerRef.current.open()} />
          </HStack>
        </HStack>

        <CheckboxProvider checks={checks} checkeds={checkeds} onChange={setCheckeds}>
          <Table size="sm" whiteSpace="nowrap">
            <Thead>
              <Tr>
                <Th>
                  <CheckboxHeader />
                </Th>
                {cells.map(({ accessor, title }) => (
                  <Th key={accessor}>{title}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {_.map(data?.data, (item) => (
                <Tr key={item._id} _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } }}>
                  <Td>
                    <HStack>
                      <CheckboxBody value={item._id} />
                      <Box>
                        <Menu placement="right-start">
                          <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
                          <MenuList>
                            <MenuGroup title={item.name} pb="5px">
                              <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                                copiar código
                              </MenuItem>
                              <MenuItem icon={<Icon as={FiEdit} />} as={RouterLink} to={`edit/${item._id}`}>
                                editar
                              </MenuItem>
                              <MenuItem
                                icon={<Icon as={HiOutlineDocumentDuplicate} />}
                                onClick={() => {
                                  setCheckeds([item._id]);
                                  onOpenDuplicate();
                                }}
                              >
                                duplicar
                              </MenuItem>
                              <MenuDivider />
                              <MenuItem
                                icon={<Icon as={FiTrash} />}
                                onClick={() => {
                                  setCheckeds([item._id]);
                                  onOpenDeleteDialog();
                                }}
                              >
                                excluir
                              </MenuItem>
                            </MenuGroup>
                          </MenuList>
                        </Menu>
                      </Box>
                      {item.isManagedStock && (item.stock < item.minStock || item.stock > item.maxStock) && (
                        <Tooltip label={item.stock < item.minStock ? "Estoque abaixo do mínimo" : "Estoque acima do máximo"}>
                          <IconButton
                            size="xs"
                            icon={<Icon as={item.stock < item.minStock ? ImBoxAdd : ImBoxRemove} />}
                            colorScheme="orange"
                          />
                        </Tooltip>
                      )}
                    </HStack>
                  </Td>
                  {cells.map(({ accessor, formatter, render }) => (
                    <Td key={accessor}>{formatter?.(item) ?? render?.(item)}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          {isLoadingData && (
            <Center p="30px">
              <Spinner />
            </Center>
          )}
          <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} />
        </CheckboxProvider>
      </Content>

      <Divider />

      <SlideFade in={checkeds.length} hidden={checkeds.length === 0} offsetY="20px">
        <HStack p="20px" justify="space-between">
          <Text>{checkeds.length} selecionados</Text>
          <Button size="sm" variant="outline" leftIcon={<Icon as={FiTrash} />} onClick={onOpenDeleteDialog}>
            excluir cadastros
          </Button>
        </HStack>
      </SlideFade>

      <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsv filename="produtos" data={data?.data} columns={columns} isOpen={isOpenExportData} onClose={onCloseExportData} />

      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDeleteDialog}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Duplicate isOpen={isOpenDuplicate} onClose={onCloseDuplicate} />
    </ProductsListContext.Provider>
  );
};
