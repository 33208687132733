import React from "react";
import { Text, Image as ChakraUiImage } from "@chakra-ui/react";

export const Image = ({ size, width, height, src, ...props }) => {
  return (
    <Text p="1px" width={width || size} height={height || size} {...props}>
      <ChakraUiImage width="100%" height="100%" src={src} border="none" />
    </Text>
  );
};
