import React, { useState, useEffect, useMemo, useCallback, createContext } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Input,
  Select,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import * as yup from "yup";
import { api, currency, percent } from "lib";
import { useApiGet, useArrayItemHandlers, useCustomToast, useDocumentTitle } from "hooks";
import { messages } from "consts";
import { BoxData, Breadcrumb } from "components";
import { Content } from "pages/Private/Container";
import { MdAddCircleOutline, MdAttachMoney, MdChevronLeft, MdOutlineDelete } from "react-icons/md";
import { TbPercentage } from "react-icons/tb";
import AddRange from "./addRange";

export const CommissionsDetailsContext = createContext();

export const CommissionRulesDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar regra de comissão" : "Novo regra de comissão");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/commission-rules/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const lastRange = useMemo(() => _.last(formData.ranges), [formData.ranges]);
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const type = useMemo(() => {
    switch (formData.type) {
      case "percentual_amount":
        return { symbol: "%", icon: TbPercentage, format: percent.format, isPercentage: true };
      default:
        return { symbol: "R$", icon: MdAttachMoney, format: currency.format, isPercentage: false };
    }
  }, [formData.type]);

  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { type: "percentual_amount", ranges: [], isActive: true };
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.put(`/commission-rules/${_id}`, data) : await api.post("/commission-rules", data);
        navigate(`/settings/records/commission-rules/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        type: yup.string().required(messages.error.required),
        ranges: yup.array().min(1, messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <CommissionsDetailsContext.Provider value={{ formData, setFormData, type }}>
      <Content>
        <HStack justify="space-between">
          <HStack spacing="10px">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/settings#records", label: "cadastros" },
                { to: "/settings/records/commission-rules", label: "regra de comissão" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Regra de comissão</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
          <HStack spacing="20px">
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Público?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isPublic}
                onChange={() => setFormData((state) => ({ ...state, isPublic: !state.isPublic }))}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={7}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={5}>
            <FormControl isInvalid={formErrors.type} isDisabled={_.size(formData.ranges) > 0}>
              <FormLabel fontSize="sm">Tipo</FormLabel>
              <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
                <option value="percentual_amount">Percentual - Calculado sobre o desempenho percentual</option>
                <option value="absolute_amount">Absoluto - Calculado sobre o valor vendido absoluto</option>
              </Select>
              <FormErrorMessage>{formErrors.type}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Divider />

        <FormControl as={HStack} justifyContent="space-between" mt={8} mb={6} isInvalid={formErrors.ranges}>
          <Box>
            <Heading size="sm">Intervalos</Heading>
            <FormErrorMessage>{formErrors.ranges}</FormErrorMessage>
          </Box>
          <Button
            size="sm"
            colorScheme="main"
            leftIcon={<Icon as={MdAddCircleOutline} />}
            isDisabled={type.isPercentage && lastRange?.soldEnd === 1}
            onClick={onOpen}
          >
            adicionar intervalo
          </Button>
        </FormControl>

        {_.map(formData.ranges, (contact, index) => (
          <Grid key={index} templateColumns="repeat(13, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={4}>
              <FormControl isInvalid={formErrors[`ranges[${index}].soldStart`]}>
                <BoxData label={`${type.symbol} vendido (inicial)`} value={type.format(contact.soldStart)} />
                <FormErrorMessage>{formErrors[`ranges[${index}].soldStart`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl isInvalid={formErrors[`ranges[${index}].soldEnd`]}>
                <BoxData label={`${type.symbol} vendido (final)`} value={type.format(contact.soldEnd)} />
                <FormErrorMessage>{formErrors[`ranges[${index}].soldEnd`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl isInvalid={formErrors[`ranges[${index}].commissionPercentage`]}>
                <BoxData label="% regra de comissão" value={percent.format(contact.commissionPercentage)} />
                <FormErrorMessage>{formErrors[`ranges[${index}].commissionPercentage`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormLabel fontSize="sm">&nbsp;</FormLabel>
              <Button
                rightIcon={<Icon as={MdOutlineDelete} />}
                size="md"
                variant="outline"
                onClick={() => handleDeleteArrayItem("ranges", index)}
              >
                deletar
              </Button>
            </GridItem>
          </Grid>
        ))}

        {_.size(formData.ranges) === 0 && (
          <Center mt={8}>
            <Box w="300px">
              <Center mb="10px">
                <Icon as={type.icon} boxSize="80px" color="gray.200" />
              </Center>
              <Heading textAlign="center" size="md">
                Nenhum intervalo adicionado
              </Heading>
              <Text textAlign="center" fontSize="sm">
                Você ainda não adicionou intervalos nesta regra de comissão.
              </Text>
              <Center mt="20px">
                <Button size="sm" colorScheme="main" leftIcon={<Icon as={MdAddCircleOutline} />} onClick={onOpen}>
                  adicionar intervalo
                </Button>
              </Center>
            </Box>
          </Center>
        )}
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
            salvar
          </Button>
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            cancelar
          </Button>
        </HStack>
      </SlideFade>

      <AddRange isOpen={isOpen} onClose={onClose} />
    </CommissionsDetailsContext.Provider>
  );
};
