import React, { useCallback } from "react";
import { VStack, FormControl, InputGroup, Input, IconButton, Icon, useDisclosure, InputRightElement } from "@chakra-ui/react";
import { Label, InputSize } from "../components";
import { MdAttachFile } from "react-icons/md";
import { FileManager } from "components";

export const Image = ({ data, onChange }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleFinish = useCallback(
    (selecteds) => {
      const [{ location }] = selecteds;
      onChange({ ...data, props: { ...data.props, src: location } });
    },
    [onChange]
  );

  return (
    <>
      <VStack>
        <FormControl>
          <Label onClean={() => onChange({ ...data, props: { ...data.props, width: undefined } })}>Largura</Label>
          <InputSize value={data.props.width} onChange={(width) => onChange({ ...data, props: { ...data.props, width } })} />
        </FormControl>
        <FormControl>
          <Label onClean={() => onChange({ ...data, props: { ...data.props, height: undefined } })}>Altura</Label>
          <InputSize value={data.props.height} onChange={(height) => onChange({ ...data, props: { ...data.props, height } })} />
        </FormControl>
        <FormControl>
          <Label onClean={() => onChange({ ...data, props: { ...data.props, src: undefined } })}>URL</Label>
          <InputGroup>
            <Input value={data.props.src} isDisabled={true} />
            <InputRightElement>
              <IconButton size="sm" icon={<Icon as={MdAttachFile} />} onClick={onOpen} />
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </VStack>
      <FileManager.Library type="image" isOpen={isOpen} onClose={onClose} onFinish={handleFinish} />
    </>
  );
};
