import React, { useContext, useCallback } from "react";
import _ from "lodash";
import { Box, Center, HStack, Icon, Button, Text, useDisclosure } from "@chakra-ui/react";
import { MdAttachFile } from "react-icons/md";
import { FileManager } from "components";
import { ProductsDetailsContext } from "./index";

const Attachments = () => {
  const { formData, setFormData } = useContext(ProductsDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleFinish = useCallback(
    (selecteds) => {
      setFormData((state) => {
        const attachments = _.map(state.attachments);
        const _ids = attachments.map((o) => o._id);
        for (const selected of selecteds) {
          if (_ids.indexOf(selected._id) === -1) attachments.push(selected);
        }
        return { ...state, attachments };
      });
    },
    [setFormData]
  );

  return (
    <>
      <HStack mb="20px" justifyContent="space-between">
        <Text fontSize="sm" fontWeight="semibold">
          {_.size(formData.attachments)} anexos adicionados
        </Text>
        <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdAttachFile} />} onClick={onOpen}>
          incluir arquivos
        </Button>
      </HStack>

      <FileManager.List data={formData.attachments} onChange={(attachments) => setFormData((state) => ({ ...state, attachments }))} />

      {_.size(formData?.attachments) === 0 && (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={MdAttachFile} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhum arquivo adicionado
            </Text>
            <Text fontSize="sm">Você ainda não adicionou arquivos neste usuário.</Text>
          </Box>
        </Center>
      )}

      <FileManager.Library isOpen={isOpen} onClose={onClose} onFinish={handleFinish} />
    </>
  );
};

export default Attachments;
