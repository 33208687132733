import React, { useCallback, useContext, useState } from "react";
import { Flex, Box, Icon, Center, Text, Divider, IconButton, HStack } from "@chakra-ui/react";
import _ from "lodash";
import { FiTrash } from "react-icons/fi";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { BsSlashSquare } from "react-icons/bs";
import * as Toolbars from "../toolbars";
import { BuilderContext } from "..";

export const Toolbar = () => {
  const { currentElementPrototype, formData, setFormData, currentElement, setCurrentElement, searchElementPath } =
    useContext(BuilderContext);
  const [isVisible, setIsVisible] = useState(true);

  const handleChangeElement = useCallback(
    (data) => {
      const path = searchElementPath(formData.elements, data);
      const elements = [...formData.elements];
      _.set(elements, path, data);
      setFormData((state) => ({ ...state, elements }));
      setCurrentElement(data);
    },
    [searchElementPath, setCurrentElement, setFormData, formData.elements]
  );

  const handleRemoveElement = useCallback(() => {
    if (currentElement.key === "canvas") return;
    const elements = [...formData.elements];
    const path = searchElementPath(formData.elements, currentElement);
    _.unset(elements, path);
    setFormData((state) => ({ ...state, elements }));
    setCurrentElement({});
  }, [searchElementPath, setCurrentElement, setFormData, formData.elements, currentElement]);

  const renderToolbar = () => {
    if (!currentElement.elementType) return;
    const Element = Toolbars[currentElement.elementType];
    return <Element data={currentElement} onChange={handleChangeElement} />;
  };

  return (
    <Flex direction="column" width={isVisible ? "200px" : "auto"}>
      <HStack p="10px" justifyContent="space-between">
        {isVisible && currentElement?.key && (
          <HStack>
            <IconButton
              size="xs"
              colorScheme="red"
              icon={<Icon as={FiTrash} />}
              onClick={() => handleRemoveElement()}
              disabled={currentElement.key === "canvas"}
            />
            <Text fontSize="sm" fontWeight="bold" textAlign="center">
              {currentElementPrototype?.title}
            </Text>
          </HStack>
        )}
        <IconButton
          size="xs"
          icon={<Icon as={isVisible ? RiMenuUnfoldLine : RiMenuFoldLine} />}
          onClick={() => setIsVisible((state) => !state)}
        />
      </HStack>
      <Divider />
      {isVisible && (
        <>
          {currentElement?.key ? (
            <Box flex={1} overflowY="auto" overflowX="hidden" p="20px">
              {renderToolbar()}
            </Box>
          ) : (
            <Center p="20px" flex={1}>
              <Box textAlign="center">
                <Icon as={BsSlashSquare} boxSize="60px" marginBottom="10px" />
                <Text fontSize="sm" fontWeight="bold">
                  Nenhum elemento selecionado
                </Text>
                <Text fontSize="xs">Selecione um elemento para visualizar as opções de edição disponíveis.</Text>
              </Box>
            </Center>
          )}
        </>
      )}
    </Flex>
  );
};
