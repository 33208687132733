import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "consts";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR";
import moment from "moment";
import "moment/locale/pt-br";
import "./style.css";

import SplashProvider from "SplashProvider";
const AppProvider = lazy(() => import("./AppProvider"));

registerLocale("pt-br", ptBr);
setDefaultLocale("pt-br");
moment.locale("pt-br");

export const __DEV__ = process.env.NODE_ENV === "development";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <SplashProvider>
      <Suspense>
        <AppProvider />
      </Suspense>
    </SplashProvider>
  </ChakraProvider>
);
