import React, { createContext, useMemo, useCallback, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import ObjectId from "bson-objectid";
import { api } from "lib";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [timestamp, setTimestamp] = useState(Date.now());
  const currentUser = useMemo(() => {
    try {
      const token = localStorage.getItem("@token");
      return jwt_decode(token);
    } catch (error) {
      return null;
    }
  }, [timestamp]);
  const deviceUUID = useMemo(() => {
    const storaged = localStorage.getItem("@device-uuid");
    if (storaged) return storaged;
    const uuid = ObjectId().toHexString();
    localStorage.setItem("@device-uuid", uuid);
    return uuid;
  }, []);

  const fetchUsersWithEmail = useCallback((email) => {
    return api.get("/sign-in", { params: { email } });
  }, []);

  const sendVerificationCode = useCallback((_id) => {
    return api.put(`/sign-in/${_id}`);
  }, []);

  const signIn = useCallback(async (_id, verificationCode) => {
    const token = await api.post(`/sign-in/${_id}`, { verificationCode });
    localStorage.setItem("@token", token);
    setTimestamp(Date.now());
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem("@token");
    setTimestamp(Date.now());
  }, []);

  useEffect(() => {
    if (currentUser) {
      const __v = currentUser.__v || 0;
      if (__v < 1) signOut();
    }
  }, [currentUser, signOut]);

  return (
    <AuthContext.Provider value={{ currentUser, deviceUUID, fetchUsersWithEmail, sendVerificationCode, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
