import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { Link, Image } from "@chakra-ui/react";
import ImgFallbackEmpty from "assets/imgs/empty.jpg";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    render: ({ nid }) => nid,
  },
  {
    accessor: "mainImg",
    title: "Imagem",
    isVisible: true,
    render: ({ mainImg }) => (
      <Image
        boxSize="50px"
        objectFit="cover"
        src={mainImg?.thumb}
        fallbackSrc={ImgFallbackEmpty}
        alt={mainImg?.name}
        borderWidth="1px"
        borderRadius="md"
      />
    ),
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    render: ({ _id, title }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {title}
      </Link>
    ),
  },
  {
    accessor: "brand",
    title: "Marca",
    isVisible: true,
    render: ({ brand }) => brand,
  },
  {
    accessor: "originalRequiredScore",
    title: "Pts original",
    isVisible: true,
    render: ({ originalRequiredScore }) => originalRequiredScore?.toLocaleString(),
  },
  {
    accessor: "currentRequiredScore",
    title: "Pts atual",
    isVisible: true,
    render: ({ currentRequiredScore }) => currentRequiredScore?.toLocaleString(),
  },
  {
    accessor: "isFavorite",
    title: "Favorito?",
    isVisible: true,
    render: ({ isFavorite }) => (isFavorite ? "Sim" : "Não"),
  },
  {
    accessor: "isActive",
    title: "Ativo?",
    isVisible: true,
    render: ({ isActive }) => (isActive ? "Sim" : "Não"),
  },
  {
    accessor: "createdAt",
    title: "Criação",
    isVisible: true,
    render: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualização",
    isVisible: true,
    render: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
