import React, { useState, useMemo, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import * as yup from "yup";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet, useCustomToast } from "hooks";
import { MdAdd, MdEdit, MdDelete, MdRefresh, MdClose } from "react-icons/md";
import ProductGroupsDetailsContext from "./context";

const Subgroups = () => {
  const { _id } = useParams();
  const { formData: parentData } = useContext(ProductGroupsDetailsContext);
  const [formData, setFormData] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(
      () => ({
        path: "/product-groups",
        params: { query: { parent: _id }, sort: { title: 1 }, perPage: -1 },
        options: { isEnabled: !!_id },
      }),
      [_id]
    )
  );
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const [selectedDeleteData, setSelectedDeleteData] = useState(null);
  const toast = useCustomToast();

  const handleSaveData = useCallback(async (data) => {
    try {
      setIsLoadingSaveData(true);
      data._id ? await api.put(`/product-groups/${data._id}`, data) : await api.post(`/product-groups`, data);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      setFormData();
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
      });
      const data = { ...formData, parent: _id };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  }, [_id, formData, handleSaveData]);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete("/product-groups", { data: [selectedDeleteData._id] });
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: messages.error.deleteData, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      setSelectedDeleteData(null);
    }
  }, [selectedDeleteData?._id]);

  if (!_id) return null;

  return (
    <>
      <HStack mb="20px">
        <Heading size="md" flex="1">
          Subgrupos
        </Heading>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
        <Button size="sm" colorScheme="main" isLoading={isLoadingData} onClick={() => setFormData({ isActive: true })}>
          incluir subgrupo
        </Button>
      </HStack>

      {data?.size === 0 && (
        <Box textAlign="center" p="30px">
          <Icon as={MdAdd} boxSize={20} marginBottom="10px" />
          <Text fontSize="lg" fontWeight="bold">
            Nenhum subgrupo cadastrado
          </Text>
          <Text mb="20px" fontSize="sm">
            Este grupo ainda não possui subgrupos cadastrados. Clique no botão abaixo para realizar o primeiro cadastro.
          </Text>
          <Button size="sm" colorScheme="main" onClick={() => setFormData({ isActive: true })}>
            incluir subgrupo
          </Button>
        </Box>
      )}

      <VStack align="stretch" divider={<StackDivider />}>
        {(data?.data ?? []).map((item) => (
          <HStack key={item._id} justify="space-between" py="10px">
            <Box opacity={item.isActive ? "1" : "0.5"}>
              <HStack>
                <Text fontSize="sm" fontWeight="semibold">
                  {item.title}
                </Text>
                {!item.isActive && <Badge>Inativo</Badge>}
              </HStack>
              <Text fontSize="xs">Criado em {moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")}</Text>
            </Box>
            <HStack>
              <Button rightIcon={<Icon as={MdDelete} />} size="sm" variant="outline" onClick={() => setSelectedDeleteData(item)}>
                excluir
              </Button>
              <Button rightIcon={<Icon as={MdEdit} />} size="sm" variant="outline" onClick={() => setFormData(item)}>
                editar
              </Button>
            </HStack>
          </HStack>
        ))}
      </VStack>

      <Modal isOpen={formData} onClose={() => setFormData()} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Text>{formData?._id ? formData.title : `Novo(a) subgrupo`}</Text>
            <HStack>
              <FormControl display="flex" alignItems="center">
                <FormLabel fontSize="sm" mb="0">
                  Ativo?
                </FormLabel>
                <Switch
                  colorScheme="main"
                  isChecked={formData?.isActive}
                  onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
                />
              </FormControl>
            </HStack>
          </ModalHeader>
          <ModalBody>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData?.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" isDisabled={isLoadingSaveData} onClick={() => setFormData()}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
              {formData?._id ? "salvar" : "adicionar"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={selectedDeleteData} onClose={() => setSelectedDeleteData()} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>{messages.warning.deleteDataConfirm}</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={() => setSelectedDeleteData()}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default Subgroups;
