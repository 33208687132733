import React, { useContext, useRef, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import { currency, translator, percent, masks } from "lib";
import { AuthContext } from "AuthProvider";
import { MdClose, MdPrint } from "react-icons/md";
import PackMovementsDetailsContext from "./context";

const Row = ({ children, ...props }) => {
  return (
    <Table size="sm" borderCollpase="collapse" borderWidth="1px" borderColor="black" borderBottomWidth="0" {...props}>
      <Tbody>{children}</Tbody>
    </Table>
  );
};

const Column = ({ children, ...props }) => {
  return (
    <Td borderWidth="1px" borderColor="black" borderBottomWidth="0" p="4px" {...props}>
      {children}
    </Td>
  );
};

const PrintDetailing = ({ children, isOpen, onClose }) => {
  const { data } = useContext(PackMovementsDetailsContext);
  const { colorMode } = useColorMode();
  const colorScheme = useMemo(() => (colorMode === "light" ? "blackAlpha" : "whiteAlpha"[colorMode]));
  const bodyRef = useRef();

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="flex-end" position="fixed" top="0" left="0" w="100%" py="10px" px="20px">
          <ReactToPrint
            content={() => bodyRef.current}
            trigger={() => <IconButton colorScheme={colorScheme} icon={<Icon as={MdPrint} />} />}
          />
          <IconButton colorScheme={colorScheme} icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <ModalBody ref={bodyRef}>
          <Row bg="blackAlpha.100">
            <Column pl="20%">
              <Heading size="md" textAlign="center">
                Detalhamento da movimentação de pacote
              </Heading>
            </Column>
            <Column w="20%" whiteSpace="nowrap">
              <Text fontSize="xs">Data de impressão</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {moment().format("DD/MM/YYYY [às] HH:mm:ss")}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text fontSize="xs">NID</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.nid}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Tipo</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {translator(data?.type ?? "-")}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Pacote de origem</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.source?.nid ?? "-"}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Pacote de destino</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.destination?.nid ?? "-"}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text fontSize="xs">Autor</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.author?.name ?? "-"}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Status</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {translator(data?.status ?? "-")}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Tipo de preço</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {translator(data?.priceType ?? "-")}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text fontSize="xs">Mercadorias</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.quantity?.toLocaleString?.() ?? 0}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Valor</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {currency.format(data?.amount ?? 0)}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Linhas</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.merchandises?.length ?? 0}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text fontSize="xs">Criado em</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {moment(data?.createdAt).format("D, MMMM yyyy [às] HH:mm")}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Atualizado em</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {moment(data?.updatedAt).format("D, MMMM yyyy [às] HH:mm")}
              </Text>
            </Column>
            <Column>
              <Text fontSize="xs">Fechado em</Text>
              <Text fontSize="xs" fontWeight="semibold">
                {data?.closedAt ? moment(data?.closedAt).format("D, MMMM yyyy [às] HH:mm") : "-"}
              </Text>
            </Column>
          </Row>

          <Row>
            <Column bg="blackAlpha.100">
              <Heading size="md" textAlign="center">
                Mercadorias
              </Heading>
            </Column>
          </Row>
          <table className="print-detailing">
            <thead>
              <tr>
                <th>SKU</th>
                <th>NID</th>
                <th>Nome</th>
                <th>Qtd</th>
                <th>Valor un.</th>
                <th>Valor total</th>
              </tr>
            </thead>
            <tbody>
              {_.map(data?.merchandises, (item) => (
                <tr>
                  <td>{item.product.sku}</td>
                  <td>{item.product.nid}</td>
                  <td>{item.product.name}</td>
                  <td>{item.quantity.toLocaleString()}</td>
                  <td>{currency.format(item.amount)}</td>
                  <td>{currency.format(item.amount * item.quantity)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <Row borderTopWidth="0">
            <Column borderTopWidth="0" bg="blackAlpha.100">
              <Heading size="md" textAlign="center">
                Observações
              </Heading>
            </Column>
          </Row>
          <Row>
            <Column h="150px" />
          </Row>
          <Divider borderColor="black" />

          <HStack alignItems="flex-start" spacing="20px" px="40px" pt="120px" pb="50px">
            {data?.agent && (
              <VStack flex="1" spacing="0" fontSize="sm">
                <Divider borderBottomWidth="2px" borderColor="black" />
                <Text fontSize="xs">Representante</Text>
                <Text fontSize="xs" fontWeight="semibold">
                  {data?.agent.name}
                </Text>
                <Text fontSize="xs">CPF/CNPJ: {masks.document(data?.agent.document)}</Text>
              </VStack>
            )}
            {data?.dealer && (
              <VStack flex="1" spacing="0" fontSize="sm">
                <Divider borderBottomWidth="2px" borderColor="black" />
                <Text fontSize="xs">Revendedor</Text>
                <Text fontSize="xs" fontWeight="semibold">
                  {data?.dealer.name}
                </Text>
                <Text fontSize="xs">CPF/CNPJ: {masks.document(data?.dealer.document)}</Text>
              </VStack>
            )}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PrintDetailing;
