import React, { useState, useImperativeHandle, forwardRef, useCallback, useEffect, useContext, useMemo } from "react";
import _ from "lodash";
import * as yup from "yup";
import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
} from "@chakra-ui/react";
import { paymentMethods } from "consts";
import { translator, currency } from "lib";
import { messages } from "consts";
import { InputCurrency } from "components";
import { AuthContext } from "AuthProvider";
import { MdAdd, MdRefresh } from "react-icons/md";
import SettlementsContext from "./context";
import PacksDetailsContext from "../context";

const Remaining = forwardRef((props, ref) => {
  const { currentUser } = useContext(AuthContext);
  const { formData: currentPack } = useContext(PacksDetailsContext);
  const {
    wallets,
    isLoadingWallets,
    refreshWallets,
    paymentCategories,
    isLoadingPaymentCategories,
    refreshPaymentCategories,
    setReceivedAmount,
  } = useContext(SettlementsContext);
  const [formData, setFormData] = useState({ receivedAmount: 0 });
  const [formErrors, setFormErrors] = useState({});
  const isHidden = useMemo(() => formData.receivableAmount === 0, [formData.receivableAmount]);

  useEffect(() => {
    const receivableAmount = _.round(currentPack.receivableAmount - currentPack.receivedAmount, 2);
    setFormData((state) => ({ ...state, receivableAmount }));
  }, [currentPack.receivableAmount, currentPack.receivedAmount]);

  useEffect(() => {
    const receivedAmount = _.round(formData.receivedAmount + currentPack.receivedAmount, 2);
    setReceivedAmount(receivedAmount);
  }, [formData.receivedAmount, currentPack.receivedAmount]);

  const submit = useCallback(async () => {
    try {
      if (isHidden) return;
      const schema = yup.object().shape({
        paymentMethod: yup.string().required(messages.error.required),
        wallet: yup.string().required(messages.error.required),
        paymentCategory: yup.string().required(messages.error.required),
        receivedAmount: yup
          .number()
          .max(formData.receivableAmount, `${messages.error.lessOrEqual} ${formData.receivableAmount.toLocaleString()}`)
          .required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
      throw error;
    }
  }, [isHidden, formData]);

  const save = useCallback(() => {
    if (formData.receivedAmount === 0) return;
    const object = {
      type: "revenue",
      status: "paid",
      wallet: formData.wallet,
      paymentCategory: formData.paymentCategory,
      paymentMethod: formData.paymentMethod,
      user: currentPack.dealer,
      issueDate: new Date(),
      dueDate: new Date(),
      paymentDate: new Date(),
      items: [{ type: "charge", description: "Pagamento de pacote", amount: formData.receivedAmount }],
      receivableAmount: formData.receivedAmount,
      receivedAmount: formData.receivedAmount,
      remainingAmount: 0,
      agent: currentUser._id,
      pack: { _id: currentPack._id, nid: currentPack.nid },
      company: currentPack.company,
    };
    return object;
  }, [currentUser, currentPack, formData]);

  useImperativeHandle(ref, () => ({ submit, save }), [submit, save]);

  if (isHidden) return null;

  return (
    <Box p="20px" borderWidth="1px" borderRadius="lg">
      <HStack space="10px" mb="20px">
        <Center bg="green.500" w="30px" h="30px" borderRadius="lg">
          <Icon as={MdAdd} color="white" />
        </Center>
        <Box>
          <Text fontSize="sm">Receber o revendedor</Text>
          <Text fontSize="lg" fontWeight="semibold">
            {currency.format(formData.receivableAmount ?? 0)}
          </Text>
        </Box>
      </HStack>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.paymentMethod}>
            <FormLabel fontSize="sm">Método</FormLabel>
            <Select
              value={formData.paymentMethod ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, paymentMethod: target.value }))}
            >
              <option>--Selecione</option>
              {_.map(paymentMethods, (value) => (
                <option key={value} value={value}>
                  {translator(value)}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formErrors.paymentMethod}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.wallet}>
            <FormLabel fontSize="sm">Carteira</FormLabel>
            <HStack>
              <Select value={formData.wallet ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, wallet: target.value }))}>
                <option>--Selecione</option>
                {_.map(wallets?.data, ({ _id, title }) => (
                  <option key={_id} value={_id}>
                    {title}
                  </option>
                ))}
              </Select>
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingWallets} onClick={refreshWallets} />
            </HStack>
            <FormErrorMessage>{formErrors.wallet}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.paymentCategory}>
            <FormLabel fontSize="sm">Categoria de pagamento</FormLabel>
            <HStack>
              <Select
                value={formData.paymentCategory ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, paymentCategory: target.value }))}
              >
                <option>--Selecione</option>
                {_.map(paymentCategories.revenue, ({ _id, title }) => (
                  <option key={_id} value={_id}>
                    {title}
                  </option>
                ))}
              </Select>
              <IconButton
                variant="outline"
                icon={<Icon as={MdRefresh} />}
                isLoading={isLoadingPaymentCategories}
                onClick={refreshPaymentCategories}
              />
            </HStack>
            <FormErrorMessage>{formErrors.paymentCategory}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.receivedAmount}>
            <FormLabel fontSize="sm">Valor</FormLabel>
            <InputGroup>
              <InputLeftAddon>R$</InputLeftAddon>
              <Input
                as={InputCurrency}
                value={formData.receivedAmount}
                onChange={(receivedAmount) => setFormData((state) => ({ ...state, receivedAmount }))}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.receivedAmount}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </Box>
  );
});

export default Remaining;
