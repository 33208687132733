import React from "react";
import ReactQRCode from "qrcode.react";
import { Text } from "@chakra-ui/react";

export const QRCode = ({ children, size, ...props }) => {
  return (
    <Text p="1px" {...props}>
      <ReactQRCode value={children} size={size} renderAs="svg" />
    </Text>
  );
};
