import React, { useContext, useCallback } from "react";
import _ from "lodash";
import { Box, Center, HStack, Icon, Button, Text, useDisclosure } from "@chakra-ui/react";
import { MdAdd, MdImage } from "react-icons/md";
import { FileManager } from "components";
import { RewardsDetailsContext } from "./index";

const Images = () => {
  const { formData, setFormData } = useContext(RewardsDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleFinish = useCallback(
    (selecteds) => {
      setFormData((state) => {
        const imgs = _.map(state.imgs);
        const _ids = imgs.map((o) => o._id);
        for (const selected of selecteds) {
          if (_ids.indexOf(selected._id) === -1) imgs.push(selected);
        }
        return { ...state, imgs };
      });
    },
    [setFormData]
  );

  return (
    <>
      <HStack mb="20px">
        <Box pr="20px" flex="1">
          <Text fontSize="sm">
            Arraste as imagens para realizar a ordenação. A primeira imagem será considerada como imagem principal do produto.
          </Text>
        </Box>
        <Button rightIcon={<Icon as={MdAdd} />} colorScheme="main" size="sm" onClick={onOpen}>
          incluir imagens
        </Button>
      </HStack>

      <FileManager.List data={formData.imgs} onChange={(imgs) => setFormData((state) => ({ ...state, imgs }))} />

      {_.size(formData?.imgs) === 0 && (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={MdImage} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhuma imagem adicionada
            </Text>
            <Text fontSize="sm">Você ainda não adicionou imagens a este produto.</Text>
          </Box>
        </Center>
      )}

      <FileManager.Library type="image" isOpen={isOpen} onClose={onClose} onFinish={handleFinish} />
    </>
  );
};

export default Images;
