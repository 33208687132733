import React from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdClose, MdRefresh } from "react-icons/md";
import { TbTags } from "react-icons/tb";
import ImgFallback from "assets/imgs/empty.jpg";

export const MerchandisesList = ({ data, isOpen, onClose, onRefresh, isLoading }) => {
  return (
    <Drawer size="md" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader as={HStack}>
          <Text flex="1">Mercadorias</Text>
          {onRefresh && <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} onClick={onRefresh} isLoading={isLoading} />}
          <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
        </DrawerHeader>
        <Divider />
        <DrawerBody>
          <VStack align="stretch" py="10px">
            {_.map(data, (item) => (
              <HStack key={item.product._id} justify="space-between">
                <HStack>
                  <Image
                    borderRadius="md"
                    boxSize="50px"
                    src={item.product.mainImg?.location}
                    fallbackSrc={ImgFallback}
                    borderWidth="1px"
                    borderStyle="solid"
                  />
                  <Box>
                    <HStack lineHeight="1" divider={<Text px="5px">•</Text>}>
                      <Text fontSize="xs">NID {item.product.nid}</Text>
                      <Text fontSize="xs">SKU {item.product.sku}</Text>
                    </HStack>
                    <Link as={RouterLink} to={`/products/edit/${item.product._id}`} target="_blank" rel="noopener noreferrer">
                      <Text fontWeight="semibold">{item.product.name}</Text>
                    </Link>
                  </Box>
                </HStack>
                <Text fontSize="sm" fontWeight="bold">
                  {item.quantity?.toLocaleString() ?? 0}
                </Text>
              </HStack>
            ))}
          </VStack>

          {_.size(data) === 0 && (
            <Center flexDirection="column" pb="30px">
              <Icon as={TbTags} boxSize="80px" color="gray.200" mb="10px" />
              <Heading textAlign="center" size="md">
                Nenhuma mercadoria
              </Heading>
              <Text textAlign="center" fontSize="sm">
                Este documento não possui mercadorias adicionadas.
              </Text>
            </Center>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
