import React, { createContext, forwardRef, memo, useCallback, useContext, useMemo } from "react";
import { CheckboxGroup, Checkbox } from "@chakra-ui/react";

const CheckboxContext = createContext();

export const CheckboxHeader = memo(() => {
  const { checks, checkeds, onChange } = useContext(CheckboxContext);
  const allChecked = useMemo(() => checks.length > 0 && checks.length === checkeds.length, [checks, checkeds]);
  const isIndeterminate = useMemo(() => checkeds.length > 0 && !allChecked, [checkeds, allChecked]);

  const handleHeadCheck = useCallback(() => {
    onChange(checkeds.length !== checks.length ? checks : []);
  }, [checkeds, checks, onChange]);

  return <Checkbox colorScheme="main" isChecked={allChecked} isIndeterminate={isIndeterminate} onChange={handleHeadCheck} />;
});

export const CheckboxBody = memo(({ value, isDisabled }) => {
  const { checkeds, onChange } = useContext(CheckboxContext);

  const handleCheck = useCallback(
    ({ target }) => {
      const tmp = [...checkeds];
      const index = tmp.findIndex((id) => id === target.value);
      if (index === -1) tmp.push(target.value);
      else tmp.splice(index, 1);
      onChange(tmp);
    },
    [checkeds, onChange]
  );

  return <Checkbox colorScheme="main" value={value} onChange={handleCheck} isDisabled={isDisabled} />;
});

export const CheckboxProvider = forwardRef(({ children, checks, checkeds, onChange }, ref) => {
  return (
    <CheckboxContext.Provider value={{ checks, checkeds, onChange }}>
      <CheckboxGroup value={checkeds}>{children}</CheckboxGroup>
    </CheckboxContext.Provider>
  );
});
