import React, { memo } from "react";
import { Text, VStack, Box } from "@chakra-ui/react";
import { formatBytes } from "lib";
import { Image } from "../components";

export const ImagePreview = memo(({ data, isSelected, onSelect }) => (
  <Box
    mb={0}
    onClick={() => onSelect?.(data)}
    bg={isSelected && "main.50"}
    color={isSelected && "main.500"}
    cursor={onSelect && "pointer"}
    overflow="hidden"
    borderWidth="1px"
    borderRadius="lg"
  >
    <Image type={data.type} title={data.title} src={data.thumb} width="100%" height="150px" />
    <VStack align="stretch" p="10px" spacing={0}>
      <Text fontSize="xs" fontWeight="bold" noOfLines={1}>
        {data.title}
      </Text>
      <Text fontSize="xs">{formatBytes(data.size)}</Text>
    </VStack>
  </Box>
));
