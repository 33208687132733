import React, { useEffect, useContext, useState, useCallback } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToken,
} from "@chakra-ui/react";
import { EventEmitter } from "lib";
import { MdClose } from "react-icons/md";
import { HiOutlineDocumentText } from "react-icons/hi";
import { AuthContext } from "AuthProvider";

export const RequiredContractError = () => {
  const { currentUser, signOut } = useContext(AuthContext);
  const [error, setError] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    EventEmitter.subscribe("onRequiredContractError", (error) => setError(error));
  }, []);

  const handleNavigate = useCallback(() => {
    navigate("/contracts");
    setError();
  }, []);

  return (
    <Modal isOpen={error?.message} onClose={() => setError()} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" justifyContent="center" alignItems="center" padding="30px">
          <Center _light={{ bg: "main.500" }} _dark={{ bg: "main.300" }} w="150px" h="150px" borderRadius="full" mb="20px">
            <Icon as={HiOutlineDocumentText} boxSize="80px" color="white" />
          </Center>
          <Heading size="lg">Contrato pendente</Heading>
          <Text fontSize="sm" textAlign="center" mb="20px">
            {error?.message}
          </Text>
          {currentUser.isProprietary ? (
            <Button variant="outline" onClick={handleNavigate}>
              visualizar contratos
            </Button>
          ) : (
            error?.data?.email && (
              <Button as={Link} href={`mailto:${error.data.email}`} variant="outline">
                contatar {error.data.email}
              </Button>
            )
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
