import React, { useContext } from "react";
import { FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Textarea } from "@chakra-ui/react";
import PackMovementsDetailsContext from "./context";

const Comments = () => {
  const { formData, setFormData, formErrors } = useContext(PackMovementsDetailsContext);

  return (
    <>
      <Grid templateColumns="repeat(1, 1fr)" gap={4} mb={4}>
        <GridItem>
          <FormControl isInvalid={formErrors.comments}>
            <FormLabel fontSize="sm">Observações</FormLabel>
            <Textarea
              value={formData.comments ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
            />
            <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </>
  );
};

export default Comments;
