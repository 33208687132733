import React, { useMemo, useCallback, useContext, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { MdAddCircleOutline, MdAttachMoney, MdClose, MdOutlineDelete, MdRefresh } from "react-icons/md";
import { api, currency, translator } from "lib";
import { useApiGet, useArrayItemHandlers } from "hooks";
import { AsyncSelect, DatePicker, InputCurrency, BoxData } from "components";
import { PaymentsDetailsContext } from "./index";

let loadUsersTimeout = null;

const BadgeType = ({ type }) => {
  const colorScheme = useMemo(() => {
    switch (type) {
      case "charge":
        return "green";
      case "discount":
        return "red";
      default:
        return "purple";
    }
  }, [type]);
  return <Badge colorScheme={colorScheme}>{translator(type)}</Badge>;
};

const General = () => {
  const { formData, setFormData, formErrors, isPaid, onOpenAddItemDialog } = useContext(PaymentsDetailsContext);
  const [wallets, isLoadingWallets, refreshWallets] = useApiGet(useMemo(() => ({ path: "/wallets" }), []));
  const [paymentCategoriesData, isLoadingPaymentCategories, refreshPaymentCategories] = useApiGet(
    useMemo(() => ({ path: "/payment-categories" }), [])
  );
  const paymentCategories = useMemo(() => {
    return _.filter(paymentCategoriesData?.data, (o) => o.type === formData.type);
  }, [paymentCategoriesData, formData.type]);
  const { handleDeleteArrayItem } = useArrayItemHandlers(setFormData);

  useEffect(() => {
    const mapped = _.map(formData.items, "amount");
    const receivableAmount = _.round(_.sum(mapped), 2);
    setFormData((state) => ({ ...state, receivableAmount }));
  }, [formData.items, setFormData]);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.get("/users", { params: { search, perPage: 20 } });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={3}>
          <FormControl isRequired={true} isInvalid={formErrors.type}>
            <FormLabel fontSize="sm">Tipo</FormLabel>
            <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
              <option value="">--Selecione</option>
              <option value="revenue">Receita</option>
              <option value="expense">Despesa</option>
            </Select>
            <FormErrorMessage>{formErrors.type}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl isRequired={true} isInvalid={formErrors.status}>
            <FormLabel fontSize="sm">Status</FormLabel>
            <Select value={formData.status ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
              <option value="">--Selecione</option>
              <option value="paid">Pago</option>
              <option value="pending">Pendente</option>
              <option value="pending_review" disabled>
                Revisão pendente
              </option>
            </Select>
            <FormErrorMessage>{formErrors.status}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={6}>
          <FormControl isRequired={true} isInvalid={formErrors.user}>
            <FormLabel fontSize="sm">Usuário</FormLabel>
            <AsyncSelect
              isInvalid={formErrors.user}
              value={formData.user}
              defaultOptions
              loadOptions={handleLoadUsers}
              placeholder="Selecione o usuário"
              onChange={(user) => setFormData((state) => ({ ...state, user }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name }) => name}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.user}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={4}>
          <FormControl isRequired={true} isInvalid={formErrors.paymentMethod}>
            <FormLabel fontSize="sm">Método de pagamento</FormLabel>
            <Select
              value={formData.paymentMethod ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, paymentMethod: target.value }))}
            >
              <option value="">--Selecione</option>
              <option value="pix">Pix</option>
              <option value="credit_card">Cartão de crédito</option>
              <option value="debit_card">Cartão de débito</option>
              <option value="boleto">Boleto</option>
              <option value="deposit">Depósito bancário</option>
              <option value="cash">Dinheiro</option>
            </Select>
            <FormErrorMessage>{formErrors.paymentMethod}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl isRequired={true} isInvalid={formErrors.wallet}>
            <FormLabel fontSize="sm">Carteira</FormLabel>
            <HStack>
              <Select value={formData.wallet ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, wallet: target.value }))}>
                <option value="">--Selecione</option>
                {_.map(wallets?.data, (o) => (
                  <option key={o._id} value={o._id}>
                    {o.title}
                  </option>
                ))}
              </Select>
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingWallets} onClick={refreshWallets} />
            </HStack>
            <FormErrorMessage>{formErrors.wallet}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl isRequired={true} isInvalid={formErrors.paymentCategory}>
            <FormLabel fontSize="sm">Categoria de pagamento</FormLabel>
            <HStack>
              <Select
                value={formData.paymentCategory ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, paymentCategory: target.value }))}
              >
                <option value="">--Selecione</option>
                {_.map(paymentCategories, (o) => (
                  <option key={o._id} value={o._id}>
                    {o.title}
                  </option>
                ))}
              </Select>
              <IconButton
                variant="outline"
                icon={<Icon as={MdRefresh} />}
                isLoading={isLoadingPaymentCategories}
                onClick={refreshPaymentCategories}
              />
            </HStack>
            <FormErrorMessage>{formErrors.paymentCategory}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
        <GridItem colSpan={4}>
          <FormControl isRequired={true} isInvalid={formErrors.issueDate}>
            <FormLabel fontSize="sm">Data de emissão</FormLabel>
            <DatePicker
              selected={formData.issueDate}
              onChange={(issueDate) => setFormData((state) => ({ ...state, issueDate: moment(issueDate).startOf("day").toDate() }))}
              dateFormat="d, MMMM yyyy"
            />
            <FormErrorMessage>{formErrors.issueDate}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl isRequired={true} isInvalid={formErrors.dueDate}>
            <FormLabel fontSize="sm">Data de vencimento</FormLabel>
            <DatePicker
              selected={formData.dueDate}
              onChange={(dueDate) => setFormData((state) => ({ ...state, dueDate: moment(dueDate).startOf("day").toDate() }))}
              dateFormat="d, MMMM yyyy"
            />
            <FormErrorMessage>{formErrors.dueDate}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl isRequired={isPaid} isInvalid={formErrors.paymentDate}>
            <BoxData label="Data de pagamento" value={isPaid ? moment(formData.paymentDate).format("D, MMMM yyyy") : "-"} />
            <FormErrorMessage>{formErrors.paymentDate}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider />

      <HStack justifyContent="space-between" mt={8} mb={6}>
        <Box>
          <Heading size="sm">Itens de cobrança</Heading>
          {formErrors.items && (
            <Text color="red.500" fontSize="sm">
              {formErrors.items}
            </Text>
          )}
        </Box>
        <Button size="sm" colorScheme="main" leftIcon={<Icon as={MdAddCircleOutline} />} onClick={onOpenAddItemDialog}>
          adicionar item
        </Button>
      </HStack>

      <Table size="sm" variant="striped">
        <Thead>
          <Tr>
            <Th>Índice</Th>
            <Th>Tipo</Th>
            <Th w="100%">Descrição</Th>
            <Th isNumeric={true}>Valor</Th>
            <Th>Criação</Th>
            <Th>#</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(formData.items, (item, index) => (
            <Tr key={index.toString()}>
              <Td>
                <Badge>{(index + 1).toString().padStart(2, "0")}</Badge>
              </Td>
              <Td>
                <BadgeType type={item.type} />
              </Td>
              <Td>{item.description}</Td>
              <Td whiteSpace="nowrap">{currency.format(item.amount)}</Td>
              <Td whiteSpace="nowrap">{moment(item.createdAt).format("DD/MM/YYYY [às] HH:mm:ss")}</Td>
              <Td>
                <Button
                  size="sm"
                  variant="outline"
                  rightIcon={<Icon as={MdOutlineDelete} />}
                  onClick={() => handleDeleteArrayItem("items", index)}
                >
                  deletar
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {_.size(formData.items) === 0 && (
        <Center mt={8}>
          <Box w="300px">
            <Center mb="10px">
              <Icon as={MdAttachMoney} boxSize="80px" color="gray.200" />
            </Center>
            <Heading textAlign="center" size="md">
              Nenhum item adicionado
            </Heading>
            <Text textAlign="center" fontSize="sm">
              Você ainda não adicionou itens de cobrança neste pagamento.
            </Text>
            <Center mt="20px">
              <Button size="sm" colorScheme="main" leftIcon={<Icon as={MdAddCircleOutline} />} onClick={onOpenAddItemDialog}>
                adicionar item
              </Button>
            </Center>
          </Box>
        </Center>
      )}

      <Divider mt={8} />

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mt={8} mb={4}>
        <GridItem colSpan={4}>
          <FormControl isRequired={true} isInvalid={formErrors.receivableAmount}>
            <BoxData label="Valor recebível" value={currency.format(formData.receivableAmount || 0)} />
            <FormErrorMessage>{formErrors.receivableAmount}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl isRequired={true} isInvalid={formErrors.remainingAmount}>
            <BoxData label="Valor pendente" value={currency.format(formData.remainingAmount || 0)} />
            <FormErrorMessage>{formErrors.remainingAmount}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          {formData.isClosed ? (
            <FormControl isRequired={true} isInvalid={formErrors.receivedAmount}>
              <BoxData label="Valor recebido" value={currency.format(formData.receivedAmount || 0)} />
              <FormErrorMessage>{formErrors.receivedAmount}</FormErrorMessage>
            </FormControl>
          ) : (
            <FormControl isRequired={true} isInvalid={formErrors.receivedAmount}>
              <FormLabel fontSize="sm">Valor recebido</FormLabel>
              <InputGroup>
                <InputLeftAddon>R$</InputLeftAddon>
                <Input
                  as={InputCurrency}
                  value={formData.receivedAmount ?? ""}
                  onChange={(receivedAmount) => setFormData((state) => ({ ...state, receivedAmount }))}
                  isDisabled={!isPaid}
                />
              </InputGroup>
              <FormErrorMessage>{formErrors.receivedAmount}</FormErrorMessage>
            </FormControl>
          )}
        </GridItem>
      </Grid>
    </>
  );
};

export default General;
