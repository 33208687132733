import React, { useState, useCallback, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import * as yup from "yup";
import { api } from "lib";
import { messages } from "consts";
import { InputCurrency } from "components";
import { useClipboard, useCustomToast } from "hooks";
import { FaEdit } from "react-icons/fa";
import { TbPackage } from "react-icons/tb";
import { MdClose } from "react-icons/md";
import { ProductsListContext } from "./index";

const Duplicate = ({ isOpen, onClose }) => {
  const { checkeds, setCheckeds, refreshData } = useContext(ProductsListContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [duplications, setDuplications] = useState([]);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const copyToClipboard = useClipboard();
  const toast = useCustomToast();

  useEffect(() => {
    setDuplications([]);
    setFormData({ quantity: 1 });
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setCheckeds([]);
    onClose();
  }, [onClose, setCheckeds]);

  const handleSaveData = useCallback(
    async ({ quantity }) => {
      try {
        setIsLoadingSaveData(true);
        const response = await api.post(`/products/duplicate/${quantity}`, checkeds);
        setDuplications(response);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [checkeds, refreshData, toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        quantity: yup
          .number()
          .min(1, `${messages.error.greaterOrEqual} 1.`)
          .max(10, `${messages.error.lessOrEqual} 10.`)
          .required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} closeOnEsc={!isLoadingSaveData} closeOnOverlayClick={!isLoadingSaveData} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Text>{duplications.length === 0 ? "Duplicar produto" : "Duplicações"}</Text>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={handleClose} />
          </ModalHeader>
          <Divider />
          <ModalBody py="20px">
            {duplications.length === 0 ? (
              <FormControl isRequired={true} isInvalid={formErrors.quantity}>
                <FormLabel fontSize="sm">Quantidade</FormLabel>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.quantity ?? ""}
                  onChange={(quantity) => setFormData((state) => ({ ...state, quantity }))}
                />
                <FormErrorMessage>{formErrors.quantity}</FormErrorMessage>
              </FormControl>
            ) : (
              <VStack alignItems="stretch" spacing="0" divider={<StackDivider />}>
                {_.map(duplications, (item) => (
                  <HStack py="20px">
                    <Center
                      bg="main.50"
                      w="40px"
                      h="40px"
                      borderRadius="lg"
                      cursor="pointer"
                      _active={{ transform: "scale(0.9)" }}
                      onClick={() => copyToClipboard(item._id)}
                    >
                      <Icon as={TbPackage} color="main.500" boxSize="20px" />
                    </Center>
                    <Box flex="1">
                      <Text fontSize="xs">NID {item.nid}</Text>
                      <Text fontSize="sm" fontWeight="semibold">
                        {item.name}
                      </Text>
                    </Box>
                    <IconButton
                      size="sm"
                      variant="outline"
                      icon={<Icon as={FaEdit} />}
                      as={RouterLink}
                      to={`/products/edit/${item._id}`}
                      target="_blank"
                    />
                  </HStack>
                ))}
              </VStack>
            )}
          </ModalBody>
          {duplications.length === 0 && (
            <>
              <Divider />
              <ModalFooter as={HStack}>
                <Button size="sm" variant="outline" onClick={handleClose} isDisabled={isLoadingSaveData}>
                  cancelar
                </Button>
                <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
                  duplicar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Duplicate;
