import moment from "moment";
import { currency, translator } from "lib";
import { CustomBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "type",
    title: "Tipo",
    isVisible: true,
    render: ({ type }) => <CustomBadge.PackMovements.Type type={type} />,
    exporter: ({ type }) => translator(type),
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <CustomBadge.PackMovements.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "source",
    title: "Origem",
    isVisible: true,
    formatter: ({ source }) => source?.nid,
  },
  {
    accessor: "destination",
    title: "Destino",
    isVisible: true,
    formatter: ({ destination }) => destination?.nid,
  },
  {
    accessor: "priceType",
    title: "Tipo de preço",
    isVisible: true,
    formatter: ({ priceType }) => translator(priceType),
  },
  {
    accessor: "quantity",
    title: "Quantidade",
    isVisible: true,
    formatter: ({ quantity }) => quantity?.toLocaleString(),
  },
  {
    accessor: "amount",
    title: "Valor",
    isVisible: true,
    formatter: ({ amount }) => currency.format(amount),
  },
  {
    accessor: "author",
    title: "Autor",
    isVisible: true,
    formatter: ({ author }) => author?.name,
  },
  {
    accessor: "closedAt",
    title: "Fechamento",
    isVisible: true,
    formatter: ({ closedAt }) => closedAt && moment(closedAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "createdAt",
    title: "Criação",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualização",
    isVisible: false,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
