import React from "react";
import _ from "lodash";
import * as Elements from "../../elements";

const Renderer = ({ document, elements, selected, processor, onSelect }) => {
  const handleSelectElement = (e, element) => {
    e.stopPropagation();
    onSelect?.(element);
  };

  const renderElementTree = (children, elementType, isFirst) => {
    if (_.isArray(children))
      return children.map((child) => {
        const Element = Elements[child.elementType];
        const props = onSelect && {
          boxSizing: "border-box",
          cursor: "pointer",
          boxShadow: `inset 0 0 0 1px ${selected.key === child.key ? "#0099ff" : "transparent"}`,
        };
        if (isFirst) child.props.overflow = "hidden";
        return (
          <Element key={child.key} {...props} {...child.props} onClick={(e) => handleSelectElement(e, child)}>
            {renderElementTree(child.children, child.elementType, false)}
          </Element>
        );
      });
    if (processor) {
      if (["Variable", "BarCode", "QRCode"].indexOf(elementType) !== -1) {
        const variable = children.replace("[", "").replace("]", "");
        return processor?.[variable]?.(document) ?? "";
      }
    }
    return children;
  };

  return renderElementTree(elements, null, true);
};

export default Renderer;
