const terms = {
  closed: "Fechado",
  default: "Padrão",
  distributed: "Distribuído",
  expense: "Despesa",
  external: "Externo",
  ignore: "Ignorar",
  entry: "Entrada",
  internal: "Interno",
  loaded: "Carregado",
  unloaded: "Descarregado",
  lost: "Perdido",
  opened: "Aberto",
  merchandise: "Mercadoria",
  pack: "Pacote",
  exit: "Saída",
  paid: "Pago",
  pending: "Pendente",
  pending_review: "Revisão pendente",
  reset_stock: "Zerar estoque",
  returned: "Devolvido",
  revenue: "Receita",
  sold: "Vendido",
  special: "Especial",
  transferred: "Transferido",
  undistributed: "Não distribuído",
  sended: "Enviado",
  received: "Recebido",
  preparing: "Preparando",
  dispatched: "Despachado",
  grouped: "Agrupado",
  rescheduled: "Reagendado",
  canceled: "Cancelado",
  failed: "Falha",
  dissolved: "Dissolvido",
  delivered: "Entregue",
  current: "Atual",
  merchant: "Mercadoria",
  product: "Produto",
  shipment: "Remessa",
  admin: "Administrador",
  manager: "Gerente",
  editor: "Editor",
  recount: "Recontagem",
  added: "Adicionado",
  checked: "Conferido",
  checking: "Conferindo",
  modified: "Modificado",
  order: "Encomenda",
  partial: "Parcial",
  waiting: "Aguardando",
  waiting_settle: "Aguardando acerto",
  settled: "Acertado",
  customer: "Cliente",
  seller: "Vendedor",
  dealer: "Revendedor",
  supplier: "Fornecedor",
  agent: "Representante",
  dashboarder: "Usuário do painel",
  synced: "Sincronizado",
  system: "Sistema",
  app: "Aplicativo",
  load: "Carga",
  unload: "Descarga",
  transfer: "Transferência",
  packs: "Pacotes",
  merchandises: "Mercadorias",
  success: "Sucesso",
  financial: "Financeiro",
  support: "Suporte",
  simple: "Simples",
  variable: "Variável",
  charge: "Cobrança",
  discount: "Desconto",
  taxes: "Taxas",
  pix: "Pix",
  credit_card: "Cartão de crédito",
  debit_card: "Cartão de débito",
  boleto: "Boleto",
  deposit: "Depósito bancário",
  cash: "Dinheiro",
  credit: "Crédito",
  debit: "Débito",
  balance: "Saldo",
  payment: "Pagamento",
  wallet: "Carteira",
  source: "Origem",
  destination: "Destino",
  transformed: "Transformado",
  costprice: "Custo",
  retailprice: "Varejo",
  wholesaleprice: "Atacado",
  automatic: "Automático",
  manual: "Manual",
  percentual_amount: "Valor percentual",
  absolute_amount: "Valor absoluto",
  waiting_accept: "Aguardando aceitação",
  accepted: "Aceito",
};

export const translator = (word) => {
  const term = word?.toLowerCase?.();
  if (terms[term]) return terms[term];
  return word;
};
