import React, { memo, useState, useRef, useCallback, useEffect, useMemo, createContext } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { CgPushChevronRight } from "react-icons/cg";
import Fuse from "fuse.js";
import { AsyncSelect } from "components";
import { api, getNumeric } from "lib";
import { messages } from "consts";
import ObjectId from "bson-objectid";
import { BsViewList, BsViewStacked } from "react-icons/bs";
import { useCustomToast } from "hooks";
import Item from "./item";
import barcodeReaderSound from "assets/sounds/scanner.mp3";
import errorSound from "assets/sounds/error.mp3";

let loadOptionsTimeout = null;

const barcodeReaderAudio = new Audio(barcodeReaderSound);
const errorSoundAudio = new Audio(errorSound);

export const EntryMerchandisesContext = createContext();

export const EntryMerchandises = memo(({ formData, setFormData, isAmountEditable = false }) => {
  const [searchKey, setSearchKey] = useState("_id");
  const [products, setProducts] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const firstMerchandise = useMemo(() => _.first(formData.merchandises), [formData.merchandises]);
  const isClosed = useMemo(() => formData.isClosed, [formData.isClosed]);
  const inputRef = useRef();
  const formRef = useRef();
  const toast = useCustomToast();

  useEffect(() => {
    const id = "sound-alerts";
    if (!toast.isActive(id)) toast({ id, status: "sound", position: "top-right", isClosable: true, duration: 5000 });
  }, []);

  useEffect(() => {
    if (isClosed) setProducts([]);
    else {
      const timeout = setTimeout(async () => {
        try {
          setIsLoadingProducts(true);
          const response = await api.get("/sync/dash/products");
          setProducts(response);
        } finally {
          setIsLoadingProducts(false);
        }
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [isClosed]);

  useEffect(() => {
    setFormData((state) => {
      const counters = { quantity: 0, amount: 0 };
      _.forEach(state.merchandises, (merchandise) => {
        counters.quantity += merchandise.quantity;
        if (!isAmountEditable && !formData.isClosed) merchandise.amount = merchandise[formData.priceType];
        counters.amount += merchandise.quantity * merchandise.amount;
      });
      return { ...state, ...counters };
    });
  }, [formData.priceType, formData.merchandises, formData.isClosed, isAmountEditable]);

  const updateOrCreateEntry = useCallback(
    (id, increment = 1, quantity, isAuto) => {
      const product = (() => {
        if (searchKey === "_id") return _.find(products, (o) => o._id === id || o.nid === getNumeric(id));
        if (searchKey === "sku") return _.find(products, (o) => o.sku === id);
        return _.find(products, (o) => o.supplierCode === id);
      })();
      if (!product) throw new Error();
      setFormData((state) => {
        const merchandises = _.assign([], state.merchandises);
        const index = _.findIndex(merchandises, (o) => o.product._id === product._id);
        if (index !== -1) {
          const entry = merchandises[index];
          if (_.isNumber(quantity)) entry.quantity = quantity;
          else entry.quantity += increment;
          if (isAuto && index > 0) {
            merchandises.splice(index, 1);
            merchandises.unshift(entry);
          }
        } else {
          const object = {
            _id: ObjectId().toString(),
            product,

            costPrice: product.costPrice,
            retailPrice: product.retailPrice,
            wholesalePrice: product.wholesalePrice,

            amount: product[state.priceType],
            quantity: quantity ?? increment,
          };
          merchandises.unshift(object);
        }
        return { ...state, merchandises };
      });
    },
    [setFormData, products, searchKey]
  );

  const handleUpdateEntry = useCallback(
    async (id, increment, quantity, isAuto) => {
      try {
        if (!id) return;
        updateOrCreateEntry(id, increment, quantity, isAuto);
        barcodeReaderAudio.play();
      } catch (error) {
        toast({ title: "Oops", description: messages.error.productNotFound, status: "error", isClosable: true });
        errorSoundAudio.play();
      }
    },
    [toast, updateOrCreateEntry]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const value = inputRef.current.value;
      formRef.current.reset();
      handleUpdateEntry(value, 1, undefined, true);
      inputRef.current.focus();
    },
    [handleUpdateEntry]
  );

  const handleDeleteEntry = useCallback(
    async (product) => {
      setFormData((state) => {
        const merchandises = [...state.merchandises];
        _.remove(merchandises, (o) => o.product._id === product._id);
        return { ...state, merchandises };
      });
    },
    [setFormData]
  );

  const handleSearch = useCallback((data) => (inputRef.current.value = data[searchKey]), [searchKey]);

  const handleLoadOptions = useCallback(
    (search, cb) => {
      clearTimeout(loadOptionsTimeout);
      loadOptionsTimeout = setTimeout(() => {
        const fuse = new Fuse(products, {
          includeScore: true,
          keys: ["_id", "nid", "sku", "supplierCode", "name"],
        });
        const results = fuse.search(search, { limit: 20 });
        const response = results.map((o) => o.item);
        cb(response);
      }, 1000);
    },
    [products]
  );

  const handleChangeAmount = useCallback(
    (product, amount) => {
      setFormData((state) => {
        const merchandises = [...state.merchandises];
        const merchandise = _.find(merchandises, (o) => o.product._id === product._id);
        merchandise.amount = amount;
        return { ...state, merchandises };
      });
    },
    [setFormData]
  );

  return (
    <EntryMerchandisesContext.Provider
      value={{
        searchKey,
        handleUpdateEntry,
        handleDeleteEntry,
        handleChangeAmount,
        isAmountEditable,
        isClosed,
      }}
    >
      {!isClosed && (
        <form ref={formRef} onSubmit={handleSubmit}>
          <HStack align="flex-end">
            <FormControl>
              <FormLabel fontSize="sm">Nome</FormLabel>
              <AsyncSelect
                loadOptions={handleLoadOptions}
                placeholder="Selecione o produto"
                onChange={handleSearch}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name }) => name}
              />
            </FormControl>
            <FormControl>
              <HStack mb="8px">
                {[
                  { value: "_id", label: "ID/NID" },
                  { value: "sku", label: "SKU" },
                  { value: "supplierCode", label: "CFOR" },
                ].map(({ value, label }) => (
                  <Button key={value} size="xs" colorScheme={searchKey === value ? "main" : "gray"} onClick={() => setSearchKey(value)}>
                    {label}
                  </Button>
                ))}
              </HStack>
              <Input type="text" ref={inputRef} autoFocus={true} />
            </FormControl>
            <IconButton type="submit" variant="outline" icon={<Icon as={CgPushChevronRight} />} isLoading={isLoadingProducts} />
          </HStack>
        </form>
      )}

      <Tabs isFitted variant="solid-rounded" colorScheme="main" mt={4} isLazy={true}>
        <TabList mb={6}>
          <Tab borderRadius="md">
            <HStack>
              <Icon as={BsViewList} />
              <Text>Última entrada</Text>
            </HStack>
          </Tab>
          <Tab borderRadius="md">
            <HStack>
              <Icon as={BsViewStacked} />
              <Text>Todas as entradas</Text>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            {firstMerchandise && (
              <Item
                key={firstMerchandise.product?._id}
                product={firstMerchandise.product}
                amount={firstMerchandise.amount}
                defaultAmount={firstMerchandise.product[formData.priceType]}
                quantity={firstMerchandise.quantity}
                max={firstMerchandise.max}
              />
            )}
          </TabPanel>
          <TabPanel p={0}>
            <VStack spacing={4} align="stretch" divider={<StackDivider />}>
              {_.map(formData.merchandises, (item) => (
                <Item
                  key={item.product._id}
                  product={item.product}
                  amount={item.amount}
                  defaultAmount={item.product[formData.priceType]}
                  quantity={item.quantity}
                  max={item.max}
                />
              ))}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </EntryMerchandisesContext.Provider>
  );
});
