import React, { useState, useEffect, useMemo, useCallback, createContext } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  Link,
  SlideFade,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdChevronLeft } from "react-icons/md";
import * as yup from "yup";
import { api, currency } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { messages } from "consts";
import { Breadcrumb, LocationRef } from "components";
import { Content } from "pages/Private/Container";
import General from "./general";
import Comments from "./comments";
import Attachments from "./attachments";
import PartialPayment from "./partialPayment";
import AddItem from "./addItem";

export const PaymentsDetailsContext = createContext();

export const PaymentsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar pagamento" : "Novo pagamento");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/payments/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenAddItemDialog, onOpen: onOpenAddItemDialog, onClose: onCloseAddItemDialog } = useDisclosure();
  const { isOpen: isOpenSubmitConfirmDialog, onOpen: onOpenSubmitConfirmDialog, onClose: onCloseSubmitConfirmDialog } = useDisclosure();
  const isPaid = useMemo(() => formData.status === "paid", [formData.status]);
  const inApp = useMemo(() => formData.ownership === "app", [formData.ownership]);
  const isPendingReview = useMemo(() => formData.status === "pending_review", [formData.status]);
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { issueDate: new Date(), receivableAmount: 0, receivedAmount: 0, remainingAmount: 0 };
    if (formData.issueDate && moment(formData.issueDate).isValid()) formData.issueDate = moment(formData.issueDate).toDate();
    if (formData.dueDate && moment(formData.dueDate).isValid()) formData.dueDate = moment(formData.dueDate).toDate();
    if (formData.paymentDate && moment(formData.paymentDate).isValid()) formData.paymentDate = moment(formData.paymentDate).toDate();
    setFormData(formData);
  }, [data]);

  useEffect(() => {
    const remainingAmount = _.round(formData.receivableAmount - formData.receivedAmount, 2);
    setFormData((state) => ({ ...state, remainingAmount }));
  }, [formData.receivableAmount, formData.receivedAmount]);

  useEffect(() => {
    setFormData((state) => ({ ...state, paymentDate: isPaid ? moment().startOf("day").toDate() : undefined }));
  }, [isPaid]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.put(`/payments/${_id}`, data) : await api.post("/payments", data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        navigate(`/payments/edit/${saved._id}`, { replace: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, toast, navigate, refreshData]
  );

  const handleSubmit = useCallback(async () => {
    try {
      onCloseSubmitConfirmDialog();
      const schema = yup.object().shape({
        type: yup.string().required(messages.error.required),
        status: yup.string().required(messages.error.required),
        user: yup.string().required(messages.error.required),
        paymentMethod: yup.string().required(messages.error.required),
        wallet: yup.string().required(messages.error.required),
        paymentCategory: yup.string().required(messages.error.required),
        issueDate: yup.date().typeError(messages.error.required).required(messages.error.required),
        dueDate: yup.date().typeError(messages.error.required).required(messages.error.required),
        paymentDate: yup.date().when("status", {
          is: "paid",
          then: yup.date().typeError(messages.error.required).required(messages.error.required),
        }),
        items: yup.array().min(1, messages.error.required).required(messages.error.required),
        receivableAmount: yup.number().min(0.01, `${messages.error.greaterOrEqual} R$0,01.`).required(messages.error.required),
        receivedAmount: yup.number().when("status", {
          is: "paid",
          then: yup
            .number()
            .max(formData.receivableAmount, `${messages.error.lessOrEqual} ${currency.format(formData.receivableAmount)}.`)
            .required(messages.error.required),
        }),
        remainingAmount: yup.number().typeError(messages.error.required),
        nextDueDate: yup.date().when(["status", "remainingAmount"], (status, remainingAmount) => {
          if (status === "paid" && remainingAmount > 0)
            return yup.date().typeError(messages.error.invalidDate).required(messages.error.required);
        }),
      });
      const data = { ...formData, user: formData.user?._id };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, onCloseSubmitConfirmDialog, handleSaveData]);

  const handleSubmitConfirm = useCallback(() => {
    if (isPaid) return onOpenSubmitConfirmDialog();
    return handleSubmit();
  }, [isPaid, handleSubmit, onOpenSubmitConfirmDialog]);

  return (
    <PaymentsDetailsContext.Provider
      value={{
        formData,
        formErrors,
        isPaid,
        isLoadingSaveData,
        setFormData,
        setFormErrors,
        handleSubmitConfirm,
        onOpenAddItemDialog,
      }}
    >
      <Content>
        <HStack justify="space-between">
          <HStack spacing="10px">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "financeiro" },
                { to: "/payments", label: "pagamento" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Pagamento</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? formData.title : "Novo cadastro"}</Text>
          </Box>
        </HStack>

        {formData.payment && (
          <Alert status="info" borderRadius="lg" mb="10px">
            <AlertIcon />
            <AlertDescription fontSize="sm">
              Este documento foi gerado a partir de outro pagamento.
              <Link fontWeight="bold" as={RouterLink} to={`/payments/edit/${formData.payment}`}>
                Clique aqui
              </Link>{" "}
              para visualizar o documento de origem.
            </AlertDescription>
          </Alert>
        )}

        {formData.pack && (
          <Alert status="info" borderRadius="lg" mb="10px">
            <AlertIcon />
            <AlertDescription fontSize="sm">
              Este documento foi gerado a partir de um pacote.{" "}
              <Link fontWeight="bold" as={RouterLink} to={`/packs/edit/${formData.pack}`}>
                Clique aqui
              </Link>{" "}
              para visualizar o documento de origem.
            </AlertDescription>
          </Alert>
        )}

        {isPendingReview && (
          <Alert status="info" borderRadius="lg" mb="10px">
            <AlertIcon />
            <AlertDescription fontSize="sm">
              A revisão de pagamento está pendente. O processo deve ser feito através dos acertos de carga.
            </AlertDescription>
          </Alert>
        )}

        <Tabs colorScheme="main">
          <TabList>
            <Tab>dados gerais</Tab>
            <Tab>observações</Tab>
            <Tab>anexos</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Comments />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Attachments />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <Button
            size="sm"
            colorScheme="main"
            isLoading={isLoadingData || isLoadingSaveData}
            isDisabled={formData.isClosed || inApp || isPendingReview}
            onClick={handleSubmitConfirm}
          >
            salvar
          </Button>
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            cancelar
          </Button>
        </HStack>
      </SlideFade>

      <PartialPayment />
      <AddItem isOpen={isOpenAddItemDialog} onClose={onCloseAddItemDialog} />

      <AlertDialog isOpen={isOpenSubmitConfirmDialog} onClose={onCloseSubmitConfirmDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Atenção
            </AlertDialogHeader>
            <AlertDialogBody>
              Esta é uma ação irreversível. Você está realizando o fechamento deste pagamento. Deseja realmente continuar?
            </AlertDialogBody>
            <AlertDialogFooter as={HStack}>
              <Button onClick={onCloseSubmitConfirmDialog}>cancelar</Button>
              <Button colorScheme="yellow" onClick={handleSubmit}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </PaymentsDetailsContext.Provider>
  );
};
