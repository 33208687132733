import React, { createContext, useContext, useEffect, useState } from "react";
import { Flex, Box, useDisclosure } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { AppContext } from "AppProvider";
import Toolbar from "./toolbar";
import Sidebar from "./sidebar";

export const ContainerContext = createContext();

export const Content = ({ children }) => {
  return (
    <Box flex="1" p="20px" w="100%" overflow="auto">
      {children}
    </Box>
  );
};

const Container = () => {
  const { isMobileView } = useContext(AppContext);
  const { isOpen: isOpenSidebar, onOpen: onOpenSidebar, onClose: onCloseSidebar } = useDisclosure();
  const [innerHeight, setInnerHeight] = useState("100vh");

  useEffect(() => {
    const onInnerHeight = () => setInnerHeight(`${window.innerHeight}px`);
    onInnerHeight();
    window.addEventListener("resize", onInnerHeight);
    window.addEventListener("orientationchange", onInnerHeight);
    return () => {
      window.removeEventListener("resize", onInnerHeight);
      window.removeEventListener("orientationchange", onInnerHeight);
    };
  }, []);

  return (
    <ContainerContext.Provider value={{ isMobileView, isOpenSidebar, onOpenSidebar, onCloseSidebar }}>
      <Flex direction="column" w="100vw" h={innerHeight} _light={{ bg: "white" }} _dark={{ bg: "gray.800" }}>
        <Toolbar />
        <Flex flex="1" overflow="hidden">
          <Sidebar />
          <Flex flex="1" direction="column" overflow="hidden">
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </ContainerContext.Provider>
  );
};

export default Container;
