import { useMemo } from "react";
import _ from "lodash";

export const useDealerCommission = (data) => {
  return useMemo(() => {
    const response = { percentage: 0, amount: 0 };
    try {
      if (data.settledAt) {
        response.percentage = data.dealerCommissionPercentage;
        response.amount = data.dealerCommissionAmount;
      } else if (data.dealer?.commissionRule && data.currentAmount) {
        const key = data.dealer?.commissionRule.type === "percentual_amount" ? "performance" : "currentAmount";
        for (const range of data.dealer?.commissionRule.ranges) {
          if (data[key] >= _.round(range.soldStart, 2) && data[key] <= _.round(range.soldEnd, 2)) {
            response.percentage = range.commissionPercentage;
            response.amount = _.round(data.currentAmount * response.percentage, 2);
            break;
          }
        }
      }
    } finally {
      return response;
    }
  }, [data]);
};
