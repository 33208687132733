import moment from "moment";
import { Image, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { icms as icmsArray } from "consts";
import { currency, translator } from "lib";
import ImgFallbackEmpty from "assets/imgs/empty.jpg";

const defaultColumns = [
  {
    accessor: "mainImg",
    title: "Imagem",
    isVisible: true,
    render: ({ mainImg }) => (
      <Image
        boxSize="50px"
        objectFit="cover"
        src={mainImg?.thumb}
        fallbackSrc={ImgFallbackEmpty}
        alt={mainImg?.name}
        borderWidth="1px"
        borderRadius="md"
      />
    ),
    exporter: ({ mainImg }) => mainImg?.location,
  },
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "sku",
    title: "SKU",
    isVisible: true,
    formatter: ({ sku }) => sku,
  },
  {
    accessor: "name",
    title: "Nome",
    isVisible: true,
    render: ({ _id, name }) => (
      <Link as={RouterLink} to={`/products/edit/${_id}`}>
        {name}
      </Link>
    ),
    exporter: ({ name }) => name,
  },
  {
    accessor: "productGroup",
    title: "Grupo",
    isVisible: true,
    formatter: ({ productGroup }) => productGroup?.title ?? "-",
  },
  {
    accessor: "supplierCode",
    title: "Código do fornecedor",
    isVisible: false,
    formatter: ({ supplierCode }) => supplierCode,
  },
  {
    accessor: "supplier",
    title: "Fornecedor",
    isVisible: false,
    formatter: ({ supplier }) => supplier?.name,
  },
  {
    accessor: "icms",
    title: "Origem",
    isVisible: false,
    formatter: ({ icms }) => icmsArray.find(({ value }) => value === icms),
  },
  {
    accessor: "type",
    title: "Tipo",
    isVisible: false,
    formatter: ({ type }) => translator(type),
  },
  {
    accessor: "ncm",
    title: "NCM",
    isVisible: false,
    formatter: ({ ncm }) => ncm,
  },
  {
    accessor: "gtin",
    title: "GTIN/EAN",
    isVisible: false,
    formatter: ({ gtin }) => gtin,
  },
  {
    accessor: "cest",
    title: "CEST",
    isVisible: false,
    formatter: ({ cest }) => cest,
  },
  {
    accessor: "costPrice",
    title: "Preço custo",
    isVisible: true,
    formatter: ({ costPrice }) => currency.format(costPrice),
  },
  {
    accessor: "retailPrice",
    title: "Preço varejo",
    isVisible: true,
    formatter: ({ retailPrice }) => currency.format(retailPrice),
  },
  {
    accessor: "wholesalePrice",
    title: "Preço atacado",
    isVisible: true,
    formatter: ({ wholesalePrice }) => currency.format(wholesalePrice),
  },
  {
    accessor: "isManagedStock",
    title: "Controlar estoque",
    isVisible: false,
    formatter: ({ isManagedStock }) => (isManagedStock ? "Sim" : "Não"),
  },
  {
    accessor: "stock",
    title: "Estoque total",
    isVisible: true,
    formatter: ({ stock }) => stock?.toLocaleString(),
  },
  {
    accessor: "minStock",
    title: "Estoque mínimo",
    isVisible: false,
    formatter: ({ minStock }) => minStock?.toLocaleString(),
  },
  {
    accessor: "maxStock",
    title: "Estoque máximo",
    isVisible: false,
    formatter: ({ maxStock }) => maxStock?.toLocaleString(),
  },
  {
    accessor: "location",
    title: "Localização",
    isVisible: false,
    formatter: ({ location }) => location,
  },
  {
    accessor: "createdAt",
    title: "Criação",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualização",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
