import React, { useState, useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import {
  Button,
  Checkbox,
  Grid,
  GridItem,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Parser } from "@json2csv/plainjs";
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox, MdOutlineIndeterminateCheckBox } from "react-icons/md";
import fileDownload from "js-file-download";

export const ExportCsv = ({ filename, data, columns, isOpen, onClose }) => {
  const [headers, setHeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const checkeds = useMemo(() => headers.filter((o) => o.isVisible), [headers]);

  useEffect(() => {
    setHeaders([...columns]);
  }, [columns]);

  const handleChange = useCallback((index) => {
    setHeaders((state) => {
      const tmp = [...state];
      tmp[index].isVisible = !tmp[index].isVisible;
      return tmp;
    });
  }, []);

  const handleSubmit = useCallback(() => {
    try {
      setIsLoading(true);
      const visibles = headers.filter((o) => o.isVisible);
      const response = _.map(data, (item) => {
        const tmp = {};
        for (const { accessor, formatter, exporter } of visibles) {
          _.set(tmp, accessor, formatter?.(item) ?? exporter?.(item) ?? "");
        }
        return tmp;
      });
      const parser = new Parser({
        fields: visibles.map((o) => ({ value: o.accessor, label: o.title })),
        delimiter: ";",
      });
      const csv = parser.parse(response);
      fileDownload(csv, `${filename}_${new Date().toISOString()}.csv`);
      onClose();
    } finally {
      setIsLoading(false);
    }
  }, [headers, data, filename, onClose]);

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="space-between">
          <Text>Exportar arquivo CSV</Text>
          {headers.length === checkeds.length ? (
            <Button
              size="sm"
              variant="outline"
              leftIcon={<Icon as={MdOutlineCheckBox} />}
              onClick={() => setHeaders(_.map(headers, (o) => ({ ...o, isVisible: false })))}
            >
              desmarcar todos
            </Button>
          ) : (
            <Button
              size="sm"
              variant="outline"
              leftIcon={<Icon as={checkeds.length === 0 ? MdCheckBoxOutlineBlank : MdOutlineIndeterminateCheckBox} />}
              onClick={() => setHeaders(_.map(headers, (o) => ({ ...o, isVisible: true })))}
            >
              marcar todos
            </Button>
          )}
        </ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            {_.map(headers, (item, index) => (
              <GridItem key={item.accessor}>
                <Checkbox key={item.accessor} colorScheme="main" isChecked={item.isVisible} onChange={() => handleChange(index)}>
                  <Text fontSize="xs">{item.title}</Text>
                </Checkbox>
              </GridItem>
            ))}
          </Grid>
        </ModalBody>
        <ModalFooter as={HStack}>
          <Button size="sm" variant="outline" onClick={onClose}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="main" isDisabled={headers.length === 0} isLoading={isLoading} onClick={handleSubmit}>
            exportar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
