import React, { useState, useCallback, useRef } from "react";
import { useParams } from "react-router";
import {
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { messages } from "consts";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { MdPrint } from "react-icons/md";

const PreviewContract = ({ data, onClose, onRefresh }) => {
  const { _id } = useParams();
  const [isLoadingAccept, setIsLoadingAccept] = useState(false);
  const bodyRef = useRef();
  const toast = useCustomToast();

  const handleAccept = useCallback(async () => {
    try {
      setIsLoadingAccept(true);
      await api.put(`/contracts/${data?._id}`);
      toast({ description: messages.success.acceptContract, status: "success", isClosable: true });
      onClose();
      onRefresh();
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingAccept(false);
    }
  }, [toast, data?._id, onClose, onRefresh]);

  return (
    <Modal size="4xl" isOpen={data?.title} onClose={onClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="flex-end">
          <ReactToPrint content={() => bodyRef.current} trigger={() => <IconButton icon={<Icon as={MdPrint} />} />} />
        </ModalHeader>
        <ModalBody p="40px" ref={bodyRef}>
          <div className="contract-wrapper" dangerouslySetInnerHTML={{ __html: data?.body }} />
        </ModalBody>
        <Divider />
        <ModalFooter as={HStack}>
          <Button size="sm" variant="outline" onClick={onClose}>
            fechar
          </Button>
          {data?.status === "waiting_accept" && (
            <Button size="sm" colorScheme="green" isLoading={isLoadingAccept} onClick={handleAccept}>
              aceitar contrato
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PreviewContract;
