import React, { useState, useEffect, useMemo, useCallback, createContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  SlideFade,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdChevronLeft } from "react-icons/md";
import _ from "lodash";
import * as yup from "yup";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { messages } from "consts";
import { Breadcrumb } from "components";
import { Content } from "pages/Private/Container";
import General from "./general";
import Comments from "./comments";

export const PackOrdersDetailsContext = createContext();

export const PackOrdersDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar encomenda" : "Nova encomenda");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/pack-orders/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const isRecount = useMemo(() => formData.type === "recount", [formData.type]);
  const isClosing = useMemo(() => formData.status === "transformed", [formData.status]);
  const { isOpen: isOpenSubmitConfirmDialog, onOpen: onOpenSubmitConfirmDialog, onClose: onCloseSubmitConfirmDialog } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    setFormData(data ?? { priceType: "retailPrice" });
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.put(`/pack-orders/${_id}`, data) : await api.post("/pack-orders", data);
        navigate(`/pack-orders/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      onCloseSubmitConfirmDialog();
      const schema = yup.object().shape(
        {
          status: yup.string().required(messages.error.required),
          priceType: yup.string().required(messages.error.required),
          agent: yup.string().when("dealer", {
            is: (dealer) => !dealer,
            then: yup.string().required(messages.error.required),
          }),
          dealer: yup.string().when("agent", {
            is: (agent) => !agent,
            then: yup.string().required(messages.error.required),
          }),
        },
        ["agent", "dealer"]
      );
      const data = { ...formData, agent: formData.agent?._id, dealer: formData.dealer?._id };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData, onCloseSubmitConfirmDialog]);

  const handleSubmitConfirm = useCallback(() => {
    if (isClosing) return onOpenSubmitConfirmDialog();
    return handleSubmit();
  }, [isClosing, handleSubmit, onOpenSubmitConfirmDialog]);

  return (
    <PackOrdersDetailsContext.Provider value={{ formData, formErrors, setFormData, isRecount, isClosing }}>
      <Content>
        <HStack justify="space-between">
          <HStack spacing="10px">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/pack-orders", label: "encomendas" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Encomendas</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
        </HStack>

        <Tabs colorScheme="main">
          <TabList>
            <Tab>dados gerais</Tab>
            <Tab>observações</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Comments />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <Button
            size="sm"
            colorScheme="main"
            isLoading={isLoadingData || isLoadingSaveData}
            isDisabled={formData.isClosed}
            onClick={handleSubmitConfirm}
          >
            salvar
          </Button>
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            cancelar
          </Button>
        </HStack>
      </SlideFade>

      <AlertDialog isOpen={isOpenSubmitConfirmDialog} onClose={onCloseSubmitConfirmDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Atenção
            </AlertDialogHeader>
            <AlertDialogBody>{messages.warning.closeDataConfirm}</AlertDialogBody>
            <AlertDialogFooter as={HStack}>
              <Button onClick={onCloseSubmitConfirmDialog}>cancelar</Button>
              <Button colorScheme="yellow" onClick={handleSubmit}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </PackOrdersDetailsContext.Provider>
  );
};
