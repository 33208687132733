import React from "react";
import { Select, VStack, FormControl, Input } from "@chakra-ui/react";
import { InputCurrency } from "components";
import { Label, InputSize } from "../components";

export const Box = ({ data, onChange }) => {
  return (
    <VStack>
      <FormControl>
        <Label onClean={() => onChange({ ...data, props: { ...data.props, width: undefined } })}>Largura</Label>
        <InputSize value={data.props.width} onChange={(width) => onChange({ ...data, props: { ...data.props, width } })} />
      </FormControl>
      <FormControl>
        <Label onClean={() => onChange({ ...data, props: { ...data.props, height: undefined } })}>Altura</Label>
        <InputSize value={data.props.height} onChange={(height) => onChange({ ...data, props: { ...data.props, height } })} />
      </FormControl>
      <FormControl>
        <Label onClean={() => onChange({ ...data, props: { ...data.props, padding: undefined } })}>Preenchimento</Label>
        <InputSize value={data.props.padding} onChange={(padding) => onChange({ ...data, props: { ...data.props, padding } })} />
      </FormControl>
      <FormControl>
        <Label onClean={() => onChange({ ...data, props: { ...data.props, margin: undefined } })}>Margem</Label>
        <InputSize value={data.props.margin} onChange={(margin) => onChange({ ...data, props: { ...data.props, margin } })} />
      </FormControl>
      <FormControl>
        <Label onClean={() => onChange({ ...data, props: { ...data.props, textAlign: undefined } })}>Alinhamento</Label>
        <Select
          value={data.props.textAlign ?? ""}
          onChange={({ target }) => onChange({ ...data, props: { ...data.props, textAlign: target.value } })}
        >
          <option value="left">Esquerda</option>
          <option value="center">Centro</option>
          <option value="right">Direita</option>
          <option value="justify">Justificado</option>
        </Select>
      </FormControl>
      <FormControl>
        <Label onClean={() => onChange({ ...data, props: { ...data.props, flex: undefined } })}>Flex</Label>
        <Input as={InputCurrency}
          precision="0"
          value={data.props.flex ?? ""}
          onChange={(floatValue) => onChange({ ...data, props: { ...data.props, flex: floatValue } })}
        />
      </FormControl>
      <FormControl>
        <Label onClean={() => onChange({ ...data, props: { ...data.props, borderRadius: undefined } })}>Raio da borda</Label>
        <InputSize
          value={data.props.borderRadius}
          onChange={(borderRadius) => onChange({ ...data, props: { ...data.props, borderRadius } })}
        />
      </FormControl>
      <FormControl>
        <Label onClean={() => onChange({ ...data, props: { ...data.props, backgroundColor: undefined } })}>Cor de fundo</Label>
        <Input
          value={data.props.backgroundColor ?? ""}
          onChange={({ target }) => onChange({ ...data, props: { ...data.props, backgroundColor: target.value } })}
        />
      </FormControl>
      <FormControl>
        <Label onClean={() => onChange({ ...data, props: { ...data.props, color: undefined } })}>Cor do conteúdo</Label>
        <Input
          value={data.props.color ?? ""}
          onChange={({ target }) => onChange({ ...data, props: { ...data.props, color: target.value } })}
        />
      </FormControl>
    </VStack>
  );
};
