const listeners = {};

export const subscribe = (eventName, cb) => {
  if (!listeners[eventName]) listeners[eventName] = [];
  listeners[eventName].push(cb);
};

export const dispatch = (eventName, value) => {
  if (!listeners[eventName] || !listeners[eventName].length) return;
  for (const listener of listeners[eventName]) {
    if (listener instanceof Function) listener(value);
  }
};

export const unsubscribe = (eventName) => {
  if (!listeners[eventName]) return;
  delete listeners[eventName];
};
