import moment from "moment";
import _ from "lodash";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { masks, translator } from "lib";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "name",
    title: "Nome",
    isVisible: true,
    render: ({ _id, name }) => (
      <Link as={RouterLink} to={`/users/edit/${_id}`}>
        {name}
      </Link>
    ),
    exporter: ({ name }) => name,
  },
  {
    accessor: "nickname",
    title: "Fantasia",
    isVisible: false,
    formatter: ({ nickname }) => nickname,
  },
  {
    accessor: "document",
    title: "Documento",
    isVisible: true,
    formatter: ({ document }) => masks.document(document),
  },
  {
    accessor: "phone1",
    title: "Telefone 1",
    isVisible: true,
    formatter: ({ phone1 }) => masks.phone(phone1),
  },
  {
    accessor: "phone2",
    title: "Telefone 2",
    isVisible: false,
    formatter: ({ phone2 }) => masks.phone(phone2),
  },
  {
    accessor: "phone3",
    title: "Telefone 3",
    isVisible: false,
    formatter: ({ phone3 }) => masks.phone(phone3),
  },
  {
    accessor: "email",
    title: "E-mail",
    isVisible: true,
    formatter: ({ email }) => email,
  },
  {
    accessor: "address.zipCode",
    title: "CEP",
    isVisible: false,
    formatter: ({ address }) => address?.zipCode,
  },
  {
    accessor: "address.street",
    title: "Rua",
    isVisible: false,
    formatter: ({ address }) => address?.street,
  },
  {
    accessor: "address.number",
    title: "Número",
    isVisible: false,
    formatter: ({ address }) => address?.number,
  },
  {
    accessor: "address.complement",
    title: "Complemento",
    isVisible: false,
    formatter: ({ address }) => address?.complement,
  },
  {
    accessor: "address.neighborhood",
    title: "Bairro",
    isVisible: false,
    formatter: ({ address }) => address?.neighborhood,
  },
  {
    accessor: "address.city",
    title: "Cidade",
    isVisible: false,
    formatter: ({ address }) => address?.city,
  },
  {
    accessor: "address.state",
    title: "Estado",
    isVisible: false,
    formatter: ({ address }) => address?.state,
  },
  {
    accessor: "attachments",
    title: "Anexos",
    isVisible: true,
    formatter: ({ attachments }) => _.size(attachments),
  },
  {
    accessor: "roles",
    title: "Funções",
    isVisible: true,
    formatter: ({ roles }) => _.map(roles, translator).join(", "),
  },
  {
    accessor: "createdAt",
    title: "Criação",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualização",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
