import React, { memo, useCallback, useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  useToken,
  VStack,
} from "@chakra-ui/react";
import { RiSettings4Line } from "react-icons/ri";
import { IoHelpBuoyOutline } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { AuthContext } from "AuthProvider";
import { ContainerContext } from "./index";

const MenuItem = memo(({ href, icon, title, RightComponent, requiredRole, ...props }) => {
  const [backgroundColor, color] = useToken("colors", [
    useColorModeValue("main.50", "whiteAlpha.100"),
    useColorModeValue("main.500", "white"),
  ]);

  const handleActiveStyle = useCallback(
    ({ isActive }) => {
      if (isActive) return { backgroundColor, color };
      return {};
    },
    [backgroundColor, color]
  );

  return (
    <HStack
      py="5px"
      px="10px"
      cursor="pointer"
      transition="200ms"
      borderRadius="md"
      _hover={{ bg: "blackAlpha.100" }}
      as={href ? "a" : NavLink}
      href={href}
      style={href ? {} : handleActiveStyle}
      {...props}
    >
      {icon && <Icon as={icon} />}
      <Text fontSize="xs" fontWeight="500" flex="1">
        {title}
      </Text>
      {RightComponent}
    </HStack>
  );
});

const MenuList = () => {
  const { currentUser } = useContext(AuthContext);
  return (
    <Box minW="220px" overflowY="auto">
      <VStack alignItems="stretch" spacing="0" py="5px" px="20px">
        <Text fontWeight="600" fontSize="sm" py="5px" px="8px">
          Índice
        </Text>
        <MenuItem to="/home" title="Página inicial" />
      </VStack>

      <VStack alignItems="stretch" spacing="0" py="5px" px="20px">
        <Text fontWeight="600" fontSize="sm" py="5px" px="8px">
          Cadastros
        </Text>
        <MenuItem to="/users" title="Usuários" />
        <MenuItem to="/products" title="Produtos" />
      </VStack>

      <VStack alignItems="stretch" spacing="0" py="5px" px="20px">
        <Text fontWeight="600" fontSize="sm" py="5px" px="8px">
          Consignação
        </Text>
        <MenuItem to="/packs" title="Pacotes" />
        <MenuItem to="/pack-orders" title="Encomendas" />
        <MenuItem to="/pack-movements" title="Movimentações" />
        <MenuItem to="/pack-expeditions" title="Expedições" />
        <MenuItem to="/pack-settlements" title="Acertos" />
        <MenuItem to="/commissions" title="Comissões" />
      </VStack>

      <VStack alignItems="stretch" spacing="0" py="5px" px="20px">
        <Text fontWeight="600" fontSize="sm" py="5px" px="8px">
          Suprimentos
        </Text>
        <MenuItem to="/stock-movements" title="Mov. de estoque" />
      </VStack>

      <VStack alignItems="stretch" spacing="0" py="5px" px="20px">
        <Text fontWeight="600" fontSize="sm" py="5px" px="8px">
          Financeiro
        </Text>
        <MenuItem to="/payments" title="Pagamentos" />
        <MenuItem to="/wallets" title="Carteiras" />
      </VStack>

      <VStack alignItems="stretch" spacing="0" py="5px" px="20px">
        <MenuItem to="/settings" title="Configurações" icon={RiSettings4Line} />
        <MenuItem
          href={`https://wa.me/5512988191212?text=Olá! Preciso de suporte. Empresa ${currentUser.company.nid} - ${currentUser.company.name}. Usuário ${currentUser.nid} - ${currentUser.name}.`}
          target="_blank"
          title="Ajuda"
          icon={IoHelpBuoyOutline}
        />
      </VStack>
    </Box>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const { isMobileView, isOpenSidebar, onCloseSidebar } = useContext(ContainerContext);

  useEffect(() => {
    onCloseSidebar();
  }, [location]);

  return isMobileView ? (
    <Drawer isOpen={isOpenSidebar} placement="left" onClose={onCloseSidebar}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <IconButton variant="outline" isRound icon={<Icon as={MdClose} />} onClick={onCloseSidebar} />
        </DrawerHeader>
        <DrawerBody p="0">
          <MenuList />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  ) : (
    <MenuList />
  );
};

export default Sidebar;
