import React, { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Link,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import { api, currency } from "lib";
import { icms } from "consts";
import { useApiGet, useCustomToast } from "hooks";
import { InputCurrency, AsyncSelect, BoxData } from "components";
import { MdHistory, MdRefresh } from "react-icons/md";
import PriceHistory from "./priceHistory";
import { ProductsDetailsContext } from ".";
import { RxEyeClosed } from "react-icons/rx";

let loadSuppliersTimeout = null;
let loadProductGroupsTimeout = null;

const General = () => {
  const { _id } = useParams();
  const { formData, setFormData, formErrors } = useContext(ProductsDetailsContext);
  const { isOpen: isOpenPriceHistory, onOpen: onOpenPriceHistory, onClose: onClosePriceHistory } = useDisclosure();
  const [groups, isLoadingGroups, refreshGroups] = useApiGet(
    useMemo(
      () => ({
        path: "/product-groups",
        params: { query: { isActive: true, parent: { $exists: false } }, perPage: -1 },
      }),
      []
    )
  );
  const [externalStock, setExternalStock] = useState(null);
  const [isLoadingExternalStock, setIsLoadingExternalStock] = useState(false);
  const toast = useCustomToast();

  const handleLoadSuppliers = useCallback((search, cb) => {
    clearTimeout(loadSuppliersTimeout);
    loadSuppliersTimeout = setTimeout(async () => {
      const query = { roles: "supplier" };
      const response = await api.get("/users", { params: { query, search, perPage: 20 } });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadSubgroups = useCallback(
    (search, cb) => {
      clearTimeout(loadProductGroupsTimeout);
      loadProductGroupsTimeout = setTimeout(async () => {
        const query = { parent: formData.productGroup, isActive: true };
        const response = await api.get("/product-groups", { params: { query, search, perPage: 20 } });
        cb(response?.data ?? []);
      }, 1000);
    },
    [formData.productGroup]
  );

  const handleExternalStock = useCallback(async () => {
    try {
      setIsLoadingExternalStock(true);
      const { quantity } = await api.get(`/products/${_id}/external-stock`);
      setExternalStock(quantity);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingExternalStock(false);
    }
  }, []);

  const handleChangeSKU = useCallback(({ target }) => {
    let sku = target.value.replaceAll(" ", "").toUpperCase();
    setFormData((state) => ({ ...state, sku }));
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <FormControl isInvalid={formErrors.nid}>
            <FormLabel fontSize="sm">NID</FormLabel>
            <Input value={formData.nid ?? ""} isDisabled={true} />
            <FormErrorMessage>{formErrors.nid}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isInvalid={formErrors.sku}>
            <FormLabel fontSize="sm">Código (SKU)</FormLabel>
            <Input value={formData.sku ?? ""} onChange={handleChangeSKU} />
            <FormErrorMessage>{formErrors.sku}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 7 }}>
          <FormControl isRequired={true} isInvalid={formErrors.name}>
            <FormLabel fontSize="sm">Nome</FormLabel>
            <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
            <FormErrorMessage>{formErrors.name}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 9 }}>
          <FormControl isInvalid={formErrors.supplier}>
            <FormLabel fontSize="sm">Fornecedor</FormLabel>
            <AsyncSelect
              isInvalid={formErrors.supplier}
              value={formData.supplier}
              defaultOptions
              loadOptions={handleLoadSuppliers}
              placeholder="Selecione o fornecedor"
              onChange={(supplier) => setFormData((state) => ({ ...state, supplier }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name }) => name}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.supplier}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isInvalid={formErrors.supplierCode}>
            <FormLabel fontSize="sm">Código do fornecedor (CFOR)</FormLabel>
            <Input
              value={formData.supplierCode ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, supplierCode: target.value.toUpperCase() }))}
            />
            <FormErrorMessage>{formErrors.supplierCode}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 8 }}>
          <FormControl isInvalid={formErrors.icms}>
            <FormLabel fontSize="sm">Origem</FormLabel>
            <Select value={formData.icms ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, icms: target.value }))}>
              {icms.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formErrors.icms}</FormErrorMessage>
            <FormHelperText>Origem do produto conforme ICMS.</FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.type}>
            <FormLabel fontSize="sm">Tipo</FormLabel>
            <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
              <option value="simple">Simples</option>
            </Select>
            <FormErrorMessage>{formErrors.type}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.ncm}>
            <FormLabel fontSize="sm">NCM</FormLabel>
            <Input value={formData.ncm ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, ncm: target.value }))} />
            <FormErrorMessage>{formErrors.ncm}</FormErrorMessage>
            <FormHelperText>Nomenclatura comum do Mercosul.</FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.gtin}>
            <FormLabel fontSize="sm">GTIN/EAN</FormLabel>
            <Input value={formData.gtin ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, gtin: target.value }))} />
            <FormErrorMessage>{formErrors.gtin}</FormErrorMessage>
            <FormHelperText>Global Trade Item Number.</FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.cest}>
            <FormLabel fontSize="sm">Código CEST</FormLabel>
            <Input value={formData.cest ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, cest: target.value }))} />
            <FormErrorMessage>{formErrors.cest}</FormErrorMessage>
            <Link href="https://www.confaz.fazenda.gov.br/legislacao/convenios/2015/cv092_15" isExternal>
              <FormHelperText fontSize="sm" color="main.500">
                Código Especificador da Substituição Tributária
              </FormHelperText>
            </Link>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.costPrice}>
            <BoxData
              label="Preço de custo médio"
              value={currency.format(formData.costPrice ?? 0)}
              RightComponent={<IconButton variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenPriceHistory} />}
            />
            <FormErrorMessage>{formErrors.costPrice}</FormErrorMessage>
            <FormHelperText>
              O preço de custo médio é o resultado da média dos preços informados nas movimentações de estoque.
            </FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.wholesalePrice}>
            <FormLabel fontSize="sm">Preço de atacado</FormLabel>
            <InputGroup>
              <InputLeftAddon>R$</InputLeftAddon>
              <Input
                as={InputCurrency}
                value={formData.wholesalePrice ?? ""}
                onChange={(wholesalePrice) => setFormData((state) => ({ ...state, wholesalePrice }))}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.wholesalePrice}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.retailPrice}>
            <FormLabel fontSize="sm">Preço de varejo</FormLabel>
            <InputGroup>
              <InputLeftAddon>R$</InputLeftAddon>
              <Input
                as={InputCurrency}
                value={formData.retailPrice ?? ""}
                onChange={(retailPrice) => setFormData((state) => ({ ...state, retailPrice }))}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.retailPrice}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.productGroup}>
            <FormLabel fontSize="sm">Grupo</FormLabel>
            <HStack>
              <Select
                value={formData.productGroup ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, productGroup: target.value }))}
              >
                <option>--Selecione</option>
                {_.map(groups?.data, (item) => (
                  <option key={item._id} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </Select>
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingGroups} onClick={refreshGroups} />
            </HStack>
            <FormErrorMessage>{formErrors.productGroup}</FormErrorMessage>
          </FormControl>
        </GridItem>

        {formData.productGroup && (
          <GridItem colSpan={{ base: 12, lg: 8 }}>
            <FormControl isInvalid={formErrors.productSubgroups}>
              <FormLabel fontSize="sm">Subgrupos</FormLabel>
              <AsyncSelect
                isInvalid={formErrors.productSubgroups}
                isMulti
                value={formData.productSubgroups ?? []}
                defaultOptions
                loadOptions={handleLoadSubgroups}
                placeholder="Selecione os subgrupos"
                onChange={(productSubgroups) => setFormData((state) => ({ ...state, productSubgroups }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ title }) => title}
                isClearable={true}
              />
              <FormErrorMessage>{formErrors.productSubgroups}</FormErrorMessage>
            </FormControl>
          </GridItem>
        )}
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Estoque
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <FormControl isRequired={true} isInvalid={formErrors.isManagedStock}>
            <FormLabel fontSize="sm">Controlar estoque</FormLabel>
            <Select
              value={formData.isManagedStock ? "yes" : "no"}
              onChange={({ target }) => setFormData((state) => ({ ...state, isManagedStock: target.value === "yes" }))}
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </Select>
            <FormErrorMessage>{formErrors.isManagedStock}</FormErrorMessage>
            <FormHelperText>Acompanhamento da movimentação de estoque.</FormHelperText>
          </FormControl>
        </GridItem>
        {formData.isManagedStock && (
          <>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl isRequired={true} isInvalid={formErrors.stock}>
                <BoxData label="Estoque total" value={<Text>{formData.stock?.toLocaleString() ?? 0} un</Text>} />
                <FormErrorMessage>{formErrors.stock}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <BoxData
                  label="Estoque externo"
                  value={externalStock !== null ? <Text>{externalStock.toLocaleString()} un</Text> : <Text>●●●●</Text>}
                  RightComponent={
                    externalStock === null && (
                      <IconButton
                        variant="outline"
                        icon={<Icon as={RxEyeClosed} />}
                        isLoading={isLoadingExternalStock}
                        onClick={handleExternalStock}
                      />
                    )
                  }
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <FormControl isRequired={true} isInvalid={formErrors.minStock}>
                <FormLabel fontSize="sm">Estoque mínimo</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    precision="0"
                    value={formData.minStock ?? 0}
                    onChange={(minStock) => setFormData((state) => ({ ...state, minStock }))}
                  />
                  <InputRightAddon>un</InputRightAddon>
                </InputGroup>
                <FormErrorMessage>{formErrors.minStock}</FormErrorMessage>
                <FormHelperText>Quantidade mínima do produto no estoque.</FormHelperText>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <FormControl isRequired={true} isInvalid={formErrors.maxStock}>
                <FormLabel fontSize="sm">Estoque máximo</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    precision="0"
                    value={formData.maxStock ?? 0}
                    onChange={(maxStock) => setFormData((state) => ({ ...state, maxStock }))}
                  />
                  <InputRightAddon>un</InputRightAddon>
                </InputGroup>
                <FormErrorMessage>{formErrors.maxStock}</FormErrorMessage>
                <FormHelperText>Quantidade máxima do produto no estoque.</FormHelperText>
              </FormControl>
            </GridItem>
          </>
        )}

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.location}>
            <FormLabel fontSize="sm">Localização</FormLabel>
            <Input
              value={formData.location ?? ""}
              placeholder="Ex: corredor A"
              onChange={({ target }) => setFormData((state) => ({ ...state, location: target.value }))}
            />
            <FormErrorMessage>{formErrors.location}</FormErrorMessage>
            <FormHelperText>Localização física no estoque.</FormHelperText>
          </FormControl>
        </GridItem>
      </Grid>

      <PriceHistory isOpen={isOpenPriceHistory} onClose={onClosePriceHistory} />
    </>
  );
};

export default General;
