import React, { useContext } from "react";
import { FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Select } from "@chakra-ui/react";
import moment from "moment";
import { currency, translator } from "lib";
import { BoxData, EntryMerchandises } from "components";
import PackMovementsDetailsContext from "./context";

const General = () => {
  const { formData, formErrors, setFormData } = useContext(PackMovementsDetailsContext);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={2}>
          <FormControl isRequired={true} isInvalid={formErrors.type}>
            <BoxData label="Tipo" value={translator(formData.type ?? "-")} />
            <FormErrorMessage>{formErrors.type}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isRequired={true} isInvalid={formErrors.source}>
            <BoxData label="Pacote origem" value={formData.source?.nid ?? "-"} />
            <FormErrorMessage>{formErrors.source}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isRequired={true} isInvalid={formErrors.destination}>
            <BoxData label="Pacote destino" value={formData.destination?.nid ?? "-"} />
            <FormErrorMessage>{formErrors.destination}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={6}>
          <FormControl isRequired={true} isInvalid={formErrors.author}>
            <BoxData label="Autor" value={formData.author?.name ?? "-"} />
            <FormErrorMessage>{formErrors.author}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={6}>
          <FormControl isRequired={true} isInvalid={formErrors.status}>
            <FormLabel fontSize="sm">Status</FormLabel>
            <Select value={formData.status} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
              <option>--Selecione</option>
              <option value="pending">Pendente</option>
              <option value="closed">Fechado</option>
            </Select>
            <FormErrorMessage>{formErrors.status}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={6}>
          <FormControl isInvalid={formErrors.priceType} isDisabled={true}>
            <FormLabel fontSize="sm">Tipo de preço</FormLabel>
            <Select
              value={formData.priceType ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, priceType: target.value }))}
            >
              <option value="costPrice">Custo</option>
              <option value="wholesalePrice">Atacado</option>
              <option value="retailPrice">Varejo</option>
            </Select>
            <FormErrorMessage>{formErrors.priceType}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Mercadorias" value={formData.quantity?.toLocaleString?.() ?? 0} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Valor" value={currency.format(formData.amount ?? 0)} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Linhas" value={formData.merchandises?.length ?? 0} />
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Criação" value={moment(formData?.createdAt).format("D, MMMM yyyy [às] HH:mm")} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Atualização" value={moment(formData?.updatedAt).format("D, MMMM yyyy [às] HH:mm")} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <BoxData label="Fechamento" value={formData?.closedAt ? moment(formData?.closedAt).format("D, MMMM yyyy [às] HH:mm") : "-"} />
          </FormControl>
        </GridItem>
      </Grid>

      <EntryMerchandises formData={formData} setFormData={setFormData} />
    </>
  );
};

export default General;
