import { useCallback } from "react";
import _ from "lodash";

export const useArrayItemHandlers = (setFormData) => {
  const handleChangeArrayItem = useCallback(
    (key, index, obj) => {
      setFormData((state) => {
        const tmp = [...state[key]];
        tmp[index] = { ...tmp[index], ...obj };
        return { ...state, [key]: tmp };
      });
    },
    [setFormData]
  );

  const handleAddArrayItem = useCallback(
    (key, obj = {}) => {
      setFormData((state) => {
        const tmp = _.map(state[key], (value) => ({ ...value }));
        tmp.push(obj);
        return { ...state, [key]: tmp };
      });
    },
    [setFormData]
  );

  const handleDeleteArrayItem = useCallback(
    (key, index) => {
      setFormData((state) => {
        const tmp = [...state[key]];
        tmp.splice(index, 1);
        return { ...state, [key]: tmp };
      });
    },
    [setFormData]
  );

  return { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem };
};
