import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Center, Box, useColorModeValue, Text, VStack } from "@chakra-ui/react";
import { SignInEmailAndCompany, SignInVerificationCode } from "./SignIn";
import { SplashContext } from "SplashProvider";

const Container = () => {
  const backgroundColor = useColorModeValue("white", "gray.900");

  return (
    <VStack width="100vw" height="100vh" alignItems="center" bg={backgroundColor}>
      <Center flex="1">
        <Box borderWidth="1px" borderRadius="xl" width={{ base: "90%", md: "400px" }} p={8}>
          <Outlet />
        </Box>
      </Center>
      <VStack spacing={0} p="10px">
        <Text fontSize="sm">&copy; {new Date().getFullYear()}. Todos os direitos reservados.</Text>
        <Text fontSize="xs">v{process.env.REACT_APP_VERSION}</Text>
      </VStack>
    </VStack>
  );
};

const Public = () => {
  const { setIsAppReady } = useContext(SplashContext);

  useEffect(() => {
    setIsAppReady(true);
  }, [setIsAppReady]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Container />}>
          <Route index={true} path="sign-in" element={<SignInEmailAndCompany />} />
          <Route index={true} path="sign-in/verification-code" element={<SignInVerificationCode />} />
        </Route>
        <Route path="*" element={<Navigate to="/sign-in" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Public;
