import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { useApiGet, useDocumentTitle } from "hooks";
import { Breadcrumb, CustomBadge, Paginator } from "components";
import { MdRefresh } from "react-icons/md";
import { HiOutlineDocumentText } from "react-icons/hi";
import { Content } from "pages/Private/Container";
import PreviewContract from "./previewContract";

export const ContractsList = () => {
  useDocumentTitle("Contratos");
  const [page, setPage] = useState(0);
  const [perPage] = useState(20);
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(() => ({ path: "/contracts", params: { sort: { createdAt: -1 }, page, perPage } }), [page, perPage])
  );
  const [selected, setSelected] = useState();

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <Breadcrumb items={[{ to: "/contracts", label: "contratos" }]} />
        </HStack>

        <HStack mb="30px">
          <Heading my="15px" size="md" flex="1">
            Contratos
          </Heading>
          <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoadingData} onClick={refreshData} />
        </HStack>

        <Table size="sm" whiteSpace="nowrap">
          <Thead>
            <Tr>
              <Th>UUID</Th>
              <Th>Status</Th>
              <Th>Título</Th>
              <Th>Aceito em</Th>
              <Th>Cancelado em</Th>
              <Th>#</Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(data?.data, (item) => (
              <Tr key={item._id} _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } }}>
                <Td>{item._id}</Td>
                <Td>
                  <CustomBadge.Contracts.Status status={item.status} />
                </Td>
                <Td>{item.title}</Td>
                <Td>{item.acceptedAt ? moment(item.acceptedAt).format("DD/MM/YYYY [às] HH:mm") : "-"}</Td>
                <Td>{item.canceledAt ? moment(item.canceledAt).format("DD/MM/YYYY [às] HH:mm") : "-"}</Td>
                <Td>
                  <Button size="sm" colorScheme="main" onClick={() => setSelected(item)}>
                    visualizar
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {isLoadingData ? (
          <Center p="30px">
            <Spinner />
          </Center>
        ) : (
          _.size(data?.data) === 0 && (
            <VStack spacing="0" zIndex="1" mt="30px">
              <Icon as={HiOutlineDocumentText} boxSize="60px" mb="10px" />
              <Heading size="md" textAlign="center">
                Nenhum contrato
              </Heading>
              <Text textAlign="center">Você ainda não possui contratos disponíveis para visualização.</Text>
            </VStack>
          )
        )}
      </Content>

      <SlideFade in={true} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <PreviewContract data={selected} onClose={() => setSelected()} onRefresh={refreshData} />
    </>
  );
};
