import { useCallback, useEffect, useState, useImperativeHandle, forwardRef } from "react";
import _ from "lodash";
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdClose } from "react-icons/md";
import ListItem from "./item";
import { TbListNumbers } from "react-icons/tb";

export const TableDrawer = forwardRef(({ sort, perPage, defaultColumns, columns, onChange }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({ sort, perPage, columns });
  }, [sort, perPage, columns]);

  const handleSubmit = useCallback(async () => {
    onChange(formData);
    onClose();
  }, [onClose, onChange, formData]);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;
    setFormData((state) => {
      const columns = [...state.columns];
      const [removed] = columns.splice(result.source.index, 1);
      columns.splice(result.destination.index, 0, removed);
      return { ...state, columns };
    });
  }, []);

  const handleChangeColumnVisibility = useCallback((accessor) => {
    setFormData((state) => {
      const columns = [...state.columns];
      const index = columns.findIndex((o) => o.accessor === accessor);
      columns[index].isVisible = !columns[index].isVisible;
      return { ...state, columns };
    });
  }, []);

  const handleChangeColumnSort = useCallback((accessor) => {
    setFormData((state) => {
      let sort = { ...state.sort };
      if (Math.abs(sort[accessor]) === 1) sort[accessor] *= -1;
      else sort = { [accessor]: 1 };
      return { ...state, sort };
    });
  }, []);

  const handleResetColumns = useCallback(() => {
    setFormData((state) => ({
      ...state,
      columns: _.map(defaultColumns, (item) => ({ ...item })),
    }));
  }, [defaultColumns]);

  useImperativeHandle(ref, () => ({ open: onOpen, close: onClose }), [onOpen, onClose]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader as={HStack} justify="space-between">
          <Text fontSize="md">Configurar exibição</Text>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
        </DrawerHeader>
        <Divider />
        <DrawerBody as={VStack} align="stretch" spacing={6}>
          {perPage && (
            <FormControl>
              <Menu closeOnSelect={false} placement="bottom">
                <MenuButton as={Button} fontSize="sm" variant="outline" w="100%" leftIcon={<Icon as={TbListNumbers} />}>
                  exibindo {formData.perPage} por página
                </MenuButton>
                <MenuList minWidth="240px">
                  <MenuOptionGroup
                    value={formData.perPage?.toString()}
                    onChange={(value) => setFormData((state) => ({ ...state, perPage: parseInt(value) }))}
                    type="radio"
                  >
                    {["5", "10", "20", "50", "100", "200"].map((value) => (
                      <MenuItemOption key={value} value={value}>
                        {value}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </FormControl>
          )}
          <FormControl>
            <HStack justify="space-between" mb="8px">
              <Text fontSize="sm" fontWeight="500">
                Colunas
              </Text>
              <Button size="xs" onClick={handleResetColumns}>
                restaurar colunas
              </Button>
            </HStack>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                  <VStack {...provided.droppableProps} ref={provided.innerRef} align="stretch">
                    {_.map(formData.columns, (item, index) => (
                      <Draggable key={item.accessor} draggableId={item.accessor} index={index}>
                        {(provided, snapshot) => (
                          <ListItem
                            {...{ provided, snapshot }}
                            sorting={formData.sort?.[item.accessor]}
                            accessor={item.accessor}
                            title={item.title}
                            isSortable={item.isSortable}
                            isVisible={item.isVisible}
                            onChangeSort={handleChangeColumnSort}
                            onChangeVisibility={handleChangeColumnVisibility}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </VStack>
                )}
              </Droppable>
            </DragDropContext>
          </FormControl>
        </DrawerBody>
        <Divider />
        <DrawerFooter as={HStack} justify="flex-end">
          <Button size="sm" variant="outline" onClick={onClose}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="main" onClick={handleSubmit}>
            salvar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
});
