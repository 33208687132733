import { useState, useCallback, useEffect } from "react";
import _ from "lodash";
import { api } from "lib";
import { useCustomToast } from "./useCustomToast";

const defaults = {
  params: {},
  options: { isNil: true, isEnabled: true },
};

export const useApiGet = ({ path, params = defaults.params, options = defaults.options }) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(options.isEnabled);
  const [error, setError] = useState(null);
  const [timestamp, setTimestamp] = useState(Date.now());
  const toast = useCustomToast();

  useEffect(() => {
    const isValidUrl = () => {
      const pieces = path.split("/");
      const filtered = _.filter(pieces, (o) => ["undefined", "null"].indexOf(o) === -1);
      if (!options.isNil) return true;
      if (pieces.length === filtered.length) return true;
      return false;
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        if (isValidUrl()) {
          const response = await api.get(path, { params });
          setResponse(response);
          setError(null);
        }
      } catch (error) {
        if (!error.handled) toast({ description: error.message, status: "error", isClosable: true });
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    options.isEnabled && fetchData();
  }, [path, params, options, timestamp]);

  const refresh = useCallback(() => setTimestamp(Date.now()), []);

  return [response, loading, refresh, error];
};
