import React, { memo, useCallback, useContext, useMemo } from "react";
import _ from "lodash";
import { Box, Heading, HStack, Grid, GridItem, Text, Icon, Center, Spinner } from "@chakra-ui/react";
import { translator } from "lib";
import { BsLock, BsUnlock } from "react-icons/bs";
import { UsersDetailsContext } from ".";
import { useApiGet } from "hooks";

const RoleItem = memo(({ id, description, roles, onClick }) => {
  const index = useMemo(() => _.findIndex(roles, (role) => role === id), [id, roles]);
  const props = useMemo(
    () =>
      index !== -1 && {
        borderColor: "main.600",
        bg: "main.500",
        color: "white",
        _hover: { bg: "main.600" },
      },
    [index]
  );
  return (
    <GridItem colSpan={{ base: 12, lg: 6 }}>
      <HStack
        key={id}
        p="20px"
        borderWidth="2px"
        borderRadius="lg"
        cursor="pointer"
        transition="200ms"
        spacing="20px"
        _hover={{ bg: "blackAlpha.100" }}
        onClick={() => onClick(id, index)}
        {...props}
      >
        <Icon as={index !== -1 ? BsUnlock : BsLock} boxSize="20px" />
        <Box>
          <Heading size="sm">{translator(id)}</Heading>
          <Text fontSize="xs">{description}</Text>
        </Box>
      </HStack>
    </GridItem>
  );
});

const Roles = () => {
  const [roles, isLoadingRoles] = useApiGet(useMemo(() => ({ path: "/roles" }), []));
  const { formData, setFormData } = useContext(UsersDetailsContext);

  const handleToggle = useCallback(
    (id, index) => {
      setFormData((state) => {
        const roles = [...state.roles];
        if (index === -1) roles.push(id);
        else roles.splice(index, 1);
        return { ...state, roles };
      });
    },
    [setFormData]
  );

  return (
    <>
      {isLoadingRoles && (
        <Center>
          <Spinner />
        </Center>
      )}
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        {_.map(roles, ({ id, description }) => (
          <RoleItem key={id} id={id} description={description} roles={formData.roles} onClick={handleToggle} />
        ))}
      </Grid>
    </>
  );
};

export default Roles;
