export const product = {
  _id: "61c9ed751b79e8634dcfdd17",
  nid: 1234,
  sku: "BKR1234",
  name: "Biker Leather Jacket",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  costPrice: 80,
  wholesalePrice: 140,
  retailPrice: 160,
};

export const pack = {
  _id: "61c9ed751b79e8634dcfdd17",
  nid: 1234,
  agent: { name: "João Silveira" },
  dealer: { name: "Maria Clara Santos" },
  loadedAmount: 7894,
  loadedQuantity: 1234,
  currentAmount: 4567,
  currentQuantity: 1234,
};
